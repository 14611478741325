import React, { FC } from "react";

import { Address, Label, Wrapper } from "./styles";
import { CurrentAddressProps } from "./types";

const CurrentAddress: FC<CurrentAddressProps> = ({ address, label }) => {
  return (
    <Wrapper className="mb-30">
      <Label className="choose_address-label" id="addressdelivery">
        {label}
      </Label>
      <Address>{address}</Address>
    </Wrapper>
  );
};
export default CurrentAddress;
