import styled from "styled-components";

import { Box, Text, Link, UserInfoForm } from "src/components";

export const Container = styled(Box)`
  max-width: 370px;
  width: 100%;
  margin: 70px auto;

  @media screen and (max-width: 480px) {
    margin: 60px auto;
  }
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0.01em;
  margin: 0;
  text-align: center;
`;

export const Description = styled(Text)`
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  margin: 25px 0 0;
`;

export const Skip = styled(Link)`
  font-size: 14px;
  text-align: center;
  color: ${({ theme }): string => theme.colors.grey};
  margin-top: 32px;
  display: block;
`;

export const Form = styled(UserInfoForm)`
  margin-top: 60px;

  @media screen and (max-width: 480px) {
    margin-top: 40px;
  }
`;
