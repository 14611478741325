import styled from "styled-components";

export const DeliveryContainer = styled.div`
  margin-top: 32px;
  width: 100%;
`;

export const DeliveryIconWrapper = styled.div`
  width: 24px;
  height: 24px;
`;

export const DeliveryContainerCard = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  padding: 12px 0 12px 16px;
  border: #e4e5e7 solid 1px;

  &:not(:first-child) {
    border-top: none;
  }
`;

export const DeliveryContainerCardText = styled.div`
  display: block;
  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-wrap: wrap;
  margin-left: 12px;

  color: #000000;

  .underline-text {
    margin-left: 4px;
  }
`;
