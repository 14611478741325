import { merge } from "lodash";
import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
  useMemo,
  FC,
} from "react";
import TagManager from "react-gtm-module";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import { getCatalog } from "src/api";
import {
  CatalogList,
  Select,
  CatalogFilters,
  MobileCatalogFilters,
  Icons,
  FilterTags,
  Link,
  BrandInfo,
  Preloader,
} from "src/components";
import {
  deserializeFilters,
  serializeFilters,
} from "src/components/CatalogFilters/CatalogFilters.utils";
import { HEADER_CLASS } from "src/components/Header/Header.contants";
import { MobileCatalogSorting } from "src/components/MobileCatalogSorting";
import { Portal } from "src/components/Portal";
import { SelectOption } from "src/components/Select/Select.types";
import { ROUTES, stringifyConfig } from "src/constants";
import { useControl, useQueryParams } from "src/hooks";
import { usePreloaderTimer } from "src/hooks/usePreloaderTimer";
import { ApplicationStore } from "src/store";
import { CatalogListData, SectionTree, SeoMeta } from "src/types";
import {
  getBasketId,
  convertImageUrl,
  parseImages,
  parseSortingItems,
} from "src/utils";

import { Garderobo } from "../../../components/Garderobo";

import {
  Container,
  Title,
  BodyContainer,
  FilterContainer,
  ListContainer,
  TabletMenu,
  FilterButton,
  FilterButtonContainer,
  SelectContainer,
  TitleContainer,
  MinusIcon,
  PlusIcon,
  MobileFilterButton,
  ResetFilterButton,
  FilterTagsContainer,
  BreadCrumbs,
  Slash,
  TabletBreadCrumbs,
  MobileBreadCrumbs,
  PrevArrow,
  CategoryName,
  MobileFilterContainer,
  HeaderContainerCollection,
} from "./CatalogList.styles";
import { ItemListElement } from "./CatalogList.types";
import { getTitle, getFilterString, getOtherQuery } from "./CatalogList.utils";

const initialSortingOption = {
  value: undefined,
  label: "По умолчанию",
};

const CatalogListPage: FC<{ isCollection?: boolean }> = ({ isCollection }) => {
  const [isLoaderShown, onStartFetching, onEndFetching] = usePreloaderTimer();

  const { menu } = useSelector(
    (state: ApplicationStore) => state.navigationMenu
  );

  const {
    state: mobileFilterIsOpened,
    toggle: toggleMobileFilter,
    deactivate: closeMobileFilter,
  } = useControl();

  const {
    state: mobileSortingIsOpened,
    toggle: toggleMobileSoring,
    deactivate: closeMobileSorting,
  } = useControl();

  const [catalogData, setCatalogData] = useState<CatalogListData>();
  const [metaData, setMetaData] = useState<SeoMeta[]>([]);
  const [itemListElements, setItemListElements] = useState<ItemListElement[]>(
    []
  );
  const [currentBanner, setCurrentBanner] = useState<string>("");

  const [filterIsOpened, setOpenFilter] = useState(true);

  const history = useHistory();
  const { pathname } = useLocation();

  const toggleFilter = () => {
    setOpenFilter(!filterIsOpened);
    TagManager.dataLayer({
      dataLayer: {
        event: "filters",
        content: !filterIsOpened,
        userId: userData?.userId,
      },
    });
  };

  const getOgImage = useMemo(() => {
    if (catalogData?.brand?.gallery) {
      const tempImage = parseImages(catalogData?.brand?.gallery);
      return convertImageUrl(tempImage[0], 1200, 630);
    } else {
      return "https://leback.leform.ru/img/storage/logo2.png";
    }
  }, [catalogData, pathname]);

  useEffect(() => {
    if (catalogData) {
      const defaultTitle = "Каталог LEFORM";
      const defaultDescription =
        "Каталог. Leform: концептуальный интернет-магазин одежды, обуви, аксессуаров и предметов интерьера";
      const metaTags = [
        {
          property: "og:title",
          content: catalogData.seo?.title || defaultTitle,
        },
        {
          property: "og:description",
          content: catalogData.seo?.description || defaultDescription,
        },
        {
          property: "og:image",
          content: getOgImage,
        },
        {
          name: "description",
          content: catalogData.seo?.description || defaultDescription,
        },
      ];
      setMetaData(metaTags);
    }
  }, [catalogData, getOgImage]);

  const title = useMemo(() => {
    if (
      catalogData &&
      catalogData?.section_tree &&
      catalogData.section_tree.length > 0
    ) {
      const lastItem =
        catalogData?.section_tree[catalogData?.section_tree.length - 1];

      if (lastItem.alter_title) {
        return lastItem.alter_title;
      } else {
        return lastItem.name;
      }
    }

    if (pathname.indexOf("catalog") > -1) {
      return getTitle(pathname, menu);
    }

    return catalogData?.brand?.name || "Бренды";
  }, [pathname, catalogData, menu]);

  const {
    queryParams,
    setQueryParams,
    getNewPath,
    clearUrl,
  } = useQueryParams();

  const filterString = getFilterString(queryParams);

  const [loadMorePage, setLoadMorePage] = useState(0);
  const [isLoadAdd, setIsLoadAdd] = useState(false);
  const [postfixTitle, setPostfixTitle] = useState("");

  const [sorting, setSorting] = useState<SelectOption>(initialSortingOption);
  const [sortPerPage, setSortPerPage] = useState<SelectOption>(
    initialSortingOption
  );
  const handleChangeSorting = useCallback(
    (option: SelectOption) => {
      if (option.label !== "По умолчанию") {
        handleSetQueryParams(option, true);
      } else {
        setSorting(initialSortingOption);
        history.replace({ search: "" });
      }
    },
    [catalogData, queryParams, setQueryParams]
  );

  const handleMobileChangeSorting = useCallback(
    (option: SelectOption) => {
      if (option.label !== "По умолчанию") {
        handleSetQueryParams(option, true);
      } else {
        setSorting(initialSortingOption);
        history.replace({ search: "" });
      }
      closeMobileSorting();
    },
    [catalogData, queryParams, setQueryParams]
  );

  const sortingItems = useMemo(
    () => parseSortingItems(catalogData?.settings?.sort.allowed),
    [catalogData]
  );

  const [showPreloader, setShowPreloader] = useState(true);
  const [isLoadingLoaderButton, setIsLoadingLoaderButton] = useState(false);

  const showLoader = () => {
    if (showPreloader === true) {
      onStartFetching(500);
    } else {
      setIsLoadingLoaderButton(true);
    }
  };

  const { info, userData } = useSelector(
    (state: ApplicationStore) => state.user
  );
  const checkoutId = useMemo(() => getBasketId() || info?.checkoutId, [info]);
  async function getData() {
    showLoader();
    const seoFilter = JSON.stringify(getNewPath(pathname));
    await getCatalog(
      `${clearUrl()}${filterString}`,
      {
        page: queryParams.page ? queryParams.page : 1,
        sort: queryParams.sort,
        q: queryParams.q,
        per_page: queryParams.per_page,
        seo_filter_param: seoFilter,
        base_sort: queryParams.base_sort,
      },
      checkoutId
    )
      .then(({ data }) => {
        if (isLoadAdd && data.categoryBanner) {
          data.categoryBanner.position += Number(sortPerPage?.value);
        }
        if (data.categoryBanner) {
          data.categoryBanner.position = loadMorePage
            ? (loadMorePage - 1) * (Number(sortPerPage?.value) || 60) +
              data.categoryBanner.position -
              loadMorePage
            : data.categoryBanner.position;
        }
        const addCatBannerArray = data.categoryBanner
          ? [data.categoryBanner]
          : [];
        if (isLoadAdd) {
          const array = catalogData?.items || [];
          data.items = array.concat(data.items);
          const array2 = catalogData?.categoryBanners || [];
          data.categoryBanners = array2.concat(addCatBannerArray);
        } else {
          data.categoryBanners = addCatBannerArray;
        }
        setCatalogData(data);
      })
      .catch((error) => {
        const errorCode = error?.response?.status;

        if (errorCode === 400 || errorCode === 404) {
          if (
            pathname.indexOf("catalog") > -1 ||
            pathname.indexOf("brands") > -1
          ) {
            const newArr = [...metaData];

            const hasRedirect = newArr.find(
              (meta) => meta.name === "prerender-status-code"
            );

            if (!hasRedirect) {
              newArr.push({
                name: "prerender-status-code",
                content: "301",
              });
              newArr.push({
                name: "prerender-header",
                content: ROUTES.catalog,
              });
              setMetaData(newArr);
            }
            history.push({
              pathname: ROUTES.page404,
            });
          }
        }
      })
      .finally(() => {
        setIsLoadAdd(false);
      });

    setShowPreloader(true);
    setIsLoadingLoaderButton(false);
    onEndFetching();
  }

  useEffect(() => {
    getData();
  }, [pathname, filterString, queryParams, checkoutId]);
  useEffect(() => {
    if (catalogData) {
      const scroll = sessionStorage.getItem("_scrollPosition_");
      const isProductBack = sessionStorage.getItem("goBack");
      if (isProductBack && scroll) {
        window.scrollTo(0, +scroll);
      }
      sessionStorage.removeItem("goBack");
    }
  }, [catalogData]);

  function prepagePerPage(Array: []) {
    if (Array.length)
      return Array.map((item) => {
        return {
          value: item,
          label: item,
        };
      });

    return [];
  }

  useEffect(() => {
    setSortPerPage({
      value: queryParams.per_page + "",
      label: queryParams.per_page + "",
    });
  }, [queryParams.per_page]);
  const sortingPerPage = useMemo(() => {
    setSortPerPage({
      value: `${
        queryParams.per_page
          ? queryParams.per_page
          : catalogData?.settings?.per_page.default
      }`,
      label: `${
        queryParams.per_page
          ? queryParams.per_page
          : catalogData?.settings?.per_page.default
      }`,
    });
    return prepagePerPage(catalogData?.settings?.per_page.allowed || []);
  }, [catalogData]);

  function handleSetQueryParams(
    option: any, //  eslint-disable-line @typescript-eslint/no-explicit-any
    isSort: boolean
  ) {
    let queryValue = undefined;
    const regexpString = isSort ? /per_page=[0-9]+/g : /sort=[a-z]+/g;
    if (window.location.search.match(regexpString)) {
      const macthed = window.location.search.match(regexpString);
      const query = macthed ? macthed[0] : "";
      queryValue = query.length ? query.split("=")[1] : undefined;
    }
    const filterNew = catalogData?.settings.filter || [];
    const addParams: any = getNewPath(window.location.pathname); //  eslint-disable-line @typescript-eslint/no-explicit-any

    const deserializedFilters = deserializeFilters(
      queryParams,
      filterNew,
      addParams
    );

    const newFilters = serializeFilters(merge(deserializedFilters), filterNew);

    setQueryParams(
      {
        ...newFilters,
        page: undefined,
        per_page: isSort ? queryValue : option.value,
        sort: isSort ? option.value : queryValue,
      },
      false
    );
  }

  function isMobileHiddenHeader() {
    return window.location.href.includes("wishlist-sobchak");
  }

  const handleSortPerPage = useCallback(
    (option: SelectOption) => {
      handleSetQueryParams(option, false);
    },
    [catalogData, queryParams, setSortPerPage]
  );
  const resetFilters = () => {
    const otherQuery = getOtherQuery(queryParams);
    setQueryParams({ ...otherQuery, page: undefined }, true, stringifyConfig);
  };

  useEffect(() => {
    if (!!catalogData?.settings?.sort) {
      const newSorting = sortingItems.find(
        (item) => item.value === queryParams.sort
      );
      setSorting(newSorting || initialSortingOption);
    }
  }, [queryParams.sort, sortingItems, catalogData]);

  const breadCrumbsJsx = (
    <>
      <li
        itemProp="itemListElement"
        itemScope
        itemType="http://schema.org/ListItem"
      >
        <Link itemProp="item" to="/">
          <span itemProp="name">Leform</span>
          <meta itemProp="position" content="1" />
        </Link>
      </li>
      <Slash>/</Slash>
      {catalogData &&
      catalogData?.section_tree &&
      catalogData.section_tree.length > 0 ? (
        <>
          <li
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ListItem"
          >
            <Link itemProp="item" to="/catalog">
              <span itemProp="name">Каталог</span>
              <meta itemProp="position" content="1" />
            </Link>
          </li>
          <Slash>/</Slash>
          {catalogData?.section_tree?.map(
            (item: SectionTree, idx: number, array: SectionTree[]) => {
              if (idx !== array.length - 1) {
                return (
                  <li
                    itemProp="itemListElement"
                    itemScope
                    itemType="http://schema.org/ListItem"
                    key={`position_1_${item.id}`}
                  >
                    <Fragment key={"cat_" + item.id}>
                      <Link itemProp="item" to={`/catalog/${item.url}`}>
                        <span itemProp="name">{item.name}</span>
                        <meta itemProp="position" content={idx.toString()} />
                      </Link>
                      <Slash>/</Slash>
                    </Fragment>
                  </li>
                );
              }
              return (
                <li
                  itemProp="itemListElement"
                  itemScope
                  itemType="http://schema.org/ListItem"
                  key={`position_2_${item.id}`}
                >
                  <meta itemProp="position" content="1" />
                  <CategoryName itemProp="name" key={"cat_" + item.id}>
                    {item.name}
                  </CategoryName>
                </li>
              );
            }
          )}
        </>
      ) : (
        <CategoryName itemProp="name">Каталог</CategoryName>
      )}
    </>
  );

  useEffect(() => {
    if (catalogData?.section_tree && catalogData.section_tree.length > 1) {
      if (catalogData.section_tree[0]?.code === "man") {
        setPostfixTitle(" для мужчин");
      }

      if (catalogData.section_tree[0]?.code === "woman") {
        setPostfixTitle(" для женщин");
      }
    }
  }, [catalogData]);

  const pageTitle = useMemo(() => {
    if (catalogData && catalogData?.seo.seoh1) {
      return catalogData?.seo.seoh1;
    }

    return title + postfixTitle;
  }, [catalogData, title, postfixTitle]);

  useEffect(() => {
    if (catalogData?.section_tree && catalogData.section_tree.length > 0) {
      if (catalogData.section_tree.length === 1) {
        const currentCategory = catalogData.section_tree[0];
        if (currentCategory.code) {
          TagManager.dataLayer({
            dataLayer: {
              pagetype: "category",
              categoryId: currentCategory.code,
            },
          });
        }
      } else {
        const currentCategory =
          catalogData.section_tree[catalogData.section_tree.length - 1];
        if (currentCategory.id) {
          TagManager.dataLayer({
            dataLayer: {
              pagetype: "category",
              categoryId: currentCategory.id,
            },
          });
        }
      }
      TagManager.dataLayer({
        dataLayer: {
          event: "page-view-mindbox",
        },
      });
    }

    if (catalogData?.brand) {
      TagManager.dataLayer({
        dataLayer: {
          pagetype: "brand",
          brandCode: catalogData.brand.code,
        },
      });

      TagManager.dataLayer({
        dataLayer: {
          event: "page-view-brand-mindbox",
        },
      });
    }
  }, [catalogData]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  }, []);

  useEffect(() => {
    const newItems: ItemListElement[] = catalogData?.items
      ? catalogData.items.map(
          (item): ItemListElement => ({
            "@type": "Offer",
            name: item.name,
            description: item.detail_text
              ? item.detail_text.replace(/<\/?p>/g, "")
              : "",
            url: item.link,
            price: item.new_price.toString(),
            priceCurrency: "RUB",
            image: convertImageUrl(parseImages(item.gallery)[0], 1200, 1200),
            availability: "https://schema.org/InStock",
          })
        )
      : [];
    setItemListElements(newItems);
  }, [catalogData?.items]);

  useEffect(() => {
    if (!catalogData || catalogData.brand) {
      return;
    }
    const pagetype = "category";
    let categoryIds: number[] | number = [];
    let gender: null | string = null;

    if (catalogData.section_tree) {
      if (catalogData.section_tree.length === 0) {
        categoryIds = [99, 100, 101, 102];
      }

      if (catalogData.section_tree.length === 1) {
        if (catalogData.section_tree[0]?.code === "man") {
          categoryIds = [99, 100, 101, 102];
          gender = "male";
        } else if (catalogData.section_tree[0]?.code === "woman") {
          categoryIds = [99, 100, 101, 102];
          gender = "female";
        } else if (
          catalogData.section_tree[0]?.code === "sale" ||
          catalogData.section_tree[0]?.code === "new"
        ) {
          categoryIds = [99, 100, 101, 102];
        } else if (catalogData.section_tree[0]?.code === "interior") {
          categoryIds = [163, 109, 107, 105, 106, 108, 114];
        }
      }

      if (catalogData.section_tree.length > 1) {
        if (catalogData.section_tree[0]?.code === "man") {
          gender = "male";
        } else if (catalogData.section_tree[0]?.code === "woman") {
          gender = "female";
        }
        categoryIds =
          catalogData.section_tree[catalogData.section_tree.length - 1]?.id;
      }
    }
    TagManager.dataLayer({
      dataLayer: {
        categoryIds: categoryIds,
        pagetype: pagetype,
        gender: gender,
      },
    });

    TagManager.dataLayer({
      dataLayer: {
        event: "page-view-category-garderobo",
      },
    });
  }, [catalogData]);

  useEffect(() => {
    if (catalogData?.brand) {
      const { gallery } = catalogData.brand;
      setCurrentBanner(
        gallery ? convertImageUrl(parseImages(gallery)[0], 1200, 1200) : ""
      );
    } else {
      const banner = menu.find(
        (category) =>
          catalogData?.section_tree &&
          category.label === catalogData?.section_tree[0]?.name
      )?.banner;
      if (banner) {
        const { gallery } = banner;
        setCurrentBanner(convertImageUrl(parseImages(gallery)[0], 1200, 1200));
      }
    }
  }, [catalogData]);

  const schema = {
    "@context": "https://schema.org",
    "@type": "OfferCatalog",
    name: pageTitle,
    image: currentBanner,
    description: metaData.find((meta) => meta.name === "description")?.content,
    itemListElement: itemListElements,
  };

  return (
    <>
      <HelmetProvider>
        <Helmet
          defer={false}
          title={catalogData?.seo?.title || "Каталог LEFORM"}
          meta={metaData}
        />
      </HelmetProvider>
      {!catalogData?.brand && <TabletMenu />}

      {!catalogData && <Preloader />}

      {catalogData?.brand?.name && catalogData?.brand?.id && (
        <BrandInfo
          name={catalogData.brand.name}
          text={catalogData.brand.detail_text}
          gallery={catalogData.brand.gallery}
        />
      )}

      <Container>
        <HeaderContainerCollection isCollection={isCollection}>
          {filterString && (
            <ResetFilterButton onClick={resetFilters}>
              <Icons.Close /> Сбросить фильтр
            </ResetFilterButton>
          )}
          {filterIsOpened}

          <FilterButtonContainer>
            <FilterButton
              onClick={toggleFilter}
              variant="white"
              filterIsOpened={filterIsOpened}
              id="filter"
            >
              {!filterIsOpened && (
                <>
                  Показать фильтр
                  <PlusIcon />
                </>
              )}

              {filterIsOpened && (
                <>
                  Скрыть фильтр
                  <MinusIcon />
                </>
              )}
            </FilterButton>
          </FilterButtonContainer>
          <Portal
            rootElement={document.querySelector(`.${HEADER_CLASS} .headroom`)}
          >
            <MobileFilterContainer>
              <MobileFilterButton onClick={toggleMobileFilter} variant="white">
                Фильтр
              </MobileFilterButton>
              <MobileFilterButton onClick={toggleMobileSoring} variant="white">
                Сортировка
              </MobileFilterButton>
            </MobileFilterContainer>
          </Portal>
          {!catalogData?.brand && (
            <MobileBreadCrumbs>
              {catalogData?.section_tree?.map(
                (item: SectionTree, idx: number, array: SectionTree[]) => {
                  if (idx === array.length - 2) {
                    return (
                      <Fragment key={`fragment_${item.id}`}>
                        <Link to={`/catalog/${item.url}`}>
                          <PrevArrow />
                          {item.name}
                        </Link>
                      </Fragment>
                    );
                  }
                }
              )}
            </MobileBreadCrumbs>
          )}
          <TitleContainer isHidden={isMobileHiddenHeader()}>
            <Title>
              {!isLoaderShown && pageTitle}
              {!isLoaderShown &&
                queryParams.q &&
                `Найдено по запросу: ${String(
                  queryParams.q
                ).toLocaleUpperCase()}`}
            </Title>
            {!catalogData?.brand && (
              <BreadCrumbs
                itemScope
                itemType="http://schema.org/BreadcrumbList"
              >
                {breadCrumbsJsx}
              </BreadCrumbs>
            )}
          </TitleContainer>
          {!catalogData?.brand && (
            <TabletBreadCrumbs>{breadCrumbsJsx}</TabletBreadCrumbs>
          )}

          {sortingItems.length > 0 && (
            <SelectContainer>
              <Select
                options={sortingItems}
                className="sorting"
                value={sorting}
                onChange={handleChangeSorting}
              />
              <Select
                className="width-content"
                options={sortingPerPage}
                value={sortPerPage}
                onChange={handleSortPerPage}
              />
            </SelectContainer>
          )}
        </HeaderContainerCollection>

        {!!catalogData && filterString && (
          <FilterTagsContainer>
            <FilterTags filters={catalogData.settings.filter} />
          </FilterTagsContainer>
        )}

        <BodyContainer>
          {filterIsOpened && (
            <FilterContainer>
              {!!catalogData && (
                <CatalogFilters
                  filters={catalogData.settings.filter}
                  filterPrice={catalogData.filterPrice}
                  isLoading={isLoaderShown}
                />
              )}
            </FilterContainer>
          )}

          {mobileFilterIsOpened && !!catalogData && (
            <MobileCatalogFilters
              onClose={closeMobileFilter}
              resetFilters={resetFilters}
              filters={catalogData.settings.filter}
              filterPrice={catalogData.filterPrice}
              isLoading={isLoaderShown}
              className="mobileBox"
            />
          )}

          {mobileSortingIsOpened && !!catalogData && (
            <MobileCatalogSorting
              onClose={closeMobileSorting}
              isLoading={isLoaderShown}
              sortingItems={sortingItems}
              sortingValue={sorting}
              onChangeSorting={setSorting}
              onAcceptSorting={handleMobileChangeSorting}
              className="mobileBox"
            />
          )}

          <ListContainer>
            <CatalogList
              data={catalogData}
              page={queryParams.page ? +queryParams.page : 1}
              loadMorePage={loadMorePage}
              catalogClassName={"catalog"}
              isPaginationVisible={true}
              isLoading={isLoaderShown}
              resetFilters={resetFilters}
              checkoutId={checkoutId}
              isLoadingLoaderButton={isLoadingLoaderButton}
              loadMore={() => {
                setShowPreloader(false);
                setIsLoadAdd(true);
                setLoadMorePage(loadMorePage + 1);
                setQueryParams(
                  {
                    page: (queryParams.page ? +queryParams.page : 1) + 1,
                  },
                  false
                );
              }}
              perPage={Number(sortPerPage?.value) || 0}
            />
          </ListContainer>
        </BodyContainer>
        <Garderobo />
      </Container>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </>
  );
};

export default CatalogListPage;
