import React, { FC } from "react";

import { ROUTES } from "src/constants";

import {
  Container,
  Title,
  Description,
  ButtonContainer,
  Button,
} from "./ResetType.styles";

const ResetTypePage: FC = () => {
  return (
    <Container>
      <Title>Восстановление пароля</Title>
      <Description>Выберите способ восстановления пароля</Description>
      <ButtonContainer>
        <Button to={ROUTES.resetByPhone}>ПО НОМЕРУ ТЕЛЕФОНА</Button>
        <Button to={ROUTES.resetByEmail}>ПО E-MAIL</Button>
      </ButtonContainer>
    </Container>
  );
};

export default ResetTypePage;
