import styled from "styled-components";

import { Icons } from "src/components";
import Document from "src/components/Icons/Glyphs/Document";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const Container = styled.div`
  position: fixed;
  text-align: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 32px 28px 28px 28px;
  border-radius: 8px;
  z-index: 1000;
  width: 500px;
  overflow-y: auto;
  max-height: 90vh;

  @media (max-width: 767px) {
    width: 350px;
  }
`;

export const ReturnModalButton = styled.button`
  position: absolute;
  padding: 4px;
  top: 13px;
  right: 13px;
  background: none;
  border: none;
`;

export const CloseIcon = styled(Icons.Close)`
  :hover {
    fill: ${({ theme }) => theme.colors.darkGrey};
  }
  :active {
    fill: ${({ theme }) => theme.colors.grey};
  }
`;

export const CloseButton = styled.button`
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  padding: 9px 102px;
  width: 444px;
  height: 48px;

  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000000;
`;

export const BoldText = styled.span`
  font-weight: 600;
`;

export const Title = styled.div`
  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-weight: 550;
  font-size: 26px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #000000;

  margin-bottom: 8px;
`;

export const ReturnLink = styled.a`
  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.1em;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #000000;
`;

export const DocumentIcon = styled(Document)`
  margin-left: 9px;
  transform: translateY(3px);
`;

export const ReturnLinkWrapper = styled.div`
  margin: 8px 0 0;
`;

export const SubTitle = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #000000;

  margin: 24px 0 8px;

  @media (max-width: 767px) {
    margin: 18px 0 8px;
  }
`;

export const Text = styled.div`
  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #000000;
  margin-top: 3px;
`;

export const QuestionsText = styled(Text)`
  margin: 24px 0;

  @media (max-width: 767px) {
    margin: 18px 0 20px;
  }
`;

export const PhoneLink = styled.a`
  font-weight: 600;
  color: #000000;
  text-decoration-line: none;
  white-space: nowrap;
`;
