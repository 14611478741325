import React, { FC, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { Element, scroller } from "react-scroll";

import { getPageContent } from "src/api";
import { Preloader } from "src/components";
import { ShopItem } from "src/components/ShopItem";
import { ShopItemProps } from "src/components/ShopItem/ShopItem.types";
import { SeoMeta } from "src/types";
import { generateMetaTags } from "src/utils";

import { Container, ShopsHeader, ShopList } from "./ShopPage.styles";

type IProps = {
  url: string;
};

const ShopPage: FC<IProps> = ({ url }) => {
  const { hash } = useLocation();
  const [content, setContent] = useState<any | null>(""); //  eslint-disable-line @typescript-eslint/no-explicit-any
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const seoTitle = "Магазины";
  const seoDescription =
    "Магазины.⚑ В официальном интернет-магазине LEFORM вы можете купить Ботинки для мужчин по цене от 16790 руб. LEFORM – это оригинальные товары ▶ Скидки ▶ Магазины в центре Москвы ✈ Быстрая доставка по Москве и России.";
  const metaTags: SeoMeta[] = generateMetaTags(seoTitle, seoDescription);

  useEffect(() => {
    setIsLoading(true);
    getPageContent(url)
      .then(({ data }) => {
        setContent(data);
      })
      .finally(() => setIsLoading(false));
  }, [url]);

  useEffect(() => {
    if (hash) {
      scroller.scrollTo(hash, {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset: -160,
      });
    }
  }, [hash]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet defer={false} title={seoTitle} meta={metaTags} />
      </HelmetProvider>
      {isLoading ? (
        <Preloader />
      ) : (
        content && (
          <Container>
            <ShopsHeader>Наши магазины</ShopsHeader>
            <ShopList>
              {content.map((shop: ShopItemProps) => (
                <Element name={`#shop${shop.id}`} key={shop.id}>
                  <ShopItem shop={shop} />
                </Element>
              ))}
            </ShopList>
          </Container>
        )
      )}
    </>
  );
};

export default ShopPage;
