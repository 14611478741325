import React, { FC } from "react";
import "./styles.css";

type props = {
  handleClick: () => void;
};
export const EmptyCookie: FC<props> = ({ handleClick }) => {
  return (
    <div className="empty_cookie">
      <p className="empty_cookie-p">
        Мы используем cookies для наилучшего представления нашего сайта.
        <span> Нажмите OK, чтобы скрыть это сообщение.</span>
      </p>
      <button
        onClick={() => {
          window.localStorage.setItem("isAcceptCookie", "true");
          handleClick();
        }}
        className="empty_cookie-btn"
      >
        ОК
      </button>
    </div>
  );
};
