import styled from "styled-components";

import { Flex } from "../Flex";
import { Icons } from "../Icons";
import { Link } from "../Link";

export const Container = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  padding: 20px 40px;
  align-items: center;

  @media screen and (max-width: 1440px) {
    padding: 15px 40px;
  }

  @media screen and (max-width: 1200px) {
    padding: 5px 40px;
  }

  @media screen and (max-width: 768px) {
    padding: 30px 40px 5px;
  }

  @media screen and (max-width: 600px) {
    padding: 25px 50px 0;
  }
`;

export const NavContainer = styled(Flex)`
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: space-around;
  }

  @media screen and (max-width: 600px) {
    justify-content: space-between;
  }
`;

export const NavLink = styled(Link)`
  font-size: 18px;
  color: ${({ theme }): string => theme.colors.black};
  margin-right: 60px;
  font-weight: 500;

  &:hover {
    color: ${({ theme }): string => theme.colors.grey};
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    text-align: center;
    margin: 0;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

export const SocialContainer = styled(Flex)`
  align-items: center;
  font-size: 18px;
  color: ${({ theme }): string => theme.colors.black};
  font-weight: 500;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const SocialLink = styled.a`
  margin: 0 0 0 28px;
  text-decoration: none;

  :hover {
    opacity: 0.5;
  }
`;

export const VkIcon = styled(Icons.Vk)`
  width: 25px;
  height: 25px;
`;

export const TgIcon = styled(Icons.Tg)`
  width: 25px;
  height: 25px;
`;
