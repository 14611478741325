import React, { FC, useState, useCallback, FormEvent, useEffect } from "react";
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { setQuantity } from "src/actions";
import { fastOrder, loginUser } from "src/api";
import { ROUTES } from "src/constants";
import { useForm } from "src/hooks";
import { ApplicationStore } from "src/store";
import {
  formatCurrency,
  getBasketId,
  getCookie,
  convertImageUrl,
} from "src/utils";

import {
  Count,
  CountButton,
  MinusIcon,
  PlusIcon,
  ProductCount,
} from "../CheckoutItem/CheckoutItem.styles";

import {
  BuyButton,
  Conditions,
  Input,
  FastOrderElem,
  FastOrderText,
  FastOrderWrap,
  FastOrderImage,
} from "./AuthDataForm.styles";
import { AuthDataFormTypes, UserValues } from "./AuthDataForm.types";
import { validateAuthDataValues } from "./AuthDataForm.utils";

const AuthDataForm: FC<AuthDataFormTypes> = ({
  offerId,
  setOrderStep,
  setOrderNumber,
  setUserName,
  setLoading,
  products,
  price,
  isLoading,
}) => {
  const { userData, info } = useSelector(
    (state: ApplicationStore) => state.user
  );

  const initialValues: UserValues = {
    name: userData?.userName || "",
    phone: userData?.phone || "",
    token: "",
  };

  const dispatch = useDispatch();
  const { quantity, new_price, old_price, name, gallery, size } = products[0];
  const imageSrc = gallery && gallery.length ? gallery[0] : "";

  const [new_quantity, setNewQuantity] = useState(quantity);
  const [cid, setCID] = useState("");
  const { values, onChange, setValues, errors, setErrors } = useForm(
    initialValues
  );

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.onload = handleLoad;

    document.getElementsByTagName("head")[0].appendChild(script);
  }, []);
  function handleLoad() {
    grecaptcha.ready(() =>
      grecaptcha.render("html_element", {
        sitekey: "6LeDjJcmAAAAAJf88E5LHqqeaF7Zh3aC4BmKJSGz",
        theme: "dark",
        callback: verifyCallback,
        "expired-callback": clearToken,
        "error-callback": clearToken,
      })
    );
  }
  const verifyCallback = (token_google: string) => {
    setValues({
      token: token_google,
    });
  };
  const clearToken = () => {
    setValues({
      token: "",
    });
  };
  useEffect(() => {
    if (!cid) {
      const cidC = getCookie("_ga")?.slice(6);
      if (cidC) {
        setCID(cidC);
      }
    }
  }, [cid]);

  const updateQuantity = useCallback(
    (quantity) => {
      dispatch(setQuantity({ new_quantity: quantity }));
    },
    [dispatch]
  );
  const onChangeCount = useCallback(
    (side: string) => {
      const checkoutId = info?.checkoutId || getBasketId();
      if (checkoutId) {
        let count: number = new_quantity;
        if (side === "plus") {
          count++;
        }

        if (side === "minus") {
          count--;
          count = count === 0 ? 1 : count;
        }
        setNewQuantity(count);
        updateQuantity(count);
      }
    },
    [info, new_quantity, updateQuantity]
  );

  const onSendOrder = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const errorsForm = validateAuthDataValues(values);

      if (!values.token.length) {
        setErrors({
          token: "Подтвердите, что вы не робот",
        });
        return;
      }

      if (Object.keys(errorsForm).length !== 0) {
        setErrors(errorsForm);
        return;
      }

      if (!userData) {
        setLoading(true);
        const phoneValue = values.phone.replace(/[^0-9]/g, "");
        loginUser({
          phone: phoneValue,
          basket_uuid: getBasketId() || undefined,
          token: values.token,
        })
          .then(({ data }) => {
            setUserName(values.name);
            sessionStorage.setItem("phone", phoneValue);
            sessionStorage.setItem("use_external", data.use_external);
            setOrderStep(1);
            setLoading(false);
          })
          .catch((error) => {
            setValues(initialValues);
            setErrors({
              phone: error.messages,
            });
            setLoading(false);
          });
      } else {
        setLoading(true);
        fastOrder({
          offer_id: offerId,
          name: values.name,
          phone: values.phone,
          quantity: new_quantity || 1,
          cid,
        }).then(({ data }) => {
          TagManager.dataLayer({
            dataLayer: {
              event: "purchase",
              ecommerce: {
                purchase: {
                  actionField: {
                    id: data.order_id,
                    affiliation: "fast",
                    revenue: price,
                    shipping: 0,
                    coupon: "",
                  },
                  products: products.map(
                    ({
                      name,
                      id,
                      new_price,
                      old_price,
                      brand,
                      category,
                      size,
                      quantity,
                    }) => {
                      return {
                        name,
                        id,
                        brand,
                        category,
                        size,
                        quantity,
                        price: new_price || old_price,
                        currency: "RUB",
                      };
                    }
                  ),
                },
              },
            },
          });

          setLoading(false);
          setOrderNumber(data.order_id);
          setOrderStep(2);
        });
      }
    },
    [
      cid,
      initialValues,
      new_quantity,
      offerId,
      price,
      products,
      setErrors,
      setLoading,
      setOrderNumber,
      setOrderStep,
      setUserName,
      setValues,
      userData,
      values,
    ]
  );

  return (
    <FastOrderWrap>
      <FastOrderElem>
        <FastOrderImage src={convertImageUrl(imageSrc, 310, 400)} alt={name} />
        <FastOrderText>
          <div className="title">{name}</div>
          <div className="price">
            {new_price < old_price && (
              <span className="old">{formatCurrency(Number(old_price))}</span>
            )}
            <span className="new">{formatCurrency(Number(new_price))}</span>
          </div>
          {size && (
            <div className="size">
              Размер: <strong>{size}</strong>
            </div>
          )}
          <ProductCount>
            <CountButton
              onClick={() => onChangeCount("minus")}
              aria-label="Уменьшить"
              disabled={new_quantity === 1}
            >
              <MinusIcon />
            </CountButton>
            <Count>{new_quantity}</Count>
            <CountButton
              onClick={() => onChangeCount("plus")}
              aria-label="Увеличить"
            >
              <PlusIcon />
            </CountButton>
          </ProductCount>
        </FastOrderText>
      </FastOrderElem>
      <form onSubmit={onSendOrder}>
        <Input
          type="text"
          name="name"
          aria-label="Имя"
          placeholder={errors.name || "Имя"}
          value={values.name}
          onChange={onChange}
          hasError={!!errors.name}
          mask=""
          disabled={isLoading}
        />
        <Input
          type="tel"
          name="phone"
          inputMode="numeric"
          aria-label="Телефон"
          placeholder={errors.phone || "Телефон"}
          value={values.phone}
          onChange={onChange}
          hasError={!!errors.phone}
          mask="+7 (999) 999-99-99"
          disabled={isLoading}
        />
        <BuyButton>Подтвердить</BuyButton>
        <div id="html_element"></div>
        {errors.token && <p className="error_captch">{errors.token}</p>}
        <Conditions>
          Завершая оформление заказа я подтверждаю ознакомление с&nbsp;
          <Link to={ROUTES.privacy}>указанными условиями</Link>
        </Conditions>
      </form>
    </FastOrderWrap>
  );
};

export default AuthDataForm;
