import React, { FC, useState, useEffect } from "react";
import { useParams } from "react-router";

import { getItemOrder, OrderData } from "src/api";
import { Icons, OrderItem } from "src/components";
import { ROUTES } from "src/constants";
import {
  formatDateTime,
  formatDate,
  formatTime,
  formatCurrency,
  convertImageUrl,
} from "src/utils";

import { Loader } from "../MyOrders.styles";

import {
  Container,
  HeaderWrapper,
  Title,
  OrderNumber,
  OrderStatus,
  BackLink,
  BackIcon,
  BackIconMobile,
  OrderList,
  OrderDataContainer,
  OrderParamsContainer,
  OrderPriceContainer,
  ParamsList,
  ParamsItem,
  ParamsTitle,
  ParamsValue,
  ParamsTimeValue,
  PriceItem,
  Discount,
  PriceTotal,
  FlexWrapper,
  ReturnContainer,
  ReturnButton,
  PriceReturnWrapper,
  SdekDeliveryWrapper,
  SdekTrackLink,
  SdekTitle,
  SdekItem,
  SdekValue,
} from "./OrderInfo.styles";
import ReturnModal from "./ReturnModal";

const OrderInfo: FC = () => {
  const { orderId } = useParams<{ orderId: string }>();

  const [orderData, setOrderData] = useState<OrderData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
    if (isModalOpen) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  useEffect(() => {
    getItemOrder(orderId)
      .then(({ data }) => {
        setOrderData(data);
      })
      .catch(() => setOrderData(null))
      .finally(() => setIsLoading(false));
  }, [orderId]);

  const handleCopy = async (valueToCopy: string) => {
    if (!valueToCopy) {
      return;
    }
    try {
      await navigator.clipboard.writeText(valueToCopy);
    } catch (err) {
      console.log("Failed to copy text: ", err);
    }
  };

  if (isLoading) {
    return (
      <Container>
        <HeaderWrapper>
          <BackLink to={ROUTES.myOrder}>
            <BackIcon />
            <BackIconMobile />
            <span>Назад</span>
          </BackLink>
          <Title>
            <OrderStatus>
              <Loader>
                <div />
                <div />
                <div />
              </Loader>
            </OrderStatus>
          </Title>
        </HeaderWrapper>
      </Container>
    );
  }
  return (
    <Container>
      <HeaderWrapper>
        <BackLink to={ROUTES.myOrder}>
          <BackIcon />
          <BackIconMobile />
          <span>Назад</span>
        </BackLink>
        {orderData && (
          <Title>
            <OrderNumber>Заказ {orderData.id.replace(/\s/g, "")}</OrderNumber>
            <OrderStatus>{orderData.status_name}</OrderStatus>
          </Title>
        )}
      </HeaderWrapper>
      {orderData?.basket && orderData && (
        <FlexWrapper>
          <OrderList>
            {orderData.basket.map((itemOrder) => {
              return (
                <OrderItem
                  key={`order_${itemOrder.id}`}
                  image={convertImageUrl(itemOrder.gallery[0], 800, 1100)}
                  imageBack={convertImageUrl(itemOrder.gallery[0], 800, 1100)}
                  title={itemOrder.name}
                  newPrice={itemOrder.new_price}
                  oldPrice={itemOrder.old_price}
                  count={itemOrder.quantity}
                  size={itemOrder.size}
                  link={itemOrder.link}
                />
              );
            })}
          </OrderList>
          <OrderDataContainer>
            <OrderParamsContainer>
              <ParamsList>
                {orderData.created && (
                  <ParamsItem>
                    <ParamsTitle>Дата заказа:</ParamsTitle>
                    <ParamsValue>{formatDate(orderData.created)}</ParamsValue>
                    <ParamsTimeValue>
                      {formatTime(orderData.created)}
                    </ParamsTimeValue>
                  </ParamsItem>
                )}
                <ParamsItem>
                  <ParamsTitle>Способ оплаты:</ParamsTitle>
                  <ParamsValue>{orderData.payment_name}</ParamsValue>
                </ParamsItem>
              </ParamsList>

              <ParamsList>
                {orderData?.delivery_date && (
                  <ParamsItem>
                    <ParamsTitle>Дата забора:</ParamsTitle>
                    <ParamsValue>
                      {formatDateTime(orderData.delivery_date)}
                    </ParamsValue>
                  </ParamsItem>
                )}
                <ParamsItem>
                  <ParamsTitle>Способ доставки:</ParamsTitle>
                  <ParamsValue>{orderData.delivery_name || "-"}</ParamsValue>
                </ParamsItem>
                {orderData.delivery_address && (
                  <ParamsItem>
                    <ParamsTitle>Адрес доставки:</ParamsTitle>
                    <ParamsValue>{orderData.delivery_address}</ParamsValue>
                  </ParamsItem>
                )}
              </ParamsList>
            </OrderParamsContainer>
            <PriceReturnWrapper>
              {orderData.sdek_ttn && (
                <SdekDeliveryWrapper>
                  <SdekItem>
                    <SdekTitle>Номер ТТН:</SdekTitle>
                    <SdekValue>#{orderData.sdek_ttn}</SdekValue>
                    <svg
                      onClick={() => {
                        if (orderData.sdek_ttn) {
                          handleCopy(orderData.sdek_ttn);
                        }
                      }}
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        cursor: "pointer",
                        transform: "translateY(-2px)",
                      }}
                    >
                      <path
                        d="M6 6V4.13346C6 3.38673 6 3.01308 6.14532 2.72786C6.27316 2.47698 6.47698 2.27316 6.72786 2.14532C7.01308 2 7.38673 2 8.13346 2H11.8668C12.6135 2 12.9867 2 13.2719 2.14532C13.5228 2.27316 13.727 2.47698 13.8548 2.72786C14.0001 3.01308 14.0001 3.38645 14.0001 4.13319V7.86654C14.0001 8.61327 14.0001 8.98664 13.8548 9.27186C13.727 9.52274 13.5226 9.72699 13.2717 9.85482C12.9868 10 12.614 10 11.8687 10H10M6 6H4.13346C3.38673 6 3.01308 6 2.72786 6.14532C2.47698 6.27316 2.27316 6.47698 2.14532 6.72786C2 7.01308 2 7.38673 2 8.13346V11.8668C2 12.6135 2 12.9867 2.14532 13.2719C2.27316 13.5228 2.47698 13.727 2.72786 13.8548C3.0128 14 3.386 14 4.13127 14H7.86903C8.61431 14 8.98698 14 9.27192 13.8548C9.5228 13.727 9.72699 13.5226 9.85482 13.2717C10 12.9868 10 12.614 10 11.8687V10M6 6H7.8668C8.61353 6 8.98671 6 9.27192 6.14532C9.5228 6.27316 9.72699 6.47698 9.85482 6.72786C10 7.0128 10 7.386 10 8.1313L10 10"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </SdekItem>
                  <SdekTrackLink
                    href={`https://www.cdek.ru/track.html?order_id=${orderData.sdek_ttn}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    отследить заказ в Транспортной компании
                  </SdekTrackLink>
                </SdekDeliveryWrapper>
              )}
              <OrderPriceContainer>
                <PriceItem>
                  Стоимость заказа: {formatCurrency(orderData.price || 0)}
                </PriceItem>
                <PriceItem>
                  Ваша скидка:
                  <Discount>
                    {formatCurrency(Math.abs(orderData.discount_price) || 0)}
                  </Discount>
                </PriceItem>
                <PriceItem>
                  Доставка: {formatCurrency(orderData.delivery_price || 0)}
                </PriceItem>
                <PriceTotal>
                  Итого: {formatCurrency(orderData.total_price || 0)}
                </PriceTotal>
              </OrderPriceContainer>
              {orderData.is_refundable !== null && (
                <ReturnContainer>
                  <ReturnButton onClick={handleModal}>
                    <Icons.Return />
                    Возврат
                  </ReturnButton>
                  {isModalOpen && (
                    <ReturnModal
                      handleModal={handleModal}
                      isRefundable={orderData.is_refundable}
                    />
                  )}
                </ReturnContainer>
              )}
            </PriceReturnWrapper>
          </OrderDataContainer>
        </FlexWrapper>
      )}
    </Container>
  );
};

export default OrderInfo;
