import styled from "styled-components";

import { Flex } from "../Flex";
import { Grid } from "../Grid";
import { IconButton } from "../IconButton";
import { Icons } from "../Icons";

import { ButtonProps } from "./Pagination.types";

export const Container = styled(Grid)`
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 16px;
`;

export const Button = styled(IconButton)<ButtonProps>`
  color: ${({ theme }): string => theme.colors.black};
  font-size: 16px;
  justify-content: flex-start;
  padding: 4px;
  display: flex;
  text-decoration: ${({ isActive }): string =>
    isActive ? "underline" : "none"};

  :hover {
    text-decoration: underline;
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const PageButton = styled(Button)`
  padding: 4px 6px;
`;

export const NextArrow = styled(Icons.Arrow)`
  width: 16px;
  height: 16px;
  transform: rotate(90deg);
  stroke: ${({ theme }): string => theme.colors.black};
  stroke-width: 2px;
  vertical-align: text-bottom;
  margin: 0 0 0 8px;
`;

export const PrevArrow = styled(NextArrow)`
  transform: rotate(270deg);
  margin: 0 8px 0 0;
`;

export const PagesContainer = styled(Flex)`
  align-items: center;
`;

export const Left = styled(Flex)`
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    button {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;

      span {
        display: none;
      }
    }
  }
`;

export const Right = styled(Flex)`
  justify-content: flex-end;

  @media screen and (max-width: 768px) {
    button {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        display: none;
      }
    }
  }
`;

export const Center = styled(Flex)`
  justify-content: center;
`;

export const Points = styled.span`
  padding: 4px 6px;
`;
