import React, { useState, useCallback, FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { setNavigationMenuList } from "src/actions";
import { getMenu } from "src/api";
import { GlobalMenu } from "src/components";

import { ApplicationStore } from "../../store";
import { isMagazineItem } from "../Header/Header.utils";

import {
  Wrapper,
  NavContainer,
  MenuList,
  MenuItem,
  MenuLink,
  Dropdown,
} from "./NavMenu.styles";

const NavMenu: FC = () => {
  const location = useLocation();
  const [active, setActive] = useState<number>();
  const [isClosed, setIsClosed] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { menu } = useSelector(
    (state: ApplicationStore) => state.navigationMenu
  );
  const toggleActive = useCallback(
    (num?: number) => (): void => {
      setIsClosed(true);
      setActive(num);
    },
    [setActive]
  );

  useEffect(() => {
    getMenu().then(({ data }) => {
      dispatch(setNavigationMenuList(data));
    });
  }, [dispatch]);

  const isCompact = isMagazineItem(location.pathname);

  return (
    <Wrapper>
      <NavContainer>
        <MenuList child={active} role="menu">
          {menu &&
            menu.map(({ label, link, isSale, items, banner }, idx) => (
              <MenuItem
                key={label + link}
                isSale={isSale}
                role="menuitem"
                onMouseEnter={() => {
                  toggleActive(idx + 1);
                  setIsClosed(false);
                }}
                onClick={() => setIsClosed(true)}
                onMouseLeave={toggleActive()}
                isClosed={isClosed}
              >
                <MenuLink to={link}>{label}</MenuLink>

                {!isSale && link !== "/shops/" && (
                  <Dropdown className="dropdownNav" isCompact={isCompact}>
                    <GlobalMenu
                      banner={banner}
                      subMenu={items}
                      onClose={() => setIsClosed(true)}
                    />
                  </Dropdown>
                )}
              </MenuItem>
            ))}
        </MenuList>
      </NavContainer>
    </Wrapper>
  );
};

export default NavMenu;
