import React, { FC } from "react";

import { BrandsItem } from "src/types";

import {
  BrandCardWrapper,
  BrandCardHeader,
  BrandCardList,
  BrandCardItem,
  BrandLink,
} from "./BrandCard.styles";
import { BrandCardProps } from "./BrandCard.types";

const BrandCard: FC<BrandCardProps> = (props) => {
  const { brandsArray, activeLetter, filterValue } = props;

  const generateLink = (brand: BrandsItem) => {
    const filterValueLink = () => {
      if (filterValue === "is_woman") {
        return "filter-gender-woman";
      } else if (filterValue === "is_man") {
        return "filter-gender-man";
      } else {
        return "";
      }
    };

    if (filterValue) {
      if (brand[filterValue]) {
        const link = filterValueLink();
        if (link === "") {
          return `/brands/${brand.link}`;
        } else {
          return `/brands/${brand.link}${filterValueLink()}/`;
        }
      } else {
        return `/brands/${brand.link}`;
      }
    } else {
      return `/brands/${brand.link}`;
    }
  };

  return (
    <BrandCardWrapper>
      <BrandCardHeader>{activeLetter}</BrandCardHeader>
      <BrandCardList>
        {brandsArray &&
          brandsArray.map((brand) => (
            <BrandCardItem
              key={brand.id}
              className={
                filterValue
                  ? brand[filterValue]
                    ? undefined
                    : "not-active"
                  : undefined
              }
            >
              <BrandLink to={generateLink(brand)}>{brand.name}</BrandLink>
            </BrandCardItem>
          ))}
      </BrandCardList>
    </BrandCardWrapper>
  );
};

export default BrandCard;
