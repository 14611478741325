import styled from "styled-components";

import { Box, Grid } from "src/components";

export const Container = styled.main`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  max-width: 1830px;
  margin: auto;
  padding: 105px 40px 40px 40px;

  @media screen and (max-width: 768px) {
    padding: 50px 20px;
  }
`;

export const MagazineHeader = styled.h1`
  font-family: Formular, sans-serif;
  font-weight: 500;
  font-size: 62px;
  line-height: 76px;
  text-align: center;
  letter-spacing: 0.01em;
  margin: 0 0 11px;

  @media screen and (max-width: 768px) {
    margin: 0 0 4px;
  }

  @media screen and (max-width: 600px) {
    font-size: 50px;
    line-height: 61px;
  }
`;

export const BreadCrumbs = styled.ul`
  line-height: 30px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #000000;
  text-align: center;
  margin-bottom: 48px;
  margin-left: 0;
  padding: 0;
  list-style: none;

  li {
    display: inline-block;
  }

  a {
    font-size: 14px;
    color: ${({ theme }): string => theme.colors.grey};
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 26px;
  }
`;

export const Slash = styled.span`
  margin: 0 7px;
  color: #8e8e8e;
`;

export const MagazineList = styled(Grid)`
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 80px;
  grid-row-gap: 166px;

  @media screen and (max-width: 1440px) {
    grid-column-gap: 50px;
    grid-row-gap: 119px;
  }

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 60px;
    grid-row-gap: 188px;
  }

  @media screen and (max-width: 768px) {
    grid-column-gap: 40px;
    grid-row-gap: 100px;
  }

  @media screen and (max-width: 680px) {
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 84px;
  }
`;

export const MockItem = styled(Box)`
  width: 100%;
  height: auto;
  background-color: lightgrey;
  opacity: 0.5;
  max-height: 826px;

  &:before {
    content: "";
    height: 0;
    display: inline-block;
    padding-top: 170%;
    width: 1px;
    position: relative;
  }

  @media screen and (min-width: 1440px) {
    max-height: 850px;
  }
`;
