/* eslint-disable max-len */

import React, { FC } from "react";

import { SvgProps } from "src/types";

const Vk: FC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 25 25">
    <g clipPath="url(#clip0_312_56)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4722 7.81256C23.6321 7.34417 23.4722 7 22.7113 7H20.1951C19.5553 7 19.2603 7.29732 19.1004 7.62517C19.1004 7.62517 17.8208 10.3652 16.0081 12.145C15.4217 12.6602 15.1551 12.8242 14.8352 12.8242C14.6752 12.8242 14.4437 12.6602 14.4437 12.1919V7.81256C14.4437 7.2505 14.258 7 13.7248 7H9.77079C9.37098 7 9.13053 7.26087 9.13053 7.5081C9.13053 8.04093 10.0368 8.1638 10.1303 9.66264V12.9179C10.1303 13.6316 9.98354 13.761 9.66364 13.761C8.81064 13.761 6.73569 11.0087 5.50506 7.85938C5.26389 7.24726 5.02199 7 4.37892 7H1.86271C1.14379 7 1 7.29732 1 7.62517C1 8.21066 1.85305 11.1146 4.97195 14.9553C7.05119 17.5782 9.98072 19 12.6465 19C14.2459 19 14.4438 18.6842 14.4438 18.1403V16.1579C14.4438 15.5263 14.5953 15.4003 15.1018 15.4003C15.475 15.4003 16.1147 15.5642 17.6076 16.8288C19.3136 18.3276 19.5949 19 20.5545 19H23.0708C23.7897 19 24.1491 18.6842 23.9418 18.061C23.7148 17.4399 22.9003 16.5388 21.8195 15.4705C21.233 14.8616 20.3533 14.2059 20.0867 13.878C19.7135 13.4565 19.8201 13.2691 20.0867 12.8945C20.0867 12.8945 23.1523 9.10058 23.4722 7.81256V7.81256Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_312_56">
        <rect width="25" height="25" />
      </clipPath>
    </defs>
  </svg>
);

Vk.defaultProps = {
  width: "20px",
  height: "20px",
  fill: "black",
};

export default Vk;
