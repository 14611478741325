import React, { FC, useMemo } from "react";

import { BoxArrow } from "./Carousel.styles";

const CarouselPrevArrow: FC = (
  props: any //  eslint-disable-line @typescript-eslint/no-explicit-any
) => {
  const { onClick } = props;
  const currentSlide = props.currentSlide || 0;

  const isMobilePrevArrowDisabled = useMemo(() => {
    if (currentSlide === 0) {
      return true;
    }
  }, [currentSlide]);
  return (
    <BoxArrow
      isRight={false}
      onClick={onClick}
      isMobilePrevArrowDisabled={isMobilePrevArrowDisabled}
    >
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.9749 16L4.94977 8.97485L11.9749 1.94974"
          stroke="white"
          stroke-width="2"
        />
      </svg>
    </BoxArrow>
  );
};

export default CarouselPrevArrow;
