/* eslint-disable max-len */

import React, { FC } from "react";

import { SvgProps } from "src/types";

const TooltipBox: FC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 12 5" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_8327_7874)">
      <path
        d="M6 -6.31372L11.6569 -0.656867L6 4.99999L0.343146 -0.656867L6 -6.31372Z"
        fill="black"
      />
    </g>
  </svg>
);

TooltipBox.defaultProps = {
  width: "12px",
  height: "5px",
  fill: "none",
};

export default TooltipBox;
