import React, { FC } from "react";

import { SvgProps } from "src/types";

const Checkout: FC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 5H2v9a1 1 0 001 1h10a1 1 0 001-1V5zM1 4v10a2 2 0 002 2h10a2 2 0 002-2V4H1z"
    />
    <path d="M8 1.5A2.5 2.5 0 005.5 4h-1a3.5 3.5 0 117 0h-1A2.5 2.5 0 008 1.5z" />
  </svg>
);

Checkout.defaultProps = {
  width: "20px",
  height: "20px",
  fill: "black",
};

export default Checkout;
