import styled from "styled-components";

import { Link } from "../Link";

export const FooterMenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: 1150px) {
    flex-direction: column;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const MenuList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

export const MenuItem = styled.li`
  position: relative;
  margin: 0;
  padding: 6px 0;
  cursor: pointer;
  font-weight: 500;
  white-space: nowrap;

  a {
    color: ${({ theme }): string => theme.colors.white};
    text-decoration: none;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.01em;
  }

  :hover {
    opacity: 0.5;
  }

  @media screen and (max-width: 1609px) {
    a {
      font-size: 14px;
    }
  }
`;

export const MenuLink = styled(Link)``;
