import React, { FC, useState, useMemo, useContext, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";

import { setCheckout, setCurrentWorkFlow } from "src/actions";
import { addToCheckout, addToCheckoutWithoutUuid, getCheckout } from "src/api";
import { CheckoutContext } from "src/context/CheckoutContext";
import { ApplicationStore } from "src/store";
import {
  formatCurrency,
  getBasketId,
  convertImageUrl,
  parseImages,
  setBasketId,
} from "src/utils";

import {
  ImageContainer,
  ImageLink,
  ImageImg,
  Picture,
  OldPrice,
} from "../CatalogListItem/CatalogListItem.styles";
import { ProductLabel } from "../ProductLabel";
import { SelectOption } from "../Select/Select.types";

import {
  Wrapper,
  ProductType,
  ProductName,
  PriceContainer,
  Price,
  ContainerItem,
  AddToCheckout,
  RemoveButton,
  ProductStatus,
  SelectSizes,
} from "./FavoritesItem.styles";
import { FavoritesItemProps } from "./FavoritesItem.types";

const FavoritesItem: FC<FavoritesItemProps> = ({
  offers,
  favorite,
  removeFavorite,
  selectedItemID,
  onClickedMobileID,
}) => {
  const dispatch = useDispatch();
  const { setIsCartOpened } = useContext(CheckoutContext);
  const { info } = useSelector((state: ApplicationStore) => state.user);
  const checkoutId = useMemo(() => getBasketId() || info?.checkoutId, [info]);
  const [isClickable, setIsClickable] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const images = useMemo(() => parseImages(favorite.gallery), [
    favorite.gallery,
  ]);

  const { ref } = useInView({
    threshold: 1,
    triggerOnce: true,
  });
  const sizesItems =
    offers &&
    offers
      .filter((offer) => offer.available === true)
      .map((offer) => {
        return {
          value: String(offer.id),
          label: offer.size_name,
          disabled: offer.available,
        };
      });

  const [isMobile, setMatches] = useState(
    window.matchMedia("(max-width: 767px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(max-width: 767px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const [isInBasket, setIsInBasket] = useState<boolean>(false);

  const isOnStock =
    sizesItems &&
    sizesItems.some((size) => {
      return size?.disabled;
    });

  const initialSizeOption = offers && offers.filter((offer) => offer.isWish)[0];

  const [sizeSelected, setSizeSelected] = useState<SelectOption>({
    value: String(initialSizeOption?.id),
    label: initialSizeOption?.size_name,
    disabled: initialSizeOption?.available,
  });

  const handleChangeSizes = (option: SelectOption) => {
    setSizeSelected(option);
  };

  const touchProduct = (e: React.TouchEvent, link: string, id: number) => {
    if (isMobile) {
      e.preventDefault();
      if (!selectedItemID) {
        setIsClickable(false);
        onClickedMobileID?.(id);
      } else {
        if (selectedItemID === id) {
          if (!isDragging) {
            window.location.href = link;
          }
        } else {
          setIsClickable(false);
          onClickedMobileID?.(id);
        }
      }
      setIsDragging(false);
    }
  };

  const stopTouchEvent = (link: string) => {
    if (isClickable && !isDragging) {
      window.location.href = link;
    }
    setIsClickable(true);
  };

  const addToCheckoutHandler = () => {
    const productId = favorite.id;
    const offerId = Number(sizeSelected.value);

    if (offerId && checkoutId) {
      setIsInBasket(true);
      if (checkoutId === "undefined" || null) {
        addToCheckoutWithoutUuid({
          product_id: productId,
          quantity: 1,
          offer_id: offerId,
        }).then(({ data }) => {
          setBasketId(data.basket_uuid);
          dispatch(
            setCurrentWorkFlow({
              userWorkFlow: "hasUuid",
            })
          );
          getCheckout(data.basket_uuid).then(({ data }) => {
            dispatch(setCheckout(data));
            if (window.matchMedia("(min-width: 768px)").matches) {
              setIsCartOpened(true);
            }
          });
        });
      } else {
        addToCheckout(checkoutId, {
          product_id: productId,
          basket_uuid: checkoutId,
          quantity: 1,
          offer_id: offerId,
        }).then(() => {
          getCheckout(checkoutId).then(({ data }) => {
            dispatch(setCheckout(data));
            if (window.matchMedia("(min-width: 768px)").matches) {
              setIsCartOpened(true);
            }
          });
        });
      }
    }
  };

  return (
    <ContainerItem ref={ref} data-id={favorite.id}>
      <Wrapper>
        <RemoveButton onClick={() => removeFavorite(favorite.id)}>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="0.483382"
              y1="0.516618"
              x2="13.2113"
              y2="13.2445"
              stroke="black"
              stroke-width="1.36721"
            />
            <line
              x1="0.516618"
              y1="13.5166"
              x2="13.2445"
              y2="0.788696"
              stroke="black"
              stroke-width="1.36721"
            />
          </svg>
        </RemoveButton>
        <ProductLabel label={favorite.label} />
        <ImageLink
          to={favorite.link || "/"}
          aria-label={favorite.name}
          className={selectedItemID === favorite.id ? "selected" : ""}
          onTouchStart={(e) => {
            touchProduct(e, favorite.link, favorite.id);
          }}
          onTouchEnd={() => {
            stopTouchEvent(favorite.link);
          }}
          onTouchCancel={() => {
            stopTouchEvent(favorite.link);
          }}
          onTouchMove={() => setIsDragging(true)}
        >
          {!!images?.[0] && (
            <ImageContainer>
              <Picture className={"image-0"}>
                <ImageImg
                  src={convertImageUrl(images[0], 800, 1100)}
                  alt={favorite.name}
                  loading="lazy"
                />
              </Picture>
              {images?.[1] && (
                <Picture className={"image-1"}>
                  <ImageImg
                    src={convertImageUrl(images[1], 800, 1100)}
                    alt={favorite.name}
                    loading="lazy"
                  />
                </Picture>
              )}
            </ImageContainer>
          )}
        </ImageLink>
        <ProductType to={`/catalog/type/${favorite?.type_code}`}>
          {favorite?.type_name}
        </ProductType>
        <ProductName to={favorite.link || "/"}>
          {favorite?.brand_name}
        </ProductName>
        {sizesItems && sizesItems.length > 1 && (
          <SelectSizes
            options={sizesItems}
            value={sizeSelected}
            onChange={handleChangeSizes}
            selectedLabel="Размеров нет"
            disabled={!isOnStock}
          />
        )}

        <PriceContainer>
          {favorite.old_price > favorite.new_price && (
            <OldPrice>{formatCurrency(favorite.old_price)}</OldPrice>
          )}
          <Price>{formatCurrency(favorite.new_price)}</Price>
        </PriceContainer>
        {!sizeSelected.disabled && (
          <ProductStatus>Товар закончился</ProductStatus>
        )}
      </Wrapper>
      <AddToCheckout
        onClick={addToCheckoutHandler}
        disabled={!sizeSelected.disabled}
      >
        {isInBasket ? "Добавлено" : "В корзину"}
      </AddToCheckout>
    </ContainerItem>
  );
};

export default FavoritesItem;
