import {
  CatalogFilterValues,
  BaseFilterValue,
} from "../../CatalogFilters.types";

export const getLabel = (label: string | number | boolean): string => {
  const str = `${label}`;

  if (str === "true") {
    return "Да";
  }

  if (str === "false") {
    return "Нет";
  }

  return str;
};

export const getValue = (
  values: CatalogFilterValues,
  code: string,
  id: string | number
): BaseFilterValue => {
  const item = values[code];

  if (item && typeof item === "object") {
    return item[id];
  }
  return item;
};
