import React, { FC } from "react";

import { SvgProps } from "src/types";

const AddToCart: FC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 28 29" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.7373 24.0157L22.4888 24.0157V27.9759V28.0259H22.5388H23.506H23.556V27.9759V24.0157L27.3041 24.0157H27.3541V23.9657V22.9985V22.9485H27.3041L23.556 22.9485V19.2019V19.1519H23.506H22.5388H22.4888V19.2019V22.9485L18.7373 22.9485H18.6873L18.6873 22.9985L18.6873 23.9657L18.6873 24.0157H18.7373Z"
      fill="black"
      stroke="black"
      strokeWidth="0.1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4825 0C10.1887 0 7.46381 2.43564 7.0106 5.6041H3.20885C2.17717 5.6041 1.34082 6.44045 1.34082 7.47214V24.2844C1.34082 25.3161 2.17717 26.1525 3.20885 26.1525H16.565V24.8449H3.20885C2.89935 24.8449 2.64844 24.594 2.64844 24.2844V7.47214C2.64844 7.16263 2.89935 6.91173 3.20885 6.91173H23.7572C24.0667 6.91173 24.3176 7.16263 24.3176 7.47214V16.931H25.6253V7.47214C25.6253 6.44045 24.7889 5.6041 23.7572 5.6041H19.9544C19.5012 2.43564 16.7763 0 13.4825 0ZM18.6298 5.6041C18.1895 3.16103 16.0524 1.30762 13.4825 1.30762C10.9126 1.30762 8.77556 3.16103 8.33516 5.6041H18.6298Z"
      fill="black"
    />
  </svg>
);
AddToCart.defaultProps = {
  width: "28px",
  height: "28px",
  fill: "none",
};

export default AddToCart;
