import styled from "styled-components";

import { Button } from "../Button";
import { IconButton } from "../IconButton";
import { Icons } from "../Icons";
import { Modal } from "../Modal";

export const ModalContainer = styled(Modal)`
  background-color: ${({ theme }): string => theme.colors.white};
  padding: 40px 35px;
  width: 740px;
  min-height: 415px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1150px) {
    width: 100%;
    max-width: 420px;
    padding: 35px 20px;
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 20px;

  &:hover {
    opacity: 0.5;
  }
`;

export const CloseIcon = styled(Icons.Close)`
  width: 14px;
  height: 14px;
  stroke: white;
  stroke-width: 1px;
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const Title = styled.h3`
  font-weight: 500;
  font-size: 26px;
  line-height: 26px;
  letter-spacing: 0.01em;
  margin: 0 0 16px;
  text-align: center;
  margin-bottom: 50px;
`;

export const OrderSuccessText = styled.p`
  font-size: 15px;
  line-height: 30px;
  text-align: center;
  margin: 0 0 36px;
`;
export const CloseFastOrder = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  height: 52px;
`;
