import React, { useMemo, FC, useEffect } from "react";
import TagManager from "react-gtm-module";
import { useInView } from "react-intersection-observer";
import LazyLoad from "react-lazyload";

import { parseImages, convertImageUrl } from "src/utils";

import {
  Container,
  ImageLink,
  Image,
  ImageMobile,
  InfoContainer,
  TextContainer,
  Title,
  Description,
  Button,
  ExternalButton,
} from "./PromotionalCard.styles";
import { PromotionalCardProps } from "./PromotionalCard.types";

const PromotionalCard: FC<PromotionalCardProps> = (props) => {
  const { className, item, withButton, withoutDescription } = props;
  const {
    gallery,
    mobile_gallery: mobileGallery,
    name,
    preview_text: previewText,
    url,
    code,
    buy_button_link: buyButtonLink,
    buy_button_name: buyButtonName,
    position,
  } = item;

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const images: string[] = useMemo(() => parseImages(gallery), [gallery]);
  const mobileImages: string[] = useMemo(() => parseImages(mobileGallery), [
    mobileGallery,
  ]);

  const isUrlAbsolute = !!buyButtonLink && buyButtonLink.includes("://");

  const clickPromoCard = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "promo-click",
        ecommerce: {
          promoClick: {
            promotions: [
              {
                id: url || "without-link",
                name: code,
                creative: "banner",
                position: position,
              },
            ],
          },
        },
      },
    });
  };

  useEffect(() => {
    if (inView) {
      TagManager.dataLayer({
        dataLayer: {
          event: "promo-impression",
          ecommerce: {
            promoView: {
              promotions: [
                {
                  id: url || "without-link",
                  name: code,
                  creative: "banner",
                  position: position,
                },
              ],
            },
          },
        },
      });
    }
  }, [inView, name, url]);

  return (
    <Container className={className} ref={ref}>
      {code !== "Telegram" ? (
        <ImageLink
          compact={`${!withoutDescription}`}
          to={url || "/"}
          onClick={clickPromoCard}
        >
          <Image
            src={convertImageUrl(images[0], 3840, 3840)}
            alt={`${name}. ${previewText}`}
            isHide={!!mobileImages[0]}
          />
          {mobileImages[0] && (
            <>
              <LazyLoad
                height={document.documentElement.clientHeight / 2}
                offset={document.documentElement.clientHeight * 3}
              >
                <ImageMobile
                  loading="lazy"
                  src={convertImageUrl(mobileImages[0], 1500, 1500)}
                  alt={`${name}. ${previewText}`}
                  isShow={!!mobileImages[0]}
                />
              </LazyLoad>
            </>
          )}
        </ImageLink>
      ) : (
        <a className="link_tg" href={buyButtonLink} onClick={clickPromoCard}>
          <Image
            loading="lazy"
            src={convertImageUrl(images[0], 3840, 3840)}
            alt={`${name}. ${previewText}`}
            isHide={!!mobileImages[0]}
          />
          {mobileImages[0] && (
            <ImageMobile
              loading="lazy"
              src={convertImageUrl(mobileImages[0], 1500, 1500)}
              alt={`${name}. ${previewText}`}
              isShow={!!mobileImages[0]}
            />
          )}
        </a>
      )}

      {!withoutDescription && (
        <InfoContainer>
          <TextContainer withButton={withButton}>
            <Title
              dangerouslySetInnerHTML={{ __html: name }}
              onClick={clickPromoCard}
            />
            <Description
              dangerouslySetInnerHTML={{ __html: previewText }}
              onClick={clickPromoCard}
            />
          </TextContainer>

          {withButton && !isUrlAbsolute && (
            <Button variant="white" to={buyButtonLink} onClick={clickPromoCard}>
              {buyButtonName || "купить"}
            </Button>
          )}
          {withButton && isUrlAbsolute && (
            <ExternalButton
              variant="white"
              href={buyButtonLink}
              target="_blank"
              rel="noreferrer noopener"
              onClick={clickPromoCard}
            >
              {buyButtonName || "купить"}
            </ExternalButton>
          )}
        </InfoContainer>
      )}
    </Container>
  );
};

export default PromotionalCard;
