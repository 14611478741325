import styled from "styled-components";

import { BorderedInput } from "../BorderedInput";
import { Box } from "../Box";
import { Button } from "../Button";
import { Flex } from "../Flex";
import { Grid } from "../Grid";
import { IconButton } from "../IconButton";
import { Icons } from "../Icons";
import { Link } from "../Link";
import { Text } from "../Text";

export const SearchWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.darkSearchBg};

  @media (max-width: 768px) {
    // min-height: 220px;
  }
`;

export const SearchContainer = styled(Flex)`
  flex-direction: column;
  max-width: 970px;
  padding: 55px 0 0 0;
  margin: 0 auto;

  @media (max-width: 1150px) {
    padding: 55px 15px 0;
  }

  @media (max-width: 768px) {
    padding: 30px 15px 0;
  }
`;

export const Form = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
`;

export const SearchInput = styled(BorderedInput)`
  border: none;
  font-size: 17px;
  line-height: 16px;
`;

export const SearchButton = styled(IconButton)`
  :hover {
    opacity: 0.5;
  }
`;

export const SearchIcon = styled(Icons.Search)`
  width: 21px;
  height: 21px;
  fill: ${({ theme }): string => theme.colors.white};

  @media screen and (max-width: 1150px) {
    width: 20px;
    height: 20px;
  }
`;

export const CloseButton = styled(IconButton)`
  :hover {
    opacity: 0.5;
  }
`;

export const CloseIcon = styled(Icons.Close)`
  width: 20px;
  height: 20px;
  fill: white;
  stroke-width: 0;
`;

export const SearchWords = styled.div`
  max-width: 760px;
`;

export const FastLink = styled(Button)`
  display: inline-block;
  border: 0;
  background: transparent;
  width: auto;
  padding: 0;
  font-family: Formular;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 41px;
  letter-spacing: 0.01em;
  color: ${({ theme }): string => theme.colors.white};
  margin: 0 40px 17px 0;

  &:hover {
    color: ${({ theme }): string => theme.colors.lime};
  }

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 34px;
  }
`;

export const ResultCount = styled(Text)`
  margin: 4px 0 17px;
  font-size: 16px;
  color: ${({ theme }): string => theme.colors.grey};
`;

export const ResultContainer = styled(Box)`
  width: 100%;
  position: absolute;
  top: 96px;
  left: 0;
  height: 370px;
  overflow-x: auto;
  padding: 13px;

  @media (max-width: 1200px) {
    height: 500px;
  }

  @media (max-width: 768px) {
    top: 71px;
    height: 400px;
  }
`;

export const Result = styled(Grid)`
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const ResultItem = styled(Link)`
  font-family: Formular, sans-serif;
  font-size: 20px;
  line-height: 40px;
  color: ${({ theme }): string => theme.colors.white};
  margin: 0 0 16px;
  display: block;

  :hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 34px;
  }
`;
