import { UserActions, UserTypes, User } from "./types";

const initialState: User = {
  isAuthorized: false,
  isFirstTime: false,
  userWorkFlow: "",
};

const UserReducer = (state: User = initialState, action: UserActions): User => {
  switch (action.type) {
    case UserTypes.SET_AUTHORIZED: {
      return { ...state, isAuthorized: action.payload };
    }

    case UserTypes.SET_CURRENT_WORKFLOW: {
      return { ...state, userWorkFlow: action.payload.userWorkFlow };
    }

    case UserTypes.SET: {
      return action.payload;
    }

    case UserTypes.SET_DATA: {
      return { ...state, userData: action.payload };
    }

    case UserTypes.SET_ADDRESSES: {
      return { ...state, userAddresses: action.payload };
    }

    case UserTypes.CLEAR: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default UserReducer;
