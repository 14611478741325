import styled from "styled-components";

import { Flex } from "../Flex";
import { Link } from "../Link";

export const RowWrapper = styled(Flex)`
  padding: 16px;
  border-bottom: 2px dotted ${({ theme }) => theme.colors.lightGrey};
  justify-content: space-between;

  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.black};

  &:last-of-type {
    border-bottom: 0;
  }

  @media (max-width: 679px) {
    flex-wrap: wrap;
  }
`;

export const Status = styled.div`
  width: 115px;

  @media (max-width: 679px) {
    width: 50%;
    margin-bottom: 15px;
  }
`;
export const OrderNumber = styled.div`
  width: 127px;

  @media (max-width: 679px) {
    width: 50%;
    margin-bottom: 15px;
  }
`;
export const Price = styled.div`
  width: 115px;

  @media (max-width: 679px) {
    width: 50%;
    margin-bottom: 15px;
  }
`;
export const OrderDate = styled.div`
  width: 150px;

  @media (max-width: 679px) {
    width: 50%;
    margin-bottom: 15px;
  }
`;
export const MoreLink = styled(Link)`
  color: ${({ theme }) => theme.colors.black};
  text-transform: uppercase;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: 679px) {
    width: 50%;
    margin-left: auto;
  }
`;
