import styled from "styled-components";

import { Flex, Link, Icons } from "src/components";

export const HeaderWrapper = styled(Flex)`
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 102px;

  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 50px;
  }
`;

export const BackLink = styled(Link)`
  margin-right: auto;
  width: 100px;
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.black};
  display: none;
  align-items: center;
  font-family: Formular;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  text-transform: capitalize;
  white-space: nowrap;
  padding: 0;

  svg {
    fill: ${({ theme }) => theme.colors.black};
    margin-right: 5px;
    margin-top: -5px;
  }

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 1150px) {
    display: flex;
  }

  @media (max-width: 767px) {
    width: auto;
    margin-bottom: 20px;
    padding: 10px 0;

    span {
      display: none;
    }
  }
`;

export const BackIcon = styled(Icons.Arrow)`
  width: 19px;
  height: 19px;
  transform: rotate(-90deg);
  stroke: ${({ theme }): string => theme.colors.black};
  stroke-width: 2px;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const BackIconMobile = styled(Icons.BackArrow)`
  width: 21px;
  height: 13px;
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
`;

export const Title = styled.h3`
  font-family: Formular;
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.black};
  margin: 0;

  @media (max-width: 1150px) {
    margin: 0 auto 0 -100px;
  }

  @media (max-width: 767px) {
    text-align: center;
    margin: 0 auto;
  }
`;

export const Container = styled.main`
  max-width: 360px;

  @media (max-width: 1150px) {
    margin: 0 auto;
  }
`;
