import styled from "styled-components";

import { Link, Flex, Icons } from "src/components";
import {
  BorderlessInput,
  BorderlessMaskedInput,
} from "src/components/BorderlessInput";
import { Button } from "src/components/Button";
import { SelectInput } from "src/components/SelectInput";

export const HeaderWrapper = styled(Flex)`
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 69px;
  margin-bottom: 33px;

  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 50px;
  }
`;

export const BackLink = styled(Link)`
  margin-right: auto;
  width: 100px;
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.black};
  display: none;
  align-items: center;
  font-family: Formular;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  text-transform: capitalize;
  white-space: nowrap;
  padding: 0;

  svg {
    fill: ${({ theme }) => theme.colors.black};
    margin-right: 5px;
    margin-top: -5px;
  }

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 1150px) {
    display: flex;
  }

  @media (max-width: 767px) {
    width: auto;
    margin-bottom: 20px;
    padding: 10px 0;

    span {
      display: none;
    }
  }
`;

export const BackIcon = styled(Icons.Arrow)`
  width: 19px;
  height: 19px;
  transform: rotate(-90deg);
  stroke: ${({ theme }): string => theme.colors.black};
  stroke-width: 2px;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const BackIconMobile = styled(Icons.BackArrow)`
  width: 21px;
  height: 13px;
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
`;

export const Title = styled.h3`
  font-family: Formular;
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.black};
  margin: 0 auto;
  width: 100%;
  max-width: 404px;

  @media (max-width: 767px) {
    text-align: center;
  }
`;

export const Container = styled.main`
  max-width: 360px;
  margin: 0 auto;

  @media (max-width: 1150px) {
    margin: 0 auto;
  }
`;

export const Text = styled.p`
  color: black;
  font-family: "HelveticaNeueCyr", sans-serif;
  width: 100%;
  grid-area: footer-text;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  & > a {
    color: black;
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;
export const Form = styled.form`
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: column;
  .checkbox--custom {
    margin-top: 16px;
    p {
      color: rgba(0, 0, 0, 0.5);
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
`;

export const Input = styled(BorderlessInput)`
  :not(:first-of-type) {
    margin-top: 30px;
  }
`;

export const PhoneInput = styled(BorderlessMaskedInput)`
  :not(:first-of-type) {
    margin-top: 30px;
  }
`;

export const SelectGenderInput = styled(SelectInput)`
  margin-top: 30px;
`;

export const FormButton = styled(Button)`
  margin-top: 30px;
  height: 50px;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const CheckboxRow = styled.div`
  margin-top: 30px;
`;

export const InputContainer = styled.div`
  margin-top: 30px;
`;
