import styled from "styled-components";

export const ArticleWrapper = styled.div`
  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-weight: 450;
  line-height: 30px;
  font-size: 12px;
  letter-spacing: 0.1em;
  color: #8e8e8e;
  margin: 0 0 16px;
`;
