import styled from "styled-components";

import { BorderlessMaskedInput } from "../BorderlessInput";
import { Button } from "../Button";

export const Input = styled(BorderlessMaskedInput)`
  margin-bottom: 29px;
`;

export const ApplyButton = styled(Button)`
  height: 64px;
  margin-bottom: 13px;
  font-size: 14px;
`;
