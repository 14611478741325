import React, { useCallback, FC } from "react";
import { useHistory } from "react-router-dom";

import { SingleInputForm } from "src/components";
import { ROUTES } from "src/constants";

import { Container } from "../Reset.styles";

const ConfirmationPage: FC = () => {
  const history = useHistory();

  const handleSubmit = useCallback(
    (value: string) => {
      history.push(ROUTES.newPassword);
    },
    [history]
  );

  return (
    <Container>
      <SingleInputForm
        inputName="code"
        placeholder="Пароль из смс"
        buttonText="ПРОДОЛЖИТЬ"
        inputMode="numeric"
        inputType="number"
        onSubmit={handleSubmit}
        isRequired
      />
    </Container>
  );
};

export default ConfirmationPage;
