/* eslint-disable max-len */

import React, { FC } from "react";

import { FooterContainer } from "./Footer.styles";

const FooterCheckout: FC = () => {
  return (
    <FooterContainer>
      <svg
        className="logo-svg"
        width="132"
        height="55"
        viewBox="0 0 132 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36.388 48.7936C36.388 47.9701 37.0013 47.4211 38.0234 47.4211C38.7048 47.4211 39.3181 47.627 39.7269 48.1074L40.0677 47.627C39.6588 47.1466 38.8411 46.8721 38.0234 46.8721C36.5924 46.8721 35.7747 47.6956 35.7747 48.7936C35.7747 51.4015 39.7269 50.578 39.7269 52.774C39.7269 53.7348 38.8411 54.2152 37.8871 54.2152C37.2057 54.2152 36.3199 53.8721 35.911 53.2544L35.5703 53.6662C35.9792 54.3525 37.0013 54.7642 37.9553 54.7642C39.3181 54.7642 40.4084 53.9407 40.4084 52.7054C40.4084 50.0976 36.388 50.9897 36.388 48.7936Z"
          fill="white"
        />
        <path
          d="M43.7461 46.9414H43.1328V54.6963H43.7461V46.9414Z"
          fill="white"
        />
        <path
          d="M52.878 53.6669L47.5629 46.9414H47.0859V54.6963H47.6992V48.0394L52.9461 54.6963H53.4231V46.9414H52.878V53.6669Z"
          fill="white"
        />
        <path
          d="M59.8274 54.2157C57.9195 54.2157 56.5566 52.8432 56.5566 50.7844C56.5566 48.7942 57.8513 47.353 59.6911 47.353C60.5088 47.353 61.1903 47.5589 61.7354 47.9706L62.008 47.4903C61.3947 47.0099 60.6451 46.7354 59.6911 46.7354C57.3743 46.7354 55.9434 48.5197 55.9434 50.7157C55.9434 53.1177 57.5106 54.6961 59.8274 54.6961C60.7133 54.6961 61.6672 54.353 62.2124 53.8726L61.9398 53.3922C61.3947 53.9412 60.6451 54.2157 59.8274 54.2157Z"
          fill="white"
        />
        <path
          d="M65.0078 51.0591H68.3468V50.51H65.0078V47.4904H68.6875V46.9414H64.3945V54.6963H68.8237V54.1473H65.0078V51.0591Z"
          fill="white"
        />
        <path
          d="M73.9336 48.3132L74.2062 48.725L75.5008 47.6956V54.6956H76.046V46.8721H75.7053L73.9336 48.3132Z"
          fill="white"
        />
        <path
          d="M81.0881 46.8721C79.9297 46.8721 78.7031 47.8329 78.7031 49.3427C78.7031 50.6466 79.589 51.7446 81.0199 51.7446C81.9739 51.7446 82.6553 51.2642 82.996 50.578C82.7916 52.7054 81.4969 54.1466 79.6571 54.4897L79.8615 54.9701C81.9739 54.6956 83.6093 52.774 83.6093 49.8917C83.6093 48.176 82.7916 46.8721 81.0881 46.8721ZM81.1562 51.127C79.9297 51.127 79.3164 50.2348 79.3164 49.274C79.3164 48.2446 80.1341 47.4897 81.0881 47.4897C82.4509 47.4897 82.996 48.5878 82.996 49.8231C82.8598 50.3721 82.2465 51.127 81.1562 51.127Z"
          fill="white"
        />
        <path
          d="M87.7678 46.8721C86.6094 46.8721 85.3828 47.8329 85.3828 49.3427C85.3828 50.6466 86.2686 51.7446 87.6996 51.7446C88.6536 51.7446 89.335 51.2642 89.6757 50.578C89.4713 52.7054 88.1766 54.1466 86.3368 54.4897L86.5412 54.9701C88.6536 54.6956 90.289 52.774 90.289 49.8917C90.289 48.176 89.4713 46.8721 87.7678 46.8721ZM87.8359 51.127C86.6094 51.127 85.9961 50.2348 85.9961 49.274C85.9961 48.2446 86.8138 47.4897 87.7678 47.4897C89.1306 47.4897 89.6757 48.5878 89.6757 49.8231C89.5394 50.3721 88.9262 51.127 87.8359 51.127Z"
          fill="white"
        />
        <path
          d="M91.9219 47.4904H95.6697L92.8077 54.6963H93.4891L96.4192 47.2845V46.9414H91.9219V47.4904Z"
          fill="white"
        />
        <path
          d="M19.5566 38.5682H31.8903V32.529H23.3044V22.0976H30.8681V16.3329H23.3044V6.72503H32.0266V0.617188H19.5566V38.5682Z"
          fill="white"
        />
        <path
          d="M3.81593 0.617188H0V38.5682H12.4699V32.529H3.81593V0.617188Z"
          fill="white"
        />
        <path
          d="M75.2281 9.19608C75.2281 5.0098 75.2281 0 68.7547 0H67.3919C61.0547 0 61.0547 5.0098 61.0547 9.19608V30.0588C61.0547 34.3824 61.0547 39.1863 67.3919 39.1863H68.7547C75.2281 39.1863 75.2281 34.3824 75.2281 30.0588V9.19608ZM71.4804 30.2647C71.4804 33.0098 70.4582 33.0098 68.0051 33.0098C65.7565 33.0098 64.8706 33.0098 64.8706 30.2647V8.9902C64.8706 6.2451 65.7565 6.2451 68.0051 6.2451C70.3901 6.2451 71.4804 6.2451 71.4804 8.9902V30.2647Z"
          fill="white"
        />
        <path
          d="M125.653 0.617188L120.815 30.1956H120.474L115.704 0.617188H109.367V38.5682H113.115V15.029L112.774 9.19562H113.115L118.226 38.5682H123.064L128.174 9.19562H128.515L128.174 15.029V38.5682H131.99V0.617188H125.653Z"
          fill="white"
        />
        <path
          d="M99.078 16.9505V8.30346C99.078 5.69562 99.078 0.891697 92.7408 0.617188H84.9727V38.5682H88.7204V24.5682H92.332L95.6709 38.6368H99.8275L96.1479 23.8133C98.8054 22.578 99.078 20.3819 99.078 16.9505ZM95.3983 15.6466C95.3983 18.3917 94.3762 18.4603 92.1275 18.4603H88.7204V6.72503H92.1275C94.3081 6.72503 95.3983 6.72503 95.3983 9.53876V15.6466Z"
          fill="white"
        />
        <path
          d="M41.498 38.5682H45.2458V22.0976H52.8096V16.3329H45.2458V6.72503H53.8998V0.617188H41.498V38.5682Z"
          fill="white"
        />
      </svg>
    </FooterContainer>
  );
};

export default FooterCheckout;
