import styled from "styled-components";

import { ButtonProps } from "./LoaderButton.types";

export const AddButton = styled.button<ButtonProps>`
  position: relative;
  font-weight: 450;

  &.catalogList {
    background-color: white;
    border: ${({ loading }) =>
      !loading ? "1px solid black;" : "1px solid #808080"};
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    font-size: 14px;
    color: black;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.1em;
    padding: 19px 21px 15px;
    width: 340px;
    height: 64px;
    margin: 64px auto 0;
    cursor: ${({ loading }) => (loading ? "not-allowed" : "pointer")};
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    &:hover {
      opacity: ${({ loading }) => (loading ? "1" : "0.5")};
    }
    @media screen and (max-width: 758px) {
      &:hover {
        opacity: 1;
      }
    }
  }
  &.checkout {
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.1em;
    padding: 6px 16px 4px;
    cursor: pointer;
    box-sizing: border-box;
    border: none;
    background-color: #000000;
    color: #ffffff;

    width: 100%;
    height: 50px;
    font-size: 10px;
    line-height: 30px;
    margin-bottom: 17px;
    opacity: 1;
    pointer-events: auto;

    &:hover {
      opacity: ${({ loading }) => (loading ? "1" : "0.8")};
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 5px;
    }
  }
`;

export const PreloaderButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.catalogList {
    position: absolute;
    height: 100%;
  }

  &.checkout {
    position: block;
  }

  div {
    display: inline-block;
    width: 10px;
    animation: preloaderButtonAnimate 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

    &.catalogList {
      background: ${({ theme }) => theme.colors.black};
    }

    &.checkout {
      background: ${({ theme }) => theme.colors.white};
    }
  
    &:nth-child(1) {
      margin-right: 10px;

      animation-delay: -0.36s;
    }

    &:nth-child(2) {
      margin-right: 10px;

      animation-delay: -0.18s;
    }

    &:nth-child(3) {
      margin-right: 0px;

      animation-delay: 0s;
    }

  @keyframes preloaderButtonAnimate {
    0% {
      top: 24px;
      height: 25px;
    }
    50%,
    100% {
      top: 35px;
      height: 10px;
    }
  }
`;
