import styled from "styled-components";

import { BorderlessInput } from "../BorderlessInput";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;

  @media (max-width: 1150px) {
    margin: 0 auto;
  }
`;
export const SearchAddressInput = styled(BorderlessInput)`
  margin-bottom: 40px;
  background: rgba(250, 250, 250, 0.93);

  :disabled {
    cursor: not-allowed;
    ::placeholder {
      color: #d8d8d8;
    }
  }
`;

export const ResultAddresses = styled.ul`
  display: block;
  position: absolute;
  width: 100%;
  top: 40px;
  left: 0;
  overflow-x: auto;
  padding: 13px;
  z-index: 101;
  background: ${({ theme }): string => theme.colors.white};
  border: 1px solid ${({ theme }): string => theme.colors.black30};
  margin: 0;

  @media (max-width: 1200px) {
    height: 500px;
  }

  @media (max-width: 768px) {
    height: 400px;
  }
`;

export const AddressItem = styled.li`
  font-family: Formular, sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }): string => theme.colors.black};
  margin: 0 0 16px;
  display: block;

  :hover {
    color: ${({ theme }): string => theme.colors.black30};
    cursor: pointer;
  }

  &:last-child {
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 34px;
  }
`;
