import styled, { css } from "styled-components";

import SelectChecked from "../Icons/Glyphs/SelectChecked";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: flex-start;

  position: relative;

  user-select: none;
`;

export const Selector = styled.div<{ isOpen?: boolean }>`
  height: 60px;
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  font-size: 12px;
  border: 1px solid black;
  cursor: pointer;

  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 60px;

  ${({ isOpen }) =>
    isOpen &&
    css`
      border-bottom: none;
    `};
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 26px;
    width: 8px;
    height: 8px;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    transform: translateY(-50%) rotate(-45deg);

    transition: transform 120ms ease-out;

    ${({ isOpen }) =>
      isOpen &&
      css`
        transform: translateY(-50%) rotate(45deg);
      `};
  }
`;

export const List = styled.div`
  position: absolute;
  top: 100%;
  z-index: 100;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: flex-start;

  border: 1px solid black;
  border-top: none;
  background-color: white;

  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;

  width: 100%;
`;

export const ListItem = styled.div`
  padding: 5px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
  &:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
`;

export const Check = styled(SelectChecked)`
  flex-shrink: 0;
`;
