import { API } from "src/constants";

import { Http } from "../http";

import {
  MagazineResponse,
  MagazinePageResponse,
  MainPageMagazineResponse,
} from "./magazine.types";

export const getMagazines = (page: number, perPage = 30): MagazineResponse =>
  Http.get(`${API}/magazine?per_page=${perPage}&page=${page}&old_page_v=true`);

export const getMainPageMagazines = (): MainPageMagazineResponse =>
  Http.get(`${API}/magazine?for_main_page=1`);

export const getMagazinePage = (url: string): MagazinePageResponse =>
  Http.get(`${API}/magazine/${url}`);
