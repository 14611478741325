import React from "react";
import styled from "styled-components";

import { Box } from "src/components/Box";

const MockItemDiv = styled(Box)`
  display: flex;
  gap: 10px 0;
  flex-direction: column;
  max-height: 670px;
  height: auto;
  overflow: hidden;
  opacity: 0.5;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(
      270deg,
      transparent,
      rgba(255, 255, 255, 0.7),
      transparent
    );
    background-size: 100% 100%;
    width: 70%;
    height: 100%;
    left: -100%;
    animation: lightStripe 2.5s linear infinite;
  }
  @keyframes lightStripe {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }

  @media screen and (min-width: 1920px) {
    max-height: 820px;
  }

  @media screen and (min-width: 2298px) {
    max-height: 790px;
  }

  @media screen and (min-width: 2760px) {
    max-height: 815px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 55px;
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 60px;
  }
`;

const MockImage = styled(Box)`
  background-color: lightgrey;
  width: 100%;
  height: auto;
  padding-top: 152%;

  @media screen and (min-width: 1920px) {
    max-height: 820px;
  }

  @media screen and (min-width: 2760px) {
    padding-top: 200%;
  }
`;

const MockLabel = styled(Box)`
  background-color: lightgrey;
  width: 35%;
  height: 10px;
`;

const MockName = styled(Box)`
  background-color: lightgrey;
  width: 80%;
  height: 15px;
`;

const MockPrice = styled(Box)`
  background-color: lightgrey;
  width: 60%;
  height: 12px;
`;

const MockItem: React.FC = () => {
  return (
    <>
      <MockItemDiv>
        <MockImage></MockImage>
        <MockLabel></MockLabel>
        <MockName></MockName>
        <MockPrice></MockPrice>
      </MockItemDiv>
    </>
  );
};

export default MockItem;
