import React, { FC, useState, useEffect } from "react";
import TagManager from "react-gtm-module";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { getPageContent } from "src/api";
import { Preloader, Accordeon } from "src/components";
import { SeoMeta } from "src/types";
import { generateMetaTags } from "src/utils";

import { Container, PaymentPage } from "./Payment.styles";
type IProps = {
  url: string;
};

const LinkPage: FC<IProps> = ({ url }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [content, setContent] = useState<any | null>(null); //  eslint-disable-line @typescript-eslint/no-explicit-any

  const seoTitle = "Оплата";
  const seoDescription =
    "Оплата. Leform: концептуальный интернет-магазин одежды, обуви, аксессуаров и предметов интерьера";
  const metaTags: SeoMeta[] = generateMetaTags(seoTitle, seoDescription);

  useEffect(() => {
    setIsLoading(true);
    getPageContent(url)
      .then(({ data }) => {
        setContent(data);
      })
      .finally(() => setIsLoading(false));
  }, [url]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  }, []);

  return (
    <Container>
      <HelmetProvider>
        <Helmet defer={false} title={seoTitle} meta={metaTags} />
      </HelmetProvider>
      {isLoading ? (
        <Preloader />
      ) : (
        <PaymentPage>
          {content && (
            <>
              <div
                dangerouslySetInnerHTML={{ __html: content["payment-text"] }}
              ></div>
              {content["payment-items"].map(
                (item: { title: string; text: string; id: number }) => {
                  return (
                    <Accordeon
                      header={item.title}
                      content={
                        <div
                          dangerouslySetInnerHTML={{ __html: item.text }}
                        ></div>
                      }
                    ></Accordeon>
                  );
                }
              )}
            </>
          )}
        </PaymentPage>
      )}
    </Container>
  );
};

export default LinkPage;
