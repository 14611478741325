import styled from "styled-components";

import { Button } from "src/components";
import { PromoCoupon } from "src/components/Promocode/Promocode.styles";

export const BonusContainer = styled.div`
  padding: 17px 15px 16px 25px;
  margin-bottom: 16px;
  background: rgba(250, 250, 250, 0.93);

  @media screen and (min-width: 768px) {
    padding: 24px 25px;
  }

  @media screen and (min-width: 769px) {
    padding: 24px 45px;
  }

  @media screen and (min-width: 800px) {
    margin-bottom: 30px;
  }
`;

export const BonusInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BonusTitle = styled.div`
  font-family: "Formular";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #000000;
`;

export const BonusButton = styled(Button)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  width: 104px;
  min-height: 50px;
  border: 1px solid #000000;
  background: #ffffff;
  flex: none;
  color: #000000;

  @media screen and (min-width: 450px) {
    width: 150px;
  }

  :hover {
    background: #000000;
    color: #ffffff;
  }
`;

export const BonusText = styled.div`
  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #000000;
  margin-top: 2px;

  @media screen and (min-width: 500px) {
    line-height: 30px;
  }
`;

export const BoldText = styled.span`
  font-weight: 700;

  &.clickable {
    cursor: pointer;
  }
`;

export const BonusFieldContainer = styled.div<{ isActive: boolean }>`
  width: 100%;
  max-height: 0;
  opacity: 1;
  transition: max-height 0.5s ease;
  overflow: hidden;

  ${(props) =>
    props.isActive &&
    `
    opacity: 1;
    max-height: 72px;

    @media screen and (min-width: 768px) {
      max-height: 77px;
    }
  `}
`;

export const BonusFieldInnerContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 15px 0 7px 0;
  display: flex;

  @media screen and (min-width: 768px) {
    padding: 9px 0 0 0;
  }
`;

export const BonusInput = styled(PromoCoupon)`
  font-size: 12px;

  @media screen and (min-width: 375px) {
    font-size: 16px;
  }

  @media screen and (min-width: 769px) {
    font-size: 12px;
  }

  :disabled {
    border: 1px solid #e6e6e6;
    color: ${({ theme }): string => theme.colors.grey};
  }
`;

export const ToggleContainer = styled.div<{ isActive: boolean }>`
  width: 53px;
  height: 28px;
  background-color: ${(props) => (props.isActive ? "#000000" : "#D9D9D9")};
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  margin-left: 5px;

  @media screen and (min-width: 768px) {
    width: 64px;
    height: 34px;
  }
`;

export const Slider = styled.div<{ isActive: boolean }>`
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border-radius: 25px;
  position: absolute;
  top: 4px;
  left: ${(props) => (props.isActive ? "29px" : "4px")};
  transition: left 0.5s ease-out;

  @media screen and (min-width: 768px) {
    left: ${(props) => (props.isActive ? "33px" : "5px")};
    width: 26px;
    height: 26px;
  }
`;

export const ErrorMessage = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #ff5151;
  margin-top: 6px;
`;
