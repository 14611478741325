import React, { FC, useState } from "react";

import { ShopMap } from "src/components/ShopMap";
import { ReactComponent as WatsappIcon } from "src/images/watsapp.svg";
import { convertImageUrl } from "src/utils";

import {
  MobileSliderDot,
  MobileSliderDots,
} from "../CatalogListItem/MobileSlider/MobileCatalogSlider.styles";

import { ShopCarouselNextArrow, ShopCarouselPrevArrow } from "./ShopCarousel";
import {
  ShopButton,
  ShopHeader,
  ShopItemWrapper,
  ShopWorkTime,
  ShopContactsHeader,
  ShopContactsWrapper,
  ShopContactsTwoWrapper,
  ShopAddressWrapper,
  ShopAddress,
  ShopPhone,
  ShopEmail,
  ShopSocial,
  ShopImage,
  ShopImageWrapper,
  ShopImageCarousel,
  ShopImageContainer,
} from "./ShopItem.styles";
import { ShopProps } from "./ShopItem.types";

const ShopItem: FC<ShopProps> = ({ shop }) => {
  const [isImageVisible, setIsImageVisible] = useState(true);

  const timer = null;
  const numImages = shop.gallery.length;

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleBeforeChange = (oldIndex: number, newIndex: number) => {
    setCurrentIndex(newIndex);
  };

  const settingsProduct = {
    dots: false,
    arrows: true,
    nextArrow: <ShopCarouselNextArrow />,
    prevArrow: <ShopCarouselPrevArrow />,
    slidesToScroll: 1,
    infinite: true,
    slidesToShow: 1,
    swipeToSlide: true,
    autoplay: timer !== null,
    beforeChange: handleBeforeChange,
    autoplaySpeed: timer !== null ? timer * 1000 : undefined,
  };

  const getDots = () => {
    const dots = [];
    const maxDots = 4;
    const totalDots = Math.min(maxDots, numImages);

    let start = Math.max(0, currentIndex - Math.floor(totalDots / 2));
    let end = start + totalDots;

    if (end > numImages) {
      end = numImages;
      start = end - totalDots;
    }

    for (let i = start; i < end; i++) {
      dots.push(<MobileSliderDot key={i} active={i === currentIndex} />);
    }
    return dots;
  };

  return (
    <ShopItemWrapper>
      <ShopImageWrapper>
        {isImageVisible && (
          <>
            <ShopImageCarousel {...settingsProduct}>
              {shop.gallery.map((img, idx) => {
                const images = convertImageUrl(img, 1200, 1200);
                return (
                  <ShopImageContainer>
                    <ShopImage key={idx} src={images}></ShopImage>
                  </ShopImageContainer>
                );
              })}
            </ShopImageCarousel>
            <MobileSliderDots>{getDots()}</MobileSliderDots>
          </>
        )}
        {!isImageVisible && (
          <ShopMap
            lat={parseFloat(shop.latitude)}
            lng={parseFloat(shop.longitude)}
            title={shop.name}
          />
        )}
      </ShopImageWrapper>
      <ShopContactsWrapper>
        <ShopHeader>{shop.name}</ShopHeader>
        <ShopWorkTime>{shop.time}</ShopWorkTime>
        <ShopAddressWrapper>
          <ShopAddress>{shop.address}</ShopAddress>
          <ShopAddress>{shop.metro}</ShopAddress>
        </ShopAddressWrapper>

        <ShopContactsTwoWrapper>
          <ShopContactsHeader>Контакты:</ShopContactsHeader>
          {shop.phones.map((phone) => (
            <ShopPhone
              href={`tel:${phone}`}
              rel="noopener noreferrer"
              key={phone}
            >
              {phone}
            </ShopPhone>
          ))}

          <ShopEmail href={`mailto:${shop.email}`}>{shop.email}</ShopEmail>
          <ShopSocial
            href={shop.whatsapp}
            target="_blanc"
            rel="noopener noreferrer"
          >
            <WatsappIcon />
            WhatsApp
          </ShopSocial>
        </ShopContactsTwoWrapper>
        <ShopButton
          onClick={() => {
            setIsImageVisible((prevValue) => !prevValue);
          }}
        >
          {isImageVisible ? "Посмотреть на карте" : "Посмотреть фотографии"}
        </ShopButton>
      </ShopContactsWrapper>
    </ShopItemWrapper>
  );
};

export default ShopItem;
