import styled from "styled-components";

import { Box } from "../Box";

const PageWrapper = styled(Box)`
  flex: 1 0 auto;
  min-height: 100%;
  .header--checkout {
    margin-top: -105px;
  }
`;

export default PageWrapper;
