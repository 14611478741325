import styled from "styled-components";

export const PreloaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.95);
  z-index: 99;

  div {
    display: inline-block;
    position: relative;
    width: 164px;
    height: 80px;

    div {
      display: inline-block;
      position: absolute;
      left: 0;
      width: 21px;
      background: ${({ theme }) => theme.colors.lightGrey};
      animation: preloaderAnimate 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

      &:nth-child(1) {
        left: 0;
        animation-delay: -0.48s;
      }

      &:nth-child(2) {
        left: 35px;
        animation-delay: -0.36s;
      }

      &:nth-child(3) {
        left: 70px;
        animation-delay: -0.24s;
      }

      &:nth-child(4) {
        left: 105px;
        animation-delay: -0.12s;
      }

      &:nth-child(5) {
        left: 140px;
        animation-delay: 0;
      }
    }

    @keyframes preloaderAnimate {
      0% {
        top: 24px;
        height: 42px;
      }
      50%,
      100% {
        top: 35px;
        height: 21px;
      }
    }
  }
`;
