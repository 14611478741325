import React, { FC } from "react";

import { ROUTES } from "src/constants";

import { Container, Button } from "./Welcome.styles";

const WelcomePage: FC = () => {
  return (
    <Container>
      <Button variant="black" to={ROUTES.login}>
        ВОЙТИ ИЛИ ЗАРЕГИСТРИРОВАТЬСЯ
      </Button>
    </Container>
  );
};

export default WelcomePage;
