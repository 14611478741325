import React, { useCallback, FC } from "react";
import { useHistory } from "react-router-dom";

import { SingleInputForm } from "src/components";
import { ROUTES } from "src/constants";

import { Container } from "../Reset.styles";

const ResetByPhonePage: FC = () => {
  const history = useHistory();

  const handleSubmit = useCallback(
    (value: string) => {
      history.push(ROUTES.resetConfirmation);
    },
    [history]
  );

  return (
    <Container>
      <SingleInputForm
        inputName="phone"
        inputType="tel"
        placeholder="Ведите номер телефона"
        buttonText="ПОДТВЕРДИТЬ"
        onSubmit={handleSubmit}
        isRequired
      />
    </Container>
  );
};

export default ResetByPhonePage;
