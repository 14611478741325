import styled from "styled-components";

import { Box, LinkButton } from "src/components";

export const Container = styled(Box)`
  margin: 200px auto 100px;
  max-width: 370px;
  width: 100%;
`;

export const Button = styled(LinkButton)`
  height: 50px;
`;
