import React, { FC, useCallback, useMemo, useState, useEffect } from "react";

import { getOrderPaymentMethods } from "src/api";
import { RadioItem, RadioGroup } from "src/components";
import { PaymentMethod } from "src/types";

import { StyledMain } from "../styles";

import { ChoosePaymentMethodProps } from "./types";

const ChoosePaymentMethod: FC<ChoosePaymentMethodProps> = ({
  className,
  delivery,
  onSelect,
  setDeliveryPrice,
  onError,
}) => {
  const [allPaymentMethods, setAllPaymentMethods] = useState<PaymentMethod[]>();
  const [payment, setPayment] = useState<PaymentMethod>();

  useEffect(() => {
    getOrderPaymentMethods(delivery.id).then(({ data }) => {
      if (delivery.id === 6) {
        if (data.basket.disabled) {
          if (data.basket.error) {
            onError(data.basket.disabled, data.basket.error);
          }
        } else {
          onError(data.basket.disabled, "");
        }
      } else {
        onError(false, "");
      }
      const array = data.payments;
      setAllPaymentMethods(array);
      setDeliveryPrice(data.basket.delivery_price.toString());
      if (array.length === 1) {
        setPayment(array[0]);
        onSelect(array[0]);
      }
    });
  }, [delivery]);

  useEffect(() => {
    const PAYMENT = localStorage.getItem("payment_type");
    if (PAYMENT) {
      const PAY = JSON.parse(PAYMENT);
      setPayment(PAY);
      onSelect(PAY);
    }
  }, [onSelect]);

  const items = useMemo(
    () =>
      allPaymentMethods?.map<RadioItem>((method) => ({
        value: String(method.id),
        text: method.name,
        code: method.code,
      })),
    [allPaymentMethods]
  );

  const current = payment && String(payment.id);

  const handleChange = useCallback(
    (key: string) => {
      setPayment(allPaymentMethods?.find((method) => `${method.id}` === key));
      onSelect(allPaymentMethods?.find((method) => `${method.id}` === key));
    },
    [allPaymentMethods, onSelect]
  );

  return (
    <>
      {items && (
        <>
          <StyledMain>
            <RadioGroup
              name={"payment"}
              items={items}
              current={current}
              onChange={handleChange}
              className={`radioGroupInCheckout radioInPayment ${className}`}
            />
          </StyledMain>
        </>
      )}
    </>
  );
};

export default ChoosePaymentMethod;
