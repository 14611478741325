import React, { FC, useState, useEffect } from "react";
import TagManager from "react-gtm-module";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { Preloader } from "src/components";
import { SeoMeta } from "src/types";
import { generateMetaTags } from "src/utils";

import { Container, CardsPageStyle, CardsRow } from "./CardsPage.styles";
type IProps = {
  gender: string;
};

const CardsPage: FC<IProps> = ({ gender }) => {
  const [isLoading] = useState<boolean>(false);
  const seoTitle = gender + " подарочные сертификаты";
  const seoDescription =
    gender +
    " подарочные сертификаты различного номинала. Купить в официальном интернет-магазине Leform в Москве. ⚑ Только оригинальные брендовые вещи, скидки, большой ассортимент. ✈ Быстрая доставка по всей России.";

  const metaTags: SeoMeta[] = generateMetaTags(seoTitle, seoDescription);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  }, []);
  const Cards = [
    {
      class: "card--blue",
      link: "/catalog/product/podarocnaa_karta-leform-СЕРТ000001",
      name: "Подарочный сертификат 10000",
    },
    {
      class: "card--tel",
      link: "/catalog/product/podarocnaa_karta-leform-СЕРТ000002",
      name: "Подарочный сертификат 20000",
    },
    {
      class: "card--brown",
      link: "/catalog/product/podarocnaa_karta-leform-СЕРТ000003",
      name: "Подарочный сертификат Exclusive",
    },
  ];
  return (
    <Container>
      <HelmetProvider>
        <Helmet defer={false} title={seoTitle} meta={metaTags} />
      </HelmetProvider>
      {isLoading ? (
        <Preloader />
      ) : (
        <CardsPageStyle>
          <h1>Подарочные карты</h1>
          <p className="bold">
            Предоставьте вашим близким приятную возможность самостоятельно
            выбрать себе подарок в одном из наших магазинов с подарочной картой
            LEFORM.
          </p>
          <p className="tel">
            Подарочную карту можно приобрести онлайн или во всех{" "}
            <a href="/shops/">оффлайн магазинах LEFORM.</a> На стоимость
            подарочной карты не влияют сезонные скидки, скидки по акциям и по
            дисконтным картам LEFORM.
          </p>
          <div className="cards-row">
            <CardsRow>
              {Cards.map((card) => (
                <div key={card?.name} className="card">
                  <a className="bold" href={card?.link}>
                    <div className={`image-content ${card?.class} `}>
                      <img src={require("src/images/image_card.png")} alt="" />
                    </div>
                    <p className="bold">{card?.name}</p>
                  </a>
                </div>
              ))}
            </CardsRow>
          </div>
          <a
            className="reglament-link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://leback.leform.ru/storage/%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0_%D0%B8%D1%81%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F_%D0%BF%D0%BE%D0%B4%D0%B0%D1%80%D0%BE%D1%87%D0%BD%D0%BE%D0%B8%CC%86_%D0%BA%D0%B0%D1%80%D1%82%D1%8B_LEFORM.pdf"
          >
            Регламент использования
          </a>
        </CardsPageStyle>
      )}
    </Container>
  );
};

export default CardsPage;
