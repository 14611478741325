import React, { FC, useEffect, useState } from "react";

import { getCatalog } from "../../api";
import { ProductList } from "../../components";
import CarouselNextArrow from "../../components/Carousel/CarouselNextArrow";
import CarouselPrevArrow from "../../components/Carousel/CarouselPrevArrow";
import { Banner, CatalogListItem } from "../../types";
import {
  ProductListContainer,
  ProductListTitle,
} from "../Catalog/Product/Product.styles";

import { BoxBannerItems, BannerLink } from "./Main.styles";

type propsType = {
  banner: Banner;
  isFirst?: boolean;
};
export const BannerItems: FC<propsType> = ({ banner, isFirst }) => {
  const [viewedItems, setViewedItems] = useState<CatalogListItem[]>([]);
  useEffect(() => {
    getCatalog(`${banner.url}?per_page=60&use_cache=Y`, {}).then(({ data }) => {
      setViewedItems(data.items);
    });
  }, []);

  return (
    <BoxBannerItems className={isFirst ? "after-main" : ""} key={banner.id}>
      <ProductListContainer>
        <div className="banner_items-header">
          <BannerLink href={banner.url || ""}>
            <ProductListTitle>{banner.name}</ProductListTitle>
          </BannerLink>
          <BannerLink href={banner.url || ""}>
            <span>{banner.buy_button_name || "СМОТРЕТЬ ЕЩЁ"}</span>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.69436 2.22227L14.5 10.028L6.69436 17.8336"
                stroke="black"
                stroke-width="2.22222"
              />
            </svg>
          </BannerLink>
        </div>
        <ProductList
          items={viewedItems}
          catalogClassName={"main"}
          itemClassName={"banner_item"}
          slidesToShowProps={5}
          nextArrowComponent={<CarouselNextArrow />}
          prevArrowComponent={<CarouselPrevArrow />}
          mobileSlidesToShowProps={2}
          mobileShowArrow={true}
          isViewed
        />
      </ProductListContainer>
    </BoxBannerItems>
  );
};
