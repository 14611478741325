import styled from "styled-components";

import { Box, LinkButton, Text } from "src/components";

export const Container = styled(Box)`
  max-width: 370px;
  width: 100%;
  margin: 70px auto;

  @media screen and (max-width: 480px) {
    margin: 60px auto;
  }
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.01em;
  margin: 0;
`;

export const Description = styled(Text)`
  font-size: 14px;
  text-align: center;
  margin: 26px 0 0;
`;

export const ButtonContainer = styled(Box)`
  width: 100%;
  margin-top: 90px;
`;

export const Button = styled(LinkButton)`
  width: 100%;
  height: 50px;

  :last-of-type {
    margin-top: 30px;
  }
`;
