import React, { useState, useCallback, FC, useEffect } from "react";
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import {
  authorizeUser,
  setUserData,
  setUserAddresses,
  setCurrentWorkFlow,
} from "src/actions";
import { getUserData, setToken, getAddresses, phoneAuth } from "src/api";
import { Preloader, SingleInputForm } from "src/components";
import { ROUTES } from "src/constants";
import { usePreloaderTimer } from "src/hooks/usePreloaderTimer";
import { ApplicationStore, WishlistTypes } from "src/store";
import {
  applyPhoneMask,
  getBasketId,
  sendUserAuthStatusToTagManager,
  sendUserRegistredEventToTagManager,
  setBasketId,
  setFavoritesToLocalStorage,
} from "src/utils";

import { Container, Title, Description } from "./Confirmation.styles";
import { ConfirmationProps, StateType } from "./Confirmation.types";

const ConfirmationPage: FC<ConfirmationProps> = ({ returnTo }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<StateType>();
  const [error, setError] = useState("");
  const [currencyMask, setCurrentMask] = useState("");
  const [isRegister, setRegister] = useState(false);
  const wishListData = useSelector((state: ApplicationStore) => state.wishlist);
  const favoritesDataString = JSON.stringify(wishListData.wishListNoUuid) || "";

  const [isLoaderShown, onStartFetching, onEndFetching] = usePreloaderTimer();

  const phone = sessionStorage.getItem("phone");
  const countryCode = sessionStorage.getItem("countryCode");

  const handleSubmit = useCallback(
    (value: string) => {
      const useExternal = sessionStorage.getItem("use_external");
      onStartFetching();
      phoneAuth({
        sms_code: value,
        phone: phone || "",
        use_external: useExternal || "",
        basket_uuid: getBasketId() || undefined,
        wishlist: favoritesDataString,
      })
        .then(({ data }) => {
          window.localStorage.setItem("token_captcha", data.access_token);
          if (data.first_time) {
            sendUserRegistredEventToTagManager();
          }
          setToken(data.access_token);
          setBasketId(data.basket_uuid);
          dispatch(authorizeUser(data));

          getUserData().then(({ data: userData }) => {
            dispatch(setUserData(userData));

            if (userData?.user_id) {
              if (isRegister) {
                TagManager.dataLayer({
                  dataLayer: {
                    registration: "success",
                    userId: userData.user_id,
                  },
                });
              }

              TagManager.dataLayer({
                dataLayer: { event: "user-auth", userId: userData.user_id },
              });
            }

            if (userData?.id_1c) {
              sendUserAuthStatusToTagManager({ id_1c: userData.default_phone });
            }

            if (!userData.first_name && !userData.last_name) {
              history.push(ROUTES.personalDataCeate);
            } else if (!userData.name) {
              history.push(ROUTES.personalDataEdit);
              localStorage.setItem("CheckoutWithData", "true");
            } else if (localStorage.getItem("inCheckout")) {
              // localStorage.removeItem("inCheckout");
              history.push({
                pathname: ROUTES.checkout,
                state: {
                  isRegister,
                },
              });
            } else if (window.localStorage.getItem("routeBack")) {
              const routeBack = window.localStorage.getItem("routeBack") || "";
              history.push({
                pathname: routeBack,
              });
              window.localStorage.removeItem("routeBack");
            } else {
              history.push(returnTo);
            }
          });
          getAddresses().then(({ data: addresses }) => {
            dispatch(setUserAddresses(addresses));
          });
          dispatch(
            setCurrentWorkFlow({
              userWorkFlow: "authenticated",
            })
          );
          setFavoritesToLocalStorage([]);
          dispatch({ type: WishlistTypes.CLEAR_NO_UUID });
          //document.location.reload();
        })
        .catch((error) => {
          setError(error.response.data.messages);
        })
        .finally(onEndFetching);
    },
    [dispatch, history, isRegister, returnTo, onStartFetching, onEndFetching]
  );

  const getPhoneMaskByContryCode = (countryCode: string | null): string => {
    switch (countryCode) {
      case "Россия +7":
        return "+_ (___) ___-__-__";
      case "Беларусь +375":
        return "+___ (__) ___-__-__";
      case "Казахстан +7":
        return "+_ (___) ___-__-__";
      case "Армения +374":
        return "+___ (__) __-__-__";
      default:
        return "+_ (___) ___-__-__";
    }
  };

  useEffect(() => {
    setCurrentMask(getPhoneMaskByContryCode(countryCode));
  }, [countryCode]);

  useEffect(() => {
    if (location.state) {
      setRegister(location.state.isRegister);
    }
  }, [location]);

  return (
    <Container>
      {isLoaderShown && <Preloader />}

      <Title>Подтвердите номер</Title>
      <Description>
        Введите код из СМС, которое мы
        <br />
        отправили на номер <b>{applyPhoneMask(phone, currencyMask)}</b>
      </Description>
      <SingleInputForm
        inputName="code"
        placeholder="Одноразовый пароль"
        buttonText="ПРОДОЛЖИТЬ"
        onSubmit={handleSubmit}
        isCentered={true}
        inputType="number"
        inputMode="numeric"
        hasError={!!error}
        error={error}
        isRequired
        setError={setError}
      />
    </Container>
  );
};

export default ConfirmationPage;
