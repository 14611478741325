import { StringifiableRecord } from "query-string";
import React, { useCallback, useMemo, FC } from "react";

import { Icons } from "src/components";
import {
  deserializeFilters,
  serializeFilters,
} from "src/components/CatalogFilters/CatalogFilters.utils";
import { stringifyConfig } from "src/constants";
import { useQueryParams } from "src/hooks";

import { Container, Tag } from "./FilterTags.styles";
import { FilterTag, FilterTagsProps } from "./FilterTags.types";
import { getFilterTags, getTagLabel } from "./FilterTags.utils";

const FilterTags: FC<FilterTagsProps> = (props) => {
  const { filters } = props;

  const { queryParams, setQueryParams } = useQueryParams({
    arrayFormat: "bracket",
  });

  const deserializedFilters = useMemo(
    () => deserializeFilters(queryParams, filters),
    [filters, queryParams]
  );
  const filterTags = useMemo(
    () => getFilterTags(deserializedFilters, filters),
    [deserializedFilters, filters]
  );

  const handleDelete = useCallback(
    (tag: FilterTag) => () => {
      const newParams = { ...deserializedFilters };

      if (tag.type === "checkbox" && tag.value?.id) {
        const item = newParams[`filter[${tag.code}]`] || {};
        newParams[`filter[${tag.code}]`] = {
          ...(typeof item === "object" ? item : {}),
          [`${tag.value?.id}`]: false,
        };
      } else {
        newParams[`filter[${tag.code}]`] = "";
      }

      const serializedFilters = serializeFilters(
        newParams as StringifiableRecord,
        filters
      );
      setQueryParams(serializedFilters, false, stringifyConfig);
    },
    [deserializedFilters, filters, setQueryParams]
  );

  if (!filterTags.length) {
    return null;
  }

  return (
    <Container>
      {filterTags.map((item) => (
        <Tag
          key={`${item.code}.${item.value?.id}`}
          onClick={handleDelete(item)}
        >
          <Icons.Close />
          <span>{getTagLabel(item)}</span>
        </Tag>
      ))}
    </Container>
  );
};

export default FilterTags;
