/* eslint-disable max-len */

import React, { FC } from "react";

import { SvgProps } from "src/types";

const FilterArrow: FC<SvgProps> = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M7.79571 4.34315L13.0074 10L7.79571 15.6569"
      stroke="black"
      stroke-width="2"
    />
  </svg>
);

FilterArrow.defaultProps = {
  width: "20px",
  height: "20px",
  fill: "black",
};

export default FilterArrow;
