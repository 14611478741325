import React, { FC } from "react";

import {
  CloseButton,
  CloseIcon,
  ModalContainer,
  ModalTitle,
  Subtitle,
  Text,
  Table,
} from "./TableSizesModal.styles";
import { TableSizesModalProps } from "./TableSizesModal.types";

const TableSizesModal: FC<TableSizesModalProps> = ({
  head,
  sizes,
  onClose,
  sizeName,
  preview,
  detail,
  size_head,
}) => {
  const sizeIndex = head.findIndex((sizeItem) => sizeItem === "Размер");
  const ruIndex =
    sizeIndex !== -1
      ? sizeIndex
      : head.findIndex((sizeItem) => sizeItem === "RU");

  return (
    <ModalContainer onClose={onClose}>
      <CloseButton onClick={onClose} aria-label="Закрыть">
        <CloseIcon />
      </CloseButton>

      <ModalTitle>Таблица размеров</ModalTitle>
      {size_head.length > 0 && <Subtitle>{size_head}</Subtitle>}
      {/*preview.length > 0 && (
        <Text dangerouslySetInnerHTML={{ __html: preview }} />
      )*/}
      {head.length > 0 && sizes.length > 0 && (
        <Table>
          <thead>
            <tr>
              {head.map((th, indexTh) => (
                <th key={`th_${indexTh}`}>{th}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sizes.map((tr, indexTr) => (
              <tr key={`tr_${indexTr}`}>
                {tr.map((size, indexSize) => (
                  <td
                    key={`size_${indexSize}`}
                    style={{
                      background:
                        size === sizeName && indexSize === ruIndex
                          ? "grey"
                          : "transparent",
                    }}
                  >
                    {size === sizeName && indexSize === ruIndex ? (
                      <b>{size}</b>
                    ) : (
                      <span>{size}</span>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {detail.length > 0 && (
        <Text dangerouslySetInnerHTML={{ __html: detail }} />
      )}
    </ModalContainer>
  );
};

export default TableSizesModal;
