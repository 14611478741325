import styled from "styled-components";

import { Box } from "src/components/Box";

export const Block = styled.div`
  border-top: 1px solid #e5e5e5;

  &:last-child {
    border-bottom: 1px solid #e5e5e5;
  }
`;

export const InnerBlock = styled(Box)`
  &:not(:first-child) {
    margin-top: 14px;
  }
  &:last-child {
    margin-bottom: 20px;
  }
`;

export const InputContainer = styled.div<{ isInnerFilter?: boolean }>`
  padding: 10px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }
  ${(props) =>
    props.isInnerFilter &&
    `
    padding: 0;
  `}
`;
