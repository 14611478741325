/* eslint-disable max-len */

import React, { FC, useMemo } from "react";
import { Link } from "react-router-dom";

import { ROUTES } from "src/constants";
import { parseImages, convertImageUrl } from "src/utils";

import {
  BrandInfoWrapper,
  ImageContainer,
  Picture,
  Image,
  BrandTitle,
  BrandText,
  BreadCrumbs,
  Slash,
  CategoryName,
} from "./BrandInfo.styles";
import { BrandInfoProps } from "./BrandInfo.types";

const BrandInfo: FC<BrandInfoProps> = ({ name, text, gallery }) => {
  const image = useMemo(() => parseImages(gallery), [gallery]);

  return (
    <>
      <BreadCrumbs
        itemScope
        itemType="http://schema.org/BreadcrumbList"
        isEmpty={!!text}
      >
        <li
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
        >
          <Link itemProp="item" to={ROUTES.home}>
            <span itemProp="name">Leform</span>
            <meta itemProp="position" content="1" />
          </Link>
        </li>
        <Slash>/</Slash>
        <li
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
        >
          <Link itemProp="item" to={ROUTES.brands}>
            <span itemProp="name">Бренды</span>
            <meta itemProp="position" content="2" />
          </Link>
        </li>
        <Slash>/</Slash>
        <li
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
        >
          <CategoryName itemProp="name">{name}</CategoryName>
          <meta itemProp="position" content="3" />
        </li>
      </BreadCrumbs>
      {text && (
        <BrandInfoWrapper>
          {!!image?.[0] && (
            <ImageContainer>
              <Picture>
                <Image src={convertImageUrl(image[0], 2000, 2000)} alt={name} />
              </Picture>
            </ImageContainer>
          )}
          <div>
            <BrandTitle>{name}</BrandTitle>
            <BrandText dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        </BrandInfoWrapper>
      )}
    </>
  );
};

export default BrandInfo;
