import styled from "styled-components";

export const Fieldset = styled.fieldset`
  display: flex;
  align-items: flex-start !important;
  flex-wrap: wrap;

  border: none;
  padding: 0;
  margin: 0;

  > *:not(:last-child) {
    margin-bottom: 36px;
  }
`;

export const Item = styled.label`
  width: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  &.payment_radio {
    width: 100%;
  }

  cursor: pointer;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const RadioInput = styled.input.attrs({
  type: "radio",
})`
  visibility: hidden;
  opacity: 0;
  position: absolute;
`;

export const StyledRadioButton = styled.div`
  flex-shrink: 0;
  margin-right: 20px;

  box-sizing: border-box;
  width: 34px;
  height: 34px;
  border-radius: 100px;
  border: 1px solid black;

  display: flex;
  align-items: center;
  justify-content: center;

  ${RadioInput}:checked + &::after {
    content: "";
    width: 12px;
    height: 12px;
    background-color: black;
    border-radius: 100px;
  }
`;

export const Text = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  margin-top: 2px;
`;

export const Img = styled.img`
  object-fit: cover;
  margin-right: 10px;
  height: 16px;
`;
