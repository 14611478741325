import styled from "styled-components";

import { Box } from "../Box";
import { Button } from "../Button";
import { Flex } from "../Flex";
import { Icons } from "../Icons";
import { Link } from "../Link";
import { Text } from "../Text";

export const ContainerItem = styled(Box)`
  width: 100%;

  &.also {
    width: calc(100% - 10px);
  }

  &.main {
    margin: 0 5px;
    width: calc(100% - 10px);
  }

  &.catalog {
    position: relative;
  }
  @media screen and (min-width: 768px) {
    &:hover.catalog ul,
    &:hover.catalog button,
    &:focus.catalog ul,
    &:focus.catalog button {
      visibility: visible;
      opacity: 1;
    }
  }

  &.favourites {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 600px) {
      width: auto;
      height: 100%;
      padding-right: 15px;
    }
  }
`;

export const ImageLink = styled(Link)`
  width: 100%;
  height: auto;
  display: block;
  position: relative;

  .image-0 {
    opacity: 1;
    visibility: visible;
    z-index: 1;
    transition: all 0.5s;
  }
  .image-1 {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
    transition: all 0.5s;
  }
  &.selected,
  &:hover,
  &:focus {
    .image-0 {
      opacity: 0;
      visibility: hidden;
      z-index: 0;
    }
    .image-1 {
      opacity: 1;
      visibility: visible;
      z-index: 1;
    }
    .sizeListMobile,
    & ~ button {
      opacity: 1;
      visibility: visible;
      z-index: 20;
    }
  }

  @media screen and (max-width: 767px) {
    &:before {
      content: "";
      background-color: rgba(0, 0, 0, 0.39);
      visibility: hidden;
      transition: all 0.3s;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
    }
    &.selected:before {
      visibility: visible;
      opacity: 1;
      z-index: 5;
    }
  }
`;

export const ImageContainer = styled(Box)`
  width: 100%;
  height: min-content;
  max-height: 670px;

  &:before {
    content: "";
    height: 0;
    display: inline-block;
    width: 1px;
    position: relative;
    padding-top: 150% !important;
  }

  &.favourites {
    width: 140px;
    height: 210px;

    @media screen and (max-width: 600px) {
      width: auto;
    }
  }

  @media screen and (min-width: 1920px) {
    max-height: 820px;
    &:before {
      padding-top: 180%;
    }
  }

  @media screen and (min-width: 2298px) {
    max-height: 790px;
    &:before {
      padding-top: 180%;
    }
  }

  @media screen and (min-width: 2760px) {
    max-height: 815px;
    &:before {
      padding-top: 200%;
    }
  }
`;

export const ImageImg = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: block;
`;

export const InfoContainer = styled(Box)`
  width: 100%;
  margin-top: 16px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
  &.favourites {
    margin-top: 13px;
  }
`;

export const PriceContainer = styled(Flex)`
  margin-top: 8px;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    margin-top: 1px;
  }

  &.favourites {
    margin: 4px 0 4px;

    @media screen and (max-width: 1200px) {
      margin-top: 7px;
    }

    @media screen and (max-width: 768px) {
      margin: 5px 0 6px;
    }
  }
`;

export const Price = styled(Text)`
  font-size: 16px;
  color: ${({ theme }): string => theme.colors.black};
  margin: 0;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }

  &.favourites {
    font-size: 12px;
    line-height: 16px;

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
`;

export const OldPrice = styled(Price)`
  color: ${({ theme }): string => theme.colors.grey};
  text-decoration: line-through;
  margin-right: 12px;
`;

export const Picture = styled.picture`
  width: 100%;
  height: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

export const CheckoutButton = styled(Button)`
  font-size: 7px;
  line-height: 10px;
  padding: 8px 2px 7px 2px;
  width: 70px;
`;

export const DeleteButton = styled.button`
  font-size: 12px;
  line-height: 20px;

  width: 60px;
  background-color: transparent;
  border: none;
  padding-left: 13px;
  margin-left: 10px;

  position: relative;

  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

export const CrossIcon = styled(Icons.Close)`
  width: 8px;
  height: 8px;

  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const SizeList = styled.ul`
  margin: 0;
  padding: 0;
  gap: 9px;
  list-style: none;
  visibility: hidden;
  position: absolute;
  bottom: -64px;
  left: 0;
  background-color: white;
  width: 100%;
  height: 52px;
  z-index: 70;
  transition: 0.3s ease;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: flex-start;
  opacity: 0;

  &.sizeListMobile {
    display: none;
  }

  @media screen and (max-width: 768px) {
    width: 80%;
    bottom: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: auto;

    &.sizeListDesktop {
      display: none;
    }
    &.sizeListMobile {
      display: flex;
    }
  }
`;

export const SizeItem = styled.li`
  padding: 0;
  font-size: 14px;
  line-height: 18px;
  height: 18px;
  min-width: 10px;

  @media screen and (max-width: 768px) {
    color: #fff;
  }
`;

export const ProductNameContainer = styled(Link)`
  &:hover * {
    color: ${({ theme }): string => theme.colors.grey};
  }
`;

export const Name = styled.div`
  font-family: Formular, sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }): string => theme.colors.black};
  margin: 0;
  word-wrap: break-word;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 3px;
  }

  &.favourites {
    font-family: Formular, sans-serif;
    font-size: 14px;
    line-height: 17px;

    margin: 0;
  }
`;

export const ProductType = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }): string => theme.colors.black};
  margin-bottom: 6px;
  word-wrap: break-word;
  margin-bottom: 6px;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
