import {
  GenderType,
  UserAddress,
  CardsType,
  UserWorkFlow,
  UserWorkFlowData,
  GradeType,
} from "src/types";

export type UserInfo = {
  checkoutId: string;
  isFirstTime?: boolean;
};

export type UserData = {
  checkoutId: string;
  userEmail: string;
  userId: number | null;
  userName: string;
  userFirstName: string;
  userLastName: string;
  phone: string;
  genders: GenderType | null;
  genderId: number;
  date_of_birth: Date;
  customerCards: CardsType | null;
  min_discount_percent: number | null;
  savings: number | null;
  is_sms: boolean;
  is_email: boolean;
  is_mobile_push: boolean;
  bonuses?: BonusesType | null;
  first_name?: string;
  last_name?: string;
  consent_to_communication?: boolean | null;
  first_time?: boolean;
  orders_count?: number;
  shareFavoriteId?: null | string;
  is_retail: boolean;
};

export type BonusesType = {
  balance: number | null;
  rate_description: string;
  percentage: number | null;
  available: number | null;
  details: Array<{
    remain?: number | null;
    action?: string | null;
    diff?: number;
  }>;
  grade: {
    current_grade: GradeType | null;
    next_grade: string | null;
    next_grade_remain: string | null;
    total_amount: number | null;
  } | null;
};

export type User = {
  // TODO: Дополнить поля
  isAuthorized: boolean;
  userWorkFlow: UserWorkFlowData;
  info?: UserInfo;
  userData?: UserData;
  userAddresses?: UserAddress[];
  isFirstTime?: boolean;
  first_time?: boolean;
};

export enum UserTypes {
  SET = "SET_USER",
  SET_AUTHORIZED = "SET_USER_AUTHORIZED",
  SET_DATA = "SET_USER_DATA",
  SET_ADDRESSES = "SET_USER_ADDRESSES",
  SET_CURRENT_WORKFLOW = "SET_USER_CURRENT_WORKFLOW",
  CLEAR = "CLEAR_USER_DATA",
}

export type SetUser = {
  type: UserTypes.SET;
  payload: User;
};

export type SetUserData = {
  type: UserTypes.SET_DATA;
  payload: UserData;
};

export type SetUserAuthorized = {
  type: UserTypes.SET_AUTHORIZED;
  payload: boolean;
};

export type SetUserAddresses = {
  type: UserTypes.SET_ADDRESSES;
  payload: UserAddress[];
};

export type SetUserCurrentWorkFlow = {
  type: UserTypes.SET_CURRENT_WORKFLOW;
  payload: UserWorkFlow;
};

export type ClearUserData = {
  type: UserTypes.CLEAR;
};

export type UserActions =
  | SetUser
  | SetUserAuthorized
  | SetUserData
  | SetUserAddresses
  | SetUserCurrentWorkFlow
  | ClearUserData;
