import React, { FC } from "react";

import { SvgProps } from "src/types";

const ArrowFilterDown: FC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.75 5L11.75 4.25L10.25 4.25L10.25 5L11.75 5ZM10.4697 17.5303C10.7626 17.8232 11.2374 17.8232 11.5303 17.5303L16.3033 12.7574C16.5962 12.4645 16.5962 11.9896 16.3033 11.6967C16.0104 11.4038 15.5355 11.4038 15.2426 11.6967L11 15.9393L6.75736 11.6967C6.46447 11.4038 5.98959 11.4038 5.6967 11.6967C5.40381 11.9896 5.40381 12.4645 5.6967 12.7574L10.4697 17.5303ZM10.25 5L10.25 17L11.75 17L11.75 5L10.25 5Z"
      fill="black"
    />
  </svg>
);

ArrowFilterDown.defaultProps = {
  width: "22px",
  height: "22px",
  fill: "none",
};

export default ArrowFilterDown;
