import React, { useState, ChangeEvent, FC } from "react";

import { Block, InputContainer } from "../../CatalogFilters.styles";
import { Collapse } from "../Collapse";
import { Input } from "../Input";
import { Scrollbar } from "../Scrollbar";
import { Search } from "../Search";

import { FilterWithSearchProps } from "./FilterWithSearch.types";

const FilterWithSearch: FC<FilterWithSearchProps> = (props) => {
  const { filter, values, disabled, onChange } = props;

  const [search, setSearch] = useState("");
  const searchedFilter = filter.values.filter(({ name }) =>
    `${name}`.toLowerCase().includes(search.toLowerCase())
  );

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <Block key={filter.name}>
      <Collapse title={filter.name}>
        <Search value={search} onChange={handleSearch} />

        <Scrollbar>
          {searchedFilter.map((value) => (
            <InputContainer key={value.id}>
              <Input
                filterValue={value}
                filterType={filter.type}
                filterCode={filter.code}
                onChange={onChange}
                values={values}
                disabled={disabled}
              />
            </InputContainer>
          ))}
        </Scrollbar>
      </Collapse>
    </Block>
  );
};

export default FilterWithSearch;
