import styled from "styled-components";

import { Box, MainMenu } from "src/components";

export const Container = styled.main`
  padding: 0 40px 180px;
  width: 100%;
  position: relative;

  @media screen and (max-width: 768px) {
    padding: 30px 20px 100px;
  }

  @media screen and (max-width: 760px) {
    padding: 25px 0 80px;
  }
`;

export const GarderoboWrapper = styled.div`
  @media screen and (min-width: 1441px) {
    padding-right: 40px;
  }

  @media screen and (min-width: 768px) and (max-width: 1000px) {
    padding-right: 5px;
  }
`;

export const ProductListContainer = styled(Box)`
  width: 100%;
  margin-top: 48px;

  @media screen and (max-width: 760px) {
    padding: 0 20px 0;
    margin-top: 48px;
  }

  @media screen and (max-width: 600px) {
    padding: 0 15px 0;
    margin-top: 48px;
  }

  &.also {
    @media screen and (min-width: 1441px) {
      padding-right: 40px;
    }

    @media screen and (min-width: 768px) and (max-width: 1000px) {
      padding-right: 5px;
    }
  }
`;

export const ProductListTitle = styled.h2`
  font-family: HelveticaNeueCyr, sans-serif;
  font-weight: 500;
  font-size: 26px;
  margin-bottom: 38px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 600px) {
    font-size: 16px;
    margin-bottom: 32px;
  }
`;

export const TabletMenu = styled(MainMenu)`
  display: none;
  padding: 30px 0 26px;
  border-bottom: 1px solid ${({ theme }): string => theme.colors.lightGrey};

  @media screen and (min-width: 601px) and (max-width: 768px) {
    display: flex;
  }
`;
