import React, { FC, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import { useActiveSlider } from "src/context/SliderContext";
import { convertImageUrl } from "src/utils";

import {
  ImageSlide,
  ImageSlideContainer,
  MobileSliderDot,
  MobileSliderDots,
  SliderWrapper,
} from "./MobileCatalogSlider.styles";
import { MobileCatalogSliderProps } from "./MobileCatalogSlider.types";

const MobileCatalogSlider: FC<MobileCatalogSliderProps> = ({
  images,
  altName,
  link,
  onProductSelect,
  sliderId,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { setActiveSliderId } = useActiveSlider();

  const sliderWrapperRef = useRef<HTMLDivElement | null>(null);
  const startX = useRef(0);
  const startY = useRef(0);
  const isDragging = useRef(false);
  const longPressTimer = useRef<number>(0);
  const numImages = images.length;
  const history = useHistory();

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    startX.current = event.touches[0].clientX;
    startY.current = event.touches[0].clientY;
    isDragging.current = true;
    setActiveSliderId(sliderId);

    longPressTimer.current = setTimeout(() => {
      onProductSelect();
      history.push(link);
    }, 500);
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    if (!isDragging.current) return;
    clearTimeout(longPressTimer.current);

    const currentX = event.touches[0].clientX;
    const diffX = startX.current - currentX;

    if (
      (diffX < 0 && currentIndex === 0) ||
      (currentIndex === numImages - 1 && diffX > 0)
    ) {
      return;
    }

    if (sliderWrapperRef.current !== null) {
      sliderWrapperRef.current.style.transform = `translateX(${
        -currentIndex * 100 - (diffX / window.innerWidth) * 100
      }%)`;
    }
  };

  const handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
    clearTimeout(longPressTimer.current);
    isDragging.current = false;

    const currentX = event.changedTouches[0].clientX;
    const currentY = event.changedTouches[0].clientY;
    const diffX = startX.current - currentX;
    const diffY = startY.current - currentY;

    if (Math.abs(diffX) < 10 && Math.abs(diffY) < 10) {
      onProductSelect();
      history.push(link);
    }

    if (diffX > 50 && currentIndex < numImages - 1) {
      setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, numImages - 1));
    } else if (diffX < -50 && currentIndex > 0) {
      setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    }

    if (sliderWrapperRef.current !== null) {
      sliderWrapperRef.current.style.transition = "transform 0.3s ease-out";
      sliderWrapperRef.current.style.transform = `translateX(${
        -currentIndex * 100
      }%)`;
    }
  };

  const getDots = () => {
    const dots = [];
    const maxDots = 4;
    const totalDots = Math.min(maxDots, numImages);

    let start = Math.max(0, currentIndex - Math.floor(totalDots / 2));
    let end = start + totalDots;

    if (end > numImages) {
      end = numImages;
      start = end - totalDots;
    }

    for (let i = start; i < end; i++) {
      dots.push(<MobileSliderDot key={i} active={i === currentIndex} />);
    }
    return dots;
  };

  return (
    <div
      className="slider-container"
      style={{ overflow: "hidden", width: "100%", position: "relative" }}
    >
      <SliderWrapper
        ref={sliderWrapperRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        style={{
          transform: `translateX(${-currentIndex * 100}%)`,
        }}
      >
        {images.map((image, index) => (
          <ImageSlideContainer key={index}>
            <ImageSlide
              src={convertImageUrl(image, 600, 824)}
              loading="lazy"
              alt={`${altName ? altName : "Slide"} ${index}`}
            />
          </ImageSlideContainer>
        ))}
      </SliderWrapper>
      <MobileSliderDots>{getDots()}</MobileSliderDots>
    </div>
  );
};

export default MobileCatalogSlider;
