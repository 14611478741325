import React, { useCallback, useState, FC, ChangeEvent } from "react";
import TagManager from "react-gtm-module";
import { useSelector } from "react-redux";

import { ApplicationStore } from "src/store";
import { ClassName } from "src/types";

import { BorderedInput } from "../BorderedInput";

import {
  FormContainer,
  SubscribeButton,
  SubscriptionMessage,
} from "./SubscribeForm.styles";

type DataLayerType = {
  emailsubscribe: string;
  userId?: number;
};

const SubscribeForm: FC<ClassName> = (props) => {
  const { className } = props;
  const user = useSelector((state: ApplicationStore) => state.user);

  const dataLayer: DataLayerType = {
    emailsubscribe: "footer",
  };

  if (user.userData?.userId) {
    dataLayer.userId = user.userData.userId;
  }

  const [isSending, setIsSending] = useState(false);
  const [email, setEmail] = useState("");

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value),
    [setEmail]
  );

  // const handleSubmit = useCallback(
  //   (event: FormEvent<HTMLFormElement>) => {
  //     event.preventDefault();
  //     if (!isSending) {
  //       subscribeClient(email).then(() => {
  //         setIsSending(true);
  //
  //         TagManager.dataLayer({
  //           dataLayer,
  //         });
  //       });
  //     }
  //   },
  //   [dataLayer, email, isSending]
  // );

  function handleSubmit() {
    TagManager.dataLayer({
      dataLayer: {
        event: "subscribeSuccess",
        um: email,
      },
    });
    setIsSending(true);
  }
  return (
    <FormContainer onSubmit={handleSubmit} className={className}>
      {!isSending ? (
        <>
          <BorderedInput
            value={email}
            onChange={handleChange}
            placeholder="Ваш e-mail"
            type="email"
            name="email"
            aria-label="Ваш e-mail"
          />
          <SubscribeButton
            variant="white"
            type="submit"
            disabled={!email}
            onClick={handleSubmit}
          >
            подписаться
          </SubscribeButton>
        </>
      ) : (
        <>
          <SubscriptionMessage>
            Вы успешно подписались на рассылку, проверьте Вашу почту.
          </SubscriptionMessage>
        </>
      )}
    </FormContainer>
  );
};

export default SubscribeForm;
