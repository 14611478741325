import styled from "styled-components";

import { Button, Flex, Icons } from "src/components";

import Link from "../../../components/Link/Link";

export const CheckoutHeadline = styled.h1`
  font-family: "Formular";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  width: 100%;
  cursor: pointer;
  svg {
    transition: transform 0.8s ease;
    margin-left: 25px;
  }
  @media screen and (max-width: 768px) {
    svg {
      margin-left: 16px;
    }
  }
  span {
    font-size: 18px;
    font-weight: 400;
  }
`;

export const NextButton = styled(Button)`
  width: 100%;
  height: 50px;
  font-weight: 450;
  font-size: 10px;
  line-height: 30px;
  margin-bottom: 17px;
  opacity: 1;
  pointer-events: auto;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 5px;
  }
`;
export const LinkInPersonal = styled(Link)`
  text-decoration: underline;
  color: #000000;
  &:visited {
    color: #000000;
  }
`;

export const CheckoutContent = styled.div`
  width: 100%;
  max-width: 554px;
  @media screen and (max-width: 800px) {
    max-width: 100%;
  }
  margin-right: 134px;
  .hover--opacity:hover {
    opacity: 0.5;
    color: black;
    svg path {
      fill: black;
    }
  }
  .back--blue {
    background: rgba(250, 250, 250, 0.93);
  }
  .back--white {
    background: #ffffff;
  }
  .personal_info {
    h1 {
      font-family: "Formular";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #000000;
      margin-bottom: 15px;
      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }
    p {
      font-family: "HelveticaNeueCyr";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      letter-spacing: 0.01em;
      color: #000000;
      @media screen and (max-width: 768px) {
        line-height: 16px;
      }
    }
    &-row {
      display: flex;
      margin-bottom: 15px;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
      p {
        font-family: "HelveticaNeueCyr";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0.01em;
        color: #808080;
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
        }
        span {
          color: #000000;
        }
      }
    }
  }
  .accordeon-header {
    transition: all 0.8s ease;
  }
  .accordeon-header--active {
    margin-bottom: 37px;
    svg {
      transform: rotate(180deg);
    }
  }
  .checkbox--margin div:first-child {
    margin-right: 15px;
  }
  p {
    margin: 0px;
  }
  .pl-45 {
    padding-left: 45px;
  }
  .p-45-include {
    padding: 45px;
    @media screen and (max-width: 768px) {
      padding: 25px;
    }
  }
  .p-45 {
    padding: 45px;
    @media screen and (max-width: 768px) {
      padding: 25px;
    }
  }
  .pt-30 {
    padding-top: 30px;
  }
  .mt-0 {
    margin-top: 0px;
  }
  .mt-5 {
    margin-top: 5px;
  }
  .mb-0 {
    margin-bottom: 0px;
  }
  .mb-15 {
    margin-bottom: 15px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-25 {
    margin-bottom: 25px;
  }
  .mb-30 {
    margin-bottom: 30px;
  }
  .mb-105 {
    margin-bottom: 105px;
  }
  .disabled {
    cursor: auto;
    opacity: 0.5;
  }
  .resize--off {
    resize: none;
  }
  .radioGroupInCheckout {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    label {
      margin-bottom: 35px;
    }
  }
  .radioInPayment {
    label:last-child {
      margin-bottom: 0;
    }
  }
  .choose_address {
    &-label {
      font-family: "Formular";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #000000;
    }
  }
`;
export const BackLink = styled(Button)`
  position: absolute;
  top: 14px;
  left: 40px;
  width: 100%;
  max-width: 70px;
  border: none;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  font-size: 14px;
  text-transform: inherit;
  white-space: nowrap;
  padding: 0;

  svg {
    fill: rgba(0, 0, 0, 0.5);
    margin-right: 5px;
  }

  &:hover {
    background: transparent;
    color: rgba(0, 0, 0, 1);

    svg {
      fill: rgba(0, 0, 0, 1);
    }
  }

  @media (max-width: 768px) {
    left: 15px;
    svg {
      fill: rgba(0, 0, 0, 1);
    }
    span {
      display: none;
    }
  }
`;

export const BackLinkEmpty = styled(BackLink)`
  background: transparent;
  color: rgba(0, 0, 0, 1);
  top: 30px;
  &:hover {
    opacity: 0.5;
  }
  .back-arrow {
    width: 8px;
    min-width: 8px;
    height: 8px;
    min-height: 8px;
    border-bottom: 2px solid #000000;
    border-right: 2px solid #000000;
    transform: matrix(-0.71, 0.77, -0.65, -0.71, 0, 0);
    margin-right: 15px;
  }
  @media (max-width: 768px) {
    span {
      display: block;
    }
  }
`;

export const ReturnLink = styled(BackLink)`
  display: contents;
`;

export const BackIcon = styled(Icons.BackArrow)`
  width: 21px;
  height: 13px;

  @media (max-width: 680px) {
    width: 28px;
    height: 17px;
  }
`;

export const Layout = styled.div`
  position: relative;
  padding: 30px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  .dn-768 {
    display: none;
  }
  @media (max-width: 1400px) {
    padding-top: 80px;
  }
  @media screen and (max-width: 800px) {
    padding: 15px;
    padding-top: 80px;
    flex-direction: column;
    .db-768 {
      display: none;
    }
    .dn-768 {
      display: block;
      @media screen and (max-width: 600px) {
        width: calc(100% - 30px);
        align-self: center;
      }
      @media screen and (min-width: 600px) and (max-width: 800px) {
        width: calc(100% - 220px);
        align-self: center;
      }
      .disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
  .text_align--center {
    text-align: center;
    cursor: auto;
  }
  .button_container {
    display: none;
  }
`;

export const StyledMain = styled.div``;

export const Text = styled.p`
  color: black;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 0.5);
  & > a {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: rgba(0, 0, 0, 0.5);
    text-decoration: underline;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const OrderCompletedWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 80%;

  > div {
    height: 500px;
  }
  > button {
    max-width: 200px;
  }
`;

export const OrderErrorWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 80%;

  > div {
    height: 500px;
  }
  > button {
    max-width: 200px;
  }
`;
