import styled from "styled-components";

import { Box } from "../Box";
import { Flex } from "../Flex";
import { Icons } from "../Icons";
import { Text } from "../Text";

import {
  ArrowContainerProps,
  WrapperProps,
  SelectHeaderTextProps,
  SelectedListItem,
} from "./Select.types";

export const Wrapper = styled(Box)<WrapperProps>`
  z-index: 10;
  max-width: 210px;
  width: 100%;
  height: 56px;

  @media screen and (max-width: 900px) {
    max-width: ${({ isOpened }): string => (isOpened ? "210px" : "56px")};
  }
  &:hover .SelectContainer {
    border: 1px solid rgba(0, 0, 0, 0.5);
    .IconContainer {
      opacity: 0.5;
    }
  }
`;

export const SelectContainer = styled(Box)`
  position: absolute;
  width: 100%;
  background-color: ${({ theme }): string => theme.colors.white};
  border: 1px solid ${({ theme }): string => theme.colors.lighterGrey};
`;

export const SelectHeader = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px 16px 18px;
  cursor: pointer;
`;

export const SelectHeaderText = styled(Text)<SelectHeaderTextProps>`
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: ${({ theme }): string => theme.colors.black};
  margin: 0;
  user-select: none;
  @media screen and (max-width: 1100px) {
    font-size: 16px;
  }
  @media screen and (max-width: 1000px) {
    font-size: 14px;
  }
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const SelectList = styled.ul`
  margin: 0;
  padding: 5px 16px 8px 16px;
  list-style-type: none;
`;

export const SelectListItem = styled.li<SelectedListItem>`
  padding: 10px 0;
  font-size: 20px;
  font-weight: ${({ isSelected }) => (isSelected ? 700 : 400)};
  letter-spacing: 0.01em;
  color: ${({ theme }): string => theme.colors.black};
  cursor: pointer;
  user-select: none;

  :hover {
    color: ${({ theme }): string => theme.colors.grey};
  }

  &.disabled {
    color: ${({ theme }): string => theme.colors.grey};
  }
  @media screen and (max-width: 1100px) {
    font-size: 16px;
  }
`;

export const IconContainer = styled(Flex)<ArrowContainerProps>`
  align-items: center;
  justify-content: center;
  transform: ${({ isInverted }): string =>
    isInverted ? "rotate(180deg)" : "none"};

  @media screen and (max-width: 768px) {
    margin-left: auto;
  }
`;

export const ArrowIcon = styled(Icons.Arrow)`
  width: 16px;
  height: 16px;
  stroke: ${({ theme }): string => theme.colors.black};
  stroke-width: 2px;
`;

export const IconWrapper = styled.div<{
  isChosen: boolean;
  isMobile?: boolean;
}>`
  transform: ${({ isMobile }) =>
    isMobile ? "translateY(-5px)" : "translateY(-3px)"};
  margin-left: 3px;
  margin-right: 22px;
  height: 19px;

  ${({ isChosen }) => isChosen && "margin: 0 22px 0 7px;"}
  ${({ isChosen, isMobile }) => isMobile && isChosen && "margin: 0 22px 0 6px;"}
`;

export const FlexWrapper = styled.div<{ isChosen?: boolean }>`
  display: flex;
  position: relative;

  ${({ isChosen }) => isChosen && "font-weight: 700;"}
`;
