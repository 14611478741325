import { useCallback, useState } from "react";

import { UseControl } from "./useControl.types";

const useControl = (initialState = false): UseControl => {
  const [state, setState] = useState(initialState);

  const activate = useCallback(() => setState(true), [setState]);
  const deactivate = useCallback(() => setState(false), [setState]);
  const toggle = useCallback(() => setState((prevState) => !prevState), [
    setState,
  ]);

  return { state, activate, deactivate, toggle };
};

export default useControl;
