import { API } from "src/constants";

import { Http } from "../http";

import { AddressResponse } from "./address.types";

export const getAddressDataWithType = (
  query: string,
  type: "c" | "s" | "f" | "h"
): AddressResponse => Http.get(`${API}/dadataExtended?q=${query}&type=${type}`);
