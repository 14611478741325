import React, { useState, useCallback, FC, ChangeEvent } from "react";

import { BorderlessInput } from "src/components";

import { Form, SubmitButton, ErrorText } from "./SingleInputForm.styles";
import { SingleInputFormProps } from "./SingleInputForm.types";

const SingleInputForm: FC<SingleInputFormProps> = (props) => {
  const {
    className,
    buttonText,
    hasError,
    inputName,
    inputType,
    placeholder,
    onSubmit,
    isCentered,
    isRequired,
    inputMode,
    error,
    setError,
    initialValue = "",
  } = props;

  const [value, setValue] = useState(initialValue);
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      setError && setError("");
    },
    [setError]
  );

  return (
    <Form
      onSubmit={(evt) => {
        onSubmit(value);
        evt.preventDefault();
      }}
      className={className}
      isCentered={isCentered}
    >
      <BorderlessInput
        className="code-input"
        type={inputType}
        name={inputName}
        placeholder={placeholder}
        aria-label={placeholder}
        hasError={hasError}
        value={value}
        onChange={handleChange}
        required={isRequired}
        inputMode={inputMode || undefined}
      />
      {error && <ErrorText>{error}</ErrorText>}
      <SubmitButton type="submit">{buttonText}</SubmitButton>
    </Form>
  );
};

export default SingleInputForm;
