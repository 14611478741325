import React, { useMemo, FC, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import ScrollToTop from "react-scroll-to-top";

import { FooterCheckout } from "src/components";
import {
  ADDRESSES,
  PHONE,
  VK,
  TG,
  PRIVACY_POLICY,
  ROUTES,
  INSTAGRAM,
} from "src/constants";

import { FooterMenu } from "../FooterMenu";
import { SubscribeForm } from "../SubscribeForm";

import {
  FooterContainer,
  Logo,
  InfoContainer,
  SocialContainer,
  Phone,
  SocialLink,
  VkIcon,
  TgIcon,
  MenuContainer,
  AddressContainer,
  Address,
  SubscribeContainer,
  Copyright,
  AgreementContainer,
  AgreementUrl,
  Containers,
  AddressList,
  InstagramIcon,
} from "./Footer.styles";

const Footer: FC = () => {
  const year = useMemo(() => new Date().getFullYear(), []);
  const location = useLocation();
  const isCheckout = location.pathname.includes("/checkout");
  const [classScroll, setClassSrcoll] = useState("scrollTop");

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [location.pathname]);

  function onScroll() {
    let isHidden = false;
    if (location.pathname.includes("product") && window.innerWidth > 768) {
      const box = document.getElementById("info_product");
      const markets = document.getElementById("markets") || { clientHeight: 0 };
      const article = document.getElementById("article") || { clientHeight: 0 };
      isHidden = !!(
        box &&
        box.scrollHeight +
          box.offsetTop -
          markets.clientHeight -
          article.clientHeight <
          window.scrollY
      );
    } else {
      isHidden = window.innerHeight < window.scrollY;
    }
    if (isHidden) {
      setClassSrcoll("scrollTop");
    } else {
      setClassSrcoll("scrollTop hidden");
    }
  }

  return (
    <>
      {isCheckout ? (
        <FooterCheckout />
      ) : (
        <FooterContainer>
          <MenuContainer>
            <Logo />
            <FooterMenu />
            <Copyright>©{year} LEFORM</Copyright>
          </MenuContainer>

          <Containers>
            <AddressContainer>
              <InfoContainer>
                <Phone href={`tel:${PHONE}`} rel="noopener noreferrer">
                  {PHONE}
                </Phone>

                <SocialContainer>
                  <SocialLink
                    href={VK}
                    rel="noopener noreferrer"
                    target="_blank"
                    aria-label="Facebook"
                  >
                    <VkIcon />
                  </SocialLink>

                  <SocialLink
                    href={TG}
                    rel="noopener noreferrer"
                    target="_blank"
                    aria-label="Instagram"
                  >
                    <TgIcon />
                  </SocialLink>
                  <SocialLink
                    href={INSTAGRAM}
                    rel="noopener noreferrer"
                    target="_blank"
                    aria-label="Instagram"
                    className="inst"
                  >
                    <InstagramIcon />
                  </SocialLink>
                </SocialContainer>
              </InfoContainer>
              <AddressList>
                {ADDRESSES.map((address) => (
                  <Address key={address.id}>
                    <Link to={`${ROUTES.shops}#shop${address.id}`}>
                      {address.address}
                    </Link>
                    <span>{address.metro}</span>
                    <Phone
                      href={`tel:${address.phone}`}
                      rel="noopener noreferrer"
                    >
                      {address.phone}
                    </Phone>
                  </Address>
                ))}
              </AddressList>
            </AddressContainer>

            <SubscribeContainer>
              <SubscribeForm />

              <AgreementContainer>
                Подписываясь на рассылку, вы подтверждаете свое согласие с{" "}
                <AgreementUrl
                  href={PRIVACY_POLICY}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Публичной офертой и политикой конфиденциальности
                </AgreementUrl>
              </AgreementContainer>
            </SubscribeContainer>
            <ScrollToTop smooth className={classScroll} />
          </Containers>
        </FooterContainer>
      )}
    </>
  );
};

export default Footer;
