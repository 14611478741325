import React, { useCallback, FC, useState, FormEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { setCurrentWorkFlow, setUserData } from "src/actions";
import { setUserPersonalData } from "src/api";
import { Checkbox, DatePicker, Preloader, SelectInput } from "src/components";
import { ROUTES } from "src/constants";
import { usePreloaderTimer } from "src/hooks/usePreloaderTimer";
import { ApplicationStore } from "src/store";
import { PersonalData } from "src/types";
import { getBasketId } from "src/utils";

import { useForm } from "../../../hooks";
import { BackLinkEmpty } from "../../Checkout/elements/styles";
import AgreementPDF from "../../CheckoutOld/elements/ChoosePaymentMethod/documents/public_agreement.pdf";

import {
  Container,
  Title,
  HeaderWrapper,
  Form,
  FormButton,
  Input,
  InputContainer,
  PhoneInput,
  Text,
} from "./Styles";

const PersonalCreate: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const { userData } = useSelector((state: ApplicationStore) => state.user);
  const { isFirstTime } = useSelector((state: ApplicationStore) => state.user);
  const [isLoading, setLoading] = useState(false);

  const [isLoaderShown, onStartFetching, onEndFetching] = usePreloaderTimer();

  const onSubmit = useCallback(
    (values: PersonalData) => {
      const phoneValue = values.default_phone.replace(/[^0-9]/g, "");
      if (phoneValue.length !== 11 || !/^\d+$/.test(phoneValue)) {
        setErrors({
          phone: "Неправильный формат",
        });
        return;
      }

      onStartFetching();
      setLoading(true);

      setUserPersonalData(values)
        .then(({ data }) => {
          const newData = {
            ...values,
            basket_uuid: userData?.checkoutId || getBasketId() || "",
            user_id: userData?.userId || null,
            name: userData?.userName || "",
            genders: userData?.genders || null,
            customer_cards: userData?.customerCards || null,
            savings: userData?.savings || null,
            min_discount_percent: userData?.min_discount_percent || null,
            is_sms: userData?.is_sms || false,
            is_email: userData?.is_email || false,
            is_mobile_push: userData?.is_mobile_push || false,
            bonuses: userData?.bonuses || null,
            is_retail: userData?.is_retail || false,
          };
          dispatch(setUserData(newData));
          dispatch(
            setCurrentWorkFlow({
              userWorkFlow: "authenticated",
            })
          );
          const routeBack = window.localStorage.getItem("routeBack");

          if (routeBack) {
            history.push({
              pathname: routeBack,
            });
            window.localStorage.removeItem("routeBack");
          } else if (localStorage.getItem("inCheckout")) {
            //localStorage.removeItem("inCheckout");
            history.push({
              pathname: ROUTES.checkout,
            });
          } else {
            history.push(ROUTES.personalData);
          }
        })
        .catch((error) => {
          setErrors(error.response.data.messages);
        })
        .finally(() => {
          setLoading(false);
          onEndFetching();
        });
    },
    [dispatch, history, onEndFetching, onStartFetching, userData]
  );

  useEffect(() => {
    if (userData) {
      if (
        userData.is_sms ||
        userData.is_email ||
        userData.is_mobile_push ||
        isFirstTime
      ) {
        userData.consent_to_communication = true;
        values.consent_to_communication = true;
      } else {
        userData.consent_to_communication = false;
        values.consent_to_communication = false;
      }
    }
  }, [userData]);
  const { values, onChange, setValues } = useForm({
    first_name: userData?.userFirstName,
    last_name: userData?.userLastName,
    phone: userData?.phone,
    email: userData?.userEmail,
    gender_id: userData?.genderId,
    date_of_birth: userData?.date_of_birth,
    consent_to_communication: userData?.consent_to_communication,
  });

  useEffect(() => {
    setValues({
      first_name: userData?.userFirstName,
      last_name: userData?.userLastName,
      phone: userData?.phone,
      email: userData?.userEmail,
      gender_id: userData?.genderId,
      date_of_birth: userData?.date_of_birth,
      consent_to_communication: userData?.consent_to_communication,
    });
  }, [userData]);
  const allGenders = useSelector(
    (s: ApplicationStore) => s.user.userData?.genders
  );

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const phoneValue = values.phone?.replace(/[^0-9]/g, "");

      onSubmit({
        first_name: values.first_name ? values.first_name : "",
        last_name: values.last_name ? values.last_name : "",
        email: values.email ? values.email : "",
        default_phone: phoneValue ? phoneValue : "",
        gender_id: values.gender_id ? +values.gender_id : 1,
        date_of_birth: values.date_of_birth
          ? values.date_of_birth
          : new Date("01.01.1990"),
        consent_to_communication: values.consent_to_communication
          ? values.consent_to_communication + ""
          : "false",
      });
    },
    [values, onSubmit, allGenders]
  );

  const setDateValue = (date: Date) => {
    setValues({ ...values, date_of_birth: date });
  };

  return (
    <>
      <HeaderWrapper>
        <BackLinkEmpty onClick={() => void history.goBack()}>
          <div className="back-arrow"></div>
          <span>Назад</span>
        </BackLinkEmpty>
        <Title>Персональные данные</Title>
      </HeaderWrapper>
      <Container>
        {isLoaderShown && <Preloader />}
        <Form onSubmit={handleSubmit}>
          <Input
            value={values.first_name || ""}
            onChange={onChange}
            name="first_name"
            placeholder="Имя"
            aria-label="Имя"
            hasError={Object.keys(errors).includes("first_name") ? true : false}
            disabled={isLoading}
          />
          <Input
            value={values.last_name || ""}
            onChange={onChange}
            name="last_name"
            placeholder="Фамилия"
            aria-label="Фамилия"
            hasError={Object.keys(errors).includes("last_name") ? true : false}
            disabled={isLoading}
          />
          <PhoneInput
            value={values.phone}
            onChange={onChange}
            type="tel"
            name="phone"
            placeholder="Телефон"
            aria-label="Телефон"
            hasError={Object.keys(errors).includes("phone") ? true : false}
            disabled={isLoading}
            mask="+7 (999) 999-99-99"
          />
          <Input
            value={values.email}
            onChange={onChange}
            name="email"
            type="email"
            placeholder="e-mail"
            aria-label="e-mail"
            hasError={Object.keys(errors).includes("email") ? true : false}
            disabled={isLoading}
          />
          <InputContainer>
            <DatePicker
              dateValue={
                values.date_of_birth ? new Date(values.date_of_birth) : null
              }
              setDateValue={setDateValue}
              placeholderText="Дата рождения*"
              className={
                Object.keys(errors).includes("email") ? "error" : undefined
              }
            />
          </InputContainer>
          <InputContainer>
            <SelectInput
              value={values.gender_id || 1}
              onChange={onChange}
              name="gender_id"
              aria-label="Пол"
              isEmpty={!values.gender_id}
              disabled={isLoading}
            >
              {allGenders?.map(({ id, name }) => (
                <option key={id} value={id} selected={values.gender_id === id}>
                  {name}
                </option>
              ))}
            </SelectInput>
          </InputContainer>
          <Checkbox
            onChange={onChange}
            className="checkbox--custom"
            label="Согласие получать персональные предложения и новости"
            name="consent_to_communication"
            checked={!!values.consent_to_communication}
          />
          <FormButton type="submit" disabled={isLoading}>
            Продолжить
          </FormButton>
          <Text>
            Завершая регистрацию я подтверждаю ознакомление с указанными
            условиями{" "}
            <a href={AgreementPDF} target="_blank" rel="noopener noreferrer">
              указанными условиями
            </a>
            .
          </Text>
        </Form>
        {values.consent_to_communication}
      </Container>
    </>
  );
};

export default PersonalCreate;
