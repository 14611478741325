import React, { FC } from "react";

import { Container, Heading } from "./AuthHeader.styles";

const AuthHeader: FC = () => {
  return (
    <Container>
      <Heading>Войти или зарегистрироваться</Heading>
    </Container>
  );
};

export default AuthHeader;
