import styled from "styled-components";

import { BorderlessInput, BorderlessMaskedInput } from "../BorderlessInput";
import { Button } from "../Button";
import { Grid } from "../Grid";

export const Form = styled.form`
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
`;

export const Input = styled(BorderlessInput)`
  :not(:first-of-type) {
    margin-top: 40px;
  }
`;

export const PhoneInput = styled(BorderlessMaskedInput)`
  margin-top: 40px;
`;

export const SubmitButton = styled(Button)`
  height: 50px;
  margin-top: 60px;

  @media screen and (max-width: 480px) {
    height: 60px;
  }
`;

export const InputContainer = styled(Grid)`
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 50px;
  width: 100%;
  margin-top: 40px;

  input {
    margin: 0 !important;
  }
`;
