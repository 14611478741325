import styled from "styled-components";

import { Box } from "../Box";
import { Flex } from "../Flex";

import { BreadCrumbsProps } from "./BrandInfo.types";

export const BrandInfoWrapper = styled(Flex)`
  padding: 0 40px;
  margin-bottom: 70px;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 20px;
    margin-bottom: 16px;
  }
`;

export const ImageContainer = styled(Box)`
  flex: 840px 0 1;
  max-height: 550px;
  margin-right: 64px;
  overflow: hidden;

  @media (max-width: 1440px) {
    flex: 690px 0 1;
    margin-right: 39px;
  }

  @media (max-width: 1200px) {
    flex: 560px 0 1;
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    flex: auto 0 0;
    width: 100%;
    margin-right: 0;
    margin-bottom: 26px;
  }

  @media (max-width: 580px) {
    margin-bottom: 22px;
  }
`;

export const Picture = styled.picture`
  width: 100%;
  height: 100%;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
`;

export const BrandTitle = styled.h1`
  font-family: Formular;
  font-weight: 500;
  font-size: 62px;
  line-height: 76px;
  letter-spacing: 0.01em;
  margin: 0 0 10px;

  @media (max-width: 580px) {
    font-size: 35px;
    line-height: 32px;
  }
`;

export const BrandText = styled.div`
    max-width: 600px;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;

    @media (max-width: 768px) {
        max-width: 520px;
    }

    *{
    margin-bottom: 0;
    margin-top: 0;
    }
`;

export const BreadCrumbs = styled.ul<BreadCrumbsProps>`
  margin: ${({ theme, isEmpty }) => (isEmpty ? "21px 0 19px" : "21px 0 0 0")};
  line-height: 30px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #000000;
  padding: 0 40px;
  margin-left: 0;
  list-style: none;
  padding: 0px 40px;

  li {
    display: inline-block;
  }

  a {
    font-size: 14px;
    color: #8e8e8e;
    white-space: nowrap;
    text-decoration: none;
  }

  @media (max-width: 1200px) {
    margin: 21px 0 19px;
  }

  @media (max-width: 600px) {
    margin: 58px 0 9px;
    padding: 0 20px;
  }
`;

export const Slash = styled.span`
  margin: 0 7px;
  color: #8e8e8e;
`;

export const CategoryName = styled.span`
  white-space: nowrap;
`;
