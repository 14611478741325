import styled from "styled-components";

import { Accordeon } from "src/components";

export const AccordeonStyled = styled(Accordeon)`
  padding: 27px 0;
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  position: relative;
  width: 100%;
  p.header {
    max-width: 100%;
  }
  .accordeon-header {
    cursor: pointer;
    padding-right: 20px;
    transition: margin 0.8s ease;
    &:hover {
      color: #808080;
      svg {
        opacity: 0.4;
      }
    }
    h2 {
      font-family: "HelveticaNeueCyr";
      font-style: normal;
      font-weight: 450;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 0.01em;
      text-align: left;
      margin: 0;
      pointer-events: none;

      @media (max-width: 770px) {
        font-size: 18px;
        max-width: 90%;
      }
      @media (max-width: 400px) {
        max-width: 275px;
      }
    }
    .light {
      font-weight: 400;
    }
    svg {
      position: absolute;
      top: 27px;
      right: 0;
      pointer-events: none;
    }
  }
  .accordeon-content {
    margin: 0;
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.01em;
    text-align: left;
    max-width: 100%;
    font-size: 15px;
    line-height: 18px;
    overflow: hidden;
    b {
      font-weight: 350;
    }
    .bold {
      font-weight: 450;
    }
    .alert {
      color: #ff7171;
    }
    .mw-455 {
      max-width: 455px;
      margin: 0;
    }
    .mw-573 {
      max-width: 573px;
      margin: 0;
    }
    p {
      font-size: 15px;
      line-height: 18px;
      overflow: hidden;
    }
  }
  .accordeon-header--active {
    margin-bottom: 17px;
    svg.vertical {
      transform: rotate(90deg);
    }
  }
`;
