import React, { FC } from "react";

import { ProductLinks } from "src/types";

import {
  ProductCategoryWrapper,
  ProductCategoryCard,
  ProductCategoryTextWrapper,
  ProductCategoryTitle,
  ProductCategoryText,
  ProductCategoryMainTitle,
} from "./ProductCategories.styles";
import { ProductCategoriesProps } from "./ProductCategories.types";

const ProductCategories: FC<ProductCategoriesProps> = ({ links }) => {
  return links?.length === 0 ? (
    <></>
  ) : (
    <ProductCategoryWrapper>
      <ProductCategoryMainTitle>
        Рекомендуем посмотреть
      </ProductCategoryMainTitle>
      {links.map((category: ProductLinks, index: number) => {
        return (
          <ProductCategoryCard key={index}>
            <ProductCategoryTextWrapper>
              <ProductCategoryTitle to={category.url}>
                {category.title}
              </ProductCategoryTitle>
              <ProductCategoryText>{category.text}</ProductCategoryText>
            </ProductCategoryTextWrapper>
          </ProductCategoryCard>
        );
      })}
    </ProductCategoryWrapper>
  );
};

export default ProductCategories;
