import styled from "styled-components";

import { IconButton } from "../IconButton";
import { Icons } from "../Icons";
import { Link } from "../Link";
import { Modal } from "../Modal";

import { Collapse } from "./elements";

export const ModalContainer = styled(Modal)`
  background-color: ${({ theme }): string => theme.colors.white};
  max-width: 350px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  animation: show 0.4s ease-out;
  padding-bottom: 32px;
  height: 100%;
  scrollbar-width: none;

  @keyframes show {
    0%,
    50% {
      transform: translateX(-100%);
    }
    100% {
      transform: none;
    }
  }

  @media screen and (max-width: 600px) {
    max-width: 270px;
  }
`;

export const CloseButton = styled(IconButton)`
  padding: 20px 20px 25px 20px;

  :hover {
    opacity: 0.5;
  }
`;

export const CloseIcon = styled(Icons.Close)`
  width: 20px;
  height: 20px;
`;

export const SearchContainer = styled.form`
  width: 100%;
  height: 60px;
  padding: 0;
  margin: 0;
  position: relative;
`;

export const SearchInput = styled.input`
  margin: 0;
  padding: 22px 60px 18px 20px;
  width: 100%;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: ${({ theme }): string => theme.colors.black};
  border: none;
  border-top: 1px solid ${({ theme }): string => theme.colors.lightGrey};
  border-bottom: 1px solid ${({ theme }): string => theme.colors.lightGrey};
  -webkit-appearance: none;
  outline: none;
`;

export const SearchButton = styled(IconButton)`
  padding: 20px;
  position: absolute;
  right: 0;
  top: 0;
`;

export const SearchIcon = styled(Icons.Search)`
  width: 20px;
  height: 20px;
`;

export const MenuList = styled.ul`
  margin: 0;
  padding: 23px 16px;
  list-style-type: none;
  width: 100%;
`;

export const SubMenuList = styled(MenuList)`
  padding: 14px 0 0;
`;

export const SearchMenuList = styled(MenuList)`
  padding: 22px 0 0 24px;
`;

export const MenuListItem = styled.li`
  margin: 0;
  padding: 0;
  width: 100%;

  :not(:last-of-type) {
    margin-bottom: 24px;
  }
`;

export const SubMenuListItem = styled.li`
  margin: 0;
  padding: 0;
  width: 100%;

  :not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

export const SaleLink = styled(Link)`
  color: ${({ theme }): string => theme.colors.green};
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  @media screen and (max-width: 1150px) {
    .color--black {
      color: #000000;
    }
    .color--green {
      color: #9cff00;
    }
  }
`;

export const IconLink = styled(SaleLink)`
  color: ${({ theme }): string => theme.colors.black};
  display: flex;
  align-items: center;
`;

export const MenuListItemFavorite = styled(MenuListItem)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const FavoriteLinkMobile = styled(IconLink)`
  display: flex;
  position: relative;
  padding: 0 8px;
  margin-left: -8px;
`;

export const MainCollapse = styled(Collapse)`
  button {
    svg {
      width: 16px;
      height: 16px;
      margin-left: 5px;
    }
  }

  a {
    font-size: 22px;
    line-height: 22px;
    margin: 0;
    color: ${({ theme }): string => theme.colors.black};
    font-weight: 500;
  }
`;

export const SubCollapse = styled(Collapse)`
  button {
    svg {
      width: 16px;
      height: 16px;
      margin-left: 5px;
    }
  }

  a {
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    color: ${({ theme }): string => theme.colors.black};
    font-weight: 500;
  }
`;

export const SubMenuItem = styled(MenuListItem)`
  :not(:last-of-type) {
    margin-bottom: 20px;
  }
`;

export const MenuLink = styled(Link)`
  font-size: 14px;
  margin: 0;
  color: ${({ theme }): string => theme.colors.black};
  font-weight: 300;
`;

export const UserIcon = styled(Icons.User)`
  width: 21px;
  height: 21px;
  margin-left: 12px;
  stroke: ${({ theme }): string => theme.colors.black};
  stroke-width: 2;
`;

export const FavoriteIcon = styled(Icons.Heart)`
  width: 25px;
  height: 24px;
  margin-left: 17px;
  stroke: ${({ theme }): string => theme.colors.black};
  stroke-width: 2;
`;

export const FooterMenuList = styled(MenuList)`
  border-top: 1px solid ${({ theme }): string => theme.colors.lightGrey};
`;

export const FooterLink = styled(MenuLink)`
  font-size: 18px;
  font-weight: 400;
`;

export const Bonuses = styled.p`
  padding: 0;

  margin-top: 2px;

  font-size: 12px;
  line-height: 12px;
`;

export const BonusCount = styled.span`
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
`;

export const UserIconLog = styled(Icons.UserLog)`
  width: 20px;
  height: 20px;
`;

export const LowSubMenuLink = styled(Link)`
  && {
    font-size: 14px;
    line-height: 16px;
    margin: 0;
    color: ${({ theme }): string => theme.colors.black};
    font-weight: 300;
  }
`;

export const SubMenuLink = styled(Link)`
  && {
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    color: ${({ theme }): string => theme.colors.black};
    font-weight: 500;
  }
`;

export const ItemsCountMobile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -4px;
  top: -8px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.black};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  padding-left: 0.1em;
  padding-top: 0.25em;
  font-size: 11px;
`;
