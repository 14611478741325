import React, { FC, FormEventHandler, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setUserAddresses } from "src/actions";
import { addAddress, getAddresses, updateAddress } from "src/api";
import { SearchAddress } from "src/components";
import { useForm } from "src/hooks";
import { CurrentAddress } from "src/pages/Checkout/elements/CurrentAddress";
import { ApplicationStore } from "src/store";
import { UserAddress } from "src/types";
import { stringifyAddress } from "src/utils";
import { getClearFields, getErrors } from "src/utils/address";

import {
  Container,
  ButtonGroup,
  Headline,
  FormButton,
  AddressButton,
  EditIcon,
  EditButton,
  ChangeAddressWrapper,
} from "./EditAddress.styles";

type EditAddressProps = {
  address?: {
    city: string;
    street: string;
    zip_code: string;
    id: number;
    house: string;
    flat: string;
  };
  handleSave?: (
    values: any //  eslint-disable-line @typescript-eslint/no-explicit-any
  ) => void;
  handleActive?: (value: boolean) => void;
  isAddAddress?: boolean;
};

const EditAddress: FC<EditAddressProps> = ({
  address,
  handleSave,
  handleActive,
  isAddAddress = false,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state: ApplicationStore) => state.user);
  const [isLoading, setLoading] = useState(false);
  const [isNewValue, setIsnewValue] = useState(true);
  const [isFormVisible, setIsFormVisible] = useState(true);
  const { values, errors, setErrors, setValues } = useForm({
    city: address?.city || "",
    street: address?.street || "",
    zip_code: address?.zip_code || "",
    house: address?.house || "",
    flat: address?.flat || "",
  });

  useEffect(() => {
    if (values.zip_code) {
      if (!values.house) {
        setValues({ ...values, zip_code: "" });
      }
    }
    if (values.flat) {
      if (!values.house) {
        setValues({ ...values, flat: "" });
      }
    }
    if (values.house) {
      if (!values.street) {
        setValues({ ...values, house: "" });
      }
    }
    if (values.street) {
      if (!values.city) {
        setValues({ ...values, street: "" });
      }
    }
  }, [values]);

  useEffect(() => {
    if (address) {
      setValues({
        city: address.city,
        street: address.street,
        zip_code: address.zip_code,
        house: address.house,
        flat: address.flat,
      });
      setIsnewValue(false);
      setIsFormVisible(false);
    }
  }, [address, setValues, user]);

  const handleSwitchClick: FormEventHandler = (e) => {
    setIsFormVisible((prevValue) => !prevValue);
    handleActive?.(true);
  };

  async function handleNew(addressPayload: Omit<UserAddress, "id">) {
    await addAddress(addressPayload);
  }

  async function handleChange(addressPayload: Omit<UserAddress, "id">) {
    if (!address) return;

    await updateAddress(address.id, addressPayload);
  }

  const handleSaveClick: FormEventHandler = (e) => {
    e.preventDefault();

    if (!values.city || !values.street || !values.house) {
      setErrors({
        city: values.city ? "" : "Заполните поле",
        street: values.street ? "" : "Заполните поле",
        house: values.house ? "" : "Заполните поле",
      });
      return;
    }

    const send = isNewValue ? handleNew : handleChange;
    if (handleSave) {
      handleSave(values);
    }
    setLoading(true);
    send(values)
      .then(() => getAddresses())
      .then((addresses) => {
        dispatch(setUserAddresses(addresses.data));
        setIsFormVisible(false);
      })
      .catch((error) => {
        const errors = getErrors(error);
        setErrors(errors);
        setValues({ ...values, ...getClearFields(errors) });
      })
      .finally(() => {
        setLoading(false);
        handleActive?.(false);
      });
  };

  const isAddressTheOnly =
    !isFormVisible &&
    !isNewValue &&
    !!address &&
    user?.userAddresses &&
    user.userAddresses.length === 1;

  const isAddressMultiply =
    !isFormVisible &&
    !isNewValue &&
    !!address &&
    user?.userAddresses &&
    user.userAddresses.length > 1;

  useEffect(() => {
    if (
      !address &&
      user?.userAddresses &&
      user.userAddresses.length > 1 &&
      !isAddAddress
    ) {
      setIsFormVisible(false);
      handleActive?.(false);
    }
  }, [address, isAddAddress, isAddressMultiply, user]);

  const [query, setQuery] = useState("");

  useEffect(() => {
    setQuery(
      `${values.city} ${values.street} ${values.house} ${values.flat}`.trim()
    );
    setErrors({});
  }, [values]);

  const addressFields = [
    { value: values.city, type: "c", error: !!errors.city },
    {
      value: values.street,
      type: "s",
      disabled: !values.city,
      error: !!errors.street,
    },
    {
      value: values.house,
      type: "h",
      disabled: !values.street,
      error: !!errors.house,
    },
    { value: values.flat, type: "f", disabled: !values.house },
    { value: values.zip_code, type: "z", disabled: !values.house },
  ];

  return (
    <Container onSubmit={handleSaveClick}>
      {isAddressMultiply && (
        <AddressButton onClick={handleSwitchClick}>
          <EditIcon />
          Изменить адрес
        </AddressButton>
      )}
      {isAddressTheOnly && !!address && (
        <ChangeAddressWrapper>
          <EditButton onClick={handleSwitchClick}>
            <EditIcon />
          </EditButton>
          <CurrentAddress
            label={"Адрес доставки: "}
            address={stringifyAddress(address)}
          />
        </ChangeAddressWrapper>
      )}
      {isFormVisible && (
        <>
          <Headline>{isNewValue ? "Добавить" : "Изменить"} адрес</Headline>

          {addressFields.map((field, index) => (
            <SearchAddress
              key={index}
              setValues={setValues}
              query={query}
              value={field.value}
              type={field.type as "c" | "s" | "h" | "f" | "z"}
              disabled={field.disabled}
              error={field.error}
            />
          ))}
          <ButtonGroup>
            <FormButton
              variant={"black"}
              onClick={handleSaveClick}
              disabled={isLoading}
              type="submit"
            >
              {isNewValue ? "Добавить" : "Изменить"}
            </FormButton>
          </ButtonGroup>
        </>
      )}
    </Container>
  );
};
export default EditAddress;
