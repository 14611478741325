import { CheckoutItem } from "../types";

export const getTotalPrice = (
  items: CheckoutItem[] = [],
  getResultPrice = false
): number =>
  items.reduce(
    (acc: number, { discount_price, result_price, quantity }) =>
      acc +
      Number(getResultPrice ? result_price : discount_price ?? 0) * quantity,
    0
  );
