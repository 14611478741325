import React, { FC } from "react";
import useCollapse from "react-collapsed";

import {
  Container,
  Button,
  Content,
  ArrowIcon,
  MenuLink,
  MenuItem,
} from "./Collapse.styles";
import { CollapseProps } from "./Collapse.types";

const collapseStyles = {
  transitionDuration: "300ms",
  transitionTimingFunction: "cubic-bezier(0.25, 0.46, 0.45, 0.94)",
};

const Collapse: FC<CollapseProps> = (props) => {
  const { title, children, className, url, onClose } = props;

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    expandStyles: collapseStyles,
    collapseStyles,
  });

  return (
    <Container className={className}>
      <Button isExpanded={isExpanded}>
        {url ? (
          <MenuLink to={url} onClick={onClose}>
            {title}
          </MenuLink>
        ) : (
          <MenuItem>{title}</MenuItem>
        )}
        <ArrowIcon {...getToggleProps()} />
      </Button>
      <Content {...getCollapseProps()}>{children}</Content>
    </Container>
  );
};

export default Collapse;
