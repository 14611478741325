import React, { useEffect, useRef, useState, VFC } from "react";
import { YMaps, Map, Placemark } from "react-yandex-maps";

import logo from "src/images/pin.svg";

type IProps = {
  shops: {
    latitude: number;
    longitude: number;
    name: string;
  }[];
};

const ContactsMap: VFC<IProps> = ({ shops }) => {
  // без any не работают ф-ии setBounds и setZoom
  const mapRef = useRef<any>(); //  eslint-disable-line @typescript-eslint/no-explicit-any

  const [bounds, setBounds] = useState<number[][]>([]);

  const mapState = {
    center: [55.76, 37.64],
    zoom: 10,
    controls: ["zoomControl", "fullscreenControl"],
  };

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current
        .setBounds(bounds, { checkZoomRange: true })
        .then(() => mapRef.current.setZoom(9));
    }
  }, [bounds]);

  return (
    <YMaps
      query={{ lang: "ru_RU", apikey: "df639532-a255-4e82-b3e0-06ad7483393e" }}
    >
      <Map
        defaultState={mapState}
        width="100%"
        height="100%"
        modules={[
          "control.ZoomControl",
          "control.FullscreenControl",
          "util.bounds",
        ]}
        instanceRef={(ref) => {
          if (ref) {
            mapRef.current = ref;
          }
        }}
        onLoad={(ymaps) => {
          const points = shops.map((itemShop) => [
            itemShop.latitude,
            itemShop.longitude,
          ]);
          setBounds(ymaps.util.bounds.fromPoints([...points]));
        }}
      >
        {shops.map((itemShop, index) => (
          <Placemark
            key={index}
            modules={["geoObject.addon.balloon"]}
            geometry={[itemShop.latitude, itemShop.longitude]}
            options={{
              iconLayout: "default#image",
              iconImageHref: logo,
              // iconImageSize: [84, 118],
              // iconImageOffset: [-42, -118],
            }}
            properties={{
              balloonContentBody: itemShop.name,
            }}
          />
        ))}
      </Map>
    </YMaps>
  );
};

export default ContactsMap;
