/* eslint-disable max-len */

import React, { FC } from "react";

import { SvgProps } from "src/types";

const UserLog: FC<SvgProps> = (props) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="14" cy="8" r="4" stroke={props.fill} stroke-width="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 24C6 20.2882 9.63766 17 14.5 17C15.2347 17 15.9415 17.0751 16.612 17.2154C17.1564 16.5947 17.8171 16.0787 18.5601 15.7013C17.2998 15.2479 15.9243 15 14.5 15C8.86893 15 4 18.8753 4 24H6Z"
      fill={props.fill}
    />
    <circle cx="22.1943" cy="22.188" r="5" fill="#9CFF00" />
  </svg>
);

UserLog.defaultProps = {
  width: "20px",
  height: "20px",
  fill: "white",
};

export default UserLog;
