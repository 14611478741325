/* eslint-disable max-len */
import React, { FC } from "react";

import { SvgProps } from "src/types";

const BackArrow: FC<SvgProps> = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="16"
    {...props}
    viewBox="0 0 32 16"
  >
    <path
      d="M32 9H3.8L9.4 14.6L8 16L0 8L8 0L9.4 1.4L3.8 7H32V9Z"
      fill={fill ?? "black"}
    />
  </svg>
);

export default BackArrow;
