import styled from "styled-components";

export const List = styled.ul`
  padding: 22px 0;
  margin: 0;
`;

export const Item = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 12px;

  line-height: 24px;
`;

export const TotalItem = styled(Item)`
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
`;

export const BoldText = styled.span`
  font-weight: 700;
`;
