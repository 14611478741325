import React, { useCallback, FC } from "react";
import { useHistory } from "react-router-dom";

import { SingleInputForm } from "src/components";
import { ROUTES } from "src/constants";

import { Container } from "../Reset.styles";

const NewPasswordPage: FC = () => {
  const history = useHistory();

  const handleSubmit = useCallback(
    (value: string) => {
      history.push(ROUTES.home);
    },
    [history]
  );

  return (
    <Container>
      <SingleInputForm
        inputName="password"
        inputType="password"
        placeholder="Придумайте свой пароль"
        buttonText="ПРОДОЛЖИТЬ"
        onSubmit={handleSubmit}
        isRequired
      />
    </Container>
  );
};

export default NewPasswordPage;
