import React, { useCallback, FC, FormEvent } from "react";

import { Checkbox } from "src/components";
import { useForm } from "src/hooks";

import {
  Form,
  CheckboxRow,
  FormButton,
  FormTitle,
} from "./EditNewslettersForm.styles";
import { EditNewslettersFormProps } from "./EditNewslettersForm.types";

const EditNewslettersForm: FC<EditNewslettersFormProps> = ({
  isSms,
  isEmail,
  isMobilePush,
  onSubmit,
  isLoading,
}) => {
  const { values, setValues } = useForm({
    isSms,
    isEmail,
    isMobilePush,
  });

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      onSubmit({
        is_sms: values.isSms,
        is_email: values.isEmail,
        is_mobile_push: values.isMobilePush,
      });
    },
    [onSubmit, values]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <FormTitle>Получать персональные предложения и новости</FormTitle>
      <CheckboxRow>
        <Checkbox
          name="isSms"
          label="СМС"
          type="checkbox"
          checked={values.isSms}
          onChange={() => setValues({ isSms: !values.isSms })}
          disabled={isLoading}
        />
      </CheckboxRow>
      <CheckboxRow>
        <Checkbox
          name="isEmail"
          label="Email"
          type="checkbox"
          checked={values.isEmail}
          onChange={() => setValues({ isEmail: !values.isEmail })}
          disabled={isLoading}
        />
      </CheckboxRow>
      <CheckboxRow>
        <Checkbox
          name="isMobilePush"
          label="Mobile Push"
          type="checkbox"
          checked={values.isMobilePush}
          onChange={() => setValues({ isMobilePush: !values.isMobilePush })}
          disabled={isLoading}
        />
      </CheckboxRow>
      <FormButton type="submit" disabled={isLoading}>
        Сохранить
      </FormButton>
    </Form>
  );
};

export default EditNewslettersForm;
