import React, { FC } from "react";

import { SvgProps } from "src/types";

const Arrow: FC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 32 32">
    <path d="M16 10l10 10-1.4 1.4-8.6-8.6-8.6 8.6L6 20l10-10z" />
  </svg>
);

Arrow.defaultProps = {
  width: "20px",
  height: "20px",
  fill: "black",
};

export default Arrow;
