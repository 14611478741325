import styled from "styled-components";

import { Link } from "../../../components";
import { BackLink } from "../../Checkout/elements";

export const Container = styled.main`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 40px;
  max-width: 1180px;
  margin: auto;

  @media screen and (max-width: 768px) {
    padding: 50px 20px 20px;
    margin: 0;
  }
`;

export const NameH1 = styled.h1`
  line-height: 62px !important;
`;

export const NameH3 = styled.h3`
  text-align: left;
  width: 100%;
`;

export const PaymentPage = styled.div`
  text-align: center;
  h1 {
    font-family: "Formular";
    font-style: normal;
    font-weight: 500;
    font-size: 62px;
    line-height: 40px;
    letter-spacing: 0.01em;
    margin-bottom: 24px;
    @media screen and (max-width: 750px) {
      font-size: 27px;
      line-height: 26px;
      margin-bottom: 13px;
    }
  }
  p {
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    width: 100%;
    max-width: 700px;
    margin: 0 auto 13px;
    a {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      font-weight: 450;
      margin-bottom: 0;
    }
  }
  .bold {
    width: 100%;
    max-width: 409px;
    font-weight: 450;
    margin: 0 auto 14px;
  }
  a {
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    font-weight: 450;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
    text-decoration: none;
    margin-bottom: 86px;
  }
  .tel {
    display: block;
    margin-bottom: 86px;
    @media screen and (max-width: 750px) {
      margin-bottom: 55px;
    }
  }
  .p--gray {
    color: rgba(0, 0, 0, 0.7);
    margin: 24px 0 30px;
  }
  .w-700 {
    max-width: 700px;
  }
  .row-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 680px;
    width: 100%;
    img {
      max-height: 29px;
      margin-bottom: 10px;
    }
  }
`;
export const Title = styled.h1`
  font-size: 34px;
  line-height: 42px;
  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    font-size: 26px;
    line-height: 32px;
  }
`;

export const Text = styled.p`
  font-family: Rubik;
  font-size: 14px;
  line-height: 18px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }

  .recommendations-table {
    display: table;
    padding-left: 30px;
  }
  .recommendations-cell {
    display: table-cell;
    vertical-align: middle;
    min-height: 75px;
  }
  .recommendations-cell b {
    font-weight: bold;
  }
  .recommendations-cell-first {
    width: 10%;
    text-align: center;
  }
  .recommendations-cell img {
    max-width: 75px;
  }
  .recommendations-row {
    display: table-row;
  }
`;

export const VacancyLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  padding: 27px 0;
  cursor: pointer;
  width: 100%;
  max-width: 470px;
  margin: 0 auto !important;
  &:last-child {
    border: none;
  }
  &:hover {
    opacity: 0.5;
  }
  h3 {
    font-weight: 450;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
    margin: 0;
  }
`;
export const BackLinkEmpty = styled(BackLink)`
  background: transparent;
  color: rgba(0, 0, 0, 1);
  margin-top: 10px;
  &:hover {
    opacity: 0.5;
  }
  .back-arrow {
    width: 8px;
    min-width: 8px;
    height: 8px;
    min-height: 8px;
    border-bottom: 2px solid #000000;
    border-right: 2px solid #000000;
    transform: matrix(-0.71, 0.77, -0.65, -0.71, 0, 0);
    margin-right: 15px;
  }
  @media (max-width: 768px) {
    left: 15px;
    top: 10px;
    span {
      display: block;
    }
  }
`;
export const VacancySinglePage = styled.div`
  margin-top: 20px;
  h1 {
    font-weight: 500;
    font-size: 62px;
    line-height: 40px;
    text-align: center;
    color: #000000;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      font-size: 27px;
      line-height: 26px;
      margin-bottom: 14px;
    }
  }
  p {
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    max-width: 630px;
    margin: 0 auto 8px;
    text-align: center;
    a {
      color: #000000;
      text-decoration: none;
    }
  }
  .mb-50 {
    margin-bottom: 50px;
    @media (max-width: 768px) {
      margin-bottom: 38px;
    }
  }
  .accordeons {
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    p,
    a {
      margin: 0;
      padding: 0;
      font-weight: 300;
      font-size: 16px !important;
      line-height: 24px !important;
      color: #000000;
      text-align: left;
      margin-bottom: 5px;
    }
  }
`;

export const Info = styled.div`
  width: fit-content;
  max-width: 149px;
  text-align: center;
  color: #000000;
  margin-right: 40px;
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 28px;
    &:last-child {
      margin-bottom: 51px;
    }
  }
  h2 {
    font-weight: 450;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 6px;
  }
  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  width: 100%;
  max-width: 440px;
  margin: 0 auto 50px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }
`;
