import { UserConfirm } from "./AuthDataConfForm.types";

export const validateUserConfirmValues = (
  values: UserConfirm
): Partial<Record<keyof UserConfirm, string | null>> => {
  const errors: Partial<Record<keyof UserConfirm, string | null>> = {};

  if (!values.phone.trim()) {
    errors.phone = "Телефон не найден";
  }

  if (!values.sms_code.trim()) {
    errors.sms_code = "Введите пароль из смс *";
  }

  return errors;
};
