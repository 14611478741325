import DatePicker from "react-datepicker";
import styled from "styled-components";

export const DatePickerWrapper = styled(DatePicker)`
  padding: 12px 0;
  font-family: HelveticaNeueCyr, sans-serif;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: #000000;
  margin: 0;
  border: none;
  border-bottom: 1px solid #000000;
  outline: 0;
  width: 100%;
  height: 40px;
  &.error {
    &::placeholder {
      color: #ff5151;
    }
  }
`;
export const CustomDatePicker = styled.div`
  position: relative;
  .select--click_outside {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
  }

  .react-datepicker__current-month {
    opacity: 0;
  }
  .react-datepicker__header__dropdown {
    position: absolute;
    left: 0;
    top: 0;
  }
  .react-datepicker__navigation {
    display: none;
  }
  .react-datepicker__month-select,
  .react-datepicker__year-select {
    border: unset;
    background: unset;
    cursor: pointer;
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #000000;
    text-transform: capitalize;
    padding: 5px 0;
    &:focus {
      outline: unset;
    }
    option {
      font-family: "HelveticaNeueCyr";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      color: #000000;
      display: block;
      margin-bottom: 4px;
      padding: 5px 12px;
      cursor: pointer;
      width: 133px;
      &:checked {
        color: #a5a5a5;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const CsutomSelect = styled.div`
  position: absolute;
  z-index: 100;
  top: 39px;
  left: 0;
  width: 100%;
  .absolute {
    position: absolute;
    top: 9px;
    left: 14px;
    z-index: 100;
  }
  .select--head {
    position: relative;
    z-index: 100;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    h4 {
      font-family: "HelveticaNeueCyr";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      color: #000000;
      margin: 0 5px 0 0;
      text-transform: capitalize;
      cursor: pointer;
      &:hover {
        opacity: 0.4;
      }
    }
    svg {
      margin-left: 6px;
      transition: transform 0.3s ease;
      margin-right: 10px;
    }
    .open {
      transform: rotate(180deg);
    }
  }
  .select--content {
    position: relative;
    z-index: 100;
    background: white;
    width: 133px;
    height: 165px;
    border: 1px solid #000000;
    padding: 6px 3px;
    .overflow--scroll {
      height: 153px;
      overflow-y: scroll;
      padding: 0 12px;

      &::-webkit-scrollbar {
        width: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background: #c4c4c4;
        border-radius: 17px;
      }
    }
    .option {
      font-family: "HelveticaNeueCyr";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      color: #000000;
      cursor: pointer;
      user-select: none;
      text-transform: capitalize;
      &:hover {
        opacity: 0.4;
      }
    }
    .option--select {
      color: #a5a5a5;
      cursor: unset;
      &:hover {
        opacity: 1;
      }
    }
  }
`;
