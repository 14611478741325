import React, { FC, useMemo } from "react";

import {
  InnerArrow,
  MainPageBannerSliderArrow,
} from "./MainPageBannerSlider.styles";

const CarouselMainPageBannerNextArrow: FC = (
  props: any //  eslint-disable-line @typescript-eslint/no-explicit-any
) => {
  const { onClick } = props;
  const slideCount = props.slideCount || 0;
  const currentSlide = props.currentSlide || 0;

  const isMobileNextArrowDisabled = useMemo(() => {
    return slideCount - currentSlide;
  }, [slideCount, currentSlide]);
  return (
    <MainPageBannerSliderArrow
      isRight={true}
      onClick={onClick}
      isMobileNextArrowDisabled={isMobileNextArrowDisabled}
    >
      <InnerArrow isRight={true} />
    </MainPageBannerSliderArrow>
  );
};

export default CarouselMainPageBannerNextArrow;
