import React, { FC } from "react";

import { Icons } from "../Icons";

import {
  DeliveryContainer,
  DeliveryContainerCard,
  DeliveryContainerCardText,
  DeliveryIconWrapper,
} from "./ProductDelivery.styles";
import { ProductDeliveryProps } from "./ProductDelivery.types";

const ProductDelivery: FC<ProductDeliveryProps> = ({ offer, isMsk }) => {
  if (isMsk && offer && !!offer?.quantity_shops) {
    return (
      <DeliveryContainer>
        {offer.offerDateDeliveryByMsk && (
          <DeliveryContainerCard>
            <DeliveryIconWrapper>
              <Icons.Car />
            </DeliveryIconWrapper>
            <DeliveryContainerCardText>
              Доставим {offer.offerDateDeliveryByMsk} в пределах
              <span className="underline-text">МКАД</span>
            </DeliveryContainerCardText>
          </DeliveryContainerCard>
        )}
        {offer.offerDatePickupByMsk && (
          <DeliveryContainerCard>
            <DeliveryIconWrapper>
              <Icons.Shop />
            </DeliveryIconWrapper>
            <DeliveryContainerCardText>
              Заберите из магазина {offer.offerDatePickupByMsk}
            </DeliveryContainerCardText>
          </DeliveryContainerCard>
        )}
      </DeliveryContainer>
    );
  } else {
    return <></>;
  }
};

export default ProductDelivery;
