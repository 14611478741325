import { Checkout, CheckoutActions, CheckoutTypes } from "./types";

const initialState: Checkout = {
  id: 0,
  uuid: "",
  items: [],
  discountText: "",
  price: 0,
  resultPrice: 0,
};

const CheckoutReducer = (
  state: Checkout = initialState,
  action: CheckoutActions
): Checkout => {
  switch (action.type) {
    case CheckoutTypes.SET_PRICE: {
      return { ...state, resultPrice: action.payload };
    }
    case CheckoutTypes.SET: {
      return (
        action.payload || {
          items: [],
        }
      );
    }

    case CheckoutTypes.CLEAR: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default CheckoutReducer;
