import React, { FC, useMemo, useCallback, Key } from "react";

import { CheckoutSelect, CheckoutSelectItem } from "src/components";

import { CurrentAddress } from "../CurrentAddress";

import { ShopSelectorProps } from "./types";

const ShopSelector: FC<ShopSelectorProps> = ({
  shops,
  value: shop,
  onChange,
}) => {
  const items = useMemo<CheckoutSelectItem[]>(
    () => shops.map((item) => ({ value: item.id, text: item.name })),
    [shops]
  );

  const handleChange = useCallback(
    (key: Key) => void onChange(shops.find((item) => item.id === key)),
    [onChange, shops]
  );

  return (
    <>
      <CurrentAddress label={"Адрес магазина: "} address="" />
      <CheckoutSelect
        label={"Выберите магазин"}
        items={items}
        onChange={handleChange}
        value={shop?.id}
      />
    </>
  );
};
export default ShopSelector;
