import {
  InputChangeEvent,
  TextAreaChangeEvent,
  SelectChangeEvent,
  InputValue,
} from "./useForm.types";

const isInputChangeEvent = (
  event: InputChangeEvent | TextAreaChangeEvent | SelectChangeEvent
): event is InputChangeEvent =>
  "checked" in event.target || "files" in event.target;

export const reduceByType = (
  event: InputChangeEvent | TextAreaChangeEvent | SelectChangeEvent
): Record<string, InputValue> => {
  const { name, type, value } = event.target;

  if (!isInputChangeEvent(event)) {
    return { [name]: value };
  }

  const { checked, files } = event.target;
  switch (type) {
    case "checkbox": {
      return { [name]: checked || false };
    }

    case "file": {
      return { [name]: files || null };
    }

    default: {
      return { [name]: value || "" };
    }
  }
};
