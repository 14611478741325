import styled, { css } from "styled-components";

import { BorderlessInput } from "../BorderlessInput";

export const Input = styled(BorderlessInput)`
  :not(:first-of-type) {
    margin-top: 40px;
  }

  background: rgba(250, 250, 250, 0.93);

  :disabled {
    background-color: rgb(211, 211, 211);
    cursor: not-allowed;

    &::placeholder {
      ${({ theme }) => css`
        color: ${theme.colors.red};
        opacity: 1;
      `}
    }

    &::-webkit-input-placeholder {
      ${({ theme }) => css`
        color: ${theme.colors.red};
        opacity: 1;
      `}
    }

    &::-moz-placeholder {
      ${({ theme }) => css`
        color: ${theme.colors.red};
        opacity: 1;
      `}
    }

    &:-ms-input-placeholder {
      ${({ theme }) => css`
        color: ${theme.colors.red};
        opacity: 1;
      `}
    }
  }

  color: blue;
`;
