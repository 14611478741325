import React, { FC } from "react";

import { PreloaderContainer } from "./Preloader.styles";

const Preloader: FC = () => {
  return (
    <PreloaderContainer>
      <div>
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </PreloaderContainer>
  );
};

export default Preloader;
