import { UserValues } from "./AuthDataForm.types";

export const validateAuthDataValues = (
  values: UserValues
): Partial<Record<keyof UserValues, string | null>> => {
  const errors: Partial<Record<keyof UserValues, string | null>> = {};

  if (!values.name.trim()) {
    errors.name = "Введите ваше имя *";
  }

  if (!values.phone.trim()) {
    errors.phone = "Введите ваш телефон *";
  }

  const phoneValue = values.phone.replace(/[^0-9]/g, "");

  if (phoneValue.length !== 11 || !/^\d+$/.test(phoneValue)) {
    errors.phone = "Неправильный формат";
  }

  return errors;
};
