import styled, { css } from "styled-components";

import { Box, Button, Icons } from "src/components";
import { IconButton } from "src/components/IconButton";

export const CollapseButton = styled.button`
  margin: 0;
  width: 100%;
  border: none;
  font-size: 20px;
  background-color: inherit;
  text-align: left;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  svg {
    fill: ${({ theme }): string => theme.colors.black30};
    width: 24px;
    height: 24px;
  }

  span {
    color: ${({ theme }): string => theme.colors.black};
  }
`;

export const Content = styled(Box)`
  width: 100%;
  height: auto;
  margin-top: 8px;

  @media screen and (max-width: 600px) {
    padding: 0 14px;
  }
`;

export const FullSizeContent = styled(Box)`
  position: fixed;

  top: 0;
  left: 0;
  right: 0;

  height: 100vh;

  z-index: 100;

  padding: 24px 15px;

  background-color: ${({ theme }): string => theme.colors.white};

  @keyframes showFilters {
    0%,
    50% {
      transform: translateX(200%);
    }
    100% {
      transform: none;
    }
  }

  animation: showFilters 0.3s ease-out;
`;

export const TitleWrapper = styled.div`
  display: flex;

  margin-bottom: 28px;
`;

export const NameWrapper = styled.div<{ isHaveChosenValues?: boolean }>`
  padding: 16px 0;

  ${({ isHaveChosenValues }) =>
    isHaveChosenValues &&
    css`
      padding: 5px 0;
    `};
`;

export const ContentInner = styled.div`
  height: calc(100% - 48px - 110px - 15px);

  overflow: auto;

  padding-right: 15px;

  margin-right: -15px;
  margin-bottom: 15px;
`;

export const Title = styled.p`
  margin: 0 0 0 12px;

  font-weight: 450;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */

  letter-spacing: 0.01em;

  color: ${({ theme }): string => theme.colors.black};
`;

export const Container = styled(Box)`
  width: 100%;
`;

export const IconWrapper = styled.div<{ isRevert?: boolean }>`
  width: 20px;
  height: 20px;

  ${({ isRevert }) =>
    isRevert &&
    css`
      transform: rotate(180deg);
    `};

  & svg {
    fill: none;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
`;

export const SortingButton = styled(Button)`
  display: block;
  width: 100%;

  padding: 10px;

  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-size: 14px;
  line-height: 30px;

  text-align: center;
  letter-spacing: 0.1em;

  &:first-child {
    margin-bottom: 8px;
  }
`;

export const CloseButton = styled(IconButton)`
  padding: 0;
  margin-left: auto;

  :hover {
    opacity: 0.5;
  }
`;

export const CloseIcon = styled(Icons.Close)`
  width: 14px;
  height: 14px;
`;

export const ChosenFilters = styled.p`
  width: 234px;

  margin: 0;
  padding: 0;

  pointer-events: none;

  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  letter-spacing: 0.01em;

  color: #8e8e8e;
`;
