import React, { FC, useEffect } from "react";

import {
  CloseButton,
  CloseIcon,
  ModalContainer,
  Title,
  CloseBottomButton,
} from "./ProductNotAvailableModal.styles";
import { ProductNotAvailableModalProps } from "./ProductNotAvailableModal.types";

const ProductNotAvailableModal: FC<ProductNotAvailableModalProps> = ({
  onClose,
}) => {
  useEffect(() => {
    const closeTimer = setTimeout(onClose, 5000);

    return () => {
      clearTimeout(closeTimer);
    };
  }, []);

  return (
    <ModalContainer onClose={onClose}>
      <CloseButton onClick={onClose} aria-label="Закрыть">
        <CloseIcon />
      </CloseButton>
      <Title>К сожалению, выбранный товар закончился</Title>
      <CloseBottomButton onClick={onClose}>ОK</CloseBottomButton>
    </ModalContainer>
  );
};

export default ProductNotAvailableModal;
