import React, { FC, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { useParams } from "react-router";

import { getStaticPageContent } from "src/api";
import { StaticPageRenderer } from "src/components";

import { PageContentState } from "./types";

const StaticPage: FC = () => {
  const { page } = useParams<{ page: string }>();

  const [data, setData] = useState<PageContentState>({
    state: "loading",
  });

  useEffect(() => {
    getStaticPageContent(page)
      .then((response) => {
        const content = response.data;
        setData({ state: "ready", content });
      })
      .catch(() => {
        setData({ state: "error" });
      });
  }, [page]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  }, []);

  return (
    <>
      {data.state === "loading" && null}
      {data.state === "ready" && (
        <StaticPageRenderer>{data.content}</StaticPageRenderer>
      )}
      {data.state === "error" && <h2>404 not found</h2>}
    </>
  );
};

export default StaticPage;
