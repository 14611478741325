import styled from "styled-components";

export const Container = styled.main`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 40px;
  max-width: 1180px;
  margin: auto;

  @media screen and (max-width: 768px) {
    padding: 20px;
    margin: 0;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  .content {
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    letter-spacing: 0.01em;
    &-svg {
      margin-bottom: 28px;
    }
    &-description {
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
      max-width: 439px;
      margin: 0px;
      &--gray {
        color: rgba(0, 0, 0, 0.7);
      }
      b {
        font-weight: 450;
        margin-right: 5px;
      }
    }
    h3 {
      font-weight: 450;
      font-size: 18px;
      line-height: 18px;
      margin-top: 0px;
    }
    h4 {
      margin-top: 0px;
      font-weight: 450;
      font-size: 15px;
      line-height: 20px;
    }
  }
  .mb-74 {
    margin-bottom: 74px;
  }
  .mb-52 {
    margin-bottom: 52px;
  }
  .mb-40 {
    margin-bottom: 40px;
  }
  .mb-27 {
    margin-bottom: 27px;
  }
  .mb-3 {
    margin-bottom: 3px;
  }
  .mb-8 {
    margin-bottom: 8px;
  }
  .mb-14 {
    margin-bottom: 14px;
  }
  .w-half {
    width: 50%;
  }
  .w-full {
    width: 100%;
  }
  @media screen and (max-width: 780px) {
    .w-half {
      width: 100%;
    }
    .mb-74,
    .mb-52,
    .mb-40,
    .mb-38 {
      margin-bottom: 38px;
    }
  }
`;
export const CardsRow = styled.div`
  display: flex;
  justify-content: space-between;
  .card {
    margin-right: 60px;
    text-align: left;
    .bold {
      display: block;
      font-style: normal;
      font-weight: 450;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 0.01em;
      color: #000000;
      text-decoration: none;
      margin-bottom: 10px;
    }
    .cost {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      user-select: none;
    }
  }
  .card:last-child {
    margin-right: 0;
  }
  .image-content {
    padding: 43px;
    margin-bottom: 18px;
    img {
      width: 100%;
      height: 100%;
      min-width: 139px;
      min-height: 87px;
      max-width: 420px;
      max-height: 260px;
    }
  }
  .card--blue {
    background: #e1ebf7;
  }
  .card--tel {
    background: #fcdfd7;
  }
  .card--brown {
    background: #d6aa91;
  }
  @media screen and (max-width: 1100px) {
    .image-content {
      padding: 20px;
    }
  }
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    .image-content {
      padding: 15px;
    }
    .card {
      margin-right: 0;
    }
  }
`;
export const CardsPageStyle = styled.div`
  text-align: center;
  h1 {
    font-family: "Formular";
    font-style: normal;
    font-weight: 500;
    font-size: 62px;
    line-height: 40px;
    letter-spacing: 0.01em;
    margin-bottom: 24px;
    @media screen and (max-width: 750px) {
      font-size: 27px;
      line-height: 26px;
      margin-bottom: 13px;
    }
  }
  p {
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    width: 100%;
    max-width: 531px;
    margin: 0 auto 13px;
    a {
      font-weight: 300 !important;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      font-weight: 450;
      margin-bottom: 0;
      text-decoration: underline;
    }
  }
  .bold {
    width: 100%;
    max-width: 409px;
    font-weight: 450;
    margin: 0 auto 14px;
  }
  .tel {
    display: block;
    margin-bottom: 86px;
    @media screen and (max-width: 750px) {
      margin-bottom: 55px;
    }
  }
  .cards-row {
    margin-bottom: 70px;
  }
  .reglament-link {
    font-style: normal;
    font-weight: 450;
    font-size: 15px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.1em;
    text-decoration-line: underline;
  }
  .reglament-link:visited {
    color: ${({ theme }): string => theme.colors.black};
  }
`;
export const Title = styled.h1`
  font-size: 34px;
  line-height: 42px;
  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    font-size: 26px;
    line-height: 32px;
  }
`;

export const Text = styled.p`
  font-family: Rubik;
  font-size: 14px;
  line-height: 18px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }

  .recommendations-table {
    display: table;
    padding-left: 30px;
  }
  .recommendations-cell {
    display: table-cell;
    vertical-align: middle;
    min-height: 75px;
  }
  .recommendations-cell b {
    font-weight: bold;
  }
  .recommendations-cell-first {
    width: 10%;
    text-align: center;
  }
  .recommendations-cell img {
    max-width: 75px;
  }
  .recommendations-row {
    display: table-row;
  }
`;
