import styled from "styled-components";

import { Box } from "../Box";
import { Link } from "../Link";

export const MagazineItemWrapper = styled.div``;

export const ImageLink = styled(Link)`
  display: block;
  width: 100%;
  margin-bottom: 15px;
`;

export const ImageContainer = styled(Box)`
  width: 100%;
  height: 100%;
  max-height: 707px;

  &:before {
    content: "";
    height: 0;
    display: inline-block;
    padding-top: 707px;
    width: 1px;
    position: relative;
  }

  @media screen and (max-width: 1440px) {
    &:before {
      padding-top: 537px;
    }
  }

  @media screen and (max-width: 1200px) {
    &:before {
      padding-top: 129%;
    }
  }

  @media screen and (max-width: 1200px) {
    &:before {
      padding-top: 129%;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  display: block;
`;

export const Picture = styled.picture`
  width: 100%;
  height: 100%;
`;

export const TitleLink = styled(Link)`
  font-family: Formular, sans-serif;
  font-weight: 500;
  font-size: 33px;
  line-height: 40px;
  letter-spacing: 0.01em;
  color: ${({ theme }): string => theme.colors.black};
  margin-bottom: 10px;

  @media screen and (max-width: 900px) {
    font-size: 26px;
  }
  @media screen and (max-width: 768px) {
    font-size: 25px;
    line-height: 32px;
  }
`;

export const Subtitle = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.01em;

  @media screen and (max-width: 768px) {
    font-size: 13px;
    line-height: 18px;
  }
`;
