import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 44px;
`;

export const Label = styled.label`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
`;

export const Text = styled.p`
  font-size: 30px;
  line-height: 30px;
  font-weight: 500;
  margin: 0;

  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 20px;
  }
`;

export const NotWeekends = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-left: 12px;
`;
