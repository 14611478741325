import React, { useEffect, FC } from "react";
import TagManager from "react-gtm-module";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { SeoMeta } from "src/types";

const EmptyPage: FC = () => {
  const metaTags: SeoMeta[] = [
    { name: "robots", content: "noindex, nofollow" },
  ];

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet defer={false} meta={metaTags} />
      </HelmetProvider>
    </>
  );
};

export default EmptyPage;
