import React, { VFC } from "react";

import LoaderIcon from "../Icons/Glyphs/LoaderIcon";

import { LoaderContainer } from "./Loader.styles";

const Loader: VFC = () => {
  return (
    <LoaderContainer>
      <LoaderIcon />
    </LoaderContainer>
  );
};

export default Loader;
