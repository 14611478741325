import styled from "styled-components";

import {
  MainMenu,
  MainPageBannerSlider,
  PromotionalCard,
} from "src/components";

export const Container = styled.main<{ isTopBannerShow: boolean }>`
  margin-top: ${({ isTopBannerShow }): string =>
    isTopBannerShow ? "0px" : "-35px"};
  box-sizing: border-box;
  position: relative;
  width: calc(100% + 20px);
  margin-left: -20px;

  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 1440px) {
    margin-left: -12px;
    width: calc(100% + 12px);
  }

  @media screen and (max-width: 1200px) {
    margin-left: -10px;
    width: calc(100% + 10px);
  }

  @media screen and (max-width: 768px) {
    margin-left: -20px;
    width: calc(100% + 20px);
  }

  @media screen and (max-width: 600px) {
    padding-bottom: 40px;
  }
`;

export const BoxBannerItems = styled.div`
  padding: 0 40px 0 60px;
  width: 100%;
  margin-top: -25px;
  margin-bottom: 150px;

  &.after-main {
    @media screen and (min-width: 769px) {
      margin-top: -93px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0 1px 0 21px;
    margin-top: -40px;
    margin-bottom: 100px;
    order: 2;
  }
  @media screen and (max-width: 600px) {
    order: 1;
  }
  .slick-slide {
    padding: 0 0 0 30px;
    @media screen and (max-width: 1440px) {
      padding: 0 0 0 20px;
    }
    @media screen and (max-width: 768px) {
      padding: 0 0 0 13px;
    }
  }
  .slick-list {
    width: calc(100% + 30px);
    margin-left: -30px;
    @media screen and (max-width: 1440px) {
      width: calc(100% + 20px);
      margin-left: -20px;
    }
    @media screen and (max-width: 768px) {
      width: calc(100% + 13px);
      margin-left: -13px;
    }
  }
  .banner_item {
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
  }
  .banner_items-header {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 30px;
    h2 {
      margin: 0;
    }
    a {
      text-decoration: unset;
      &:hover {
        opacity: 0.6;
      }
      span {
        text-align: center;
        font-size: 16px;
        font-weight: 450;
        letter-spacing: 0.1em;
        color: black;
        line-height: 25px;
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
      svg {
        margin-left: 9px;
      }
    }
  }
  &.magazine_before_banner {
    margin-top: -110px;

    @media screen and (max-width: 768px) {
      margin-top: -40px;
    }
  }
  &.magazine_before_promotion {
    margin-top: -45px;

    @media screen and (max-width: 768px) {
      margin-top: -40px;
    }
  }

  &.magazine_after_promotion {
    margin-bottom: 160px;

    @media screen and (max-width: 768px) {
      margin-bottom: 120px;
    }
  }

  &.magazine_after_banner {
    margin-bottom: 55px;

    @media screen and (max-width: 768px) {
      margin-bottom: 100px;
    }
  }
`;

export const Promotion = styled(PromotionalCard)`
  margin-left: 20px;
  margin-bottom: 20px;

  flex-basis: ${({ bannerType }): string => `calc(${bannerType} - 20px)`};

  @media screen and (max-width: 1440px) {
    margin-left: 12px;
    flex-basis: ${({ bannerType }): string => `calc(${bannerType} - 12px)`};
  }

  @media screen and (max-width: 1200px) {
    margin-left: 10px;
    flex-basis: ${({ bannerType }): string => `calc(${bannerType} - 10px)`};
  }

  @media screen and (max-width: 768px) {
    margin-left: 20px;

    flex-basis: ${(props) =>
      props.bannerType === "25%" && !props.isTopBanner
        ? "calc(50% - 20px)"
        : "100%"};

    order: ${(props) =>
      (props.bannerType === "25%" && !props.isTopBanner) || props.isTopBanner
        ? "2"
        : "3"};
  }

  @media screen and (max-width: 600px) {
    flex-basis: 100%;
    order: 1;
  }
`;

export const SliderBanner = styled(MainPageBannerSlider)`
  ${Promotion}
  width: 33% !important;
  flex-basis: 33% !important;
`;

export const Menu = styled(MainMenu)`
  flex-basis: 100%;

  @media screen and (min-width: 769px) {
    margin-bottom: 38px;
    padding: 20px 40px 60px 60px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
    order: -1;
  }
`;

export const BannerLink = styled.a`
  display: flex;
  color: black;
`;
