import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Link = styled(NavLink)`
  font-size: 16px;
  letter-spacing: 0.01em;
  text-decoration: none;
  font-family: HelveticaNeueCyr, sans-serif;
`;

export default Link;
