import styled from "styled-components";

import { Button, Icons } from "src/components";

export const Text = styled.p`
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 24px;
`;

export const TextArea = styled.textarea`
  position: relative;
  width: 100%;
  height: 114px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  font-size: 14px;
  color: black;
  padding: 8px;
`;

export const CommentWrapper = styled.label`
  position: relative;
  width: 100%;
`;

export const CommentLabel = styled.span`
  font-size: 12px;
  display: block;
  color: black;
  margin-top: 32px;
  margin-bottom: 16px;
`;

export const AddressButton = styled(Button)`
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  padding-left: 23px;
  border: none;
  background-color: transparent;

  position: relative;

  cursor: pointer;

  color: initial;
  text-decoration: none;
  text-transform: none;
  margin: 10px 0;
  &:hover {
    opacity: 0.5;
  }

  svg {
    margin-top: 7px;
  }
`;

export const PlusIcon = styled(Icons.Plus)`
  width: 18px;
  height: 18px;
  padding: 2px;
  border-radius: 50%;
  border: 1px solid ${({ theme }): string => theme.colors.black};

  position: absolute;
  left: 0;
  top: -2px;
`;
