import React, { FC } from "react";

import CatalogSorting from "../CatalogSorting";

import {
  CloseButton,
  CloseIcon,
  ModalContainer,
  Container,
} from "./SortingModal.styles";
import { SortingModalProps } from "./SortingModal.types";

const SortingModal: FC<SortingModalProps> = ({
  onClose,
  onChangeSorting,
  sortingItems,
  sortingValue,
  isLoading,
}) => {
  return (
    <ModalContainer onClose={onClose}>
      <CloseButton onClick={onClose} aria-label="Закрыть">
        <CloseIcon />
      </CloseButton>
      <Container>
        <CatalogSorting
          sortingItems={sortingItems}
          sortingValue={sortingValue}
          onChangeSorting={onChangeSorting}
          isLoading={isLoading}
        />
      </Container>
    </ModalContainer>
  );
};

export default SortingModal;
