import React, { useMemo, useState, useEffect, useCallback, FC } from "react";

import { useQueryParams } from "src/hooks";

import {
  Container,
  Button,
  PageButton,
  NextArrow,
  PrevArrow,
  PagesContainer,
  Left,
  Right,
  Center,
  Points,
} from "./Pagination.styles";
import { PaginationProps } from "./Pagination.types";
import { getPages } from "./Pagination.utils";

const Pagination: FC<PaginationProps> = (props) => {
  const { page, totalPages, pageCount, className } = props;

  const [pages, setPages] = useState(getPages(page, totalPages, pageCount));
  const hasNextPage = useMemo(() => page < totalPages, [page, totalPages]);
  const hasPrevPage = useMemo(() => page > 1, [page]);

  const { setQueryParams, queryParams } = useQueryParams();

  useEffect(() => {
    const searchPage = queryParams.page ? Number(queryParams.page) : 1;

    if (searchPage > 0 && searchPage <= totalPages && page !== 1) {
      setQueryParams({ page: searchPage });
    }
  }, [setQueryParams, totalPages, queryParams.page, queryParams]);

  useEffect(() => {
    const newPages = getPages(page, totalPages, pageCount);
    setPages(newPages);
  }, [page, pageCount, setPages, totalPages]);

  const nextPage = useCallback(() => {
    if (hasNextPage) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setQueryParams({ page: page + 1 });
    }
  }, [hasNextPage, page, setQueryParams]);

  const prevPage = useCallback(() => {
    if (hasPrevPage) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setQueryParams({ page: page - 1 });
    }
  }, [hasPrevPage, page, setQueryParams]);

  const toPage = useCallback(
    (value: number) => (): void => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setQueryParams({ page: value });
    },
    [setQueryParams]
  );

  if (totalPages <= 1) {
    return <></>;
  }

  return (
    <Container className={className}>
      <Left>
        {hasPrevPage && (
          <Button onClick={prevPage}>
            <PrevArrow />
            <span>Предыдущая</span>
          </Button>
        )}
      </Left>

      <Center>
        <PagesContainer>
          {page > 2 && totalPages > 3 && (
            <>
              <PageButton onClick={toPage(1)}>1</PageButton>
              <Points>...</Points>
            </>
          )}
          {pages.map((item) => (
            <PageButton
              isActive={item === page}
              onClick={toPage(item)}
              key={item}
            >
              {item}
            </PageButton>
          ))}

          {page + 1 < totalPages && totalPages > 3 && (
            <>
              <Points>...</Points>
              <PageButton onClick={toPage(totalPages)}>{totalPages}</PageButton>
            </>
          )}
        </PagesContainer>
      </Center>

      <Right>
        {hasNextPage && (
          <Button onClick={nextPage}>
            <span>Следующая</span>
            <NextArrow />
          </Button>
        )}
      </Right>
    </Container>
  );
};

export default Pagination;
