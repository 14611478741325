import React, {
  useCallback,
  useMemo,
  useState,
  FC,
  FormEvent,
  ChangeEvent,
} from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  FOOTER_MENU_ITEMS,
  FOOTER_SECOND_MENU_ITEMS,
  stringifyConfig,
  ROUTES,
} from "src/constants";
import { useQueryParams } from "src/hooks";
import { CatalogListItem } from "src/types";

import { ApplicationStore } from "../../store";
import { formatCurrencyWithoutSymbol } from "../../utils";
import { getUserLink } from "../Header/Header.utils";

import {
  ModalContainer,
  CloseButton,
  CloseIcon,
  MenuList,
  MenuListItem,
  SaleLink,
  IconLink,
  SubMenuList,
  SearchMenuList,
  SubCollapse,
  SubMenuItem,
  MenuLink,
  UserIcon,
  FooterMenuList,
  FooterLink,
  FavoriteIcon,
  ItemsCountMobile,
  Bonuses,
  UserIconLog,
  BonusCount,
  LowSubMenuLink,
  SubMenuLink,
  MainCollapse,
  SubMenuListItem,
  FavoriteLinkMobile,
  MenuListItemFavorite,
} from "./MobileGlobalMenu.styles";
import { MobileGlobalMenuProps } from "./MobileGlobalMenu.types";

const MobileGlobalMenu: FC<MobileGlobalMenuProps> = (props) => {
  const location = useLocation();
  const { onClose } = props;
  const [items, setItems] = useState<CatalogListItem[]>([]);
  const [search, setSearch] = useState("");

  const {
    user: { userWorkFlow, isAuthorized, userData },
    wishlist: wishListData,
    navigationMenu: { menu },
  } = useSelector((state: ApplicationStore) => state);

  const userLink = useMemo(
    () => getUserLink(isAuthorized, location.pathname, true),
    [isAuthorized, location.pathname]
  );

  const { setQueryParams } = useQueryParams({
    arrayFormat: "bracket",
  });

  const getBonusWordForm = (value: number): string => {
    const n = Math.abs(value) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) return "бонусов";
    if (n1 > 1 && n1 < 5) return "бонуса";
    if (n1 === 1) return "бонус";
    return "бонусов";
  };

  const wishlistCount = useMemo(() => {
    if (userWorkFlow) {
      if (userWorkFlow === "authenticated") {
        return wishListData.wish_list?.length || 0;
      } else {
        return wishListData.wishListNoUuid?.length || 0;
      }
    }
  }, [wishListData, userWorkFlow]);

  const handleSearch = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      setQueryParams(
        { q: search || undefined },
        true,
        stringifyConfig,
        "/catalog"
      );
      onClose();
    },
    [onClose, search, setQueryParams]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.value) {
        setItems([]);
      }
      setSearch(event.target.value);
    },
    [setSearch]
  );
  function classLinkNewSale(label: string) {
    let classNaming = "";
    if (label === "New") {
      classNaming = "color--black";
    }
    if (label === "Магазины") {
      classNaming = "color--black";
    }
    if (label === "Sale") {
      classNaming = "color--green";
    }

    return classNaming;
  }
  const handleClose = useCallback(() => {
    onClose();
    setItems([]);
  }, [onClose]);

  return (
    <ModalContainer {...props}>
      <CloseButton onClick={handleClose} aria-label="Закрыть">
        <CloseIcon />
      </CloseButton>
      {!!items && items.length > 0 && (
        <SearchMenuList>
          {items.map((item) => (
            <MenuListItem key={item.id}>
              <MenuLink to={item.link} onClick={onClose}>
                {item.name}
              </MenuLink>
            </MenuListItem>
          ))}
        </SearchMenuList>
      )}
      <MenuList role="menu">
        <MenuListItem role="menuitem">
          <IconLink to={userLink.url} onClick={onClose}>
            {userLink.label}
            {isAuthorized ? <UserIconLog fill={"black"} /> : <UserIcon />}
          </IconLink>
          {isAuthorized && userData && (
            <Bonuses>
              <BonusCount>
                {formatCurrencyWithoutSymbol(userData.bonuses?.available || 0)}
              </BonusCount>{" "}
              {getBonusWordForm(userData.bonuses?.available || 0)}
            </Bonuses>
          )}
        </MenuListItem>

        <MenuListItemFavorite role="menuitem">
          <FavoriteLinkMobile
            to={ROUTES.favorites}
            aria-label="Избранное"
            onClick={onClose}
          >
            Избранное
            {!!wishlistCount && (
              <ItemsCountMobile>{wishlistCount}</ItemsCountMobile>
            )}
            <FavoriteIcon />
          </FavoriteLinkMobile>
        </MenuListItemFavorite>

        {menu && menu[4] && menu[4].link === "/shops/" && (
          <MenuListItem key={menu[4].label} role="menuitem">
            <SaleLink to={menu[4].link} onClick={onClose}>
              <span className={classLinkNewSale(menu[4].label)}>
                {menu[4].label}
              </span>
            </SaleLink>
          </MenuListItem>
        )}
        {menu &&
          menu.map(({ label, link, isSale, items }) => (
            <MenuListItem key={label} role="menuitem">
              {isSale && (
                <SaleLink to={link} onClick={onClose}>
                  <span className={classLinkNewSale(label)}>{label}</span>
                </SaleLink>
              )}
              {!isSale && link !== "/shops/" && (
                <MainCollapse title={label} url={link} onClose={onClose}>
                  {!!items && items.length > 0 && (
                    <SubMenuList>
                      {items.map((item) => (
                        <SubMenuListItem key={item.label}>
                          {!item.items && (
                            <SubMenuLink to={item.url} onClick={onClose}>
                              {item.label}
                            </SubMenuLink>
                          )}
                          {!!item.items && (
                            <SubCollapse
                              title={item.label}
                              url={item.url}
                              onClose={onClose}
                            >
                              <SubMenuList>
                                {item.items.map((subItem) => (
                                  <SubMenuItem key={subItem.label}>
                                    <LowSubMenuLink
                                      to={subItem.url}
                                      onClick={onClose}
                                    >
                                      {subItem.label}
                                    </LowSubMenuLink>
                                  </SubMenuItem>
                                ))}
                              </SubMenuList>
                            </SubCollapse>
                          )}
                        </SubMenuListItem>
                      ))}
                    </SubMenuList>
                  )}
                </MainCollapse>
              )}
            </MenuListItem>
          ))}
      </MenuList>

      <FooterMenuList role="menu">
        {FOOTER_MENU_ITEMS.map(({ label, url }) => (
          <SubMenuItem key={label} role="menuitem">
            <FooterLink to={url} onClick={onClose}>
              {label}
            </FooterLink>
          </SubMenuItem>
        ))}
      </FooterMenuList>
      <FooterMenuList role="menu">
        {FOOTER_SECOND_MENU_ITEMS.map(({ label, url }) => (
          <SubMenuItem key={label} role="menuitem">
            <FooterLink to={url} onClick={onClose}>
              {label}
            </FooterLink>
          </SubMenuItem>
        ))}
      </FooterMenuList>
    </ModalContainer>
  );
};

export default MobileGlobalMenu;
