import React, { FC } from "react";

import { SvgProps } from "src/types";

const ArrowFilterUp: FC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.25 17L10.25 17.75L11.75 17.75L11.75 17L10.25 17ZM11.5303 4.46967C11.2374 4.17678 10.7626 4.17678 10.4697 4.46967L5.6967 9.24264C5.40381 9.53553 5.40381 10.0104 5.6967 10.3033C5.98959 10.5962 6.46447 10.5962 6.75736 10.3033L11 6.06066L15.2426 10.3033C15.5355 10.5962 16.0104 10.5962 16.3033 10.3033C16.5962 10.0104 16.5962 9.53553 16.3033 9.24264L11.5303 4.46967ZM11.75 17L11.75 5L10.25 5L10.25 17L11.75 17Z"
      fill="black"
    />
  </svg>
);

ArrowFilterUp.defaultProps = {
  width: "22px",
  height: "22px",
  fill: "none",
};

export default ArrowFilterUp;
