/* eslint-disable max-len */
import React, { FC } from "react";

import { SvgProps } from "src/types";

const Search: FC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 21 21">
    <path d="M16.0716 14.3691C17.2537 12.8165 17.8989 10.9058 17.8989 8.94946C17.8989 4.01269 13.8862 0 8.94946 0C6.56016 0 4.31239 0.932409 2.6224 2.6224C0.932409 4.31239 0 6.56016 0 8.94946C0 11.3388 0.932409 13.5865 2.6224 15.2765C4.31239 16.9665 6.56016 17.8989 8.94946 17.8989C10.9058 17.8989 12.8165 17.2537 14.3649 16.0757L19.2975 21L21 19.3017L16.0716 14.3691ZM8.94946 15.4805C5.3447 15.4805 2.41427 12.55 2.41427 8.94946C2.41427 5.3447 5.3447 2.41427 8.94946 2.41427C12.5542 2.41427 15.4846 5.3447 15.4846 8.94946C15.4846 12.55 12.5542 15.4805 8.94946 15.4805Z" />
  </svg>
);

Search.defaultProps = {
  width: "20px",
  height: "20px",
  fill: "black",
};

export default Search;
