import styled from "styled-components";

import { Box } from "src/components";

export const Container = styled(Box)`
  width: 100%;
  max-width: 370px;
  margin: 200px auto 100px;
`;

export const Title = styled.h3`
  font-weight: 500;
  font-size: 26px;
  line-height: 26px;
  letter-spacing: 0.01em;
  margin: 0 auto 16px;
  text-align: center;
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 24px;
  text-align: center;

  padding: 0;

  margin: 0 0 32px;
`;
