import styled from "styled-components";

import { Link, Select } from "src/components";

import { Box } from "../Box";
import { Button } from "../Button";
import { Flex } from "../Flex";
import { IconButton } from "../IconButton";
import { Icons } from "../Icons";
import { Text } from "../Text";

export const Wrapper = styled.div`
  position: relative;
`;

export const AddToCheckout = styled(Button)`
  position: absolute;
  bottom: 0px;
  height: 64px;
  font-size: 14px;
  color: ${({ theme }): string => theme.colors.white};

  &:disabled {
    color: ${({ theme }): string => theme.colors.grey};
    background-color: ${({ theme }): string => theme.colors.lightGrey};
    cursor: not-allowed;
  }
`;

export const RemoveButton = styled(IconButton)`
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 2;

  &:hover {
    opacity: 0.5;
  }
`;

export const RemoveIcon = styled(Icons.Close)`
  width: 22px;
  height: 22px;
  stroke: white;
  stroke-width: 0.1;
`;

export const ProductType = styled(Link)`
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }): string => theme.colors.black};
  word-wrap: break-word;
  margin-bottom: 6px;
  margin-top: 16px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }

  &.favourites {
    margin-top: 13px;
  }
`;

export const ProductName = styled(Link)`
  font-family: Formular, sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: ${({ theme }): string => theme.colors.black};
  margin-bottom: 6px;
  overflow: hidden;
  display: block;
  word-wrap: break-word;

  &:hover {
    color: ${({ theme }): string => theme.colors.grey};
  }
`;

export const PriceContainer = styled(Flex)`
  margin-top: 8px;
  margin-bottom: 15px;

  @media screen and (max-width: 768px) {
    margin-top: 4px;
  }

  &.favourites {
    margin: 4px 0 4px;

    @media screen and (max-width: 1200px) {
      margin-top: 7px;
    }

    @media screen and (max-width: 768px) {
      margin: 5px 0 6px;
    }
  }
`;

export const Price = styled(Text)`
  font-size: 16px;
  color: ${({ theme }): string => theme.colors.black};
  margin: 0;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }

  &.favourites {
    font-size: 12px;
    line-height: 16px;

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
`;

export const ProductStatus = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const SelectSizes = styled(Select)`
  max-width: unset;
  height: 48px;

  > div {
    border-color: ${({ theme }): string => theme.colors.lightGrey};

    > div {
      padding: 10px 12px;

      p {
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        letter-spacing: 0.01em;

        @media screen and (max-width: 768px) {
          display: block;
        }
      }

      svg {
        stroke: ${({ theme }): string => theme.colors.lightGrey};
      }

      > div {
        @media screen and (max-width: 768px) {
          display: block;
          font-size: 14px;
        }
      }
    }

    > ul {
      padding: 16px 11px;

      li {
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 0.01em;
        padding: 0;
      }
    }
  }
`;

export const ContainerItem = styled(Box)`
  width: 100%;
  padding-bottom: 64px;

  &.also {
    margin: 0 5px;
    width: calc(100% - 10px);
  }

  &.catalog {
    position: relative;
  }
  @media screen and (min-width: 768px) {
    &:hover.catalog ul,
    &:hover.catalog button,
    &:focus.catalog ul,
    &:focus.catalog button {
      visibility: visible;
      opacity: 1;
    }
  }

  &.favourites {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 600px) {
      width: auto;
      height: 100%;
      padding-right: 15px;
    }
  }
`;

export const SizeList = styled.ul`
  margin: 0;
  padding: 0;
  gap: 9px;
  list-style: none;
  visibility: hidden;
  position: absolute;
  bottom: -64px;
  left: 0;
  background-color: white;
  width: 100%;
  height: 52px;
  z-index: 70;
  transition: 0.3s ease;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: flex-start;
  opacity: 0;

  @media screen and (max-width: 768px) {
    width: 80%;
    bottom: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: auto;

    &.sizeListDesktop {
      display: none;
    }
    &.sizeListMobile {
      display: flex;
    }
  }
`;
