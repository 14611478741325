import React, { useState, useCallback, FC } from "react";
import { MOUSE_ACTIVATION, TOUCH_ACTIVATION } from "react-image-magnifiers";

import { convertImageUrl } from "src/utils";

import {
  ModalContainer,
  CloseIcon,
  CloseButton,
  ThumbnailList,
  ThumbnailImage,
  Thumbnail,
  MagnifierWrapper,
  ImageWrapper,
} from "./ImageModal.styles";
import { ImageModalProps } from "./ImageModal.types";

const ImageModal: FC<ImageModalProps> = (props) => {
  const [zoom] = useState(false);
  const { onClose, images, initialImageId = 0 } = props;

  const [imageId, setImageId] = useState(initialImageId);

  const handleChangeImg = useCallback((idx: number) => () => setImageId(idx), [
    setImageId,
  ]);

  return (
    <ModalContainer onClose={onClose} zoom={zoom}>
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>

      <ThumbnailList>
        {images.map((img, idx) => (
          <Thumbnail
            isActive={imageId === idx}
            onClick={handleChangeImg(idx)}
            key={img}
          >
            <ThumbnailImage
              src={convertImageUrl(img, 200, 160)}
              alt="Миниатюра"
            />
          </Thumbnail>
        ))}
      </ThumbnailList>

      <ImageWrapper>
        <MagnifierWrapper
          imageSrc={`${convertImageUrl(images[imageId], 1920, 1920)}`}
          largeImageSrc={`${convertImageUrl(images[imageId], 3920, 3920)}`}
          mouseActivation={MOUSE_ACTIVATION.CLICK}
          touchActivation={TOUCH_ACTIVATION.DOUBLE_TAP}
          dragToMove={window.innerWidth < 768}
        />
      </ImageWrapper>
    </ModalContainer>
  );
};

export default ImageModal;
