import styled from "styled-components";

import { Box } from "../Box";
import { Grid } from "../Grid";
import { SecondaryMenu } from "../SecondaryMenu";
import { Text } from "../Text";

export const Container = styled(Box)`
  width: 100%;
`;

export const DescriptionContainer = styled(Box)`
  width: 100%;
  padding-bottom: 30px;
  border-bottom: 1px solid ${({ theme }): string => theme.colors.lightGrey};
`;

export const Span = styled.span`
  p {
    padding: 0;
    margin: 0;
  }
`;

export const Menu = styled(SecondaryMenu)`
  max-width: 620px;

  @media screen and (max-width: 600px) {
    max-width: 100%;
  }
`;

export const DescriptionText = styled(Text)`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  margin: 30px 0 0;
  max-width: 620px;

  @media screen and (max-width: 1440px) {
    max-width: 100%;
  }

  @media screen and (max-width: 1120px) {
    font-size: 14px;
    line-height: 22px;
  }

  @media screen and (max-width: 600px) {
    margin-top: 16px;
  }
`;

export const InfoContainer = styled(Grid)`
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 80px;
  padding: 100px 0;
  width: 100%;
  border-bottom: 1px solid ${({ theme }): string => theme.colors.lightGrey};

  @media screen and (max-width: 1120px) {
    padding: 60px 0;
  }

  @media screen and (max-width: 600px) {
    padding: 30px 0;
    grid-column-gap: 50px;
  }
`;

export const InfoContainerModel = styled(Grid)`
  grid-template-columns: 1fr 1fr;
  padding: 30px 0 26px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }): string => theme.colors.lightGrey};
  grid-column-gap: 50px;
`;

export const InfoBlock = styled(Box)`
  width: 100%;

  * p {
    padding: 0;
    margin: 0;
  }
`;

export const InfoTitle = styled.h2`
  font-family: HelveticaNeueCyr, sans-serif;
  font-weight: 500;
  font-size: 22px;
  letter-spacing: 0.01em;
  margin: 0;

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }

  @media screen and (max-width: 360px) {
    font-size: 14px;
  }

  * p {
    padding: 0;
    margin: 0;
  }
`;

export const InfoText = styled(Text)`
  font-weight: 300;
  font-size: 20px;
  line-height: 1.5;
  margin: 15px 0 0;

  @media screen and (max-width: 1120px) {
    font-size: 18px;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
    margin-top: 12px;
  }
`;
