import React, { FC, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setUserData } from "src/actions";
import { setNewsletters } from "src/api";
import { NewslettersForm } from "src/components";
import { NewslettersFormValues } from "src/components/NewslettersForm/NewslettersForm.types";
import { ROUTES } from "src/constants";
import { ApplicationStore } from "src/store";
import { getBasketId } from "src/utils";

import {
  Title,
  HeaderWrapper,
  BackLink,
  BackIcon,
  BackIconMobile,
  Container,
} from "./Newsletters.styles";

const Newsletters: FC = () => {
  const dispatch = useDispatch();

  const { userData } = useSelector((state: ApplicationStore) => state.user);
  const [isLoading, setLoading] = useState(false);

  const onSubmit = useCallback(
    (values: NewslettersFormValues) => {
      const paramsStr = `is_sms=${values.is_sms ? "Y" : "N"}&is_email=${
        values.is_email ? "Y" : "N"
      }&is_mobile_push=${values.is_mobile_push ? "Y" : "N"}`;
      if (userData?.userId) {
        setLoading(true);
        setNewsletters(userData.userId, paramsStr)
          .then(() => {
            const refreshData = {
              email: userData.userEmail,
              first_name: userData.userFirstName,
              last_name: userData.userLastName,
              gender_id: userData.genderId,
              basket_uuid: userData?.checkoutId || getBasketId() || "",
              user_id: userData?.userId || null,
              name: userData?.userName || "",
              default_phone: userData?.phone || "",
              genders: userData?.genders || null,
              date_of_birth: userData?.date_of_birth || new Date(),
              customer_cards: userData?.customerCards || null,
              savings: userData?.savings || null,
              min_discount_percent: userData?.min_discount_percent || null,
              is_sms: values.is_sms || false,
              is_email: values.is_email || false,
              is_mobile_push: values.is_mobile_push || false,
              bonuses: userData?.bonuses || null,
              is_retail: userData?.is_retail || false,
            };
            dispatch(setUserData(refreshData));
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [dispatch, userData]
  );

  return (
    <>
      <HeaderWrapper>
        <BackLink to={ROUTES.accountPage}>
          <BackIcon />
          <BackIconMobile />
          <span>Назад</span>
        </BackLink>
        <Title>Рассылки</Title>
      </HeaderWrapper>
      <Container>
        {userData && (
          <NewslettersForm
            isSms={userData.is_sms || false}
            isEmail={userData.is_email || false}
            isMobilePush={userData.is_mobile_push || false}
            onSubmit={onSubmit}
            isLoading={isLoading}
          />
        )}
      </Container>
    </>
  );
};

export default Newsletters;
