import { ProductOffer, ProductQuantity, DataShopType } from "src/types";

export const checkSizeAvailability = (
  sizeId: number,
  offers: ProductOffer[] = []
): boolean => {
  if (offers.length < 1) {
    return false;
  }

  return offers.some((offer) => Number(offer.size_id) === sizeId);
};

export const filterAvailableOffersByQuantity = (
  data: ProductQuantity,
  availableOffers: ProductOffer[]
): ProductOffer[] => {
  return (availableOffers
    .map((offer: ProductOffer) => {
      if (data.data[offer.id]) {
        const filteredShops = offer.quantity_shops.filter((shop) => {
          return data.data[offer.id].find(
            (dataShop: DataShopType) =>
              dataShop.warehouse_id === shop?.warehouse_id
          );
        });
        const filterOffers = data.data[offer.id].filter(
          (shop: DataShopType) => shop.count > 0
        );
        if (filterOffers.length > 0)
          return {
            ...offer,
            quantity_shops: filteredShops,
          };
        else {
          return null;
        }
      } else {
        return null;
      }
    })
    .filter(Boolean) as unknown) as ProductOffer[];
};
