import styled from "styled-components";

import { CheckoutItem } from "src/components";

export const ListContainer = styled.div`
  width: 369px;
  position: relative;
  @media screen and (max-width: 600px) {
    width: calc(100% - 30px);
    align-self: center;
  }
  @media screen and (min-width: 600px) and (max-width: 800px) {
    width: calc(100% - 220px);
    align-self: center;
  }
  .sticky {
    position: sticky;
    top: 70px;
    @media screen and (max-width: 768px) {
      position: static;
    }
  }
`;

export const OrderItems = styled.div``;

export const Separator = styled.div`
  height: 1px;
  background-color: #e5e5e5;
`;

export const StyledCheckoutItem = styled(CheckoutItem)`
  padding-left: 0;
  padding-right: 0;

  &:first-child::before {
    left: 0;
    right: 0;
    width: 100%;
  }

  &::after {
    left: 0;
    right: 0;
    width: 100%;
  }
`;
