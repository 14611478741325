import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";

import { SelectInputProps } from "./SelectInput.types";

const SelectInput = styled.select<SelectInputProps>`
  padding: 12px 0;
  font-family: HelveticaNeueCyr, sans-serif;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: ${({ theme }): string => theme.colors.black};
  margin: 0;
  border: none;
  border-bottom: 1px solid ${({ theme }): string => theme.colors.black};
  outline: 0;
  width: 100%;
  height: 40px;
  background-color: inherit;

  :focus {
    border-color: ${({ theme }): string => theme.colors.black};
  }

  ${({
    theme,
    isEmpty,
  }): FlattenInterpolation<ThemeProps<DefaultTheme>> | false =>
    !!isEmpty &&
    css`
      color: ${theme.colors.grey};
      border-color: ${({ theme }): string => theme.colors.grey};
    `};
`;

export default SelectInput;
