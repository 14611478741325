import React, { FC, useEffect, useMemo, useState } from "react";

import { MainPageMagazineItemData, getMainPageMagazines } from "src/api";
import { ROUTES } from "src/constants";
import { BannerLink, BoxBannerItems } from "src/pages/Main/Main.styles";
import { convertImageUrl, parseImages } from "src/utils";

import CarouselNextArrow from "../../components/Carousel/CarouselNextArrow";
import CarouselPrevArrow from "../../components/Carousel/CarouselPrevArrow";
import {
  ProductListContainer,
  ProductListTitle,
} from "../../pages/Catalog/Product/Product.styles";
import {
  ContainerItem,
  ImageImg,
  ImageLink,
  InfoContainer,
  Name,
  Picture,
  ProductNameContainer,
} from "../CatalogListItem/CatalogListItem.styles";
import { ProductCarousel } from "../ProductList/ProductList.styles";

import { MagazineItem, ImageContainer } from "./MainPageMagazine.styles";
import { MainPageMagazineProps } from "./MainPageMagazine.types";

const settingsProduct = {
  dots: false,
  arrows: true,
  nextArrow: <CarouselNextArrow />,
  prevArrow: <CarouselPrevArrow />,
  slidesToScroll: 5,
  infinite: false,
  slidesToShow: 5,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 1441,
      settings: {
        slidesToScroll: 5,
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 901,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        swipeToSlide: false,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        swipeToSlide: false,
        arrows: true,
      },
    },
  ],
};

const MainPageMagazine: FC<MainPageMagazineProps> = ({
  banner,
  previousBannerType,
  nextBannerType,
}) => {
  const [items, setItems] = useState<MainPageMagazineItemData[]>([]);

  useEffect(() => {
    getMainPageMagazines().then(({ data }) => setItems(data.items));
  }, []);

  const magazinClassName = useMemo(() => {
    let className = "";

    if (previousBannerType === "BannerItems") {
      className += "magazine_before_banner";
    } else {
      className += "magazine_before_promotion";
    }

    if (nextBannerType === "BannerItems") {
      className += " magazine_after_banner";
    } else if (nextBannerType === "Promotion") {
      className += " magazine_after_promotion";
    }
    return className;
  }, [previousBannerType, nextBannerType]);

  return (
    <BoxBannerItems className={magazinClassName} key={banner.id}>
      <ProductListContainer>
        <div className="banner_items-header">
          <BannerLink href={banner.url || ""}>
            <ProductListTitle>{banner.name}</ProductListTitle>
          </BannerLink>
        </div>
        <ProductCarousel {...settingsProduct}>
          {items.map((item) => {
            const desktopImageParsedUrl = convertImageUrl(
              parseImages(item.gallery_for_main)[0],
              600,
              600
            );
            const mobileImageParsedUrl = item.mobile_gallery_for_main
              ? convertImageUrl(
                  parseImages(item.mobile_gallery_for_main)[0],
                  600,
                  600
                )
              : desktopImageParsedUrl;

            const main_page_name = item.main_page_name || "";

            return (
              <MagazineItem className="main banner_item" key={item.id}>
                <ContainerItem className="main banner_item" data-id={item.id}>
                  <ImageLink
                    to={`${ROUTES.magazinePage}${item.code}`}
                    aria-label={main_page_name}
                  >
                    <ImageContainer>
                      <Picture>
                        <source
                          media="(max-width: 600px)"
                          srcSet={mobileImageParsedUrl || desktopImageParsedUrl}
                        />
                        <ImageImg
                          src={desktopImageParsedUrl}
                          alt={main_page_name}
                        />
                      </Picture>
                    </ImageContainer>
                  </ImageLink>
                  <InfoContainer className="main">
                    {main_page_name && (
                      <ProductNameContainer
                        aria-label={main_page_name}
                        to={`${ROUTES.magazinePage}${item.code}`}
                      >
                        <Name>{main_page_name}</Name>
                      </ProductNameContainer>
                    )}
                  </InfoContainer>
                </ContainerItem>
              </MagazineItem>
            );
          })}
        </ProductCarousel>
      </ProductListContainer>
    </BoxBannerItems>
  );
};

export default MainPageMagazine;
