import styled from "styled-components";

import { Box } from "../Box";
import { Carousel } from "../Carousel";

export const MagazineCarousel = styled(Carousel)`
  .slick-track {
    margin: 0;
    display: flex;
  }
`;

export const MagazineItem = styled.div`
  max-width: 370px;
  padding: 0 6px;

  @media screen and (max-width: 1440px) {
    padding: 0 5px;
  }

  @media screen and (max-width: 600px) {
    padding: 0 7px;
  }
`;

export const ImageContainer = styled(Box)`
  width: 100%;
  height: min-content;
  max-height: 670px;

  &:before {
    content: "";
    height: 0;
    display: inline-block;
    width: 1px;
    position: relative;
    padding-top: 150% !important;
  }

  @media screen and (min-width: 1920px) {
    max-height: 820px;
    &:before {
      padding-top: 180%;
    }
  }

  @media screen and (min-width: 2298px) {
    max-height: 790px;
    &:before {
      padding-top: 180%;
    }
  }

  @media screen and (min-width: 2760px) {
    max-height: 815px;
    &:before {
      padding-top: 200%;
    }
  }
`;
