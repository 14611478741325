import styled from "styled-components";

import { Button } from "../Button";

export const FormContainer = styled.form`
  position: relative;
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 0;
  display: flex;
`;

export const SubscribeButton = styled(Button)`
  max-width: 180px;
  height: 40px;
  border: none;

  :hover {
    opacity: 0.5;
    background-color: ${({ theme }): string => theme.colors.white};
    color: ${({ theme }): string => theme.colors.black};
  }

  :disabled {
    cursor: not-allowed;
    opacity: 1;
  }

  @media screen and (max-width: 1200px) {
    max-width: 150px;
  }

  @media screen and (max-width: 1150px) {
    max-width: 180px;
  }

  @media screen and (max-width: 600px) {
    max-width: 120px;
  }
`;

export const SubscriptionMessage = styled.p`
  font-size: 23px;
  line-height: 25px;
  color: ${({ theme }): string => theme.colors.white};

  margin: 0;
  animation: show_message 0.4s ease-out;

  @keyframes show_message {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 22px;
  }
`;
