import React, { FC } from "react";

import { Checkbox } from "src/components";
import { FilterType } from "src/types";

import { InputProps } from "./Input.types";
import { getLabel, getValue } from "./Input.utils";

// TODO: Дополнить виды инпутов
const Input: FC<InputProps> = (props) => {
  const {
    filterValue,
    filterType,
    filterCode,
    values,
    onChange,
    disabled,
  } = props;
  const inputName = `filter[${filterCode}]`;

  if (filterType === FilterType.checkbox) {
    return (
      <Checkbox
        name={`${inputName}:${filterValue.id}`}
        label={`${filterValue.name}`}
        checked={!!getValue(values, inputName, filterValue.id)}
        onChange={onChange}
        disabled={disabled}
        data-code={filterValue.code ? filterValue.code : ""}
      />
    );
  }

  if (filterType === FilterType.radio) {
    return (
      <Checkbox
        name={inputName}
        label={getLabel(filterValue.name)}
        type="radio"
        value={`${filterValue.id}`}
        checked={`${values[inputName]}` === `${filterValue.id}`}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  return null;
};

export default Input;
