import { useRef, useEffect, useCallback, RefObject } from "react";

const useClickOutside = <T extends HTMLElement>(
  onClose: VoidFunction
): RefObject<T> => {
  const ref = useRef<T>(null);

  const mouseListener = useCallback(
    (e) => {
      if (
        e.target.tagName !== "svg" &&
        e.target.tagName !== "path" &&
        !ref.current?.contains(e.target)
      ) {
        onClose();
      }
    },
    [onClose]
  );
  const keyboardListener = useCallback(
    (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("click", mouseListener);
    document.addEventListener("keyup", keyboardListener);

    return (): void => {
      document.removeEventListener("click", mouseListener);
      document.removeEventListener("keyup", keyboardListener);
    };
  }, [mouseListener, keyboardListener]);

  return ref;
};

export default useClickOutside;
