import React, { FC, useState, useEffect } from "react";
import TagManager from "react-gtm-module";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { getMagazines, MagazineItemData } from "src/api";
import { Link, LoaderButton, MagazineItem, Preloader } from "src/components";
import { usePreloaderTimer } from "src/hooks/usePreloaderTimer";
import { SeoMeta } from "src/types";
import { generateMetaTags } from "src/utils";

import {
  BreadCrumbs,
  Container,
  MagazineHeader,
  Slash,
  MagazineList,
  MockItem,
} from "./Magazine.styles";

const Magazine: FC = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [magazines, setMagazines] = useState<MagazineItemData[]>([]);
  const [currPage, setCurrPage] = useState(1);
  const [firstFetch, setFirstFetch] = useState(true);
  const [totalPages, setTotalPages] = useState(1);

  const seoTitle = "Журнал";
  const seoDescription =
    "Журнал. Leform: концептуальный интернет-магазин одежды, обуви, аксессуаров и предметов интерьера";
  const metaTags: SeoMeta[] = generateMetaTags(seoTitle, seoDescription);

  const [isLoaderShown, onStartFetching, onEndFetching] = usePreloaderTimer();

  useEffect(() => {
    setLoading(true);
    if (firstFetch) onStartFetching(0);
    getMagazines(currPage)
      .then(({ data }) => {
        setTotalPages(data.paginate ? data.paginate.total : 1);
        setMagazines([...magazines, ...data.items]);
      })
      .finally(() => {
        if (firstFetch) onEndFetching(100);
        setFirstFetch(false);
        setLoading(false);
      });
  }, [currPage]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  }, []);

  return (
    <Container>
      <HelmetProvider>
        <Helmet defer={false} title={seoTitle} meta={metaTags} />
      </HelmetProvider>

      {isLoaderShown && <Preloader />}

      <MagazineHeader>Журнал</MagazineHeader>
      <BreadCrumbs itemScope itemType="http://schema.org/BreadcrumbList">
        <li
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
        >
          <Link to="/" itemProp="item">
            <span itemProp="name">Leform</span>{" "}
            <meta itemProp="position" content="1" />
          </Link>
        </li>
        <Slash>/</Slash>
        <li
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
        >
          <span itemProp="name">Журнал</span>
          <meta itemProp="position" content="2" />
        </li>
      </BreadCrumbs>
      <MagazineList>
        {isLoading &&
          firstFetch &&
          new Array(20).fill(null).map((_, idx) => <MockItem key={idx} />)}
        {magazines.length > 0 &&
          magazines.map((item) => (
            <MagazineItem
              key={item.id}
              id={item.id}
              gallery={item.gallery_old}
              title={item.name_old}
              subtitle={item.preview_text_old}
              code={item.code}
            />
          ))}
      </MagazineList>
      {currPage < totalPages && (
        <LoaderButton
          className="catalogList"
          buttonText="Показать ещё"
          isLoading={isLoading}
          onClick={() => setCurrPage(currPage + 1)}
        />
      )}
    </Container>
  );
};

export default Magazine;
