import React, { FC } from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import { ROUTES } from "src/constants";

import { ConfirmationPage } from "./Confirmation";
import { NewPasswordPage } from "./NewPassword";
import { ResetByEmailPage } from "./ResetByEmail";
import { ResetByPhonePage } from "./ResetByPhone";
import { ResetTypePage } from "./ResetType";

const ResetPage: FC = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.reset} component={ResetTypePage} />
      <Route exact path={ROUTES.resetByEmail} component={ResetByEmailPage} />
      <Route exact path={ROUTES.resetByPhone} component={ResetByPhonePage} />
      <Route
        exact
        path={ROUTES.resetConfirmation}
        component={ConfirmationPage}
      />
      <Route exact path={ROUTES.newPassword} component={NewPasswordPage} />

      <Redirect to={ROUTES.reset} />
    </Switch>
  );
};

export default ResetPage;
