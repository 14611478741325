import React, { FC } from "react";
import { createPortal } from "react-dom";

export type PortalProps = {
  rootElement?: Element | null;
};

const Portal: FC<PortalProps> = ({ children, rootElement = document.body }) => {
  if (rootElement) {
    return createPortal(children, rootElement);
  }

  return <>{children}</>;
};

export default Portal;
