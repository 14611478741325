import styled from "styled-components";

import { Box } from "../Box";
import { Flex } from "../Flex";

export const ModalWrapper = styled(Flex)`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }): string => theme.colors.blackOpacity};
  z-index: 100;
  top: 0;
  left: 0;
  overflow-y: auto;
`;

export const Container = styled(Box)`
  position: absolute;
  width: 100%;
  min-height: 100%;
  height: auto;
  background-color: ${({ theme }): string => theme.colors.white};
  overflow-y: auto;
`;
