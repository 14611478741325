import React, { FC } from "react";

import { AddButton, PreloaderButton } from "./LoaderButton.styles";
import { PreloaderButtonProps } from "./LoaderButton.types";

const LoaderButton: FC<PreloaderButtonProps> = ({
  buttonText,
  className,
  disabled,
  isLoading,
  onClick,
}) => {
  return (
    <AddButton
      className={className}
      disabled={disabled}
      loading={isLoading}
      onClick={onClick}
    >
      {!isLoading ? (
        buttonText
      ) : (
        <PreloaderButton className={className}>
          <div className={className} />
          <div className={className} />
          <div className={className} />
        </PreloaderButton>
      )}
    </AddButton>
  );
};

export default LoaderButton;
