import { Banner, SeoMeta } from "src/types";

export const calculateTopBanners = (banners: Banner[]): number => {
  let sum = 0;
  let index = 0;
  banners.forEach((banner, i) => {
    const bannerType = banner.type ? Number(banner.type) : 25;
    sum += bannerType;
    if (sum > 100 && index === 0) {
      index = i;
    }
  });
  return index;
};

export const getButtonVisibility = (
  type: string,
  show_buy_button: boolean | null
): boolean => {
  return (type === "50" || type === "100") && !!show_buy_button;
};

export const deleteSlash = (path: string): string => {
  if (path[path.length - 1] === "/") return path.slice(0, path.length - 1);
  return path;
};

export const generateMetaTags = (
  seoTitle: string,
  seoDescription: string,
  prerenderMeta?: SeoMeta[]
): SeoMeta[] => {
  const baseMetaTags: SeoMeta[] = [
    { name: "description", content: seoDescription },
    { property: "og:title", content: seoTitle },
    { property: "og:description", content: seoDescription },
  ];
  if (prerenderMeta) {
    baseMetaTags.push(...prerenderMeta);
  }
  return baseMetaTags;
};

export const applyPhoneMask = (
  phone: string | null,
  phoneMask: string | null
): string => {
  if (phone && phoneMask) {
    let phoneInd = 0;
    return phoneMask.replace(/_/g, () => phone?.[phoneInd++] || "");
  }
  return "";
};
