import React, { FC, useMemo } from "react";

import { ROUTES } from "src/constants";
import { convertImageUrl, parseImages } from "src/utils";

import {
  MagazineItemWrapper,
  ImageContainer,
  ImageLink,
  Picture,
  Image,
  TitleLink,
  Subtitle,
} from "./MagazineItem.styled";
import { MagazineItemProps } from "./MagazineItem.types";

const MagazineItem: FC<MagazineItemProps> = ({
  id,
  title,
  gallery,
  subtitle,
  code,
}) => {
  const images = useMemo(() => parseImages(gallery), [gallery]);

  return (
    <MagazineItemWrapper>
      <ImageLink to={`${ROUTES.magazinePage}${code}`}>
        <ImageContainer>
          <Picture>
            <source
              srcSet={convertImageUrl(images[0], 1200, 1200)}
              media="(max-width: 1200px)"
            />
            <Image src={convertImageUrl(images[0], 2000, 2000)} alt={title} />
          </Picture>
        </ImageContainer>
      </ImageLink>
      <TitleLink to={`${ROUTES.magazinePage}${code}`}>{title}</TitleLink>
      <Subtitle>{subtitle}</Subtitle>
    </MagazineItemWrapper>
  );
};

export default MagazineItem;
