/* eslint-disable max-len */

import React, { FC } from "react";

import { SvgProps } from "src/types";

const Instagram: FC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 25 25">
    <path d="M18.1 0H6.9A7 7 0 000 6.9v11.2A7 7 0 006.9 25h11.2a7 7 0 006.9-6.9V6.9A7 7 0 0018.1 0zm4.7 18.1c0 2.6-2.1 4.7-4.7 4.7H6.9A4.7 4.7 0 012.2 18V6.9c0-2.6 2.1-4.7 4.7-4.7h11.2c2.6 0 4.7 2.1 4.7 4.7v11.2z" />
    <path d="M12.5 6a6.4 6.4 0 100 13 6.4 6.4 0 000-13zm0 10.7a4.2 4.2 0 110-8.4 4.2 4.2 0 010 8.4zM19.2 4.2a1.6 1.6 0 00-1.6 1.6 1.6 1.6 0 001.6 1.6c.4 0 .9-.1 1.2-.4a1.6 1.6 0 000-2.3c-.3-.3-.8-.5-1.2-.5z" />
  </svg>
);

Instagram.defaultProps = {
  width: "20px",
  height: "20px",
  fill: "black",
};

export default Instagram;
