import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: center;

  text-align: center;
`;

export const Title = styled.h2`
  font-family: Formular, sans-serif;
  font-weight: 500;
  font-size: 24px;

  margin-bottom: 25px;
`;

export const OrderNumber = styled.span`
  font-size: 18px;
`;

export const PaymentLink = styled.a`
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.1em;
  font-weight: 500;
  padding: 6px 16px 4px;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  border: none;
  text-decoration: none;
  margin-top: 20px;
  background-color: ${({ theme }): string => theme.colors.black};
  color: ${({ theme }): string => theme.colors.white};
  transition: 0.3s ease opacity;

  :hover {
    opacity: 0.8;
  }
`;
