import styled from "styled-components";

const BorderedInput = styled.input`
  position: relative;
  width: 100%;
  background-color: inherit;
  border: 1px solid ${({ theme }): string => theme.colors.grey};
  letter-spacing: 0.01em;
  font-size: 12px;
  padding: 9px 32px 7px;
  height: 40px;
  color: ${({ theme }): string => theme.colors.white};
  outline: 0;
  border-radius: 0;

  :focus {
    border-color: ${({ theme }): string => theme.colors.white};
  }
`;

export default BorderedInput;
