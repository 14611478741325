import React, { FC, useCallback, useState } from "react";

import { AuthDataForm, AuthDataConfForm, Preloader } from "src/components";

import {
  CloseButton,
  CloseIcon,
  ModalContainer,
  Wrapper,
  Title,
  OrderSuccessText,
  CloseFastOrder,
} from "./FastOrder.styles";
import { FastOrderProps } from "./FastOrder.types";

const FastOrder: FC<FastOrderProps> = ({
  offerId,
  onClose,
  fastProduct,
  price,
}) => {
  const titles = ["Быстрый заказ", "Пароль из смс", "Ваш заказ оформлен"];
  const [userName, setUserName] = useState("");
  const [orderStep, setOrderStep] = useState(0);
  const [orderNumber, setOrderNumber] = useState<number | null>(null);
  const [isLoading, setLoading] = useState(false);

  const onCloseModal = useCallback(() => {
    onClose();
    if (orderStep === 2) {
      window.location.reload();
    }
  }, [onClose, orderStep]);

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <ModalContainer onClose={onCloseModal}>
          <CloseButton onClick={onCloseModal} aria-label="Закрыть">
            <CloseIcon />
          </CloseButton>

          <Wrapper>
            <Title>{titles[orderStep]}</Title>
            {orderStep === 0 && (
              <AuthDataForm
                setOrderStep={setOrderStep}
                setOrderNumber={setOrderNumber}
                offerId={offerId}
                setUserName={setUserName}
                products={fastProduct}
                setLoading={setLoading}
                isLoading={isLoading}
                price={price}
              />
            )}
            {orderStep === 1 && (
              <AuthDataConfForm
                setOrderStep={setOrderStep}
                setOrderNumber={setOrderNumber}
                offerId={offerId}
                userName={userName}
                products={fastProduct}
                setLoading={setLoading}
                isLoading={isLoading}
                price={price}
              />
            )}
            {orderStep === 2 && (
              <>
                <OrderSuccessText>№заказа: {orderNumber}</OrderSuccessText>
                <CloseFastOrder onClick={onCloseModal} variant="white">
                  Закрыть
                </CloseFastOrder>
              </>
            )}
          </Wrapper>
        </ModalContainer>
      )}
    </>
  );
};

export default FastOrder;
