/* eslint-disable max-len */

import React, { FC } from "react";

import { SvgProps } from "src/types";

const FullLogo: FC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 132 55">
    <path d="M36.4 48.8c0-.8.6-1.4 1.6-1.4.7 0 1.3.2 1.7.7l.4-.5c-.4-.5-1.3-.7-2-.7-1.5 0-2.3.8-2.3 1.9 0 2.6 4 1.8 4 4 0 1-1 1.4-2 1.4-.6 0-1.5-.3-1.9-1l-.3.5c.4.7 1.4 1 2.4 1 1.3 0 2.4-.8 2.4-2 0-2.6-4-1.7-4-4zM43.7 47h-.6v7.7h.6v-7.8zM52.9 53.7l-5.3-6.8H47v7.8h.6V48l5.2 6.7h.5v-7.8H53v6.8zM59.8 54.2c-1.9 0-3.2-1.4-3.2-3.4s1.3-3.4 3-3.4c1 0 1.6.2 2.1.6l.3-.5c-.6-.5-1.4-.8-2.3-.8-2.3 0-3.8 1.8-3.8 4 0 2.4 1.6 4 4 4 .8 0 1.8-.3 2.3-.8l-.3-.5a3 3 0 01-2 .8zM65 51h3.3v-.5H65v-3h3.7v-.6h-4.3v7.8h4.4v-.6H65v-3zM74 48.3l.2.4 1.3-1v7h.5v-7.8h-.3L74 48.3zM81 46.9c-1 0-2.3 1-2.3 2.4 0 1.3.9 2.4 2.3 2.4 1 0 1.7-.4 2-1.1-.2 2.1-1.5 3.5-3.3 3.9l.2.5c2-.3 3.7-2.2 3.7-5.1 0-1.7-.8-3-2.5-3zm.2 4.2c-1.3 0-1.9-.9-1.9-1.8 0-1 .8-1.8 1.8-1.8 1.4 0 1.9 1 1.9 2.3-.1.6-.8 1.3-1.8 1.3zM87.8 46.9c-1.2 0-2.4 1-2.4 2.4 0 1.3.9 2.4 2.3 2.4 1 0 1.6-.4 2-1.1-.2 2.1-1.5 3.5-3.4 3.9l.2.5c2.2-.3 3.8-2.2 3.8-5.1 0-1.7-.8-3-2.5-3zm0 4.2c-1.2 0-1.8-.9-1.8-1.8 0-1 .8-1.8 1.8-1.8 1.3 0 1.9 1 1.9 2.3-.2.6-.8 1.3-1.9 1.3zM92 47.5h3.7l-2.9 7.2h.7l3-7.4v-.4h-4.6v.6zM19.6 38.6h12.3v-6h-8.6V22h7.6v-5.8h-7.6V6.7H32v-6H19.6v37.9zM3.8.6H0v38h12.5v-6H3.8V.5zM75.2 9.2c0-4.2 0-9.2-6.4-9.2h-1.4C61 0 61 5 61 9.2v20.9c0 4.3 0 9 6.3 9h1.4c6.4 0 6.4-4.7 6.4-9V9.2zm-3.7 21c0 2.8-1 2.8-3.5 2.8-2.2 0-3.1 0-3.1-2.7V9c0-2.8.9-2.8 3.1-2.8 2.4 0 3.5 0 3.5 2.8v21.3zM125.7.6l-4.9 29.6h-.3L115.7.6h-6.3v38h3.7V15l-.3-5.8h.3l5.1 29.4h4.9l5-29.4h.4l-.3 5.8v23.6h3.8V.6h-6.3zM99 17V8.3c0-2.6 0-7.4-6.3-7.7H85v38h3.7v-14h3.6l3.4 14h4.1l-3.7-14.8c2.7-1.2 3-3.4 3-6.8zm-3.6-1.4c0 2.8-1 2.9-3.3 2.9h-3.4V6.7h3.4c2.2 0 3.3 0 3.3 2.8v6.1zM41.5 38.6h3.7V22h7.6v-5.8h-7.6V6.7H54v-6H41.5v37.9z" />
  </svg>
);

FullLogo.defaultProps = {
  width: "132px",
  height: "55px",
  fill: "black",
};

export default FullLogo;
