import styled from "styled-components";

import { Box } from "src/components";

export const CheckBoxContainer = styled(Box)`
  display: block;
`;

export const NativeCheckbox = styled.input`
  clip: rect(0 0 0 0);
  height: 1px;
  margin: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
`;

export const CustomCheckbox = styled.div<{ isRadio?: boolean }>`
  position: relative;
  background-color: inherit;
  border: 1px solid ${({ theme }): string => theme.colors.blackOpacity};
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  min-height: 20px;
  min-width: 20px;
  margin-right: 16px;
  border-radius: ${({ isRadio }): string => (isRadio ? "50%" : "0")};

  ${NativeCheckbox}:checked + &:after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: ${({ theme }): string => theme.colors.black};
    top: 5px;
    left: 5px;
    border-radius: ${({ isRadio }): string => (isRadio ? "50%" : "0")};
  }

  ${NativeCheckbox}:disabled + & {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const Label = styled.label`
  align-items: center;
  color: ${({ theme }): string => theme.colors.black};
  display: flex;
  font-family: "HelveticaNeueCyr", sans-serif;
  padding: 0;
  position: relative;
  font-size: 14px;
  cursor: pointer;
  p {
    margin: 0;
  }
  &:hover {
    color: ${({ theme }): string => theme.colors.grey};
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`;
