import styled from "styled-components";

export const FavouritesTitle = styled.h2`
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
  text-align: left;

  margin: 0 0 30px;

  @media screen and (max-width: 1200px) {
    margin-bottom: 27px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 34px;
  }

  @media screen and (max-width: 600px) {
    width: 255px;
    margin: 55px 0 28px;
  }
`;

export const Favourites = styled.section`
  width: 915px;

  @media screen and (max-width: 1440px) {
    width: 605px;
    margin-right: 65px;
  }

  @media screen and (max-width: 1200px) {
    width: 450px;
    margin-right: 100px;
  }

  @media screen and (max-width: 768px) {
    width: 50%;
    max-width: 305px;
    margin-right: 0;
    flex-grow: 1;
  }

  @media screen and (max-width: 600px) {
    max-width: 100%;
    width: 100%;
    margin-top: 28px;

    position: relative;

    &::before {
      content: "";

      width: 100%;
      height: 2px;
      background-color: ${({ theme }): string => theme.colors.lightGrey};

      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @media screen and (max-width: 480px) {
    &::before {
      width: calc(100% - 15px);
    }
  }
`;

export const FavouritesContainer = styled.div``;
