import styled, { css } from "styled-components";

import { Box } from "../Box";
import { ExternalLinkButton } from "../ExternalLinkButton";
import { Flex } from "../Flex";
import { Link } from "../Link";
import { LinkButton } from "../LinkButton";
import { Text } from "../Text";

import {
  ImageLinkProps,
  ImageProps,
  MobileImageProps,
  TextContainerProps,
} from "./PromotionalCard.types";

export const Container = styled(Box)`
  width: 100%;
  .link_tg {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ImageLink = styled(Link)<ImageLinkProps>`
  width: 100%;
  /* height: ${({ compact }): string => (compact ? "720px" : "950px")};*/
  display: flex;
  align-items: center;
  justify-content: center;

  /*  @media screen and (min-width: 1920px) {
    height: 1140px;
  }

  @media screen and (min-width: 2298px) {
    height: 1370px;
  }

  @media screen and (min-width: 2760px) {
    height: 1900px;
  }

  @media screen and (max-width: 1440px) {
    height: ${({
    compact,
  }): string =>
    !!compact
      ? "635px"
      : "780px"};
  }

  @media screen and (max-width: 1200px) {
    height: ${({
    compact,
  }): string =>
    !!compact
      ? "530px"
      : "650px"};
  }

  @media screen and (max-width: 768px) {
    height: ${({
    compact,
  }): string =>
    !!compact
      ? "380px"
      : "460px"};
  }

  @media screen and (max-width: 600px) {
    height: 410px;
  }
  */
`;

export const Image = styled.img<ImageProps>`
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media screen and (max-width: 768px) {
    display: ${({ isHide }): string =>
      isHide ? "none !important" : "block !important"};
  }
`;

export const ImageMobile = styled.img<MobileImageProps>`
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: none !important;

  @media screen and (max-width: 768px) {
    display: ${({ isShow }): string =>
      isShow ? "block !important" : "none !important"};
  }
`;

export const InfoContainer = styled(Flex)`
  width: 100%;
  margin-top: 12px;
  padding: 12px 32px;

  @media screen and (max-width: 1440px) {
    padding: 12px 16px;
  }

  @media screen and (max-width: 768px) {
    justify-content: space-between;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    margin: 16px 0 0;
  }
`;

export const TextContainer = styled(Box)<TextContainerProps>`
  max-width: ${({ withButton }): string => (withButton ? "60%" : "100%")};
  margin-right: ${({ withButton }): string => (withButton ? "32px" : "0")};

  @media screen and (max-width: 1200px) {
    margin-right: ${({ withButton }): string => (withButton ? "24px" : "0")};
  }

  @media screen and (max-width: 600px) {
    margin: 0;
    max-width: 100%;
  }
`;

export const Title = styled.h2`
  font-family: Formular, sans-serif;
  font-weight: 500;
  font-size: 44px;
  letter-spacing: 0.01em;
  color: ${({ theme }): string => theme.colors.black};
  margin: 0;

  @media screen and (max-width: 1440px) {
    font-size: 34px;
    line-height: 42px;
  }

  @media screen and (max-width: 1200px) {
    font-size: 30px;
    line-height: 38px;
  }

  @media screen and (max-width: 768px) {
    font-size: 34px;
    line-height: 42px;
  }

  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const Description = styled(Text)`
  font-size: 22px;
  margin: 20px 0 0;
  color: ${({ theme }): string => theme.colors.darkGrey};
  max-width: 450px;
  line-height: 1.5;

  @media screen and (max-width: 1440px) {
    font-size: 16px;
    margin-top: 12px;
  }

  @media screen and (max-width: 1200px) {
    font-size: 14px;
    margin-top: 4px;
    max-width: 360px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    margin-top: 16px;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
    margin-top: 8px;
  }
`;

const LinkButtonStyles = css`
  height: 86px;
  font-size: 14px;
  max-width: 290px;
  margin-top: 8px;

  :hover {
    background-color: ${({ theme }): string => theme.colors.black};
    color: ${({ theme }): string => theme.colors.white};
  }

  @media screen and (max-width: 1440px) {
    max-width: 250px;
    height: 64px;
  }

  @media screen and (max-width: 1200px) {
    max-width: 200px;
    height: 55px;
    padding: 10px 0 5px;
    margin-top: 4px;
  }

  @media screen and (max-width: 768px) {
    height: 64px;
    max-width: 250px;
  }

  @media screen and (max-width: 600px) {
    height: 50px;
    max-width: 170px;
    font-size: 10px;
    margin-top: 16px;
  }
`;

export const Button = styled(LinkButton)`
  ${LinkButtonStyles};
`;

export const ExternalButton = styled(ExternalLinkButton)`
  ${LinkButtonStyles};
`;
