import styled from "styled-components";

import { Accordeon } from "src/components";

export const Container = styled.main`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 40px;
  max-width: 1180px;
  margin: auto;

  @media screen and (max-width: 768px) {
    padding: 20px;
    margin: 0;
  }
`;

export const AccordeonInner = styled(Accordeon)`
  border-bottom: 0;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
`;

export const AccordeonTop = styled(Accordeon)`
  &:last-child {
    border-top: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  .content {
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    letter-spacing: 0.01em;
    &-svg {
      margin-bottom: 28px;
    }
    &-description {
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
      max-width: 439px;
      margin: 0px;
      &--gray {
        color: rgba(0, 0, 0, 0.7);
      }
      b {
        font-weight: 400;
        margin-right: 5px;
      }
    }
    h3 {
      font-weight: 450;
      font-size: 18px;
      line-height: 18px;
      margin-top: 0px;
    }
    h4 {
      margin-top: 0px;
      font-weight: 450;
      font-size: 15px;
      line-height: 20px;
    }
    ol {
      padding-left: 23px;
      li {
        margin-bottom: 10px;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        position: relative;
        padding-left: 13px;
        margin-bottom: 10px;
        b {
          font-weight: 400;
        }
        &::before {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 100%;
          top: 4px;
          left: 4px;
          background: black;
        }
      }
    }
  }
  .d-flex {
    display: flex;
    flex-wrap: wrap;
  }
  .mb-74 {
    margin-bottom: 74px;
  }
  .mb-52 {
    margin-bottom: 52px;
  }
  .mb-40 {
    margin-bottom: 40px;
  }
  .mb-27 {
    margin-bottom: 27px;
  }
  .mb-3 {
    margin-bottom: 3px;
  }
  .mb-8 {
    margin-bottom: 8px;
  }
  .mb-14 {
    margin-bottom: 14px;
  }
  .mb-12 {
    margin-bottom: 12px;
  }
  .w-half {
    width: 50%;
  }
  .w-full {
    width: 100%;
  }
  @media screen and (max-width: 780px) {
    .w-half {
      width: 100%;
    }
    .mb-74,
    .mb-52,
    .mb-40,
    .mb-38 {
      margin-bottom: 38px;
    }
    .d-flex {
      justify-content: center;
    }
  }
`;
export const PaymentPage = styled.div`
  text-align: center;
  .bb-none {
    border-bottom: none;
  }
  h1 {
    font-family: "Formular";
    font-style: normal;
    font-weight: 500;
    font-size: 62px;
    line-height: 40px;
    letter-spacing: 0.01em;
    margin: 84px 0 70px;
    @media screen and (max-width: 750px) {
      font-size: 27px;
      line-height: 26px;
      margin-bottom: 13px;
    }
  }
  p {
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    width: 100%;
    max-width: 531px;
    margin: 0 auto 13px;
    a {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      font-weight: 450;
      margin-bottom: 0;
    }
  }
  .bold {
    width: 100%;
    max-width: 409px;
    font-weight: 450;
    margin: 0 auto 14px;
  }
  a {
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    font-weight: 450;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
    text-decoration: none;
    margin-bottom: 86px;
  }
  .tel {
    display: block;
    margin-bottom: 86px;
    @media screen and (max-width: 750px) {
      margin-bottom: 55px;
    }
  }

  .fXMkec {
    background: #f8f8f8;
    width: 266px;
    height: 296px;
    margin: 0 6px 6px 0;
    &:last-child {
      margin: 0;
    }
    hr {
      border: 1px solid #e1e1e1;
    }
    .card-svg {
      padding: 16px 0;
      display: flex;
      justify-content: center;
    }
    .card-text {
      padding: 16px;
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #000000;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;
export const Title = styled.h1`
  font-size: 34px;
  line-height: 42px;
  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    font-size: 26px;
    line-height: 32px;
  }
`;

export const Text = styled.p`
  font-family: Rubik;
  font-size: 14px;
  line-height: 18px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }

  .recommendations-table {
    display: table;
    padding-left: 30px;
  }
  .recommendations-cell {
    display: table-cell;
    vertical-align: middle;
    min-height: 75px;
  }
  .recommendations-cell b {
    font-weight: bold;
  }
  .recommendations-cell-first {
    width: 10%;
    text-align: center;
  }
  .recommendations-cell img {
    max-width: 75px;
  }
  .recommendations-row {
    display: table-row;
  }
`;
export const Card = styled.div`
  background: #f8f8f8;
  width: 266px;
  height: 296px;
  margin: 0 6px 6px 0;
  &:last-child {
    margin: 0;
  }
  hr {
    border: 1px solid #e1e1e1;
  }
  .card-svg {
    padding: 16px 0;
    display: flex;
    justify-content: center;
  }
  .card-text {
    padding: 16px;
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
      color: #000000;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;
