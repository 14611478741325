export type LetterItemProps = {
  isActive?: boolean;
  isdisabled?: boolean;
};

export type AlphabetProps = {
  scrollPosition: number;
  isWayDown: boolean;
  isOnBottom: boolean;
  contentHeight: number;
};

export enum GenderBrands {
  isMan = "is_man",
  isWoman = "is_woman",
  isInterior = "is_interior",
  isTop = "is_top",
}
