import { SearchAddressData, UserAddress } from "src/types";

export const getAddressValues = (
  addressData: SearchAddressData
): Partial<Omit<UserAddress, "id">> => {
  const cityField: string[] = [];
  const streetField: string[] = [];
  const houseField: string[] = [];
  const flatField: string[] = [];

  if (
    addressData.region_with_type &&
    addressData.region_with_type !== addressData.city_with_type
  ) {
    cityField.push(addressData.region_with_type);
  }
  if (addressData.city_with_type) {
    cityField.push(addressData.city_with_type);
  }
  if (addressData.area_with_type) {
    cityField.push(addressData.area_with_type);
  }
  if (addressData.settlement_with_type) {
    cityField.push(addressData.settlement_with_type);
  }

  if (addressData.street_with_type) {
    streetField.push(addressData.street_with_type);
  }
  if (addressData.house_type && addressData.house) {
    const house = `${addressData.house_type} ${addressData.house} ${
      addressData.block_type ?? ""
    } ${addressData.block ?? ""}`;

    console.log(house);
    houseField.push(house);
  }
  if (addressData.flat_type && addressData.flat) {
    const flat = `${addressData.flat_type} ${addressData.flat}`;
    flatField.push(flat);
  }

  return {
    city: cityField.join(", "),
    zip_code: addressData.postal_code || "",
    street: streetField.join(", "),
    house: houseField.join(", "),
    flat: flatField.join(", "),
  };
};
