import {
  NavigationMenu,
  NavigationMenuActions,
  NavigationMenuTypes,
} from "./types";

const initialState: NavigationMenu = {
  menu: [],
};

const NavigationMenuReducer = (
  state: NavigationMenu = initialState,
  action: NavigationMenuActions
): NavigationMenu => {
  switch (action.type) {
    case NavigationMenuTypes.SET: {
      return { ...state, menu: action.payload || [] };
    }

    case NavigationMenuTypes.CLEAR: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default NavigationMenuReducer;
