/* eslint-disable max-len */

import React, { FC } from "react";

import { SvgProps } from "src/types";

const Facebook: FC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 25 25">
    <path d="M25.2 12.6A12.6 12.6 0 1010.6 25v-8.8H7.4v-3.6h3.2V9.8c0-3.1 1.9-4.9 4.8-4.9 1.3 0 2.8.3 2.8.3v3h-1.6c-1.6 0-2 1-2 2v2.4H18l-.5 3.6h-3V25c6-1 10.7-6.1 10.7-12.4z" />
  </svg>
);

Facebook.defaultProps = {
  width: "20px",
  height: "20px",
  fill: "black",
};

export default Facebook;
