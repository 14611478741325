import React, { FC, useEffect } from "react";
import useCollapse from "react-collapsed";

import { AccordeonProps } from "./types";

const Accordeon: FC<AccordeonProps> = ({ header, content, isOpen = false }) => {
  const collapseStyles = {
    transitionTimingFunction: "cubic-bezier(0.25, 0.46, 0.45, 0.94)",
  };

  const {
    getCollapseProps,
    getToggleProps,
    isExpanded,
    setExpanded,
  } = useCollapse({
    expandStyles: collapseStyles,
    duration: 300,
    collapseStyles,
    defaultExpanded: isOpen,
  });
  useEffect(() => {
    if (isOpen) {
      setExpanded(true);
    }
  }, [isOpen, setExpanded]);
  return (
    <div>
      <div
        {...getToggleProps()}
        role="none"
        className={`accordeon-header mb-0 ${
          isExpanded ? "accordeon-header--active" : ""
        }`}
        id="header"
      >
        <div className="header">{header}</div>
      </div>
      <div {...getCollapseProps()} className="accordeon-content">
        {content}
      </div>
    </div>
  );
};

export default Accordeon;
