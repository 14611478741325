import React, { FC, useEffect, useState } from "react";

import {
  CloseButton,
  CloseIcon,
  ModalContainer,
  Wrapper,
  Title,
  AddButton,
  SizeContainer,
  SizeButton,
} from "../AddToFavorites/AddToFavorites.styles";

import { AddToCartProps } from "./AddToCart.types";

const AddToCart: FC<AddToCartProps> = ({
  productId,
  offers,
  chosenOffer,
  onClose,
  addItemToCart,
}) => {
  const [activeOffer, setActiveOffer] = useState(0);
  const realOffers = offers.filter((offer) => offer.available);

  const toggleActive = (offerId: number) => {
    setActiveOffer(offerId);
  };

  const addToCartHandler = () => {
    addItemToCart(productId, activeOffer);
    onClose();
  };

  useEffect(() => {
    if (realOffers.length === 1) {
      setActiveOffer(realOffers[0].id);
    }
  }, []);

  return (
    <ModalContainer onClose={onClose}>
      <CloseButton onClick={onClose} aria-label="Закрыть">
        <CloseIcon />
      </CloseButton>

      <Wrapper>
        {realOffers.length >= 1 && !!realOffers[0].size_id && (
          <div>
            <Title>Выберите размер</Title>
            <SizeContainer>
              {realOffers.map(
                (offer) =>
                  offer.available && (
                    <SizeButton
                      onClick={() => toggleActive(offer.id)}
                      isActive={activeOffer === offer.id}
                      key={offer.id}
                    >
                      {offer.size_name}
                    </SizeButton>
                  )
              )}
            </SizeContainer>
          </div>
        )}
        <AddButton onClick={addToCartHandler} disabled={activeOffer === 0}>
          Добавить в корзину
        </AddButton>
      </Wrapper>
    </ModalContainer>
  );
};

export default AddToCart;
