import React, { FC, useRef, useState } from "react";
import useCollapse from "react-collapsed";

import { Icons } from "src/components/index";
import { FilterType } from "src/types";

import {
  ButtonWrapper,
  ChosenFilters,
  CloseButton,
  CloseIcon,
  CollapseButton,
  Container,
  Content,
  ContentInner,
  FullSizeContent,
  IconWrapper,
  SortingButton,
  Title,
  TitleWrapper,
  NameWrapper,
} from "./Collapse.styles";
import { CollapseProps } from "./Collapse.types";

const TRANSITION_DURATION = 300;

const collapseStyles = {
  transitionDuration: `${TRANSITION_DURATION}ms`,
  transitionTimingFunction: "cubic-bezier(0.25, 0.46, 0.45, 0.94)",
};

const FULL_SIZE_FILTERS = ["Категория", "Бренд", "Размер", "Цвет"];

const Collapse: FC<CollapseProps> = ({
  title,
  children,
  className,
  onClose,
  onReset,
  values,
  filters,
}) => {
  const isFullSize = FULL_SIZE_FILTERS.includes(title);
  const collapseRef = useRef<HTMLButtonElement>(null);

  const [isExpanded, setExpanded] = useState(false);

  const { getCollapseProps, getToggleProps } = useCollapse({
    expandStyles: collapseStyles,
    collapseStyles,
    isExpanded,
  });

  const chosenFilter = filters.find((filter) => {
    return (
      isFullSize &&
      filter.name === title &&
      Object.keys(values).includes(`filter[${filter.code}]`)
    );
  });

  const chosenValues = chosenFilter?.values
    .map((value) => {
      const valueFromFilter = values[`filter[${chosenFilter.code}]`];
      if (
        chosenFilter.type === FilterType.checkbox &&
        valueFromFilter &&
        valueFromFilter[value.id as keyof typeof valueFromFilter]
      ) {
        return value.name;
      }

      if (
        chosenFilter.type === FilterType.radio &&
        `${value.id}` === valueFromFilter
      ) {
        return value.name;
      }

      return null;
    })
    .filter(Boolean);

  const handleClick = () => {
    setExpanded((prevExpanded) => !prevExpanded);

    if (!isFullSize) {
      setTimeout(() => {
        if (collapseRef.current && !isExpanded) {
          collapseRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, TRANSITION_DURATION);
    }
  };

  const handleCloseCollapse = () => {
    setTimeout(() => setExpanded((prevExpanded) => !prevExpanded), 0);
  };

  return (
    <Container className={className}>
      <CollapseButton
        ref={collapseRef}
        {...getToggleProps({
          onClick: handleClick,
        })}
      >
        <NameWrapper isHaveChosenValues={(chosenValues || []).length > 0}>
          <span>{title}</span>
          {isFullSize && (
            <ChosenFilters>{chosenValues?.join(", ")}</ChosenFilters>
          )}
        </NameWrapper>
        {!isExpanded && (
          <IconWrapper>
            <Icons.FilterArrow />
          </IconWrapper>
        )}
      </CollapseButton>
      {!isFullSize && <Content {...getCollapseProps()}>{children}</Content>}
      {isFullSize && isExpanded && (
        <FullSizeContent>
          <TitleWrapper
            {...getToggleProps({
              onClick: handleCloseCollapse,
            })}
          >
            <IconWrapper isRevert>
              <Icons.FilterArrow />
            </IconWrapper>
            <Title>{title}</Title>
            <CloseButton onClick={onClose} aria-label="Закрыть">
              <CloseIcon />
            </CloseButton>
          </TitleWrapper>
          <ContentInner>{children}</ContentInner>
          <ButtonWrapper>
            <SortingButton onClick={handleCloseCollapse}>Готово</SortingButton>
            <SortingButton onClick={onReset} variant="white">
              Сбросить
            </SortingButton>
          </ButtonWrapper>
        </FullSizeContent>
      )}
    </Container>
  );
};

export default Collapse;
