import React, { FC, useState } from "react";

import {
  Container,
  ProductCarousel,
  ProductItemNew,
} from "./ProductListNew.styles";
import { LastViewsProps } from "./ProductListNew.types";

const ProductListNew: FC<LastViewsProps> = (props) => {
  const [isDraggable, setIsDraggable] = useState(false);
  const [clickedMobileID, setClickedMobileID] = useState<number | null>(null);

  const {
    items,
    catalogClassName,
    onCheckoutProduct,
    onDeleteProduct,
    onCheckoutLabel,
    isViewed,
    slidesToShowProps = 6,
    itemClassName,
    prevArrowComponent,
    nextArrowComponent,
    mobileSlidesToShowProps = 2,
    mobileShowArrow,
  } = props;

  const settingsProduct = {
    dots: false,
    arrows: true,
    nextArrow: nextArrowComponent,
    prevArrow: prevArrowComponent,
    slidesToScroll: 5,
    infinite: false,
    slidesToShow: slidesToShowProps,
    swipeToSlide: true,
    beforeChange: () => {
      setIsDraggable(true);
      setTimeout(() => {
        setIsDraggable(false);
      }, 1000);
    },
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          slidesToScroll: 5,
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 901,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          swipeToSlide: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: mobileSlidesToShowProps,
          slidesToScroll: 2,
          swipeToSlide: false,
          arrows: mobileShowArrow,
        },
      },
    ],
  };

  const settingsFavourites = {
    dots: false,
    arrows: false,
    infinite: false,
    swipeToSlide: true,
    beforeChange: () => {
      setIsDraggable(true);
      setTimeout(() => {
        setIsDraggable(false);
      }, 1000);
    },
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 374,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const selectMobileItem = (id: number | null) => {
    setClickedMobileID(id);
  };

  const settings =
    catalogClassName !== "favourites" ? settingsProduct : settingsFavourites;

  return (
    <Container className={catalogClassName}>
      <ProductCarousel {...settings}>
        {items.map((item, idx) => (
          <ProductItemNew
            item={item}
            key={item.id}
            className={`${catalogClassName} ${itemClassName}`}
            catalogClassName={catalogClassName}
            onCheckout={onCheckoutProduct}
            onDelete={onDeleteProduct}
            onCheckoutLabel={onCheckoutLabel}
            isDraggable={isDraggable}
            order={idx + 1}
            isViewed={isViewed}
            selectedItemID={clickedMobileID}
            onClickedMobileID={selectMobileItem}
          />
        ))}
      </ProductCarousel>
    </Container>
  );
};

export default ProductListNew;
