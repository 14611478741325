import React, { FC } from "react";

import {
  ProductColorWrapper,
  Image,
  TooltipSvg,
} from "./ProductColorCard.styles";
import { ProductColorWrapperProps } from "./ProductColorCard.types";

const ProductColorCard: FC<ProductColorWrapperProps> = ({
  isCurrent,
  imageUrl,
  color,
  href,
  position,
}) => {
  return (
    <>
      {isCurrent ? (
        <ProductColorWrapper
          className={`choose_color ${isCurrent ? "active" : ""}`}
        >
          <Image src={imageUrl} alt="image" />
          {color && (
            <div className={`tooltipText ${position}`}>
              {color}
              <TooltipSvg
                className={`${position}`}
                width="12px"
                height="5px"
                fill="none"
              />
            </div>
          )}
        </ProductColorWrapper>
      ) : (
        <a
          className={`choose_color ${isCurrent ? "active" : ""} ${position}`}
          href={href}
        >
          <ProductColorWrapper className="choose_color">
            <Image src={imageUrl} alt="image" />
            {color && (
              <div className={`tooltipText ${position}`}>
                {color}
                <TooltipSvg
                  className={`${position}`}
                  width="12px"
                  height="5px"
                  fill="none"
                />
              </div>
            )}
          </ProductColorWrapper>
        </a>
      )}
    </>
  );
};

export default ProductColorCard;
