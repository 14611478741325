import styled from "styled-components";

export const ErrorNumber = styled.div`
  font-family: "Formular";
  font-style: normal;
  font-weight: 500;
  font-size: 62px;
  line-height: 76px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #8e8e8e;

  @media (min-width: 600px) {
    font-size: 79px;
    line-height: 97px;
  }
`;

export const ErrorMessage = styled.h1`
  font-family: "Formular";
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: black;
  text-align: center;
  margin: 0;

  @media (min-width: 600px) {
    font-size: 62px;
    line-height: 76px;
  }

  @media (min-width: 1440px) {
    font-size: 79px;
    line-height: 97px;
  }
`;

export const ErrorDescription = styled.div`
  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.01em;
  color: black;
  max-width: 345px;

  margin: 5px auto 23px;

  @media (min-width: 600px) {
    font-size: 22px;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 23px;
    max-width: 500px;
  }
`;

export const ErrorButton = styled.button`
  box-sizing: border-box;
  align-content: center;
  text-align: center;
  border: 1px solid black;
  width: 180px;
  height: 50px;
  display: block;
  background-color: white;
  transition: 0.3s ease background-color, color;
  font-weight: 500;
  font-family: "HelveticaNeueCyr";
  font-size: 14px;
  padding: 2px 6px 1px 6px;
  color: black;
  line-height: 30px;
  letter-spacing: 0.1em;
  margin: 0px auto;

  :hover {
    background-color: black;
    color: white;
    cursor: pointer;
  }
  :active {
    border: 2px solid white;
  }

  @media (min-width: 600px) {
    width: 236px;
    height: 64px;
  }
`;

export const ErrorWrapper = styled.div`
  padding: 0;
  margin: 135px auto 120px auto;
  box-sizing: border-box;

  @media (min-width: 600px) {
    margin: 150px auto 120px auto;
  }

  @media (min-width: 1200px) {
    margin: 160px auto 150px auto;
  }

  @media (min-width: 1440px) {
    margin: 160px auto 200px auto;
  }
`;
