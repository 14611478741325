import React, { useEffect, useState } from "react";
interface Props {
  val: number;
  handleBlur: (
    event: any, //  eslint-disable-line @typescript-eslint/no-explicit-any
    val: number
  ) => void;
  name: string;
  range: any; //  eslint-disable-line @typescript-eslint/no-explicit-any
}
export const Input: React.FC<Props> = ({ val, handleBlur, name, range }) => {
  const [formatVal, setFormatVal] = useState<string>(format(val));
  const [focus, setFocus] = useState<boolean>(false);
  const [value, setValue] = useState<number | string>(val);

  async function setValueAsync(v: number) {
    await setValue(v);
  }
  function format(nubmer: number) {
    return String(nubmer).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, "$1 ");
  }
  useEffect(() => {
    setValue(val);
  }, [val]);
  useEffect(() => {
    if (typeof value === "number") setFormatVal(format(value));
  }, [value]);
  return (
    <input
      id={name}
      name={name}
      value={focus ? value : formatVal}
      onInput={(
        event: any //  eslint-disable-line @typescript-eslint/no-explicit-any
      ) => {
        if (event.target.value) {
          setValue(parseInt(event.target.value, 10));
        } else {
          setValue("");
        }
      }}
      onBlur={(
        event: any //  eslint-disable-line @typescript-eslint/no-explicit-any
      ) => {
        event.persist();
        let valueCurrent = 0;
        if (typeof value === "number") {
          valueCurrent = Math.min(range.max, value);
          valueCurrent = Math.max(range.min, valueCurrent);
        } else {
          valueCurrent = +range.min + 1;
        }
        event.target.value = valueCurrent;
        setValueAsync(valueCurrent);
        setFocus(false);
        handleBlur(event, +value);
      }}
      onFocus={() => {
        setFocus(true);
      }}
      inputMode="decimal"
      type="text"
      autoComplete="off"
      className="digi-facet-slider__input"
      onChange={(
        event: any //  eslint-disable-line @typescript-eslint/no-explicit-any
      ) => {
        if (event.target.value) {
          setValue(parseInt(event.target.value, 10));
        } else {
          setValue("");
        }
      }}
    />
  );
};
