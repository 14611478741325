/* eslint-disable max-len */

import React, { FC } from "react";

import { SvgProps } from "src/types";

const Tg: FC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 25 25">
    <g clipPath="url(#clip0_312_47)">
      <path d="M19.7847 5.10579L3.8403 11.6234C2.75207 12.0861 2.75852 12.7294 3.64195 13.0163L7.61854 14.3317L9.14007 19.2756C9.32505 19.8167 9.23386 20.0313 9.77002 20.0313C10.1838 20.0313 10.3673 19.8314 10.5978 19.5928C10.7443 19.4408 11.6144 18.5443 12.586 17.5432L16.7224 20.7821C17.4835 21.2272 18.0331 20.9966 18.2226 20.0329L20.9379 6.47247C21.2159 5.29129 20.513 4.75551 19.7847 5.10579ZM8.24262 14.0295L17.2059 8.03625C17.6533 7.74866 18.0636 7.90328 17.7267 8.22018L10.0519 15.559L9.75302 18.937L8.24262 14.0295V14.0295Z" />
    </g>
    <defs>
      <clipPath id="clip0_312_47">
        <rect width="25" height="25" />
      </clipPath>
    </defs>
  </svg>
);

Tg.defaultProps = {
  width: "20px",
  height: "20px",
  fill: "black",
};

export default Tg;
