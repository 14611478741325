/* eslint-disable no-console */

import axios from "axios";

import { removeToken } from "../auth";

const Http = axios.create();

export function addMetaTag(
  content: { name?: string; content?: string; property?: string }[]
): boolean {
  const head = document.head;

  content.map(
    (
      tagOneItem: any //  eslint-disable-line @typescript-eslint/no-explicit-any
    ) => {
      const tagsAll = document.getElementsByTagName("meta");
      if (
        [].slice.call(tagsAll).filter((
          l: any //  eslint-disable-line @typescript-eslint/no-explicit-any
        ) => l.outerHTML.includes(tagOneItem.property)).length
      ) {
        const tag: HTMLMetaElement = [].slice.call(tagsAll).filter((
          l: any //  eslint-disable-line @typescript-eslint/no-explicit-any
        ) => l.outerHTML.includes(tagOneItem.property))[0];
        tag.content = tagOneItem.content;
      } else {
        const tag: any = document.createElement("meta"); //  eslint-disable-line @typescript-eslint/no-explicit-any
        Object.keys(tagOneItem).map((key) => {
          tag.setAttribute(key, tagOneItem[key]);
          return true;
        });
        if (
          ![].slice.call(tagsAll).filter(
            (
              l: any //  eslint-disable-line @typescript-eslint/no-explicit-any
            ) => l.name === tagOneItem.name && l.content === tagOneItem.content
          ).length
        ) {
          head.appendChild(tag);
        }
      }
      return true;
    }
  );
  return true;
}
Http.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : 408;

    if (status >= 500) {
      console.error(error);
    }

    if (status === 401) {
      removeToken();
      console.error(error);
    }
    const isPrerender =
      navigator.userAgent.toLowerCase().indexOf("prerender") !== -1;

    if (status === 301) {
      if (isPrerender) {
        addMetaTag([
          {
            name: "prerender-status-code",
            content: "301",
          },
          {
            name: "prerender-header",
            content:
              "Location: " +
              window.location.origin +
              error.response.data.redirect_to,
          },
        ]);
      } else {
        window.location.pathname = error.response.data.redirect_to
          ? error.response.data.redirect_to
          : "";
      }
    }

    // if (status === 404) {
    //   if (isPrerender) {
    //     addMetaTag([
    //       {
    //         name: "prerender-status-code",
    //         content: "404",
    //       },
    //     ]);
    //   } else {
    //     if (!window.location.pathname.includes("/page404/"))
    //       window.location.pathname = "/page404/";
    //   }
    // }

    return Promise.reject(error);
  }
);

export default Http;
