import styled from "styled-components";

import { Box } from "../Box";

import { FilterBlockProps } from "./CatalogFilters.types";

export const Block = styled(Box)`
  &:not(:first-child) {
    margin-top: 40px;
  }
`;

export const InnerBlock = styled(Box)`
  &:not(:first-child) {
    margin-top: 14px;
  }
  &:last-child {
    margin-bottom: 20px;
  }
`;

export const BoxWrapper = styled(Box)<FilterBlockProps>`
  position: sticky;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  top: ${({ isScrollTop }) => (isScrollTop ? "140px" : "40px")};
  transition: 500ms all;
  &.mobileBox {
    > div {
      margin-top: 40px;
    }
  }
  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const InputContainer = styled(Box)`
  &:not(:first-child) {
    margin-top: 10px;
  }

  &.filterInner {
    width: 100%;

    @media (min-width: 1200px) {
      width: 240px;
    }
    @media (min-width: 1920px) {
      width: 316px;
    }
  }
`;
