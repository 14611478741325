import styled from "styled-components";
import { compose, layout, position, space } from "styled-system";

import { BoxProps } from "./Box.types";

const Box = styled.div<BoxProps>`
  background-color: inherit;
  box-sizing: border-box;
  position: relative;

  ${compose(layout, position, space)};
`;

export default Box;
