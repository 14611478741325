import { css } from "styled-components";

const formularFonts = [
  {
    name: "Light",
    weight: 300,
  },
  {
    name: "Regular",
    weight: 400,
  },
  {
    name: "Medium",
    weight: 500,
  },
  {
    name: "Bold",
    weight: 700,
  },
  {
    name: "Black",
    weight: 900,
  },
];

const formularFontFaces = formularFonts.map(
  (font) => css`
    @font-face {
      font-family: "Formular";
      font-weight: ${font.weight};
      src: url(${require(`../../../static/fonts/Formular/Formular-${font.name}.woff2`)})
          format("woff2"),
        url(${require(`../../../static/fonts/Formular/Formular-${font.name}.woff`)})
          format("woff"),
        url(${require(`../../../static/fonts/Formular/Formular-${font.name}.otf`)})
          format("opentype");
    }
  `
);

export default formularFontFaces;
