import Masonry from "react-masonry-css";
import styled from "styled-components";

import { Flex } from "../Flex";
import { Link } from "../Link";

export const Wrapper = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  height: auto;
`;

export const MasonryWrapper = styled(Masonry)`
  display: flex;
  width: 100%;
`;

export const MenuWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column wrap;
  align-content: space-between;
  height: 530px;
`;

export const MenuList = styled.ul`
  padding: 0;
  margin: 0 10px 50px 0;
  list-style-type: none;
`;

export const ListTitleLink = styled(Link)`
  font-family: HelveticaNeueCyr, sans-serif;
  font-size: 16px;
  letter-spacing: 0.01em;
  margin: 0 0 24px 0;
  font-weight: 500;
  color: ${({ theme }): string => theme.colors.black};
  text-decoration: none;
  display: block;
  transition: 0.3s ease opacity;

  :hover {
    opacity: 0.5;
  }
`;

export const ListTitle = styled.div`
  font-family: HelveticaNeueCyr, sans-serif;
  font-size: 16px;
  letter-spacing: 0.01em;
  margin: 0 0 24px 0;
  font-weight: 500;
  color: ${({ theme }): string => theme.colors.black};
  transition: 0.3s ease opacity;
`;

export const ListItem = styled.li`
  margin: 0;
  padding: 2px 0;
`;

export const ItemLink = styled(Link)`
  font-size: 12px;
  margin: 0;
  color: ${({ theme }): string => theme.colors.black};
  font-weight: 400;
  line-height: 25px;
  padding: 0;
  transition: 0.3s ease opacity text-decoration;

  :hover {
    text-decoration: underline;
    opacity: 0.5;
  }
`;

export const ImageLink = styled(Link)`
  width: 100%;
  max-width: 600px;
  height: 480px;
  margin-left: 60px;
  display: block;

  @media screen and (max-width: 1440px) {
    max-width: 480px;
  }

  @media screen and (max-width: 1250px) {
    max-width: 320px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: red;
`;

export const MenuFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
export const MenuGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 30%);
  justify-content: space-between;
  width: 540px;

  @media screen and (max-width: 1670px) {
    width: auto;
  }

  @media screen and (max-width: 1560px) {
    grid-template-columns: repeat(2, 50%);
    width: 295px;
  }
`;

export const LinkWrapper = styled.div`
  width: 30%;
  align-self: flex-end;
  margin-top: -20px;

  @media screen and (max-width: 1560px) {
    width: 50%;
  }
`;

export const LinkAll = styled(Link)`
  color: ${({ theme }): string => theme.colors.black};
  text-decoration: underline;
  font-size: 14px;

  &:hover {
    text-decoration: none;
  }
`;
