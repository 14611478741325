import { Link } from "react-router-dom";
import styled from "styled-components";

import { Icons } from "../Icons";
import { LinkButton } from "../LinkButton";

export const Title = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
  text-align: left;

  margin: 0 0 22px;

  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 600px) {
    width: 255px;

    margin-bottom: 35px;
  }
`;

export const Personal = styled.section`
  width: 580px;

  @media screen and (max-width: 1200px) {
    width: 350px;
  }

  @media screen and (max-width: 768px) {
    width: calc(50% - 20px);
    margin-right: 30px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    margin-right: 0;
  }

  @media screen and (max-width: 480px) {
    padding-right: 15px;
  }
`;

export const PersonalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 1200px) {
    margin-right: 15px;

    flex-direction: column;
  }

  @media screen and (max-width: 600px) {
    margin-right: 0;
  }
`;

export const UserContainer = styled.div`
  order: 1;
  margin-right: 10px;

  @media screen and (max-width: 768px) {
    padding-top: 7px;
    margin-right: 0;
  }

  @media screen and (max-width: 600px) {
    padding-top: 0;
  }
`;

export const LoyalityContainer = styled.div`
  order: 2;

  @media screen and (max-width: 600px) {
    order: 3;
  }
`;

export const UserRow = styled.p`
  font-size: 14px;
  line-height: 20px;

  width: 240px;
  margin: 14px 0;
`;

export const DiscountRow = styled.p`
  font-size: 14px;
  line-height: 20px;

  width: 240px;
  margin: 2px 0 2px;
`;

export const UserRowAddress = styled.p`
  font-size: 14px;
  line-height: 20px;

  width: 240px;
  margin: 24px 0 24px;
`;

export const UserTitle = styled.span`
  font-weight: 500;

  padding-right: 5px;
`;

export const AddressButton = styled(Link)`
  padding-left: 23px;
  border: none;
  background-color: transparent;

  position: relative;

  cursor: pointer;

  color: initial;
  text-decoration: none;

  &:hover {
    opacity: 0.5;
  }
`;

export const PersonalButton = styled(LinkButton)`
  width: 332px;
  padding 12px 15px 10px;
  margin-top: 92px;

  order: 3;

  @media screen and (max-width: 1200px) {
    margin-top: 50px;
  }

  @media screen and (max-width: 768px) {
    width: 236px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    margin-top: 60px;
    padding 14px 28px 13px 28px;

    order: 2;
  }
`;

export const PersonalButtonSpan = styled.span`
  @media screen and (max-width: 768px) {
    display: none;
  }

  @media screen and (max-width: 600px) {
    display: inline-block;
  }
`;

export const PlusIcon = styled(Icons.Plus)`
  width: 18px;
  height: 18px;
  padding 2px;
  border-radius: 50%;
  border: 1px solid ${({ theme }): string => theme.colors.black};

  position: absolute;
  left: 0;
  top: -2px;
`;

export const LoyalityTitle = styled.h3`
  margin: 14px 0 12px;

  @media screen and (max-width: 1200px) {
    margin-top: 65px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 70px;
  }

  @media screen and (max-width: 600px) {
    margin: 87px 0 16px;
  }
`;

export const LogoutButton = styled.button`
  font-size: 14px;
  color: #808080;
  width: 100%;
  padding: 12px 15px 10px;
  background: transparent;
  order: 4;
  max-width: 332px;
  border: none;
  margin-top: 16px;
  cursor: pointer;
  font-family: "HelveticaNeueCyr", sans-serif;

  @media screen and (max-width: 768px) {
    max-width: 236px;
  }

  @media screen and (max-width: 600px) {
    max-width: 100%;
    margin-top: 10px;
  }
`;
