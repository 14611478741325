import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setCheckout, setCurrentWorkFlow } from "src/actions";
import { setWishlistUuid, setWishlistNoUuid } from "src/actions/wishlist";
import {
  addFavoriteProductWithBasketUuid,
  addToCheckout,
  addToCheckoutWithoutUuid,
  getCheckout,
} from "src/api";
import {
  AddToFavorites,
  AddToCart,
  LoaderButton,
  CatalogListItem as ProductItem,
} from "src/components";
import { CheckoutContext } from "src/context/CheckoutContext";
import { useControl } from "src/hooks";
import { ApplicationStore } from "src/store";
import {
  CatalogListItem,
  LocalStorageFavoriteItem,
  ShortProductOffer,
} from "src/types";
import {
  getBasketId,
  getFavoritesFromLocalStorage,
  sendTagManagerAddToCheckoutData,
  sendTagManagerFavoritesData,
  setBasketId,
  setFavoritesToLocalStorage,
} from "src/utils";

import {
  Container,
  Pagination,
  EmptyFilterData,
  ResetFilterButton,
} from "./CatalogList.styles";
import { CatalogListProps } from "./CatalogList.types";
import MockItem from "./MockItem/MockItem";
import PromoCard from "./PromoCard";

const CatalogList: FC<CatalogListProps> = (props) => {
  const dispatch = useDispatch();
  const {
    wishlist: favoriteItemsFromRedux,
    user: { userWorkFlow },
  } = useSelector((state: ApplicationStore) => state);
  const { userData } = useSelector((state: ApplicationStore) => state.user);

  const {
    data,
    page,
    isPaginationVisible,
    catalogClassName,
    onDeleteProduct,
    onCheckoutProduct,
    isLoading,
    isLoadingLoaderButton,
    onCheckoutLabel,
    resetFilters,
    loadMore,
    perPage = 60,
    loadMorePage = 0,
  } = props;
  const { items, paginate, section_tree, categoryBanners } = data || {};
  const basketUuid = getBasketId();
  const {
    state: isAddToFavoritesOpened,
    deactivate: closeAddToFavorites,
    activate: openAddToFavorites,
  } = useControl();

  const {
    state: isAddToCartOpened,
    deactivate: closeAddToCart,
    activate: openAddToCart,
  } = useControl();

  const [offers, setOffers] = useState<ShortProductOffer[]>([]);
  const [productId, setProductId] = useState<number>(0);
  const [currProduct, setCurrProduct] = useState<CatalogListItem>();

  const [favoriteItems, setFavoriteItems] = useState<
    LocalStorageFavoriteItem[]
  >([]);

  const addItemToFavorites = (productId: number, offerId?: number) => {
    if (basketUuid && userWorkFlow) {
      let eventName = "";
      const favItem = items?.find((item) => item.id === productId);
      if (userWorkFlow !== "authenticated") {
        const favoriteItems = getFavoritesFromLocalStorage();
        const items = new Set<LocalStorageFavoriteItem>(favoriteItems);
        const newFavoriteItem = {
          product_id: productId,
          offer_id: offerId,
          created_at: new Date().toISOString(),
        };
        const existingItem = Array.from(items).find(
          (item) => item.product_id === newFavoriteItem.product_id
        );
        if (existingItem) {
          items.delete(existingItem);
        } else {
          items.add(newFavoriteItem);
        }
        const newFavoriteItems = Array.from(items);
        setFavoriteItems(newFavoriteItems);
        setFavoritesToLocalStorage(newFavoriteItems);

        const favorites = getFavoritesFromLocalStorage();
        if (favorites) {
          dispatch(setWishlistNoUuid(favorites));
        }

        eventName = !existingItem
          ? "add-wishlist-item"
          : "remove-wishlist-item";
      } else {
        if (Array.isArray(items)) {
          const item = items.find((item) => item.id === productId);
          if (item) {
            Object.assign(item, {
              is_wish: favItem ? !favItem.is_wish : false,
            });
          }
        }
        addFavoriteProductWithBasketUuid(productId, basketUuid, offerId).then(
          () =>
            getCheckout(basketUuid).then(({ data }) =>
              dispatch(setWishlistUuid(data.wish_list))
            )
        );

        eventName = favItem?.is_wish
          ? "add-wishlist-item"
          : "remove-wishlist-item";
      }
      if (favItem) {
        const firstAvailable1cSize = favItem.offers.find(
          (offer) => offer.available
        )?.size_id_1c;
        const vendorcode = `${favItem.id_1c}#${
          firstAvailable1cSize || "ONE_SIZE"
        }`;
        sendTagManagerFavoritesData(eventName, vendorcode, productId);
      }
    }
  };

  const selectMobileItem = (id: number | null) => {
    setClickedMobileID(id);
  };

  const addToFavorites = (item: CatalogListItem) => {
    setProductId(item.id);
    if (favoriteItems.some((favItem) => favItem.product_id === item.id)) {
      addItemToFavorites(item.id);
    } else if (
      item?.offers[0]?.size_id &&
      item?.offers[0]?.size_name &&
      !item.is_wish
    ) {
      setOffers(item.offers);
      openAddToFavorites();
    } else {
      if (item?.offers[0]?.id) {
        addItemToFavorites(item.id, item.offers[0].id);
      } else {
        addItemToFavorites(item.id);
      }
    }
  };

  const [checkoutProductId, setCheckoutProductId] = useState<number>(0);
  const [checkoutProductOfferId, setCheckoutProductOfferId] =
    useState<number>(0);

  const addToCart = (item: CatalogListItem, isModalDisabled: boolean) => {
    setCheckoutProductId(item.id);
    setOffers(item.offers);
    setCurrProduct(item);
    !isModalDisabled
      ? openAddToCart()
      : addToCartHandler(item.id, item.offers[0].id, item);
  };

  const { setIsCartOpened } = useContext(CheckoutContext);

  const addToCartHandler = (
    productId: number,
    offerId?: number,
    currentProduct?: CatalogListItem
  ) => {
    const realCurrentProduct = currProduct ?? currentProduct;
    if (
      offerId &&
      realCurrentProduct &&
      (basketUuid || basketUuid === undefined)
    ) {
      const realOffer = realCurrentProduct.offers.find(
        (offer) => offer.id === offerId
      );
      sendTagManagerAddToCheckoutData({
        productId: productId,
        productName: realCurrentProduct.name,
        productPrice: realCurrentProduct.new_price,
        productBrand: realCurrentProduct.brand.name,
        vendorCode: `${realCurrentProduct.id_1c}#${
          realOffer?.size_id_1c ?? "ONE_SIZE"
        }`,
        category: realCurrentProduct.sectionTree,
        is_retail: userData?.is_retail ?? false,
      });
      if (
        basketUuid === undefined ||
        basketUuid === "undefined" ||
        basketUuid === null
      ) {
        addToCheckoutWithoutUuid({
          product_id: productId,
          quantity: 1,
          offer_id: offerId,
        }).then(({ data }) => {
          setBasketId(data.basket_uuid);
          dispatch(
            setCurrentWorkFlow({
              userWorkFlow: "hasUuid",
            })
          );
          getCheckout(data.basket_uuid).then(({ data }) => {
            dispatch(setCheckout(data));
            if (window.matchMedia("(min-width: 768px)").matches) {
              setIsCartOpened(true);
            }
          });
        });
      } else {
        addToCheckout(basketUuid, {
          product_id: productId,
          basket_uuid: basketUuid,
          quantity: 1,
          offer_id: offerId,
        }).then(() => {
          getCheckout(basketUuid).then(({ data }) => {
            dispatch(setCheckout(data));
            if (window.matchMedia("(min-width: 768px)").matches) {
              setIsCartOpened(true);
            }
          });
        });
      }
    }
  };

  const isIndexOfBanner = useCallback(
    (idx: number) => {
      return (
        categoryBanners &&
        categoryBanners.filter((catBanner) => {
          return (
            items &&
            (idx + 1 === catBanner.position ||
              (items &&
                idx === items.length - 1 &&
                catBanner.position > items.length - 1))
          );
        })[0]
      );
    },
    [loadMorePage, perPage, items]
  );
  const [clickedMobileID, setClickedMobileID] = useState<number | null>(null);
  const categoriesTree = section_tree
    ?.map((category) => category.name)
    .join("/");

  useEffect(() => {
    if (userWorkFlow) {
      if (userWorkFlow === "authenticated") {
        setFavoriteItems(favoriteItemsFromRedux.wish_list);
      } else {
        setFavoriteItems(favoriteItemsFromRedux.wishListNoUuid);
      }
    }
  }, [userWorkFlow, favoriteItemsFromRedux]);

  const isWish = useCallback(
    (item: CatalogListItem): boolean => {
      if (userWorkFlow && items) {
        if (userWorkFlow === "authenticated") {
          if (item && item.is_wish === true) {
            return true;
          }
        } else {
          if (favoriteItems.some((favItem) => favItem.product_id === item.id)) {
            return true;
          }
        }
      }
      return false;
    },
    [userWorkFlow, favoriteItems, items]
  );

  return (
    <>
      {!isLoading && items && !items.length && (
        <EmptyFilterData>
          <div>По заданным параметрам фильтрации ничего не найдено</div>
          <ResetFilterButton onClick={resetFilters}>
            Сбросить фильтр
          </ResetFilterButton>
        </EmptyFilterData>
      )}
      <div id="scroll_content">
        <Container className={catalogClassName}>
          {/* {!isLoading &&
            new Array(items?.length ? items.length : 0)
              .fill(null)
              .map((_, idx) => <MockItemOld key={idx} />)} */}

          {isLoading &&
            new Array(items?.length ? items.length : 0)
              .fill(null)
              .map((_, idx) => <MockItem key={idx} />)}

          {!isLoading &&
            items?.map((item, idx) => (
              <>
                {isIndexOfBanner(idx) && (
                  <PromoCard
                    key={isIndexOfBanner(idx).position}
                    item={isIndexOfBanner(idx)}
                  ></PromoCard>
                )}
                <ProductItem
                  item={item}
                  key={item.id}
                  loadMorePage={loadMorePage}
                  catalogClassName={catalogClassName}
                  onDelete={onDeleteProduct}
                  onCheckout={onCheckoutProduct}
                  addToCart={addToCart}
                  onCheckoutLabel={onCheckoutLabel}
                  addToFavorites={addToFavorites}
                  order={idx + 1}
                  categories={categoriesTree}
                  selectedItemID={clickedMobileID}
                  onClickedMobileID={selectMobileItem}
                  isWish={isWish(item)}
                />
              </>
            ))}
        </Container>
      </div>
      {page && !!items?.length && isPaginationVisible && (
        <div>
          {(isLoadingLoaderButton || page < (paginate?.total || 0)) && (
            <LoaderButton
              className="catalogList"
              isLoading={isLoadingLoaderButton}
              onClick={() => loadMore?.()}
              buttonText="Показать ещё"
            ></LoaderButton>
          )}
          <Pagination page={page} totalPages={paginate?.total || 0} />
        </div>
      )}

      {isAddToFavoritesOpened && (
        <AddToFavorites
          productId={productId}
          onClose={closeAddToFavorites}
          offers={offers}
          addItemToFavorites={addItemToFavorites}
        />
      )}

      {isAddToCartOpened && (
        <AddToCart
          productId={checkoutProductId}
          onClose={closeAddToCart}
          chosenOffer={checkoutProductOfferId}
          offers={offers}
          addItemToCart={addToCartHandler}
        />
      )}
    </>
  );
};

export default CatalogList;
