import styled from "styled-components";

import { Button, Carousel } from "src/components";

export const ShopHeader = styled.h2`
  font-size: 34px;
  line-height: 42px;
  width: 100%;
  margin: 0;

  @media screen and (max-width: 1440px) {
    font-size: 30px;
    line-height: 37px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 24px;
    line-height: 29px;
  }

  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 29px;
    grid-area: title;
  }

  @media screen and (max-width: 600px) {
    text-align: center;
    font-size: 20px;
    line-height: 24px;
  }
`;

export const ShopItemWrapper = styled.li`
  color: ${({ theme }): string => theme.colors.black};
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 50px;
  }
`;

export const ShopItemHeader = styled.h2`
  font-size: 34px;
  line-height: 42px;
  grid-area: title;
`;

export const ShopWorkTime = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }): string => theme.colors.grey};
  grid-area: time;
  margin: 5px 0 12px;

  @media screen and (max-width: 1440px) {
    margin: 5px 0;
  }

  @media screen and (max-width: 600px) {
    font-size: 10px;
    line-height: 16px;
  }
`;

export const ShopAddressWrapper = styled.div`
  font-size: 16px;
  line-height: 18px;
  grid-area: address;
  margin-left: 5px;

  @media screen and (max-width: 1440px) {
    margin-left: 0;
  }

  @media screen and (max-width: 1200px) {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 600px) {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const ShopAddress = styled.p`
  margin: 16px 0;

  @media screen and (max-width: 1440px) {
    margin: 5px 0;
  }
`;

export const ShopContactsHeader = styled.p`
  font-size: 22px;
  line-height: 20px;
  margin: 22px 0;

  @media screen and (max-width: 1440px) {
    margin: 22px 0 5px;
  }

  @media screen and (max-width: 1200px) {
    font-size: 18px;
    line-height: 20px;
  }

  @media screen and (max-width: 768px) {
    margin: 8px 0 5px;
  }

  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const ShopContactsWrapper = styled.div`
  font-size: 18px;
  line-height: 20px;
  width: 332px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 510px;

  @media screen and (max-width: 1440px) {
    min-height: 440px;
    width: 310px;
  }

  @media screen and (max-width: 1200px) {
    min-height: 362px;
    width: 274px;
  }

  @media screen and (max-width: 768px) {
    order: -1;
    width: 100%;

    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 246px auto;
    grid-auto-rows: auto;
    grid-gap: 133px;

    grid-template:
      "title contacts"
      "time contacts"
      "address contacts"
      "button contacts";
    grid-row-gap: 0;
    margin-bottom: 13px;
    min-height: auto;
  }

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ShopContactsTwoWrapper = styled.div`
  grid-area: contacts;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;

  @media screen and (max-width: 1440px) {
    margin-left: 0;
    margin-top: 0;
  }

  @media screen and (max-width: 768px) {
    width: 47vw;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 0;
  }

  @media screen and (max-width: 600px) {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const ShopPhone = styled.a`
  display: block;
  margin: 10px 0 7px;
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 768px) {
    margin: 20px 0 15px;
  }

  @media screen and (max-width: 600px) {
    margin: 13px 0 10px;
  }
`;

export const ShopEmail = styled.a`
  margin: 3px 0 7px;
  color: black;
  width: 100%;
  display: block;
  text-decoration: none;

  transition: 0.3s ease opacity;

  :hover {
    opacity: 0.5;
  }

  @media screen and (max-width: 1440px) {
    margin: 10px 0;
  }

  @media screen and (max-width: 768px) {
    margin: 20px 0;
  }

  @media screen and (max-width: 600px) {
    margin: 5px 0;
  }
`;

export const ShopSocial = styled.a`
  color: black;
  width: 100%;
  position: relative;
  padding-left: 30px;
  text-decoration: underline;
  margin-top: 8px;
  display: block;

  transition: 0.3s ease opacity;

  :hover {
    opacity: 0.5;
  }

  svg {
    position: absolute;
    left: 0;
    top: -3px;
  }

  @media screen and (max-width: 768px) {
    margin: 25px 0;
  }

  @media screen and (max-width: 600px) {
    margin: 5px 0;
    display: inline;
  }
`;

export const ShopImageCarousel = styled(Carousel)`
  width: 100%;
  height: 100%;
  display: block;
`;

export const ShopImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ShopImageContainer = styled.div`
  height: 510px;

  @media screen and (max-width: 1440px) {
    height: 440px;
  }

  @media screen and (max-width: 1200px) {
    height: 362px;
  }

  @media screen and (max-width: 768px) {
    height: 309px;
  }

  @media screen and (max-width: 600px) {
    height: 390px;
  }
`;

export const ShopImageWrapper = styled.div`
  height: 510px;
  position: relative;
  background-color: ${({ theme }): string => theme.colors.lightGrey};
  margin-right: 47px;
  flex-grow: 1;
  max-width: calc(100% - 379px);

  @media screen and (max-width: 1440px) {
    margin-right: 29px;
    height: 440px;
    max-width: calc(100% - 339px);
  }

  @media screen and (max-width: 1200px) {
    margin-right: 26px;
    height: 362px;
    max-width: calc(100% - 300px);
  }

  @media screen and (max-width: 768px) {
    margin-right: 0;
    grid-area: img;
    width: 100%;
    height: 309px;
    max-width: 100%;
  }

  @media screen and (max-width: 600px) {
    height: 390px;
  }
`;

export const ShopButton = styled(Button)`
  height: 70px;
  grid-area: button;
  margin-top: 0;
  font-size: 14px;
  line-height: 15px;
  text-transform: none;

  @media screen and (max-width: 1440px) {
    max-width: 229px;
    height: 50px;
  }

  @media screen and (max-width: 1200px) {
    max-width: 100%;
    margin-top: 10px;
  }

  @media screen and (max-width: 768px) {
    grid-area: button;
    margin: 10px 0 20px;
    min-width: 236px;
  }

  @media screen and (max-width: 600px) {
    margin: 34px 0 20px;
    max-width: 240px;
  }
`;
