import React, { FC } from "react";

import { SvgProps } from "src/types";

const Close: FC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 14 14">
    <path d="M14 1.4L12.6 0 7 5.6 1.4 0 0 1.4 5.6 7 0 12.6 1.4 14 7 8.4l5.6 5.6 1.4-1.4L8.4 7 14 1.4z" />
  </svg>
);

Close.defaultProps = {
  width: "20px",
  height: "20px",
  fill: "black",
};

export default Close;
