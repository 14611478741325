/* eslint-disable max-len */

import React, { FC } from "react";

import { SvgProps } from "src/types";

const User: FC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 21 21">
    <circle cx="10" cy="5" r="4" stroke-width="2" />
    <path
      d="M20 21C20 16.5817 15.7467 13 10.5 13C5.25329 13 1 16.5817 1 21"
      stroke-width="2"
    />
  </svg>
);

User.defaultProps = {
  width: "20px",
  height: "20px",
  fill: "none",
};

export default User;
