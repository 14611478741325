import React, { FC } from "react";

import { Checkbox } from "src/components";
import { ROUTES } from "src/constants";
import { useForm } from "src/hooks";

import {
  Form,
  CheckboxRow,
  FormTitle,
  UserRow,
  EditLink,
} from "./NewslettersForm.styles";
import { NewslettersFormProps } from "./NewslettersForm.types";

const NewslettersForm: FC<NewslettersFormProps> = ({
  isSms,
  isEmail,
  isMobilePush,
  isLoading,
}) => {
  const { values, setValues } = useForm({
    isSms,
    isEmail,
    isMobilePush,
  });

  return (
    <Form>
      <FormTitle>Получать персональные предложения и новости</FormTitle>
      <CheckboxRow>
        <Checkbox
          name="isSms"
          label="СМС"
          type="checkbox"
          checked={values.isSms}
          onChange={() => setValues({ isSms: !values.isSms })}
          disabled={isLoading || true}
        />
      </CheckboxRow>
      <CheckboxRow>
        <Checkbox
          name="isEmail"
          label="Email"
          type="checkbox"
          checked={values.isEmail}
          onChange={() => setValues({ isEmail: !values.isEmail })}
          disabled={isLoading || true}
        />
      </CheckboxRow>
      <CheckboxRow>
        <Checkbox
          name="isMobilePush"
          label="Mobile Push"
          type="checkbox"
          checked={values.isMobilePush}
          onChange={() => setValues({ isMobilePush: !values.isMobilePush })}
          disabled={isLoading || true}
        />
      </CheckboxRow>

      <UserRow>
        <EditLink to={ROUTES.newslettersFormEdit}>изменить</EditLink>
      </UserRow>
    </Form>
  );
};

export default NewslettersForm;
