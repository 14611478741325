import styled from "styled-components";

import { Box } from "../Box";
import { Flex } from "../Flex";
import { Link } from "../Link";

export const Wrapper = styled(Flex)`
  padding-bottom: 16px;
  padding-top: 16px;
  align-items: flex-start;
  border-bottom: 2px dotted ${({ theme }) => theme.colors.lightGrey};

  &:first-of-type {
    padding-top: 0;
  }

  @media screen and (max-width: 1200px) {
    padding-bottom: 16px;
    padding-top: 16px;
  }

  @media (max-width: 767px) {
    padding-bottom: 16px;
    padding-top: 16px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    border-bottom: none;
  }
`;

export const ImageLink = styled(Link)`
  display: block;
  width: 120px;
  margin-right: 32px;

  @media screen and (max-width: 600px) {
    width: 144px;
    margin-right: 13px;
    max-height: 198px;
  }
`;

export const ImageContainer = styled(Box)`
  flex: 120px 0 0;
  height: 100%;
  max-height: 162px;
  margin-right: 30px;
  max-height: 325px;
  width: 120px;

  &:before {
    content: "";
    height: 0;
    display: inline-block;
    padding-top: 170%;
    width: 1px;
    position: relative;
  }

  @media (max-width: 900px) {
    width: 120px;
    max-height: 195px;
  }

  @media screen and (max-width: 600px) {
    width: 144px;
    max-height: 198px;
  }
`;

export const Picture = styled.picture`
  width: 100%;
  height: 100%;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  display: block;
`;

export const ParamsContainer = styled(Flex)`
  width: 100%;
  padding-bottom: 25px;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    border-bottom: 0;
    padding-bottom: 0;
  }
`;

export const ParamsItemContainer = styled.div`
  width: 20%;
  margin-right: 30px;

  @media (max-width: 1200px) {
    margin-right: 0;
    width: 200px;

    &:first-of-type {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 27px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  @media (min-width: 768px) {
    width: 33%;
    &:first-of-type {
      width: 66%;
    }
    &:last-of-type {
      width: 95px;
    }
  }

  @media (min-width: 1200px) {
    &:first-of-type {
      margin-top: 0;
    }
  }
`;

export const ProductNameLink = styled(Link)`
  font-family: Formular;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.black};
  white-space: pre-wrap;

  @media (max-width: 767px) {
    margin: 0 0 12px;
    line-height: 22px;
  }
`;

export const ProductSize = styled.div`
  margin: 15px 0 0;
  font-size: 14px;
  line-height: 20px;

  @media screen and (max-width: 767px) {
    margin: 6px 0 0;
  }
`;

export const PriceLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  margin: 2px 0 15px;

  @media (max-width: 767px) {
    margin: 0 0 5px;
  }
`;

export const CountLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  margin: 2px 0 15px;

  @media (max-width: 767px) {
    margin: 0 0 5px;
  }
`;

export const PriceContainer = styled(Flex)`
  display: flex;
  align-items: flex-end;
`;

export const Price = styled.span`
  font-size: 20px;
  line-height: 20px;
  color: ${({ theme }): string => theme.colors.black};
  margin: 0;
`;

export const OldPrice = styled(Price)`
  font-size: 15px;
  color: ${({ theme }): string => theme.colors.grey};
  text-decoration: line-through;
  margin-right: 12px;
`;

export const Count = styled.div`
  font-size: 20px;
`;
