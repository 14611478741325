import React, { ChangeEvent, FC, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setCheckout } from "src/actions";
import { checkCoupon, getCheckout } from "src/api";
import { useDebounce } from "src/hooks";
import { ApplicationStore } from "src/store";
import { getBasketId } from "src/utils";

import {
  Promo,
  PromoCoupon,
  PromoError,
  PromoButton,
} from "./Promocode.styles";

const DEBOUNCE_TIME = 1000;

const Promocode: FC = () => {
  const [promoCodeValue, setPromoCodeValue] = useState("");
  const [promoError, setPromoError] = useState("");
  const [isInputEnded, setIsInputEnded] = useState(false);
  const dispatch = useDispatch();
  const debounce = useDebounce();

  const { info } = useSelector((state: ApplicationStore) => state.user);

  const promoHandleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPromoCodeValue(event.target.value);
      setPromoError("");
      setIsInputEnded(false);
    },
    []
  );

  const onApplyPromocode = () => {
    if (promoCodeValue) {
      debounce(() => {
        const checkoutId = getBasketId() || info?.checkoutId;
        setPromoError("");
        setIsInputEnded(true);
        if (checkoutId) {
          checkCoupon(checkoutId, promoCodeValue)
            .then((data) => {
              if (data.data.messages) {
                setPromoError(data.data.messages);
              }

              getCheckout(checkoutId).then(({ data }) => {
                dispatch(setCheckout(data));
              });
            })
            .catch((error) => {
              setPromoError(error.response.data.messages);
            });
        }
      }, DEBOUNCE_TIME);
    }
  };

  return (
    <>
      <Promo>
        <PromoCoupon
          value={isInputEnded ? "" : promoCodeValue}
          type="text"
          name="promo"
          id="promo"
          isInputEnded={isInputEnded}
          placeholder={isInputEnded ? "Ввести другой промокод" : "Промокод"}
          aria-label="промокод"
          onChange={promoHandleChange}
        />
        <PromoButton variant="white" onClick={onApplyPromocode}>
          Применить
        </PromoButton>
      </Promo>
      <PromoError>{promoError}</PromoError>
    </>
  );
};

export default Promocode;
