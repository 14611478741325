import styled from "styled-components";

import { Button } from "../Button";
import { IconButton } from "../IconButton";
import { Icons } from "../Icons";
import { Modal } from "../Modal";

export const ModalContainer = styled(Modal)`
  background-color: ${({ theme }): string => theme.colors.white};
  right: 0;
  display: flex;

  flex-direction: column;
  align-items: flex-start;

  animation: showFilters 0.4s ease-out;
  padding: 24px 15px;

  height: 100%;

  @keyframes showFilters {
    0%,
    50% {
      transform: translateX(200%);
    }
    100% {
      transform: none;
    }
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;

  justify-content: space-between;

  width: 100%;

  margin-bottom: 28px;
`;

export const ModalInner = styled.div`
  display: flex;

  flex-direction: column;

  justify-content: space-between;

  width: 100%;
  height: 100%;
`;

export const Header = styled.h3`
  margin: 0;

  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 20px;

  letter-spacing: 0.01em;

  color: #000000;
`;

export const CloseButton = styled(IconButton)`
  padding: 0;

  :hover {
    opacity: 0.5;
  }
`;

export const CloseIcon = styled(Icons.Close)`
  width: 14px;
  height: 14px;
`;

export const Container = styled.div`
  height: calc(100% - 48px - 110px - 15px);

  margin-bottom: 15px;
  margin-right: -15px;

  padding-right: 15px;

  overflow: auto;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
`;

export const SortingButton = styled(Button)`
  display: block;
  width: 100%;

  padding: 10px;

  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-size: 14px;
  line-height: 30px;

  text-align: center;
  letter-spacing: 0.1em;

  &:first-child {
    margin-bottom: 8px;
  }
`;
