import React, { FC } from "react";
import { useSelector } from "react-redux";

import { ROUTES } from "src/constants";
import { ApplicationStore } from "src/store";

import {
  Title,
  UserRow,
  UserTitle,
  EditLink,
  HeaderWrapper,
  BackLink,
  BackIcon,
  BackIconMobile,
} from "./PersonalData.styles";

const PersonalData: FC = () => {
  const { userData } = useSelector((state: ApplicationStore) => state.user);
  function GenderName() {
    if (userData?.genders) {
      return userData.genders?.find(
        (gender) => gender.id === userData?.genderId
      )?.name;
    }

    return "не указан";
  }

  function formatBirthDate(date: Date) {
    if (date) {
      return new Date(date).toLocaleDateString("ru-RU");
    }
    return null;
  }
  return (
    <>
      <HeaderWrapper>
        <BackLink to={ROUTES.accountPage}>
          <BackIcon />
          <BackIconMobile />
          <span>Назад</span>
        </BackLink>
        <Title>Персональные данные</Title>
      </HeaderWrapper>
      {userData && (
        <>
          <UserRow>
            <UserTitle>Имя:</UserTitle>
            {userData?.userLastName || ""} {userData?.userFirstName || ""}
          </UserRow>
          <UserRow>
            <UserTitle>Телефон:</UserTitle>+{userData?.phone || "не указан"}
          </UserRow>
          <UserRow>
            <UserTitle>E-mail:</UserTitle>
            {userData?.userEmail || "не указан"}
          </UserRow>
          <UserRow>
            <UserTitle>Дата рождения:</UserTitle>
            {formatBirthDate(userData?.date_of_birth) || "не указана"}
          </UserRow>
          <UserRow>
            <UserTitle>Пол:</UserTitle>
            {GenderName()}
          </UserRow>
          <UserRow>
            <EditLink to={ROUTES.personalDataEdit}>изменить</EditLink>
          </UserRow>
        </>
      )}
    </>
  );
};

export default PersonalData;
