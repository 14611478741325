import React, { useState, useCallback, FC, FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { setUserData } from "src/actions";
import { setUserPersonalData } from "src/api";
import { DatePicker, SelectInput } from "src/components";
import { ROUTES } from "src/constants";
import { useForm } from "src/hooks";
import { ApplicationStore } from "src/store";
import { getBasketId } from "src/utils";

import { validateUserInfoForm } from "./UserInfo.utils";
import {
  Form,
  Input,
  SubmitButton,
  InputContainer,
} from "./UserInfoForm.styles";
import { UserInfoFormProps, UserFormValues } from "./UserInfoForm.types";

const initialValues: UserFormValues = {
  name: "",
  email: "",
  birthDate: new Date(),
  gender: 0,
};

const UserInfoForm: FC<UserInfoFormProps> = (props) => {
  const { className } = props;
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const { userData } = useSelector((state: ApplicationStore) => state.user);
  const { values, onChange, errors, setErrors, setValues } =
    useForm(initialValues);

  const allGenders = useSelector(
    (s: ApplicationStore) => s.user.userData?.genders
  );

  const setDateValue = (date: Date) => {
    setValues({ ...values, birthDate: date });
  };

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const errorsForm = validateUserInfoForm(values);

      if (Object.keys(errorsForm).length !== 0) {
        setErrors(errorsForm);
        return;
      }

      const [day, month, year] = values.birthDate
        .toLocaleDateString("ru-RU")
        .split(".");

      const names = values.name.split(" ");
      setLoading(true);
      const valuesData = {
        first_name: names[1],
        last_name: names[0],
        email: values.email,
        gender_id: values.gender || allGenders?.[0].id || 1,
        date_of_birth: `${year}-${month}-${day} 00:00:00`,
      };
      setUserPersonalData(valuesData)
        .then(({ data }) => {
          const newData = {
            ...valuesData,
            basket_uuid: userData?.checkoutId || getBasketId() || "",
            user_id: userData?.userId || null,
            name: userData?.userName || "",
            default_phone: userData?.phone || "",
            genders: userData?.genders || null,
            date_of_birth: userData?.date_of_birth || new Date(),
            customer_cards: userData?.customerCards || null,
            savings: userData?.savings || null,
            min_discount_percent: userData?.min_discount_percent || null,
            is_sms: userData?.is_sms || false,
            is_email: userData?.is_email || false,
            is_mobile_push: userData?.is_mobile_push || false,
            bonuses: userData?.bonuses || null,
            is_retail: userData?.is_retail || false,
          };
          dispatch(setUserData(newData));
          history.push(ROUTES.accountPage);
        })
        .catch((error) => {
          setErrors({
            name:
              error.response.data.messages.first_name ||
              error.response.data.messages.last_name,
            email: error.response.data.messages.email,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [values, allGenders, userData, dispatch, history, setErrors]
  );

  return (
    <Form onSubmit={handleSubmit} className={className}>
      <Input
        value={values.name}
        hasError={!!errors.name}
        onChange={onChange}
        name="name"
        placeholder="Имя и фамилия*"
        aria-label="Имя и фамилия"
        disabled={isLoading}
      />
      <Input
        value={values.email}
        hasError={!!errors.email}
        onChange={onChange}
        name="email"
        type="email"
        placeholder="Ваш e-mail*"
        aria-label="Ваш e-mail"
        disabled={isLoading}
      />
      <InputContainer>
        <DatePicker
          dateValue={values.birthDate}
          setDateValue={setDateValue}
          placeholderText="Дата рождения*"
          className={!!errors.birthDate ? "error" : undefined}
        />
        <SelectInput
          value={values.gender}
          onChange={onChange}
          name="gender"
          aria-label="Пол"
          isEmpty={!values.gender}
          disabled={isLoading}
        >
          {allGenders?.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </SelectInput>
      </InputContainer>
      <SubmitButton type="submit">СОХРАНИТЬ ДАННЫЕ</SubmitButton>
    </Form>
  );
};

export default UserInfoForm;
