import { useCallback, useRef, useState } from "react";

type preloaderType = [
  boolean,
  (delay?: number) => void,
  (delay?: number) => void
];

const usePreloaderTimer = (): preloaderType => {
  const [isShown, setShown] = useState(false);
  const timer = useRef<number>();

  const startLoading = useCallback(
    (delay = 1000) => {
      window.clearTimeout(timer.current);
      timer.current = window.setTimeout(() => {
        setShown(true);
      }, delay);
    },
    [timer, setShown]
  );

  const endLoading = useCallback(
    (delay = 0) => {
      window.clearTimeout(timer.current);
      timer.current = window.setTimeout(() => {
        setShown(false);
      }, delay);
    },
    [timer, setShown]
  );

  return [isShown, startLoading, endLoading];
};

export default usePreloaderTimer;
