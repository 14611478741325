export const formatCurrency = (num: number): string =>
  num.toLocaleString("ru-RU", {
    style: "currency",
    currency: "RUB",
    minimumFractionDigits: 0,
  });

export const formatCurrencyWithoutSymbol = (num: number): string =>
  num.toLocaleString("ru-RU", {
    style: "decimal",
    currency: "RUB",
    minimumFractionDigits: 0,
  });
