import React, { FC, useState, useEffect } from "react";
import TagManager from "react-gtm-module";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { getPageContent } from "src/api";
import { SeoMeta } from "src/types";
import { generateMetaTags } from "src/utils";

import { Container, Title, Text } from "./LinkPage.styles";

type IProps = {
  seoTitle: string;
  seoDescription: string;
  url: string;
};

const LinkPage: FC<IProps> = ({ seoTitle, url, seoDescription }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [content, setContent] = useState<string>("");

  const metaTags: SeoMeta[] = generateMetaTags(seoTitle, seoDescription);

  useEffect(() => {
    setIsLoading(true);
    getPageContent(url)
      .then(({ data }) => {
        setContent(data);
      })
      .finally(() => setIsLoading(false));
  }, [url]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  }, []);

  return (
    <Container>
      <HelmetProvider>
        <Helmet defer={false} title={seoTitle} meta={metaTags} />
      </HelmetProvider>
      <Title>{seoTitle}</Title>
      {isLoading ? (
        <Text>Загрузка...</Text>
      ) : (
        <Text dangerouslySetInnerHTML={{ __html: content }} />
      )}
    </Container>
  );
};

export default LinkPage;
