import { MenuItem } from "../SecondaryMenu/SecondaryMenu.types";

export const ABOUT = "about";
export const BRAND = "brand";
export const DELIVERY = "delivery";

export const getMenuItems = (
  currentPath: string,
  activeTab?: string | string[] | null
): MenuItem[] => {
  return [
    {
      label: "О товаре",
      to: `${currentPath}?tab=${ABOUT}`,
      isActive: activeTab !== BRAND && activeTab !== DELIVERY,
    },
    {
      label: "О бренде",
      to: `${currentPath}?tab=${BRAND}`,
      isActive: activeTab === BRAND,
    },
    {
      label: "Доставка и возврат",
      to: `${currentPath}?tab=${DELIVERY}`,
      isActive: activeTab === DELIVERY,
    },
  ];
};
