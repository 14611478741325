import styled from "styled-components";

import { Link } from "src/components";

import { BorderlessInput, BorderlessMaskedInput } from "../BorderlessInput";
import { Button } from "../Button";
import { SelectInput } from "../SelectInput";

export const Form = styled.form`
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
`;

export const Input = styled(BorderlessInput)`
  :not(:first-of-type) {
    margin-top: 30px;
  }
`;

export const PhoneInput = styled(BorderlessMaskedInput)`
  :not(:first-of-type) {
    margin-top: 30px;
  }
`;

export const SelectGenderInput = styled(SelectInput)`
  margin-top: 30px;
`;

export const FormButton = styled(Button)`
  margin-top: 30px;
  height: 50px;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const CheckboxRow = styled.div`
  margin-top: 30px;
`;

export const FormTitle = styled.p`
  margin: 0 0 37px;
`;

export const UserRow = styled.p`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  margin: 30px 0 30px 0;

  @media (max-width: 1150px) {
    text-align: center;
  }
`;

export const EditLink = styled(Link)`
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 0.1em;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    text-decoration: none;
  }
`;
