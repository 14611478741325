import React, { FC, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { getPageContent } from "src/api";
import { Preloader, ContactsMap } from "src/components";
import { SeoMeta } from "src/types";
import { generateMetaTags } from "src/utils";

import {
  Container,
  ContactsHeader,
  HTMLtext,
  ContactsMapWrapper,
} from "./ContactsPage.styles";

type IProps = {
  url: string;
};

const ContactsPage: FC<IProps> = ({ url }) => {
  const [content, setContent] = useState<any | null>(""); //  eslint-disable-line @typescript-eslint/no-explicit-any
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const seoTitle = "Контакты";
  const seoDescription =
    "Контакты. ⚑ В официальном интернет-магазине LEFORM вы можете купить Ботинки для мужчин по цене от 16790 руб. LEFORM – это оригинальные товары ▶ Скидки ▶ Магазины в центре Москвы ✈ Быстрая доставка по Москве и России.";
  const metaTags: SeoMeta[] = generateMetaTags(seoTitle, seoDescription);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getPageContent(url)
      .then(({ data }) => {
        setContent(data);
      })
      .finally(() => setIsLoading(false));
  }, [url]);

  return (
    <>
      <HelmetProvider>
        <Helmet defer={false} title={seoTitle} meta={metaTags} />
      </HelmetProvider>
      {isLoading ? (
        <Preloader />
      ) : (
        <Container>
          <ContactsHeader>Контакты</ContactsHeader>
          {content && (
            <>
              <HTMLtext
                dangerouslySetInnerHTML={{ __html: content["main_text"] }}
              ></HTMLtext>
              <ContactsMapWrapper>
                <ContactsMap shops={content.shops} />
              </ContactsMapWrapper>
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default ContactsPage;
