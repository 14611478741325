import { Link } from "react-router-dom";
import styled from "styled-components";

import { Button } from "../Button";
import { Flex } from "../Flex";
import { Grid } from "../Grid";
import { Pagination as CustomPagination } from "../Pagination";

export const LinkBanner = styled(Link)`
  img {
    width: 100%;
    maxheight: 670px;
  }
`;
export const AddButton = styled.button`
  background-color: white;
  border: 1px solid black;
  color: black;
  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.1em;
  padding: 19px 21px 15px;
  width: 298px;
  margin: 64px auto 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  &:hover {
    opacity: 0.5;
  }
  @media screen and (max-width: 758px) {
    &:hover {
      opacity: 1;
    }
  }
`;
export const Container = styled(Grid)`
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 64px;

  @media screen and (min-width: 2298px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media screen and (min-width: 2760px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media screen and (max-width: 1600px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 40px;
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
  }

  @media screen and (max-width: 480px) {
    grid-row-gap: 20px;
    grid-column-gap: 14px;
  }

  &.favourites {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 50px;

    @media screen and (max-width: 1600px) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media screen and (max-width: 1440px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 850px) {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 40px;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 20px;
    }

    @media screen and (max-width: 480px) {
      display: none;
    }
  }
`;

export const Pagination = styled(CustomPagination)`
  margin-top: 64px;
`;

export const EmptyFilterData = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;
  text-align: center;
`;

export const ResetFilterButton = styled(Button)`
  margin-top: 30px;
  width: 270px;
`;
