import styled from "styled-components";

export const Container = styled.main`
  box-sizing: border-box;
  margin: 105px auto 323px;
  max-width: 1520px;

  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 1920px) {
    max-width: auto;
    margin: 105px 200px 323px;
  }

  @media screen and (max-width: 1440px) {
    margin: 114px 100px 223px;
  }

  @media screen and (max-width: 1200px) {
    margin: 45px 40px 178px;
  }

  @media screen and (max-width: 768px) {
    margin: 34px 20px 100px;
  }

  @media screen and (max-width: 600px) {
    margin: 24px 15px 157px;
  }
`;

export const ShopsHeader = styled.h1`
  font-size: 62px;
  line-height: 76px;
  text-align: center;
  width: 100%;
  margin: 0 0 80px;

  @media screen and (max-width: 1200px) {
    margin: 0 0 35px;
  }

  @media screen and (max-width: 768px) {
    font-size: 48px;
    line-height: 59px;
    margin: 0 0 45px;
  }

  @media screen and (max-width: 600px) {
    font-size: 26px;
    line-height: 32px;
    margin: 0 0 34px;
  }
`;

export const ShopList = styled.ul`
  padding: 0;
  margin: 0;
  width: 100%;
`;
