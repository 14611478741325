import React, { FC, useState, useEffect } from "react";
import TagManager from "react-gtm-module";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { getPageContent } from "src/api";
import { Preloader } from "src/components";
import { SeoMeta } from "src/types";
import { generateMetaTags } from "src/utils";

import {
  Container,
  PaymentPage,
  Content,
  AccordeonInner,
  AccordeonTop,
} from "./RecomendationPage.styles";
type IProps = {
  url: string;
};

const RecomendationPage: FC<IProps> = ({ url }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [content, setContent] = useState<any | null>(""); //  eslint-disable-line @typescript-eslint/no-explicit-any
  const seoTitle = "Рекомендации по уходу";
  const seoDescription =
    "Рекомендации по уходу. Leform: концептуальный интернет-магазин одежды, обуви, аксессуаров и предметов интерьера";
  const metaTags: SeoMeta[] = generateMetaTags(seoTitle, seoDescription);

  useEffect(() => {
    setIsLoading(true);
    getPageContent(url)
      .then(({ data }) => {
        setContent(data);
      })
      .finally(() => setIsLoading(false));
  }, [url]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  }, []);

  return (
    <Container>
      <HelmetProvider>
        <Helmet defer={false} title={seoTitle} meta={metaTags} />
      </HelmetProvider>
      {isLoading ? (
        <Preloader />
      ) : (
        <PaymentPage>
          {content && (
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: content["recomendation-text"],
                }}
              ></div>
              {content["recomendation-items"].map(
                (item: {
                  title: string;
                  text: string;
                  id: number;
                  children?: any; //  eslint-disable-line @typescript-eslint/no-explicit-any
                }) => {
                  return (
                    <AccordeonTop
                      header={item.title}
                      key={item.id}
                      content={
                        <>
                          <Content
                            dangerouslySetInnerHTML={{ __html: item.text }}
                            key={item.id}
                          ></Content>
                          {item["children"].map(
                            (item: {
                              title: string;
                              text: string;
                              id: number;
                            }) => {
                              return (
                                <>
                                  <AccordeonInner
                                    header={item.title}
                                    key={item.id}
                                    content={
                                      <Content
                                        dangerouslySetInnerHTML={{
                                          __html: item.text,
                                        }}
                                      ></Content>
                                    }
                                  ></AccordeonInner>
                                </>
                              );
                            }
                          )}
                        </>
                      }
                    ></AccordeonTop>
                  );
                }
              )}
            </>
          )}
        </PaymentPage>
      )}
    </Container>
  );
};

export default RecomendationPage;
