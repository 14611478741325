import React, { FC, useState, useEffect } from "react";
import TagManager from "react-gtm-module";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { getPageContent } from "src/api";
import { Preloader } from "src/components";
import { SeoMeta } from "src/types";
import { generateMetaTags } from "src/utils";

import {
  Container,
  PaymentPage,
  VacancyLink,
  NameH3,
} from "./VacancyPage.styles";
type IProps = {
  url: string;
};

const VacancyPage: FC<IProps> = ({ url }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [content, setContent] = useState<any>([]); //  eslint-disable-line @typescript-eslint/no-explicit-any

  const seoTitle = "Вакансии";
  const seoDescription = "Вакансии в магазине LEFORM";
  const metaTags: SeoMeta[] = generateMetaTags(seoTitle, seoDescription);

  useEffect(() => {
    setIsLoading(true);
    getPageContent(url)
      .then(({ data }) => {
        setContent(data);
      })
      .finally(() => setIsLoading(false));
  }, [url]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  }, []);

  const vacansyRow = (name: string, url: string) => {
    return (
      <VacancyLink key={url} to={url}>
        <NameH3>{name}</NameH3>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.79578 4.34315L13.0075 10L7.79578 15.6569"
            stroke="#555555"
            stroke-width="2"
          />
        </svg>
      </VacancyLink>
    );
  };
  return (
    <Container>
      <HelmetProvider>
        <Helmet defer={false} title={seoTitle} meta={metaTags} />
      </HelmetProvider>
      {isLoading ? (
        <Preloader />
      ) : (
        <PaymentPage>
          <h1>Вакансии</h1>
          <p>
            LEFORM — главный концепт-стор России. Открывшись в 1997 году,
            концепт-стор стал первым и самым большим в Восточной Европе. Именно
            LEFORM познакомил москвичей с такими марками, как Dries Van Noten,
            Maison Margiela, Comme des Garcons и Helmut Lang. В магазинах LEFORM
            представлено более 200 мировых брендов одежды и обуви, а также
            уникальные предметы интерьера и декора. Сегодня LEFORM имеет четыре
            магазина: три из них расположены в сердце столицы — в Дмитровском
            переулке, на Поварской и Краснопролетарской улицах — и один магазин
            в Московской области.
            <br /> Присоединяйтесь к команде LEFORM!&nbsp;
          </p>
          {content.map((
            vac: any //  eslint-disable-line @typescript-eslint/no-explicit-any
          ) => vacansyRow(vac.name, vac.code_vacancy))}
        </PaymentPage>
      )}
    </Container>
  );
};

export default VacancyPage;
