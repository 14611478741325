import React, { FC } from "react";

import { ROUTES } from "../../constants";
import { Link } from "../Link";

import { HeaderContainer } from "./Header.styles";

const HeaderCheckout: FC = () => {
  return (
    <HeaderContainer className="header--checkout" isCompact={false}>
      <Link className="logo-svg" to={ROUTES.home}>
        <svg
          width="132"
          height="39"
          viewBox="0 0 132 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M109.42 0.614711V38.3853H113.172V14.958L112.899 9.15236H113.172L118.288 38.3853H123.132L128.248 9.15236H128.521L128.248 14.958V38.3853H132V0.614711H125.656L120.812 29.9842H120.54L115.696 0.614711H109.42ZM71.5597 30.0525C71.5597 32.7846 70.5364 32.7846 68.0806 32.7846C65.8295 32.7846 64.9426 32.7846 64.9426 30.0525V8.87916C64.9426 6.14711 65.8295 6.14711 68.0806 6.14711C70.4682 6.14711 71.5597 6.14711 71.5597 8.87916V30.0525ZM67.4667 0C61.1225 0 61.1225 4.98599 61.1225 9.15236V29.9159C61.1225 34.2189 61.1225 39 67.4667 39H68.831C75.3116 39 75.3116 34.2189 75.3116 29.9159V9.15236C75.3116 4.98599 75.3116 0 68.831 0H67.4667ZM0 0.614711V38.3853H12.4837V32.3065H3.75194V0.614711H0ZM41.476 38.3853H45.2279V21.993H52.8V16.2557H45.2961V6.62522H53.9597V0.614711H41.476V38.3853ZM19.5783 38.3853H31.9256V32.3065H23.3302V21.993H30.9023V16.2557H23.3302V6.62522H31.9938V0.614711H19.5783V38.3853ZM95.5039 15.5727C95.5039 18.3047 94.4806 18.373 92.2295 18.373H88.8186V6.62522H92.2295C94.4124 6.62522 95.5039 6.62522 95.5039 9.42557V15.5727ZM96.2543 23.6322C98.9147 22.4028 99.1876 20.2172 99.1876 16.8021V8.19615C99.1876 5.6007 99.1876 0.819615 92.8434 0.54641H85.0667V38.317H88.8186V24.3835H92.4341L95.7767 38.3853H99.938L96.2543 23.6322Z"
            fill="white"
          />
        </svg>
      </Link>
    </HeaderContainer>
  );
};

export default HeaderCheckout;
