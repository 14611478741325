import React, { useEffect, useState, FC, useMemo } from "react";
import TagManager from "react-gtm-module";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import { Element } from "react-scroll";

import { getBrands } from "src/api";
import { BrandCard } from "src/components/BrandCard";
import { ROUTES, LETTERS } from "src/constants";
import { BrandsItem, SeoMeta } from "src/types";
import {
  getSortedBrandsArray,
  getLettersArray,
  generateMetaTags,
} from "src/utils";

import { useQueryParams } from "../../hooks/useQueryParams";

import {
  Container,
  LettersList,
  LetterItem,
  BrandHeader,
  BrandFilters,
  BrandButton,
  BrandNav,
  Alphabet,
} from "./Brands.styles";
import { GenderBrands } from "./Brands.types";

const filterButtons = [
  { label: "Мужское", value: GenderBrands.isMan },
  { label: "Женское", value: GenderBrands.isWoman },
  { label: "Интерьер", value: GenderBrands.isInterior },
];

const BrandsPage: FC = () => {
  const history = useHistory();
  const [brandsLettersArray, setBrandsLettersArray] = useState<BrandsItem[][]>(
    []
  );
  const [scrollPosition, setScrollPosition] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);
  const { queryParams, setQueryParams } = useQueryParams({
    arrayFormat: "bracket",
  });
  const [isWayDown, setIsWayDown] = useState(true);
  const [isOnBottom, setIsOnBottom] = useState(false);
  const [filterValue, setFilterValue] = useState<GenderBrands | null>(null);

  const seoTitle = "BRANDS";
  const seoDescription =
    "Brands. Leform: концептуальный интернет-магазин одежды, обуви, аксессуаров и предметов интерьера";
  const [prerenderMeta, setPrerenderMeta] = useState<SeoMeta[] | undefined>([]);

  const metaTags: SeoMeta[] = useMemo(() => {
    const meta: SeoMeta[] = generateMetaTags(
      seoTitle,
      seoDescription,
      prerenderMeta
    );
    return meta;
  }, [seoTitle, seoDescription, prerenderMeta]);

  const onFilterClick = (value: GenderBrands) => {
    setFilterValue((prevValue) =>
      prevValue === null || prevValue !== value ? value : null
    );
    setQueryParams({ filter: value }, true);
  };

  useEffect(() => {
    getBrands()
      .then(({ data }) => {
        setBrandsLettersArray(
          getLettersArray(getSortedBrandsArray(data.items))
        );
      })
      .catch((error) => {
        const errorCode = error?.response?.status;

        if (errorCode === 400 || errorCode === 404) {
          const newArr = [...metaTags];

          const hasRedirect = newArr.find(
            (meta) => meta.name === "prerender-status-code"
          );
          if (!hasRedirect) {
            newArr.push({
              name: "prerender-status-code",
              content: "301",
            });
            newArr.push({
              name: "prerender-header",
              content: ROUTES.home,
            });
            setPrerenderMeta(newArr);
          }
        }
      });
  }, [history]);

  useEffect(() => {
    const footerHeight = document.querySelector("#root > footer")?.clientHeight;
    const elemHeight = document.getElementById("alphabet")?.clientHeight;
    setContentHeight(
      document.documentElement.scrollHeight - Number(footerHeight)
    );
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
      setIsWayDown(position > scrollPosition);
      setIsOnBottom(position + Number(elemHeight) > contentHeight);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  useEffect(() => {
    const currentFilter = queryParams.filter as GenderBrands;
    setFilterValue(currentFilter);
  }, [queryParams.filter]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  }, []);

  return (
    <Container>
      <HelmetProvider>
        <Helmet title={seoTitle} meta={metaTags} />
      </HelmetProvider>
      <BrandNav>
        <BrandHeader>Бренды</BrandHeader>
        <BrandFilters>
          {filterButtons.map((itemButton) => (
            <BrandButton
              variant="white"
              className={
                itemButton.value === filterValue ? "active" : undefined
              }
              onClick={() => onFilterClick(itemButton.value)}
              key={itemButton.value}
            >
              {itemButton.label}
            </BrandButton>
          ))}
        </BrandFilters>
        <Alphabet
          id="alphabet"
          scrollPosition={scrollPosition}
          isWayDown={isWayDown}
          isOnBottom={isOnBottom}
          contentHeight={contentHeight}
        >
          <LettersList>
            {LETTERS.map((letter, i) => (
              <li key={i}>
                <LetterItem
                  activeClass="active"
                  to={`letter_${i}`}
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  isdisabled={Boolean(!brandsLettersArray[i]?.length)}
                >
                  {letter.toUpperCase()}
                </LetterItem>
              </li>
            ))}
          </LettersList>
        </Alphabet>
      </BrandNav>
      {brandsLettersArray.length > 0 &&
        brandsLettersArray.map((_, index) => (
          <Element key={`fragment_${index}`} name={`letter_${index}`}>
            <BrandCard
              brandsArray={brandsLettersArray[index]}
              activeLetter={LETTERS[index].toUpperCase()}
              filterValue={filterValue}
            />
          </Element>
        ))}
    </Container>
  );
};

export default BrandsPage;
