import React, { FC } from "react";
import { useSelector } from "react-redux";

import { ROUTES } from "src/constants";
import { ApplicationStore } from "src/store";

import {
  Title,
  EmptyOrdersList,
  CardRow,
  HeaderWrapper,
  BackLink,
  BackIcon,
  BackIconMobile,
  CatalogLink,
  AboutLink,
  CostParagraph,
  CardHeader,
  Card,
  CardBody,
} from "./LoyaltyCard.styles";

const LoyaltyCard: FC = () => {
  const { userData } = useSelector((state: ApplicationStore) => state.user);
  function openRouteBonus() {
    window.open(
      "https://get.osmicards.com/anketa/3610TRE453DLVT/get",
      "_blank"
    );
  }

  const massiveBonuses = userData?.bonuses?.details?.filter(
    (bonus) => bonus.diff && bonus.diff < 0
  );
  function dateAction(action_date: string | null | undefined) {
    if (action_date) {
      return action_date.match(/[0-9]{2}.[0-9]{2}.[0-9]{4}/);
    }
    return "Дата не определена";
  }
  function preparedText(text: string | number | null | undefined) {
    let preparedText = text;
    if (typeof preparedText === "number") {
      preparedText = `${preparedText}`;
    }

    if (preparedText) {
      const text = preparedText
        ?.match(/-{0,1}[0-9]{1,}/)
        ?.join("")
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return text;
    }

    return "Данные отсутствуют";
  }
  return (
    <>
      <HeaderWrapper>
        <BackLink to={ROUTES.accountPage}>
          <BackIcon />
          <BackIconMobile />
          <span>Назад</span>
        </BackLink>
      </HeaderWrapper>
      {userData?.bonuses?.details?.length ? (
        <Card>
          <Title>
            Мои бонусы: {preparedText(userData?.bonuses?.available)}
          </Title>
          <>
            <CardHeader>
              <h2>Статус карты: {userData?.bonuses?.grade?.current_grade}</h2>
              <h2>
                Сумма покупок:{" "}
                {preparedText(
                  Math.round(
                    userData?.bonuses?.grade?.total_amount
                      ? userData?.bonuses?.grade?.total_amount
                      : 0
                  )
                )}{" "}
                ₽
              </h2>
              {userData?.bonuses?.grade?.next_grade?.includes(
                "У Вас максимальный статус"
              ) ||
              userData?.bonuses?.grade?.current_grade?.toUpperCase() ===
                "BLACK" ? (
                <p>У Вас максимальный статус</p>
              ) : (
                <p>
                  До статуса {userData?.bonuses?.grade?.next_grade} осталось{" "}
                  {preparedText(userData?.bonuses?.grade?.next_grade_remain)} ₽
                </p>
              )}
            </CardHeader>

            <CardBody>
              <h2>Дата сгорания бонусов</h2>
              {massiveBonuses?.map((detailBonus) => (
                <CardRow>
                  {detailBonus.diff && (
                    <>
                      <p>
                        {preparedText(Math.abs(+detailBonus.diff))} бонусов
                        сгорает
                      </p>
                      <p>{dateAction(detailBonus.action)}</p>
                    </>
                  )}
                </CardRow>
              ))}
            </CardBody>
          </>
        </Card>
      ) : (
        <EmptyOrdersList>
          <Title>Мои бонусы</Title>
          <h2>У вас нет бонусной карты</h2>
          <CatalogLink
            variant="black"
            to=""
            role="none"
            onClick={openRouteBonus}
          >
            оформить карту
          </CatalogLink>
        </EmptyOrdersList>
      )}
      {userData?.bonuses?.available ? (
        <CostParagraph>
          Баллами можно оплатить до{" "}
          {userData?.bonuses?.grade?.current_grade?.toUpperCase() === "SILVER"
            ? 50
            : 99}
          % стоимости заказа по курсу{" "}
          <span>{userData?.bonuses?.rate_description}</span>
        </CostParagraph>
      ) : (
        <p></p>
      )}
      <AboutLink to="/bonus-program/">Подробнее о бонусах</AboutLink>
    </>
  );
};

export default LoyaltyCard;
