import React, { useCallback, FC, FormEvent } from "react";
import { useSelector } from "react-redux";

import { DatePicker, SelectInput } from "src/components";
import { useForm } from "src/hooks";
import { InputChangeEvent } from "src/hooks/useForm/useForm.types";
import { ApplicationStore } from "src/store";

import {
  Form,
  Input,
  FormButton,
  InputContainer,
} from "./EditPersonalInfo.styles";
import { EditPersonalInfoProps } from "./EditPersonalInfo.types";

const EditPersonalInfo: FC<EditPersonalInfoProps> = (props) => {
  const { onSubmit, errors, isLoading } = props;
  const { values, onChange, setValues } = useForm({
    name: props.fio,
    phone: props.phone,
    email: props.email,
    gender_id: props.gender_id,
    date_of_birth: props.birthDate,
  });

  const allGenders = useSelector(
    (s: ApplicationStore) => s.user.userData?.genders
  );

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const names = values.name.split(" ");

      onSubmit({
        last_name: names[1],
        first_name: names[0],
        email: values.email,
        gender_id: +values.gender_id || allGenders?.[0].id || 1,
        date_of_birth: values.date_of_birth,
      });
    },
    [values, onSubmit, allGenders]
  );

  const handleNameOnChange = (e: InputChangeEvent) => {
    if (e.target.value.match(/[^a-zа-я-\\.\s]/i)) return;
    onChange(e);
  };

  const setDateValue = (date: Date) => {
    date.setUTCHours(0, 0, 0, 0);
    setValues({ ...values, date_of_birth: date });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        value={values.name || ""}
        onChange={handleNameOnChange}
        name="name"
        placeholder="Имя"
        aria-label="Имя"
        hasError={!!errors.name}
        disabled={isLoading}
      />
      <Input
        value={values.email}
        onChange={onChange}
        name="email"
        type="email"
        placeholder="e-mail"
        aria-label="e-mail"
        hasError={!!errors.email}
        disabled={isLoading}
      />
      <InputContainer>
        <DatePicker
          dateValue={
            values.date_of_birth ? new Date(values.date_of_birth) : null
          }
          setDateValue={setDateValue}
          placeholderText="Дата рождения*"
          className={!!errors.date_of_birth ? "error" : undefined}
          disabled={false}
        />
      </InputContainer>
      <InputContainer>
        <SelectInput
          value={values.gender_id}
          onChange={onChange}
          name="gender_id"
          aria-label="Пол"
          isEmpty={!values.gender_id}
          disabled={isLoading}
        >
          {allGenders?.map(({ id, name }) => (
            <option key={id} value={id} selected={values.gender_id === id}>
              {name}
            </option>
          ))}
        </SelectInput>
      </InputContainer>
      <FormButton type="submit" disabled={isLoading}>
        Сохранить
      </FormButton>
    </Form>
  );
};

export default EditPersonalInfo;
