/* eslint-disable max-len */

import React, { FC } from "react";

import { SvgProps } from "src/types";

const FilledCheckout: FC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 16 16">
    <path d="M1 4h14v10a2 2 0 01-2 2H3a2 2 0 01-2-2V4zm7-2.5A2.5 2.5 0 005.5 4h-1a3.5 3.5 0 117 0h-1A2.5 2.5 0 008 1.5z" />
  </svg>
);

FilledCheckout.defaultProps = {
  width: "20px",
  height: "20px",
  fill: "black",
};

export default FilledCheckout;
