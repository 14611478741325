export type VariablesState = {
  cid?: number;
  new_quantity?: number;
};

export enum VariablesTypes {
  SET_CID = "SET_CID",
  SET_QUANTITY = "SET_QUANTITY",
}

export type SetCID = {
  type: VariablesTypes.SET_CID;
  payload: VariablesState;
};

export type SetQuantity = {
  type: VariablesTypes.SET_QUANTITY;
  payload: VariablesState;
};

export type VariablesActions = SetCID | SetQuantity;
