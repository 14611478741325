import {
  BaseFilterValue,
  CatalogFilterValues,
  CatalogFilterObject,
} from "src/components/CatalogFilters/CatalogFilters.types";
import { Filter } from "src/types";

import { FilterTag } from "./FilterTags.types";

const clearName = (name: string): string => {
  return name.replace("filter[", "").replace("]", "");
};

const getFilterTag = (
  filter: Filter,
  item: CatalogFilterObject | BaseFilterValue
): FilterTag => {
  return {
    code: filter.code,
    label: filter.name,
    type: filter.type,
    value: filter.values.find(({ id }) => `${id}` === `${item}`),
  };
};

export const getFilterTags = (
  deserialized: CatalogFilterValues,
  filterArr: Filter[]
): FilterTag[] => {
  const filters = Object.entries(deserialized).map(([name, item]) => {
    const filterItem = filterArr.find(({ code }) => code === clearName(name));

    if (filterItem) {
      if (item && typeof item === "object") {
        return Object.keys(item).map((key) => getFilterTag(filterItem, key));
      }

      return getFilterTag(filterItem, item);
    }

    return [];
  });

  return filters.flat();
};

export const getTagLabel = (tag: FilterTag): string => {
  if (!tag.value) {
    return "";
  }

  if (tag.code === "is_sale") {
    return "Sale";
  }

  if (tag.code === "is_new") {
    return "Новинка";
  }

  if (tag.code === "is_archive") {
    return "Archive sale";
  }

  if (tag.code === "is_super") {
    return "Supersale";
  }

  if (tag.type === "radio") {
    if (`${tag.value.name}` === "true") {
      return `${tag.label}: Да`;
    } else if (`${tag.value.name}` === "false") {
      return `${tag.label}: Нет`;
    }
  }

  return `${tag.value.name}`;
};
