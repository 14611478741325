import styled from "styled-components";

const IconButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
  background-color: inherit;
  border: none;
  margin: 0;

  :disabled {
    cursor: not-allowed;
  }
`;

export default IconButton;
