import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";

import { Link } from "../Link";

import { MenuItemProps } from "./SecondaryMenu.types";

export const MenuList = styled.ul`
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
`;

export const MenuItem = styled.li<MenuItemProps>`
  position: relative;
  cursor: pointer;
  padding: 12px 0;
  margin: 0;

  :hover a {
    color: ${({ theme }): string => theme.colors.black};
  }

  ${({
    isActive,
    theme,
  }): FlattenInterpolation<ThemeProps<DefaultTheme>> | false =>
    isActive &&
    css`
      border-bottom: 2px solid ${theme.colors.black};

      a {
        color: ${theme.colors.black};
      }
    `};

  @media screen and (max-width: 600px) {
    padding: 8px 0;
  }
`;

export const MenuLink = styled(Link)`
  font-family: HelveticaNeueCyr, sans-serif;
  font-weight: 500;
  font-size: 22px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: ${({ theme }): string => theme.colors.grey};
  margin: 0;

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }

  @media screen and (max-width: 360px) {
    font-size: 14px;
  }
`;
