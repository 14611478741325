import styled from "styled-components";

import { Box, LoginForm } from "src/components";

export const Container = styled(Box)`
  max-width: 570px;
  width: 100%;
  margin: 200px auto 100px;

  @media screen and (max-width: 768px) {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #html_element {
    margin-top: 20px;
    & > div {
      width: 100% !important;
      display: flex;
      justify-content: center;
    }
  }
  .error_captch {
    color: red;
    margin-top: 10px;
    text-align: center;
  }
`;

export const ErrorCaptcha = styled.div`
  margin-top: 12px;
  width: 304px;
  color: red;
  text-align: center;
`;

export const WrapperError = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const Form = styled(LoginForm)`
  margin-top: 60px;
  @media screen and (max-width: 768px) {
    max-width: 304px;
    padding-right: 2px;
  }
`;
