import { merge } from "lodash";
import React, { ChangeEvent, FC, useRef, useState } from "react";
import TagManager from "react-gtm-module";
import { useSelector } from "react-redux";

import { MobileCatalogFiltersInner } from "src/components/MobileCatalogFilters/MobileCatalogFiltersInner";

import { stringifyConfig } from "../../constants";
import { useQueryParams } from "../../hooks";
import { ApplicationStore } from "../../store";
import { SeoParams, Filter, FilterValue } from "../../types";
import { CatalogFilterObject } from "../CatalogFilters/CatalogFilters.types";

import {
  ModalContainer,
  CloseButton,
  CloseIcon,
  Container,
  ButtonWrapper,
  Header,
  HeaderWrapper,
  ModalInner,
  SortingButton,
} from "./MobileCatalogFilters.styles";
import { MobileCatalogFiltersProps } from "./MobileCatalogFilters.types";
import {
  deserializeFilters,
  serializeFilters,
  setFilterValues,
} from "./MobileCatalogFiltersInner/MobileCatalogFiltersInner.utils";

const MobileCatalogFilter: FC<MobileCatalogFiltersProps> = ({
  onClose,
  filters,
  isLoading,
  className,
  filterPrice,
  resetFilters,
}) => {
  const { queryParams, setQueryParams, getNewPath } = useQueryParams({
    arrayFormat: "bracket",
  });

  const addParams: any = getNewPath(window.location.pathname); //  eslint-disable-line @typescript-eslint/no-explicit-any

  const { userData } = useSelector((state: ApplicationStore) => state.user);

  const deserializedFilters = deserializeFilters(
    queryParams,
    filters,
    addParams
  );

  const [filtersValues, setFiltersValues] = useState(deserializedFilters);

  const filterCode = useRef<string | undefined>();

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    filter: Filter,
    value?: FilterValue
  ) => {
    event.persist();
    const newFilters = merge(filtersValues, setFilterValues(event));

    filterCode.current = event.target.dataset.code;

    const currentFilterChanged = Object.entries(setFilterValues(event));
    let content = undefined;
    if (value) {
      content = value.name;
    }
    if (filter.name === "Цена") {
      content = `${currentFilterChanged[0][0]} - ${currentFilterChanged[0][1]}`;
    }

    TagManager.dataLayer({
      dataLayer: {
        event: "filters",
        name: filter.name,
        content,
        userId: userData?.userId,
      },
    });

    setFiltersValues({ ...newFilters });
  };

  const handleReset = () => {
    resetFilters();
    onClose();
  };

  const seoParams: SeoParams = {};

  const handleAccept = () => {
    const newFilters: any = serializeFilters(filtersValues, filters); //  eslint-disable-line @typescript-eslint/no-explicit-any
    Object.entries(filtersValues).forEach(([key, value]) => {
      const filterCode: string = key.replace("filter[", "").replace("]", "");
      const filter = filters.find((f) => f.code === filterCode);
      if (filter) {
        const codes = Object.keys(value as CatalogFilterObject)
          .map((id) => {
            const filterValue = filter.values.find(
              (v) => v && v.id && v.id.toString() === id
            );
            return filterValue && filterValue.code ? filterValue.code : null;
          })
          .filter((code) => code !== null);
        if (codes.length === 1) {
          seoParams[filterCode] = codes[0];
        }
      }
    });

    setQueryParams(
      { ...newFilters, page: undefined },
      false,
      stringifyConfig,
      undefined,
      seoParams,
      filters
    );

    onClose();
  };

  return (
    <ModalContainer onClose={onClose} withoutScroll>
      <ModalInner>
        <HeaderWrapper>
          <Header>Фильтр</Header>
          <CloseButton onClick={onClose} aria-label="Закрыть">
            <CloseIcon />
          </CloseButton>
        </HeaderWrapper>
        <Container>
          <MobileCatalogFiltersInner
            filters={filters}
            values={filtersValues}
            isLoading={isLoading}
            className={className}
            filterPrice={filterPrice}
            onClose={onClose}
            onChange={handleChange}
            onReset={handleReset}
          />
        </Container>
        <ButtonWrapper>
          <SortingButton onClick={handleAccept}>Применить</SortingButton>
          <SortingButton variant="white" onClick={handleReset}>
            Сбросить фильтры
          </SortingButton>
        </ButtonWrapper>
      </ModalInner>
    </ModalContainer>
  );
};

export default MobileCatalogFilter;
