import styled from "styled-components";

import { Button, Flex, LinkButton, Icons, Link } from "src/components";

export const OrderHeader = styled(Flex)`
  justify-content: space-between;
  margin-bottom: 72px;

  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 50px;
  }
`;
export const BackLink = styled(Link)`
  width: 217px;
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.black};
  display: none;
  align-items: center;
  font-family: Formular;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  text-transform: capitalize;
  white-space: nowrap;
  padding: 0;
  margin-top: -12px;

  svg {
    fill: ${({ theme }) => theme.colors.black};
    margin-right: 5px;
    margin-top: -5px;
  }

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 1150px) {
    display: flex;
  }

  @media (max-width: 767px) {
    width: auto;
    margin-bottom: 20px;
    padding: 10px 0;

    span {
      display: none;
    }
  }
`;

export const BackIcon = styled(Icons.Arrow)`
  width: 19px;
  height: 19px;
  transform: rotate(-90deg);
  stroke: ${({ theme }): string => theme.colors.black};
  stroke-width: 2px;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const BackIconMobile = styled(Icons.BackArrow)`
  width: 21px;
  height: 13px;
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
`;

export const Title = styled.h3`
  font-family: Formular;
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.black};
  margin: 0;

  @media (max-width: 767px) {
    margin: 0 -88px 0 auto;
  }

  @media (max-width: 439px) {
    margin: 0;
  }
`;

export const EmptyOrdersList = styled.div`
  p {
    font-family: Formular;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.colors.black};
    max-width: 209px;
    margin: 0 0 29px;
  }
`;

export const CatalogLink = styled(LinkButton)`
  max-width: 366px;
`;

export const SelectContainer = styled(Flex)`
  width: 217px;
  margin-top: -12px;
  justify-content: flex-end;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const MobileFilterButton = styled(Button)`
  display: none;
  font-size: 12px;
  max-width: 88px;
  text-transform: none;
  font-weight: 400;
  padding: 5px 2px 3px;
  margin-left: auto;

  @media screen and (max-width: 767px) {
    display: block;
    // TODO: временно скрываем сортировку по статусу заказа
    visibility: hidden;
  }
`;

export const TitleWrapper = styled.div`
  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: flex-end;
  }
`;

export const Loader = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: ${({ theme }) => theme.colors.lightGrey};
    animation: loadingAnimate 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

    &:nth-child(1) {
      left: 8px;
      animation-delay: -0.24s;
    }

    &:nth-child(2) {
      left: 32px;
      animation-delay: -0.12s;
    }

    &:nth-child(3) {
      left: 56px;
      animation-delay: 0;
    }
  }

  @keyframes loadingAnimate {
    0% {
      top: 8px;
      height: 64px;
    }
    50%,
    100% {
      top: 24px;
      height: 32px;
    }
  }
`;
