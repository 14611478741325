import styled from "styled-components";

import { Flex, Link, Icons, LinkButton } from "src/components";

export const HeaderWrapper = styled(Flex)`
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const BackLink = styled(Link)`
  margin-right: auto;
  width: 100px;
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.black};
  display: none;
  align-items: center;
  font-family: Formular;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  text-transform: capitalize;
  white-space: nowrap;
  padding: 0;

  svg {
    fill: ${({ theme }) => theme.colors.black};
    margin-right: 5px;
    margin-top: -5px;
  }

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 1150px) {
    display: flex;
  }

  @media (max-width: 767px) {
    width: auto;
    margin-bottom: 20px;
    padding: 10px 0;

    span {
      display: none;
    }
  }
`;

export const CatalogLink = styled(LinkButton)`
  max-width: 291px;
  margin-bottom: 41px;
  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 30px;
  text-decoration: none;
`;
export const BackIcon = styled(Icons.Arrow)`
  width: 19px;
  height: 19px;
  transform: rotate(-90deg);
  stroke: ${({ theme }): string => theme.colors.black};
  stroke-width: 2px;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const BackIconMobile = styled(Icons.BackArrow)`
  width: 21px;
  height: 13px;
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
`;

export const Title = styled.h3`
  font-family: Formular;
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.black};
  margin: 0;

  @media (max-width: 1150px) {
    margin: 0 auto 0 -100px;
  }

  @media (max-width: 767px) {
    text-align: center;
    margin: 0 auto;
  }
`;

export const AboutLink = styled(Link)`
  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-weight: 450;
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 0.1em;
  text-decoration-line: underline;
  color: #000000;
  margin-left: 2px;
  @media (max-width: 1150px) {
    margin: 0 auto;
    width: 350px;
    display: block;
  }
  @media (max-width: 750px) {
    text-align: center;
    width: 100%;
  }
`;
export const Card = styled.div`
  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0.01em;
  h2 {
    font-size: 20px;
    color: #000000;
    margin-bottom: 16px;
  }
  p {
    font-size: 14px;
    color: #979797;
  }
  @media (max-width: 1150px) {
    max-width: 350px;
    margin: 0 auto;
    h3 {
      margin-left: 0;
    }
  }
  @media (max-width: 750px) {
    max-width: 100%;
    h2,
    p {
      text-align: center;
    }
  }
`;
export const CardHeader = styled.div`
  margin-bottom: 70px;
  @media (max-width: 750px) {
    margin-bottom: 42px;
    h2,
    p {
      margin: 0 0 11px;
    }
  }
`;
export const CardBody = styled.div`
  margin-bottom: 61px;
  @media (max-width: 750px) {
    h2 {
      margin-bottom: 26px;
    }
  }
`;
export const CostParagraph = styled.p`
  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  width: 100%;
  max-width: 350px;
  margin-bottom: 8px;

  span {
    font-weight: 500;
  }
  @media (max-width: 1150px) {
    margin: 0 auto;
  }
  @media (max-width: 750px) {
    text-align: center;
    max-width: 100%;
  }
`;
export const EmptyOrdersList = styled.div`
  h3 {
    margin-bottom: 102px;
    @media (max-width: 767px) {
      margin-bottom: 50px;
    }
    @media (max-width: 1150px) {
      margin-left: 0;
    }
    @media (max-width: 750px) {
      margin: 0 auto 75px;
    }
  }
  @media (max-width: 1150px) {
    width: 350px;
    margin: 0 auto;
  }
  @media (max-width: 750px) {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  h2 {
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    font-weight: 450;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.colors.black};
    margin-bottom: 102px;
    @media (max-width: 750px) {
      text-align: center;
    }
  }
`;

export const CardRow = styled.p`
  display: flex;
  justify-content: space-between;
  max-width: 350px;
  margin-bottom: 17px;
  p {
    margin: 0px;
  }
  @media (max-width: 750px) {
    max-width: 100%;
  }
`;

export const CardTitle = styled.span`
  font-weight: 500;
  margin-right: 5px;
`;
