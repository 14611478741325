import React, { FC, useState, useEffect } from "react";
import TagManager from "react-gtm-module";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { getPageContent } from "src/api";
import { Preloader } from "src/components";
import { SeoMeta } from "src/types";
import { generateMetaTags } from "src/utils";

import { AccordeonStyled } from "./ReturnAccordeon.styles";
import { Container, ReturnPageStyle, Content } from "./ReturnPage.styles";

type IProps = {
  url: string;
};

const ReturnPage: FC<IProps> = ({ url }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [content, setContent] = useState<any | null>(""); //  eslint-disable-line @typescript-eslint/no-explicit-any

  const seoTitle = "Обмен и возврат";
  const seoDescription =
    "Обмен и возврат. Leform: концептуальный интернет-магазин одежды, обуви, аксессуаров и предметов интерьера";
  const metaTags: SeoMeta[] = generateMetaTags(seoTitle, seoDescription);

  useEffect(() => {
    setIsLoading(true);
    getPageContent(url)
      .then(({ data }) => {
        setContent(data);
      })
      .finally(() => setIsLoading(false));
  }, [url]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  }, []);

  return (
    <Container>
      <HelmetProvider>
        <Helmet defer={false} title={seoTitle} meta={metaTags} />
      </HelmetProvider>
      {isLoading ? (
        <Preloader />
      ) : (
        <ReturnPageStyle>
          {content && (
            <>
              <div
                dangerouslySetInnerHTML={{ __html: content["return-text"] }}
              ></div>
              {content["return-items"].map(
                (item: { title: string; text: string; id: number }) => {
                  return (
                    <AccordeonStyled
                      header={item.title}
                      key={item.id}
                      content={
                        <Content
                          dangerouslySetInnerHTML={{ __html: item.text }}
                        ></Content>
                      }
                    ></AccordeonStyled>
                  );
                }
              )}
            </>
          )}
        </ReturnPageStyle>
      )}
    </Container>
  );
};

export default ReturnPage;
