import { debounce } from "lodash";
import { useEffect, useState } from "react";

import { UseIsMobile } from "./useIsMobile.types";

const useIsMobile = (): UseIsMobile => {
  const [isMobile, setIsMobile] = useState<boolean>(
    "ontouchstart" in window || navigator.maxTouchPoints > 0
  );

  useEffect(() => {
    const updateIsMobile = debounce(() => {
      setIsMobile("ontouchstart" in window || navigator.maxTouchPoints > 0);
    }, 300);

    window.addEventListener("resize", updateIsMobile);
    return () => {
      window.removeEventListener("resize", updateIsMobile);
      updateIsMobile.cancel();
    };
  }, []);

  return {
    isMobile,
  };
};

export default useIsMobile;
