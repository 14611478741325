import { ROUTES } from "./routes";

export const WOMAN_MENU_ITEMS = [
  {
    title: "Одежда",
    items: [
      {
        label: "Верхняя одежда",
        url: ROUTES.catalog,
      },
      {
        label: "Платья",
        url: ROUTES.catalog,
      },
      {
        label: "Джинсы",
        url: ROUTES.catalog,
      },
      {
        label: "Брюки",
        url: ROUTES.catalog,
      },
      {
        label: "Комбинезоны",
        url: ROUTES.catalog,
      },
      {
        label: "Свитеры и джемперы",
        url: ROUTES.catalog,
      },
      {
        label: "Толстовки и худи",
        url: ROUTES.catalog,
      },
      {
        label: "Футболки и поло",
        url: ROUTES.catalog,
      },
      {
        label: "Шорты",
        url: ROUTES.catalog,
      },
      {
        label: "Рубашки",
        url: ROUTES.catalog,
      },
      {
        label: "Топы и туники",
        url: ROUTES.catalog,
      },
      {
        label: "Блузы",
        url: ROUTES.catalog,
      },
      {
        label: "Пиджаки и костюмы",
        url: ROUTES.catalog,
      },
      {
        label: "Жилеты",
        url: "Юбки",
      },
      {
        label: "Пляжная одежда",
        url: ROUTES.catalog,
      },
      {
        label: "Нижнее белье и носки",
        url: ROUTES.catalog,
      },
    ],
  },
  {
    title: "Обувь",
    items: [
      {
        label: "Кроссовки и кеды",
        url: ROUTES.catalog,
      },
      {
        label: "Ботинки",
        url: ROUTES.catalog,
      },
      {
        label: "Ботильоны",
        url: ROUTES.catalog,
      },
      {
        label: "Сапоги",
        url: ROUTES.catalog,
      },
      {
        label: "Туфли",
        url: ROUTES.catalog,
      },
      {
        label: "Летняя обувь",
        url: ROUTES.catalog,
      },
      {
        label: "Босоножки",
        url: ROUTES.catalog,
      },
    ],
  },
  {
    title: "Аксессуары",
    items: [
      {
        label: "Головные уборы",
        url: ROUTES.catalog,
      },
      {
        label: "Очки",
        url: ROUTES.catalog,
      },
      {
        label: "Шарфы",
        url: ROUTES.catalog,
      },
      {
        label: "Перчатки",
        url: ROUTES.catalog,
      },
      {
        label: "Ремни",
        url: ROUTES.catalog,
      },
      {
        label: "Чехлы",
        url: ROUTES.catalog,
      },
      {
        label: "Брелоки",
        url: ROUTES.catalog,
      },
      {
        label: "Другое",
        url: ROUTES.catalog,
      },
    ],
  },
  {
    title: "Сумки",
    items: [
      {
        label: "Рюкзаки",
        url: ROUTES.catalog,
      },
      {
        label: "Сумки и клатчи",
        url: ROUTES.catalog,
      },
      {
        label: "Чемоданы",
        url: ROUTES.catalog,
      },
      {
        label: "Кошельки",
        url: ROUTES.catalog,
      },
    ],
  },
  {
    title: "Украшения",
    items: [
      {
        label: "Кольца",
        url: ROUTES.catalog,
      },
      {
        label: "Серьги",
        url: ROUTES.catalog,
      },
      {
        label: "Колье и подвески",
        url: ROUTES.catalog,
      },
      {
        label: "Браслеты",
        url: ROUTES.catalog,
      },
      {
        label: "Броши",
        url: ROUTES.catalog,
      },
      {
        label: "Часы",
        url: ROUTES.catalog,
      },
    ],
  },
  {
    title: "Уход за собой",
    items: [
      {
        label: "Парфюмерия",
        url: ROUTES.catalog,
      },
      {
        label: "Для лица",
        url: ROUTES.catalog,
      },
      {
        label: "Для тела",
        url: ROUTES.catalog,
      },
      {
        label: "Для волос",
        url: ROUTES.catalog,
      },
      {
        label: "Уход за полостью рта",
        url: ROUTES.catalog,
      },
    ],
  },
];

export const MENU_ITEMS = [
  {
    label: "Sale",
    url: ROUTES.saleCatalog,
    isSale: true,
  },
  {
    label: "Мужское",
    url: ROUTES.manCatalog,
    items: WOMAN_MENU_ITEMS,
  },
  {
    label: "Женское",
    url: ROUTES.womanCatalog,
    items: WOMAN_MENU_ITEMS,
  },
  {
    label: "Интерьер",
    url: ROUTES.interiorCatalog,
    items: WOMAN_MENU_ITEMS,
  },
  {
    label: "Бренды",
    url: ROUTES.brands,
    items: WOMAN_MENU_ITEMS,
  },
  {
    label: "Магазины",
    url: ROUTES.shops,
    items: WOMAN_MENU_ITEMS,
  },
];

export const FOOTER_MENU_ITEMS = [
  {
    label: "Оплата",
    url: ROUTES.payment,
  },
  {
    label: "Доставка",
    url: ROUTES.delivery,
  },
  {
    label: "Бонусная программа",
    url: ROUTES.bonusProgram,
  },
  {
    label: "Подарочные карты",
    url: ROUTES.sertificateMan,
  },
  {
    label: "Обмен и возврат",
    url: ROUTES.return,
  },
  {
    label: "Рекомендации по уходу",
    url: ROUTES.recommendations,
  },
  /*{
    label: "Карта дизайнера",
    url: ROUTES.design,
  },*/
];

export const FOOTER_SECOND_MENU_ITEMS = [
  {
    label: "Журнал",
    url: ROUTES.magazinePage,
  },
  {
    label: "Магазины",
    url: ROUTES.shops,
  },
  {
    label: "Вакансии",
    url: ROUTES.vacancy,
  },
  {
    label: "О нас",
    url: ROUTES.about,
  },
  {
    label: "Контакты",
    url: ROUTES.contacts,
  },
];
