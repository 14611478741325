import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";

import { ButtonProps } from "./Button.types";

const variants = {
  black: css`
    background-color: ${({ theme }): string => theme.colors.black};
    color: ${({ theme }): string => theme.colors.white};
    transition: 0.3s ease opacity;

    :hover {
      opacity: 0.8;
    }
  `,
  white: css`
    background-color: ${({ theme }): string => theme.colors.white};
    color: ${({ theme }): string => theme.colors.black};
    border: 1px solid ${({ theme }): string => theme.colors.black};
    transition: 0.3s ease background-color, color;

    &.active,
    &:hover {
      background-color: ${({ theme }): string => theme.colors.black};
      color: ${({ theme }): string => theme.colors.white};
    }
  `,
};

const Button = styled.button<ButtonProps>`
  font-size: 10px;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.1em;
  font-weight: 500;
  padding: 6px 16px 4px;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  border: none;

  ${({ variant }): FlattenInterpolation<ThemeProps<DefaultTheme>> =>
    variants[variant || "black"]};
`;

export default Button;
