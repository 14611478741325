import React, { FC } from "react";

import { FOOTER_MENU_ITEMS, FOOTER_SECOND_MENU_ITEMS } from "src/constants";

import {
  FooterMenuWrapper,
  MenuList,
  MenuItem,
  MenuLink,
} from "./FooterMenu.styles";

const FooterMenu: FC = () => {
  return (
    <FooterMenuWrapper>
      <MenuList role="menu">
        {FOOTER_MENU_ITEMS.map(({ label, url }, idx) => (
          <MenuItem key={idx} role="menuitem">
            <MenuLink to={url}>{label}</MenuLink>
          </MenuItem>
        ))}
      </MenuList>
      <MenuList role="menu">
        {FOOTER_SECOND_MENU_ITEMS.map(({ label, url }, idx) => (
          <MenuItem key={idx} role="menuitem">
            <MenuLink to={url}>{label}</MenuLink>
          </MenuItem>
        ))}
      </MenuList>
    </FooterMenuWrapper>
  );
};

export default FooterMenu;
