import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";

import { ButtonVariants } from "src/types";

import { Link } from "../Link";

import { LinkButtonProps } from "./LinkButton.types";

const variants = {
  black: css`
    background-color: ${({ theme }): string => theme.colors.black};
    color: ${({ theme }): string => theme.colors.white};
    transition: 0.3s ease opacity;

    :hover {
      opacity: 0.8;
    }
  `,
  white: css`
    background-color: ${({ theme }): string => theme.colors.white};
    color: ${({ theme }): string => theme.colors.black};
    border: 1px solid ${({ theme }): string => theme.colors.black};
    transition: 0.3s ease background-color, color;

    :hover {
      background-color: ${({ theme }): string => theme.colors.black};
      color: ${({ theme }): string => theme.colors.white};
    }
  `,
};

export const LinkButtonStyles = css<ButtonVariants>`
  font-size: 10px;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.1em;
  font-weight: 500;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  border: none;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 15px 0 10px;

  ${({ variant }): FlattenInterpolation<ThemeProps<DefaultTheme>> =>
    variants[variant || "black"]};
`;

export const LinkButton = styled(Link)<LinkButtonProps>`
  ${LinkButtonStyles};
`;
