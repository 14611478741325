import { API } from "src/constants";

import { Http } from "../http";

import {
  BannersResponse,
  BannerByCodeResponse,
  TopTextBannerResponse,
} from "./main.types";

export const getBanners = (): BannersResponse => Http.get(`${API}/main`);

export const getBannerByCode = (code: string): BannerByCodeResponse =>
  Http.get(`${API}/banner_code?code=${code}`);

export const getTopTextBanner = (): TopTextBannerResponse =>
  Http.get(`${API}/include_area?code=top_text`);

export const subscribeClient = (email: string): TopTextBannerResponse =>
  Http.post(`${API}/subscribe?email=${encodeURIComponent(email)}`);
