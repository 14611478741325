import React, { useState, ChangeEvent, FC } from "react";

import { InputContainer } from "../../MobileCatalogFiltersInner.styles";
import { Input } from "../Input";
import { Search } from "../Search";

import { FilterWithSearchProps } from "./FilterWithSearch.types";

const FilterWithSearch: FC<FilterWithSearchProps> = (props) => {
  const { filter, values, disabled, onChange } = props;

  const [search, setSearch] = useState("");
  const searchedFilter = filter.values.filter(({ name }) =>
    `${name}`.toLowerCase().includes(search.toLowerCase())
  );

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <div key={filter.name}>
      <Search value={search} onChange={handleSearch} />
      <>
        {searchedFilter.map((value) => (
          <InputContainer key={value.id}>
            <Input
              filterValue={value}
              filterType={filter.type}
              filterCode={filter.code}
              onChange={onChange}
              values={values}
              disabled={disabled}
            />
          </InputContainer>
        ))}
      </>
    </div>
  );
};

export default FilterWithSearch;
