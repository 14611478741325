import styled from "styled-components";

import { Link } from "../Link";

export const ProductCategoryWrapper = styled.div`
  @media screen and (max-width: 760px) {
    border-bottom: 1px solid ${({ theme }): string => theme.colors.lightGrey};
  }

  margin-top: 32px;
  margin-bottom: 96px;
  width: 100%;

  @media screen and (max-width: 600px) {
    margin: 22px 0;
    margin-top: 24px;
    margin-bottom: 22px;
  }
`;
export const ProductCategoryCard = styled.div`
  width: 100%;

  @media screen and (max-width: 1200px) {
    &:last-child {
      padding: 0 0 8px 0;
    }
  }

  @media screen and (max-width: 600px) {
    &:last-child {
      padding: 0 0 14px 0;
    }
  }
`;

export const ProductCategoryTextWrapper = styled.div`
  padding: 10px 0 5px 0;

  @media screen and (max-width: 1199px) {
    padding: 10px 0 2px 0;
  }
  @media screen and (max-width: 600px) {
    padding: 8px 0;
  }
`;

export const ProductCategoryMainTitle = styled.div`
  font-family: HelveticaNeueCyr;
  font-weight: 500;
  font-size: 22px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #000000;
  margin-bottom: 11px;

  @media screen and (max-width: 600px) {
    margin-bottom: 14px;
    font-size: 16px;
  }

  @media screen and (max-width: 360px) {
    font-size: 14px;
  }
`;

export const ProductCategoryTitle = styled(Link)`
  inherit: none;
  padding: 3px 0;
  text-decoration: underline;
  font-family: HelveticaNeueCyr;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #000000;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    padding: 2px 0;
  }
`;

export const ProductCategoryText = styled.div`
  text-decoration: none;
  padding: 5px 0;
  color: #000000;
  font-family: HelveticaNeueCyr;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    padding: 5px 0 2px 0;
  }
`;
