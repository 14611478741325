import styled from "styled-components";

export const Container = styled.main`
  position: relative;
  width: 100%;
  height: calc(100% - 160px);
  display: flex;
  flex-direction: column;
  padding: 0 40px;

  @media screen and (max-width: 1150px) {
    height: calc(100% - 70px);
  }

  @media screen and (max-width: 360px) {
    padding: 0 20px;
  }
`;
