import { VariablesState, VariablesTypes, VariablesActions } from "./types";

const initialState: VariablesState = {
  cid: 0,
  new_quantity: 1,
};

const VariablesReducer = (
  state = initialState,
  action: VariablesActions
): VariablesState => {
  switch (action.type) {
    case VariablesTypes.SET_CID: {
      return action.payload;
    }

    case VariablesTypes.SET_QUANTITY: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

export default VariablesReducer;
