import styled from "styled-components";

import { Link } from "src/components/Link";

export const BrandCardWrapper = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 60px;

  &:before {
    content: "";
    position: absolute;
    top: 76px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #000000;
  }

  @media (max-width: 768px) {
    padding-bottom: 20px;
  }

  @media (max-width: 720px) {
    &:before {
      top: 48px;
    }
  }
`;

export const BrandCardHeader = styled.span`
  font-family: Formular, sans-serif;
  font-weight: bold;
  font-size: 90px;
  line-height: 90px;
  margin: 0;
  padding: 0;
  background: #ffffff;
  position: relative;
  padding-right: 30px;

  @media (max-width: 768px) {
    font-size: 72px;
    line-height: 72px;
    padding-right: 25px;
  }

  @media (max-width: 720px) {
    font-size: 48px;
    line-height: 48px;
    padding-right: 15px;
  }
`;

export const BrandCardList = styled.ul`
  width: 100%;
  column-count: 4;
  column-gap: 20px;
  list-style: none;

  @media (max-width: 768px) {
    column-count: 2;
  }

  @media (max-width: 720px) {
    column-count: 1;
  }
`;

export const BrandCardItem = styled.li`
  padding: 10px 0;
  break-inside: avoid;

  &.not-active {
    opacity: 0.3;
  }
`;

export const BrandLink = styled(Link)`
  color: ${({ theme }): string => theme.colors.black};
  font-size: 22px;
  line-height: 41px;
  letter-spacing: 0.01em;

  &:hover {
    color: ${({ theme }): string => theme.colors.grey};
  }

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 22px;
  }
`;
