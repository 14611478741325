import styled from "styled-components";

import { Checkbox, Icons, Link, Flex } from "src/components";

export const HeaderWrapper = styled(Flex)`
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 102px;

  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 50px;
  }
`;

export const BackLink = styled(Link)`
  margin-right: auto;
  width: 100px;
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.black};
  display: none;
  align-items: center;
  font-family: Formular;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  text-transform: capitalize;
  white-space: nowrap;
  padding: 0;

  svg {
    fill: ${({ theme }) => theme.colors.black};
    margin-right: 5px;
    margin-top: -5px;
  }

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 1150px) {
    display: flex;
  }

  @media (max-width: 767px) {
    width: auto;
    margin-bottom: 20px;
    padding: 10px 0;

    span {
      display: none;
    }
  }
`;

export const BackIcon = styled(Icons.Arrow)`
  width: 19px;
  height: 19px;
  transform: rotate(-90deg);
  stroke: ${({ theme }): string => theme.colors.black};
  stroke-width: 2px;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const BackIconMobile = styled(Icons.BackArrow)`
  width: 21px;
  height: 13px;
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
`;

export const Title = styled.h3`
  font-family: Formular;
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.black};
  margin: 0;

  @media (max-width: 1150px) {
    margin: 0 auto 0 -100px;
  }

  @media (max-width: 767px) {
    text-align: center;
    margin: 0 auto;
  }
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  @media (max-width: 1150px) {
    max-width: 300px;
    margin: 0 auto;
  }
`;

export const ListItem = styled.li`
  margin-bottom: 28px;
`;

export const CheckboxWrapper = styled.div`
  margin-bottom: 5px;
`;

export const MainAddress = styled(Checkbox)`
  label {
    font-weight: 500;
    font-size: 15px;
    color: ${({ theme, checked }) =>
      checked ? theme.colors.black : theme.colors.grey};
  }
`;

export const ListItemAddress = styled.div`
  font-family: HelveticaNeueCyr, sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  max-width: 260px;
  margin: 0 0 4px 38px;
`;

export const ListItemAddressTitle = styled.span`
  font-weight: 500;
`;

export const ListItemLink = styled(Link)`
  font-size: 15px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.black};
  margin-left: 38px;

  &:hover {
    text-decoration: none;
  }
`;

export const DeleteItemLink = styled(Link)`
  font-size: 15px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-decoration: underline;
  color: #ff5151;
  margin-left: 38px;

  &:hover {
    text-decoration: none;
  }
`;

export const AddAddressLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  margin-left: 38px;

  &:hover {
    color: ${({ theme }) => theme.colors.grey};
  }

  @media (max-width: 1150px) {
    margin-left: 0;
    justify-content: center;
  }
`;

export const PlusIcon = styled(Icons.Plus)`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  padding: 2px;
  border: 1px solid ${({ theme }): string => theme.colors.black};
  margin-right: 13px;
  margin-bottom: 4px;
`;
