/* eslint-disable max-len */

import React, { FC } from "react";

import { SvgProps } from "src/types";

const Logo: FC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 132 39">
    <path d="M109.4.6v37.8h3.8V15l-.3-5.8h.3l5 29.2h5l5-29.2h.3l-.3 5.8v23.4h3.8V.6h-6.3L120.8 30h-.3L115.7.6h-6.3zM71.6 30.1c0 2.7-1 2.7-3.5 2.7-2.3 0-3.2 0-3.2-2.7V8.9C65 6 66 6 68.1 6c2.4 0 3.5 0 3.5 2.8V30zM67.5 0C61 0 61 5 61 9.2v20.7c0 4.3 0 9.1 6.4 9.1h1.3c6.5 0 6.5-4.8 6.5-9V9.1c0-4.2 0-9.2-6.5-9.2h-1.3zM0 .6v37.8h12.5v-6H3.8V.5H0zm41.5 37.8h3.7V22h7.6v-5.7h-7.5V6.6H54v-6H41.5v37.8zm-22 0H32v-6h-8.6V22H31v-5.7h-7.6V6.6H32v-6H19.6v37.8zm76-22.8c0 2.7-1 2.8-3.3 2.8h-3.4V6.6h3.4c2.2 0 3.3 0 3.3 2.8v6.2zm.8 8c2.6-1.2 2.9-3.4 2.9-6.8V8.2c0-2.6 0-7.4-6.4-7.7h-7.7v37.8h3.7v-14h3.6l3.4 14h4.1l-3.6-14.7z" />
  </svg>
);

Logo.defaultProps = {
  width: "132px",
  height: "39px",
  fill: "black",
};

export default Logo;
