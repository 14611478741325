import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";

import { ROUTES } from "../../constants";
import { BrandsPage } from "../Brands";

import { CatalogListPage } from "./CatalogList";

const BrandPage: FC = () => {
  return (
    <Switch>
      <Route exact path={`${ROUTES.brands}`} component={BrandsPage} />
      <Route component={CatalogListPage} />
    </Switch>
  );
};

export default BrandPage;
