import React, { FC } from "react";

import { Checkbox } from "src/components";

import { Collapse, Scrollbar } from "../CatalogFilters/elements";
import { getLabel } from "../CatalogFilters/elements/Input/Input.utils";

import { Block, InputContainer } from "./CatalogSorting.styles";
import { CatalogSortingProps } from "./CatalogSorting.types";

const CatalogSorting: FC<CatalogSortingProps> = ({
  onChangeSorting,
  sortingItems,
  sortingValue,
  isLoading,
  className,
}) => {
  const checkedValue = sortingValue.value || "";
  return (
    <Block>
      <Collapse title="Сортировка" className={className}>
        <Scrollbar>
          {sortingItems.map((itemSort) => (
            <InputContainer key={itemSort.value}>
              <Checkbox
                name={"sort"}
                label={getLabel(itemSort.label)}
                type="radio"
                value={`${itemSort.value}`}
                checked={checkedValue === itemSort.value}
                onChange={() =>
                  onChangeSorting({
                    label: itemSort.label,
                    value: itemSort.value,
                  })
                }
                disabled={isLoading}
              />
            </InputContainer>
          ))}
        </Scrollbar>
      </Collapse>
    </Block>
  );
};

export default CatalogSorting;
