import React, { FC } from "react";

import { OrderNumber, Title, Container, PaymentLink } from "./styles";
import { OrderCompletedProps } from "./types";

const OrderCompleted: FC<OrderCompletedProps> = ({
  orderNumber,
  paymentLink,
  isRetail,
}) => {
  return (
    <Container>
      {!isRetail && (
        <>
          <Title>
            Спасибо!
            <br />
            Мы получили ваш заказ и совсем скоро свяжемся для подтверждения.
          </Title>
          <OrderNumber>Номер заказа: {orderNumber}</OrderNumber>
        </>
      )}
      {isRetail && (
        <Title>Оформлены заказы на перемещение номер: {orderNumber}</Title>
      )}
      {paymentLink && (
        <PaymentLink target="_blank" href={paymentLink}>
          Оплатить
        </PaymentLink>
      )}
    </Container>
  );
};
export default OrderCompleted;
