import React, { FC, useEffect, createRef } from "react";

import { Container, CustomCarousel } from "./Carousel.styles";
import { CarouselProps } from "./Carousel.types";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
};

let firstClientX: number, clientX: number;

const preventTouch = (e: TouchEvent) => {
  const minValue = 5;
  clientX = e.touches[0].clientX - firstClientX;
  if (Math.abs(clientX) > minValue) {
    e.preventDefault();

    return false;
  }
};

const touchStart = (e: TouchEvent) => {
  firstClientX = e.touches[0].clientX;
};

const Carousel: FC<CarouselProps> = (props) => {
  const { className, children, ...rest } = props;
  const containerRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("touchstart", touchStart);
      containerRef.current.addEventListener("touchmove", preventTouch, {
        passive: false,
      });
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("touchstart", touchStart);
        containerRef.current.removeEventListener("touchmove", preventTouch);
      }
    };
  });

  return (
    <Container ref={containerRef} className={className}>
      <CustomCarousel {...settings} {...rest}>
        {children}
      </CustomCarousel>
    </Container>
  );
};

export default Carousel;
