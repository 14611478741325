import React, { FC, useState, FormEvent, useEffect } from "react";
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "react-redux";

import { setUserData } from "src/actions";
import { fastOrder, getUserData, phoneAuth, setToken } from "src/api";
import { useForm } from "src/hooks";
import {
  getBasketId,
  getCookie,
  sendUserAuthStatusToTagManager,
  sendUserRegistredEventToTagManager,
  sendFastOrderEventToTagManager,
  setBasketId,
} from "src/utils";

import { ApplicationStore, WishlistTypes } from "../../store";

import { ApplyButton, Input } from "./AuthDataConfForm.styles";
import { AuthDataFormConfTypes, UserConfirm } from "./AuthDataConfForm.types";
import { validateUserConfirmValues } from "./AuthDataConfForm.utils";

const AuthDataConfForm: FC<AuthDataFormConfTypes> = ({
  offerId,
  userName,
  setOrderStep,
  setOrderNumber,
  setLoading,
  isLoading,
  products,
  price,
}) => {
  const dispatch = useDispatch();

  const { new_quantity } = useSelector(
    (state: ApplicationStore) => state.variables
  );
  const wishListData = useSelector((state: ApplicationStore) => state.wishlist);
  const favoritesDataString = JSON.stringify(wishListData.wishListNoUuid);

  const useExternal = sessionStorage.getItem("use_external");
  const initialValues: UserConfirm = {
    phone: sessionStorage.getItem("phone") || "",
    sms_code: "",
    use_external: useExternal || "",
  };

  const [cid, setCID] = useState("");

  const { values, onChange, errors, setErrors } = useForm(initialValues);

  useEffect(() => {
    if (!cid) {
      const cidC = getCookie("_ga")?.slice(6);
      if (cidC) {
        setCID(cidC);
      }
    }
  }, [cid]);

  const onSendOrder = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const errorsForm = validateUserConfirmValues(values);

    if (Object.keys(errorsForm).length !== 0) {
      setErrors(errorsForm);
      return;
    }

    setLoading(true);
    phoneAuth({
      sms_code: values.sms_code,
      phone: values.phone,
      use_external: values.use_external,
      basket_uuid: getBasketId() || undefined,
      wishlist: favoritesDataString,
    })
      .then(({ data }) => {
        setToken(data.access_token);
        //dispatch(authorizeUser(data));
        if (data.first_time) {
          sendUserRegistredEventToTagManager();
        }
        getUserData().then(({ data: userData }) => {
          dispatch(setUserData(userData));
          setBasketId(userData.basket_uuid);
          if (userData?.id_1c) {
            sendUserAuthStatusToTagManager({ id_1c: userData.default_phone });
          }
          fastOrder({
            offer_id: offerId,
            name: userName,
            cid,
            quantity: new_quantity,
            phone: values.phone,
          })
            .then(({ data }) => {
              dispatch({ type: WishlistTypes.CLEAR_NO_UUID });
              TagManager.dataLayer({
                dataLayer: {
                  event: "purchase",
                  ecommerce: {
                    purchase: {
                      actionField: {
                        id: data.order_id,
                        affiliation: "fast",
                        revenue: price,
                        shipping: 0,
                        coupon: "",
                      },
                      products: products.map(
                        ({
                          name,
                          id,
                          new_price,
                          old_price,
                          brand,
                          category,
                          size,
                          quantity,
                        }) => {
                          return {
                            name,
                            id,
                            brand,
                            category,
                            size,
                            quantity,
                            price: new_price || old_price,
                            currency: "RUB",
                          };
                        }
                      ),
                    },
                  },
                },
              });

              sendFastOrderEventToTagManager();
              setLoading(false);
              setOrderNumber(data.order_id);
              setOrderStep(2);
            })
            .catch((error) => {
              setErrors(error.response.data.messages);
              setLoading(false);
            });
        });
      })
      .catch((error) => {
        setErrors(error.response.data.messages);
        setLoading(false);
      });
  };

  return (
    <form onSubmit={onSendOrder}>
      <Input
        type="text"
        name="sms_code"
        aria-label="Пароль из смс"
        placeholder={errors.sms_code || "Пароль из смс"}
        value={values.sms_code}
        onChange={onChange}
        hasError={!!errors.sms_code}
        mask=""
        disabled={isLoading}
      />
      <ApplyButton>ПОДТВЕРДИТЬ</ApplyButton>
    </form>
  );
};

export default AuthDataConfForm;
