import React, { FC, FormEventHandler, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Redirect, useHistory } from "react-router-dom";

import { setUserAddresses } from "src/actions";
import { addAddress, updateAddress } from "src/api";
import { Checkbox, SearchAddress } from "src/components";
import { ROUTES } from "src/constants";
import { useForm } from "src/hooks";
import { ApplicationStore } from "src/store";
import { UserAddress } from "src/types";
import {
  getClearFields,
  getCurrentAddress,
  getErrors,
} from "src/utils/address";

import {
  Title,
  Form,
  Wrapper,
  Headline,
  ButtonGroup,
  FormButton,
  CheckboxRow,
  MainAddress,
  HeaderWrapper,
  BackLink,
  BackIcon,
  BackIconMobile,
} from "./EditAddress.styles";

const EditAddress: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { id: incomingIdString } = useParams<{ id: string }>();

  const user = useSelector((state: ApplicationStore) => state.user);
  const [isNew, address] = getCurrentAddress(
    incomingIdString,
    user.userAddresses
  );

  const isMainAddress = address?.main_address;

  const [isLoading, setLoading] = useState(false);

  const { values, errors, setErrors, setValues } = useForm({
    city: address?.city || "",
    street: address?.street || "",
    house: address?.house || "",
    flat: address?.flat || "",
    zip_code: address?.zip_code || "",
    main_address: address?.main_address || false,
  });

  const handleSaveClick: FormEventHandler = (e) => {
    e.preventDefault();

    if (!values.city || !values.street || !values.house) {
      setErrors({
        city: values.city ? "" : "Заполните поле",
        street: values.street ? "" : "Заполните поле",
        house: values.house ? "" : "Заполните поле",
      });
      return;
    }

    async function addNewAddress(
      addressPayload: Omit<UserAddress, "id" | "main_address">
    ) {
      return await addAddress(addressPayload);
    }

    async function editAddress(
      addressPayload: Omit<UserAddress, "id" | "main_address">
    ) {
      if (!address) return;
      return await updateAddress(address.id, addressPayload);
    }

    const send = isNew ? addNewAddress : editAddress;
    setLoading(true);
    send(values)
      .then((addresses) => {
        dispatch(setUserAddresses(addresses?.data || []));
        history.push(ROUTES.myAddresses);
      })
      .catch((error) => {
        const errors = getErrors(error);
        setErrors(errors);
        setValues({ ...values, ...getClearFields(errors) });
      })
      .finally(() => setLoading(false));
  };

  const [query, setQuery] = useState("");

  useEffect(() => {
    if (values.zip_code) {
      if (!values.house) {
        setValues({ ...values, zip_code: "" });
      }
    }
    if (values.flat) {
      if (!values.house) {
        setValues({ ...values, flat: "" });
      }
    }
    if (values.house) {
      if (!values.street) {
        setValues({ ...values, house: "" });
      }
    }
    if (values.street) {
      if (!values.city) {
        setValues({ ...values, street: "" });
      }
    }
  }, [values]);

  useEffect(() => {
    setQuery(
      `${values.city} ${values.street} ${values.house} ${values.flat}`.trim()
    );
    setErrors({});
  }, [values]);

  if (!(address || isNew)) {
    return <Redirect to={ROUTES.myAddresses} />;
  }

  const addressFields = [
    { value: values.city, type: "c", error: !!errors.city },
    {
      value: values.street,
      type: "s",
      disabled: !values.city,
      error: !!errors.street,
    },
    {
      value: values.house,
      type: "h",
      disabled: !values.street,
      error: !!errors.house,
    },
    { value: values.flat, type: "f", disabled: !values.house },
    { value: values.zip_code, type: "z", disabled: !values.house },
  ];

  return (
    <>
      <HeaderWrapper>
        <BackLink to={ROUTES.myAddresses}>
          <BackIcon />
          <BackIconMobile />
          <span>Назад</span>
        </BackLink>
        <Title>Мои адреса</Title>
      </HeaderWrapper>

      <Headline>
        {isNew ? "Добавить" : "Редактировать"} адрес
        {isMainAddress && <MainAddress>(Основной адрес)</MainAddress>}
      </Headline>

      {addressFields.map((field, index) => (
        <Wrapper>
          <SearchAddress
            key={index}
            setValues={setValues}
            query={query}
            value={field.value}
            type={field.type as "c" | "s" | "h" | "f" | "z"}
            disabled={field.disabled}
            error={field.error}
          />
        </Wrapper>
      ))}

      <Form onSubmit={handleSaveClick}>
        {(isNew || !isMainAddress) && (
          <CheckboxRow>
            <Checkbox
              name="main_address"
              label="Сделать основным адресом"
              type="checkbox"
              checked={values.main_address}
              onChange={() => {
                setValues({ main_address: !values.main_address });
              }}
              disabled={isLoading}
            />
          </CheckboxRow>
        )}

        <ButtonGroup>
          <FormButton
            variant={"black"}
            onClick={handleSaveClick}
            disabled={isLoading}
            type="submit"
          >
            {isNew ? "Добавить" : "Сохранить"}
          </FormButton>
        </ButtonGroup>
      </Form>
    </>
  );
};
export default EditAddress;
