import React, { FC } from "react";

import { ROUTES } from "src/constants";

import { Container, Title, Description, Skip, Form } from "./UserInfo.styles";

const UserInfoPage: FC = () => {
  return (
    <Container>
      <Title>Добро пожаловать!</Title>
      <Description>
        Расскажите немного о себе чтобы получить доступ к закрытым акциями
        мероприятиям
      </Description>
      <Form />
      <Skip to={ROUTES.home}>Пропустить этот шаг</Skip>
    </Container>
  );
};

export default UserInfoPage;
