import { stringify, ParsedQuery } from "query-string";

import { stringifyConfig } from "src/constants";
import { MenuItem, SubMenu, SubMenuItem } from "src/types";

const getChildTitle = (
  pathname: string,
  subMenu: SubMenu | SubMenuItem[] | null
): string => {
  for (const element of subMenu || []) {
    if (element.url === pathname) {
      return element.label;
    } else if (pathname.includes(element.url) && element.items) {
      return getChildTitle(pathname, element.items);
    }
  }

  return "";
};

export const getTitle = (pathname: string, menu: MenuItem[]): string => {
  for (const element of menu) {
    if (element.link === pathname) {
      return element.label;
    } else if (pathname.includes(element.link)) {
      return getChildTitle(pathname, element.items);
    }
  }
  return "";
};

const getChildLink = (
  code: string,
  subMenu: SubMenu | SubMenuItem[] | null
): string => {
  for (const element of subMenu || []) {
    if (element.url.includes(code)) {
      return element.url;
    } else {
      const link = getChildLink(code, element.items);
      if (link) return link;
    }
  }

  return "";
};

export const getLinkFromMenuItem = (
  code: string,
  genderCode: string,
  menu: MenuItem[]
): string => {
  for (const element of menu) {
    if (element.link.includes(genderCode)) {
      const link = getChildLink(code, element.items);
      if (link) return link;
    }
  }
  return "";
};

export const getBrandLink = (
  code: string,
  menu: MenuItem[]
): string | undefined => {
  return menu
    .find((item) => item.link === "/brands")
    ?.items.find((item) => item.url.includes(code))?.url;
};

export const getFilterString = (params: ParsedQuery): string => {
  const filters = { ...params };

  Object.keys(filters).forEach((key) => {
    if (!key.includes("filter")) {
      delete filters[key];
    }
  });
  if (filters.page === "1") {
    delete filters.page;
  }
  const filterString = stringify(filters, stringifyConfig);

  return !!filterString ? `?${filterString}` : "";
};

export const getOtherQuery = (params: ParsedQuery): ParsedQuery => {
  const filters = { ...params };
  Object.keys(filters).forEach((key) => {
    if (
      key.includes("filter") ||
      key.includes("sort") ||
      key.includes("per_page")
    ) {
      delete filters[key];
    }
  });

  return filters;
};
