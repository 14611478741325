export const getTodayByString = (): string => {
  const today = new Date();
  const isoDate = today.toISOString();
  return isoDate.substring(0, isoDate.indexOf("T"));
};

export const formatDateTime = (date: string): string => {
  return new Date(date).toLocaleDateString("ru-RU", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};

export const formatDate = (date: string): string => {
  return new Date(date).toLocaleDateString("ru-RU", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

export const formatTime = (date: string): string => {
  return new Date(date).toLocaleTimeString("ru-RU", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};
