import React, { FC } from "react";
import styled from "styled-components";

import { convertImageUrl, parseImages } from "src/utils";

import { Box } from "../Box";
import { Carousel } from "../Carousel";
import {
  Image,
  ImageMobile,
  ImageLink,
} from "../PromotionalCard/PromotionalCard.styles";

import CarouselMainPageBannerNextArrow from "./CarouselMainPageBannerNextArrow";
import CarouselMainPageBannerPrevArrow from "./CarouselMainPageBannerPrevArrow";
import { Container } from "./MainPageBannerSlider.styles";
import { MainPageBannerSliderProps } from "./MainPageBannerSlider.types";

export const SliderBanner = styled(Box)``;

const MainPageBannerSlider: FC<MainPageBannerSliderProps> = ({
  slides,
  timer,
  type,
}) => {
  const settingsProduct = {
    dots: false,
    arrows: true,
    nextArrow: <CarouselMainPageBannerNextArrow />,
    prevArrow: <CarouselMainPageBannerPrevArrow />,
    slidesToScroll: 1,
    infinite: true,
    slidesToShow: 1,
    swipeToSlide: true,
    autoplay: timer !== null,
    autoplaySpeed: timer !== null ? timer * 1000 : undefined,
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 901,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1,
          swipeToSlide: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1,
          swipeToSlide: false,
        },
      },
    ],
  };

  return (
    <Container bannerType={type} isTopBanner={false}>
      <Carousel {...settingsProduct}>
        {slides.map((item) => {
          const images: string[] = parseImages(item.image);
          const mobileImages: string[] = parseImages(item.image_mobile);

          return (
            <ImageLink key={item.id} compact={""} to={item.link || "/"}>
              <Image
                loading="lazy"
                src={convertImageUrl(images[0], 3840, 3840)}
                alt={item.name || ""}
                isHide={!!item.image_mobile}
              />
              <ImageMobile
                loading="lazy"
                src={convertImageUrl(mobileImages[0], 1500, 1500)}
                alt={item.name || ""}
                isShow={!!item.image_mobile}
              />
            </ImageLink>
          );
        })}
      </Carousel>
    </Container>
  );
};

export default MainPageBannerSlider;
