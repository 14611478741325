import React, { FC } from "react";

import { Container, Input, SearchIcon } from "./Search.styles";
import { SearchProps } from "./Search.types";

const Search: FC<SearchProps> = (props) => {
  const { value, onChange } = props;

  return (
    <Container>
      <SearchIcon />
      <Input value={value} onChange={onChange} placeholder="Поиск" />
    </Container>
  );
};

export default Search;
