import styled from "styled-components";

export const Container = styled.main`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 40px;
  max-width: 1180px;
  margin: auto;

  @media screen and (max-width: 768px) {
    padding: 20px;
    margin: 0;
  }
`;
export const PaymentPage = styled.div`
  text-align: center;
  h1 {
    font-family: "Formular";
    font-style: normal;
    font-weight: 500;
    font-size: 62px;
    line-height: 40px;
    letter-spacing: 0.01em;
    margin-bottom: 24px;
    @media screen and (max-width: 750px) {
      font-size: 27px;
      line-height: 26px;
      margin-bottom: 13px;
    }
  }
  p {
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    width: 100%;
    max-width: 531px;
    margin: 0 auto 13px;
    a {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      font-weight: 450;
      margin-bottom: 0;
    }
  }
  .bold {
    width: 100%;
    max-width: 409px;
    font-weight: 450;
    margin: 0 auto 14px;
  }
  a {
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    font-weight: 450;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
    text-decoration: none;
    margin-bottom: 86px;
  }
  .tel {
    display: block;
    margin-bottom: 86px;
    @media screen and (max-width: 750px) {
      margin-bottom: 55px;
    }
  }
  .p--gray {
    color: rgba(0, 0, 0, 0.7);
    margin: 24px 0 30px;
  }
  .p-margin {
    margin: 24px 0 30px;
  }

  .w-700 {
    max-width: 700px;
  }
  .row-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 680px;
    width: 100%;
    img {
      max-height: 29px;
      margin-bottom: 10px;
    }
  }
`;
export const Title = styled.h1`
  font-size: 34px;
  line-height: 42px;
  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    font-size: 26px;
    line-height: 32px;
  }
`;

export const Text = styled.p`
  font-family: Rubik;
  font-size: 14px;
  line-height: 18px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }

  .recommendations-table {
    display: table;
    padding-left: 30px;
  }
  .recommendations-cell {
    display: table-cell;
    vertical-align: middle;
    min-height: 75px;
  }
  .recommendations-cell b {
    font-weight: bold;
  }
  .recommendations-cell-first {
    width: 10%;
    text-align: center;
  }
  .recommendations-cell img {
    max-width: 75px;
  }
  .recommendations-row {
    display: table-row;
  }
`;
