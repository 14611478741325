import styled from "styled-components";

import { Icons, Box, BorderlessInput } from "src/components";

export const Container = styled(Box)`
  width: 100%;
  margin-bottom: 24px;
`;

export const Input = styled(BorderlessInput)`
  font-size: 15px;
  border-color: ${({ theme }): string => theme.colors.lightGrey};
  padding-left: 34px;
`;

export const SearchIcon = styled(Icons.Search)`
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0;
  top: 9px;
`;
