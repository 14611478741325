import styled from "styled-components";

export const Container = styled.main`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 40px;
  max-width: 1180px;
  margin: auto;

  @media screen and (max-width: 768px) {
    padding: 20px;
    margin: 0;
  }
`;

export const Title = styled.h1`
  font-size: 34px;
  line-height: 42px;
  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    font-size: 26px;
    line-height: 32px;
  }
`;

export const Text = styled.p`
  font-family: Rubik;
  font-size: 14px;
  line-height: 18px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }

  .recommendations-table {
    display: table;
    padding-left: 30px;
  }
  .recommendations-cell {
    display: table-cell;
    vertical-align: middle;
    min-height: 75px;
  }
  .recommendations-cell b {
    font-weight: bold;
  }
  .recommendations-cell-first {
    width: 10%;
    text-align: center;
  }
  .recommendations-cell img {
    max-width: 75px;
  }
  .recommendations-row {
    display: table-row;
  }
`;
