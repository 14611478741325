import styled from "styled-components";

import { Box } from "../Box";
import { IconButton } from "../IconButton";
import { Icons } from "../Icons";
import { Modal } from "../Modal";

export const ModalContainer = styled(Modal)`
  background-color: ${({ theme }): string => theme.colors.white};
  max-width: 270px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  animation: showFilters 0.4s ease-out;
  padding-bottom: 32px;

  @keyframes showFilters {
    0%,
    50% {
      transform: translateX(200%);
    }
    100% {
      transform: none;
    }
  }
`;

export const CloseButton = styled(IconButton)`
  padding: 20px 14px;

  :hover {
    opacity: 0.5;
  }
`;

export const CloseIcon = styled(Icons.Close)`
  width: 14px;
  height: 14px;
`;

export const Container = styled(Box)`
  width: 100%;
  margin-top: 15px;
`;
