/* eslint-disable max-len */

import React, { FC } from "react";

import { SvgProps } from "src/types";

const Plus: FC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 1024 1024">
    <path d="M482 152h60c5.3 0 8 2.7 8 8v704c0 5.3-2.7 8-8 8h-60c-5.3 0-8-2.7-8-8V160c0-5.3 2.7-8 8-8z" />
    <path d="M176 474h672c5.3 0 8 2.7 8 8v60c0 5.3-2.7 8-8 8H176c-5.3 0-8-2.7-8-8v-60c0-5.3 2.7-8 8-8z" />
  </svg>
);

Plus.defaultProps = {
  width: "20px",
  height: "20px",
  fill: "black",
};

export default Plus;
