import styled from "styled-components";

import { Box, Flex } from "src/components";

import { isHiddenProps } from "./ShareFavorites.types";

export const Title = styled.h1`
  font-family: HelveticaNeueCyr, sans-serif;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${({ theme }): string => theme.colors.black};
  margin: 0;
`;

const HeaderItemContainer = styled(Flex)`
  width: 100%;
  align-items: center;
`;

export const TitleContainer = styled(HeaderItemContainer)<isHiddenProps>`
  justify-content: center;
  flex-direction: column;
  margin-bottom: 32px;

  @media screen and (max-width: 768px) {
    padding-top: 60px;
    margin-bottom: 20px;
    display: ${({ isHidden }): string => (isHidden ? "block" : "none")};
  }
`;

export const Container = styled(Box)`
  width: 100%;
  padding: 36px 40px 180px;

  @media screen and (max-width: 768px) {
    padding: 14px 20px 100px;
  }

  @media screen and (max-width: 600px) {
    padding: 6px 15px 80px;
  }
`;
