import styled from "styled-components";

import { Box } from "../Box";

export const Container = styled(Box)`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const Tag = styled.button`
  position: relative;
  padding: 8px;
  border: none;
  font-size: 10px;
  color: ${({ theme }): string => theme.colors.blackOpacity};
  background-color: ${({ theme }): string => theme.colors.veryLightGray};
  display: flex;
  margin: 2.5px;
  cursor: pointer;
  align-items: center;

  span {
    padding-top: 2px;
  }

  svg {
    width: 8px;
    height: 8px;
    fill: ${({ theme }): string => theme.colors.black};
    margin-right: 6px;
  }
`;
