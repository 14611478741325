import styled, { css } from "styled-components";

import { Button } from "../Button";
import { Icons } from "../Icons";

import { FavoriteButtonProps } from "./FavoriteButton.types";

export const FavoriteButton = styled(Button)<FavoriteButtonProps>`
  height: 70px;
  width: 70px;
  min-width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: relative;
  transition: all 0.3s;
  background: transparent !important;
  border: none;

  @keyframes show_help {
    0% {
      opacity: 1;
    }
    70% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }

  ${({ isCatalog }) =>
    !!isCatalog &&
    css`
      position: absolute;
      top: 17px;
      right: 19px;
      z-index: 7;
      border: none;
      background-color: transparent;
      visibility: hidden;
      opacity: 0;
      transition: 0.3s ease;
      width: 20px;
      height: 20px;
      min-width: 20px;
    `};

  ${({ isHelpVisible }) =>
    !!isHelpVisible &&
    css`
      &::after {
        animation: show_help 3s ease-out forwards;
        padding: 6px 0;
        line-height: 15px;
        content: "Удалено из избранного";
        position: absolute;
        right: 0;
        width: 175px;
        top: -10px;
        background-color: ${({ theme }): string => theme.colors.veryLightGray};
        color: ${({ theme }): string => theme.colors.black};
        pointer-events: none;
      }
    `};

  ${(props) =>
    !!props.isFavourite &&
    !props.isCatalog &&
    css`
      position: relative;
    `};

  ${({ isFavourite }) =>
    !!isFavourite &&
    css`
      &::after {
        display: none;
      }

      svg {
        fill: ${({ theme }): string => theme.colors.black};
      }
    `};

  ${(props) =>
    !!props.isFavourite &&
    !!props.isHelpVisible &&
    css`
      &::before {
        animation: show_help 3s ease-out forwards;
        padding: 6px 0;
        line-height: 15px;
        content: "Добавлено в избранное";
        position: absolute;
        right: 0;
        width: 175px;
        top: -10px;
        background-color: ${({ theme }): string => theme.colors.veryLightGray};
        color: ${({ theme }): string => theme.colors.black};
        pointer-events: none;
      }
    `};

  @media screen and (min-width: 761px) {
    :hover svg {
      fill: ${({ theme }): string => theme.colors.black};
      stroke-width: 0;
    }
  }

  @media screen and (max-width: 760px) {
    height: 39px;
    min-width: 60px;
    width: 61px;
    position: absolute;
    right: -9px;
    top: 0px;
  }
`;

export const NewFavoriteButton = styled(Button)<FavoriteButtonProps>`
  height: 70px;
  width: 70px;
  min-width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: relative;
  transition: all 0.3s;
  background: transparent !important;
  border: none;
  visibility: visible;
  opacity: 1;

  ${({ isCatalog }) =>
    !!isCatalog &&
    css`
      position: absolute;
      top: 17px;
      right: 19px;
      z-index: 7;
      border: none;
      background-color: transparent;
      transition: 0.3s ease;
      width: 20px;
      height: 20px;
      min-width: 20px;
    `};

  ${(props) =>
    !!props.isFavourite &&
    !props.isCatalog &&
    css`
      position: relative;
    `};

  ${({ isFavourite }) =>
    !!isFavourite &&
    css`
      &::after {
        display: none;
      }

      svg {
        fill: ${({ theme }): string => theme.colors.black};
      }
    `};

  ${(props) =>
    !!props.isFavourite &&
    !!props.isHelpVisible &&
    css`
      &::before {
        animation: show_help 3s ease-out forwards;
        padding: 6px 0;
        line-height: 15px;
        content: "Добавлено в избранное";
        position: absolute;
        right: 0;
        width: 175px;
        top: -10px;
        background-color: ${({ theme }): string => theme.colors.veryLightGray};
        color: ${({ theme }): string => theme.colors.black};
        pointer-events: none;
      }
    `};

  @media screen and (min-width: 761px) {
    :hover svg {
      fill: ${({ theme }): string => theme.colors.black};
      stroke-width: 0;
    }
  }

  @media screen and (max-width: 760px) {
    height: 39px;
    min-width: 60px;
    width: 61px;
    position: absolute;
    right: -9px;
    top: 0px;
  }
`;

export const HeartIcon = styled(Icons.Heart)`
  width: 31px;
  height: 27px;
  stroke: ${({ theme }): string => theme.colors.black};
  stroke-width: 1px;
`;
