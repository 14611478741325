import React, { FC } from "react";

import { ROUTES } from "src/constants";
import { formatCurrency, formatDateTime } from "src/utils";

import {
  RowWrapper,
  Status,
  OrderNumber,
  Price,
  OrderDate,
  MoreLink,
} from "./OrderRow.styles";
import { OrderRowProps } from "./OrderRow.types";

const OrderRow: FC<OrderRowProps> = ({ orderId, orderDate, price, status }) => {
  return (
    <RowWrapper>
      <Status>{status}</Status>
      <OrderNumber>№ {orderId}</OrderNumber>
      <Price>{formatCurrency(price)}</Price>
      <OrderDate>{formatDateTime(orderDate)}</OrderDate>
      <MoreLink to={`${ROUTES.myOrder}/${orderId}`}>Детали</MoreLink>
    </RowWrapper>
  );
};

export default OrderRow;
