import styled from "styled-components";

import { BorderlessInput } from "../BorderlessInput";
import { Button } from "../Button";

export const Form = styled.form`
  width: 100%;
  padding: 0;
  margin: 0;
`;

export const RegistrationButton = styled(Button)`
  height: 50px;
  margin-top: 60px;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const Input = styled(BorderlessInput)`
  width: 100%;

  :not(:first-of-type) {
    margin-top: 40px;
  }
`;
