import styled from "styled-components";

import { Link, Flex, Icons } from "src/components";

export const Container = styled.div`
  padding: 100px 40px 111px;
  color: ${({ theme }) => theme.colors.black};

  @media (max-width: 1150px) {
    padding: 109px 28px 60px;
  }

  @media (max-width: 767px) {
    padding: 12px 15px 20px;
  }
`;

export const HeaderWrapper = styled(Flex)`
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 60px;

  @media (max-width: 1440px) {
    margin-bottom: 70px;
  }

  @media (max-width: 1199px) {
    margin-bottom: 60px;
  }

  @media (max-width: 767px) {
    margin-bottom: 57px;
    padding-top: 20px;
    flex-direction: column;
    position: static;
  }
`;

export const Title = styled.div`
  margin: 0 auto 0 -100px;

  @media (max-width: 767px) {
    margin: 0;
    padding-top: 4px;
  }
`;

export const OrderNumber = styled.h2`
  font-family: Formular;
  font-weight: 500;
  font-size: 25px;
  line-height: 28px;
  letter-spacing: 0.01em;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  margin: 0;

  @media screen and (min-width: 768px) {
    margin: 0 0 10px;
    font-size: 34px;
    line-height: 40px;
  }
`;

export const OrderStatus = styled.div`
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
  margin: 0;

  @media screen and (max-width: 767px) {
    text-align: left;
  }
`;

export const OrderList = styled.div`
  margin-bottom: 8px;

  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
  @media (min-width: 1200px) {
    flex-grow: 1;
  }
`;

export const OrderDataContainer = styled(Flex)`
  justify-content: space-between;
  padding: 25px 35px 40px;
  border-top: 2px dotted ${({ theme }) => theme.colors.lightGrey};

  letter-spacing: 0.01em;

  @media (max-width: 767px) {
    border-top: 0;
    flex-wrap: wrap;
    padding: 0 0 16px;
  }

  @media (min-width: 1200px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-top: none;
    border-bottom: none;
    padding: 0;
    width: 350px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    padding: 25px 0 40px;
  }

  @media (min-width: 1440px) {
    width: 400px;
  }

  @media (min-width: 1920px) {
    width: 450px;
  }
`;

export const OrderParamsContainer = styled(Flex)`
  width: 68%;
  justify-content: space-between;
  align-content: flex-start;

  @media (max-width: 1199px) {
    flex-wrap: wrap;
    width: 65%;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 0;
  }

  @media (min-width: 1200px) {
    display: flex;
    flex-direction: column;
    border-bottom: 2px dotted ${({ theme }) => theme.colors.lightGrey};
    width: 100%;
  }
`;

export const ReturnButton = styled.button`
  border: none;
  background: none;
  display: flex;
  column-gap: 6px;
  padding: 0;
  width: 100%;

  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.1em;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #000000;

  :hover {
    text-decoration: none;
    cursor: pointer;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    justify-content: right;
  }
`;

export const PriceReturnWrapper = styled.div`
  @media (max-width: 767px) {
    width: 100%;
  }

  @media (min-width: 1200px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ReturnContainer = styled.div`
  display: flex;
  margin-top: 16px;

  @media (min-width: 768px) and (max-width: 1199px) {
    width: 100%;
    text-align: right;
  }
`;

export const OrderPriceContainer = styled(Flex)`
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  border-bottom: 2px dotted ${({ theme }) => theme.colors.lightGrey};
  padding-bottom: 22px;

  @media (max-width: 767px) {
    border-top: 2px dotted ${({ theme }) => theme.colors.lightGrey};
    padding-top: 16px;
  }
`;

export const ParamsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
`;

export const ParamsItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0 22px;

  @media (max-width: 1199px) {
    margin: 0 0 24px;
  }

  @media (max-width: 767px) {
    margin: 0 0 17px;
  }
`;

export const ParamsTitle = styled.span`
  flex: 150px 0 0;
  font-size: 15px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.grey};
`;

export const ParamsValue = styled.span`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  max-width: 313px;
`;

export const ParamsTimeValue = styled.span`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  max-width: 313px;
  margin-left: 5px;
`;

export const PriceItem = styled.div`
  font-size: 14px;
  line-height: 16px;
  margin: 0 0 12px;
  width: 100%;

  @media screen and (min-width: 1200px) {
    &:first-of-type {
      margin: 22px 0 12px;
    }
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    text-align: right;
  }
`;
export const Discount = styled.span`
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  margin-left: 5px;
`;
export const PriceTotal = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  width: 100%;
  text-align: left;

  @media (min-width: 768px) and (max-width: 1199px) {
    text-align: right;
  }

  @media (min-width: 1200px) {
    text-align: left;
  }
`;

export const SdekDeliveryWrapper = styled.div`
  padding: 22px 0 20px;
  border-bottom: 2px dotted ${({ theme }) => theme.colors.lightGrey};
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 1199px) {
    padding: 0 0 22px;
    margin-bottom: 22px;
  }

  @media (max-width: 767px) {
    border-top: 2px dotted ${({ theme }) => theme.colors.lightGrey};
    border-bottom: none;
    padding: 16px 0 16px;
    margin-bottom: 0;
  }
`;

export const SdekTrackLink = styled.a`
  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-decoration-line: underline;
  text-transform: uppercase;

  color: ${({ theme }) => theme.colors.black};

  @media (max-width: 1199px) {
    max-width: 270px;
    text-align: right;
  }

  @media (max-width: 768px) {
    max-width: none;
    text-align: left;
  }
`;

export const SdekItem = styled.div`
  display: flex;
`;

export const SdekTitle = styled.div`
  flex: 150px 0 0;
  font-size: 15px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.grey};
`;

export const SdekValue = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  max-width: 313px;
  margin-right: 5px;
`;

export const BackIcon = styled(Icons.Arrow)`
  width: 19px;
  height: 19px;
  transform: rotate(-90deg);
  stroke: ${({ theme }): string => theme.colors.black};
  stroke-width: 2px;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const BackIconMobile = styled(Icons.BackArrow)`
  width: 21px;
  height: 13px;
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
`;

export const BackLink = styled(Link)`
  margin-right: auto;
  width: 100px;
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.black};
  display: none;
  align-items: center;
  line-height: 40px;
  text-transform: capitalize;
  white-space: nowrap;
  padding: 0;
  display: flex;

  svg {
    fill: ${({ theme }) => theme.colors.black};
    margin-right: 5px;
    margin-top: -5px;
  }

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 767px) {
    width: auto;
    margin-bottom: 16px;
    padding: 10px 0;

    span {
      display: none;
    }
  }
`;

export const FlexWrapper = styled.div`
  @media (min-width: 1200px) {
    display: flex;
    justify-content: space-between;
    column-gap: 86px;
  }
  @media (min-width: 1440px) {
    column-gap: 136px;
  }
  @media (min-width: 1200px) {
    column-gap: 200px;
  }
`;
