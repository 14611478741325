import styled from "styled-components";

export const MobileSliderDot = styled.div<{ active: boolean }>`
  width: 6px;
  height: 6px;
  margin: 0 3px;
  border-radius: 50%;
  background-color: #ccc;
  transition: background-color 0.5s ease, border 0.5s ease;
  ${({ active }) =>
    active &&
    `
        background-color: #fff;
        border: 1px solid #ccc;
        `}
`;

export const MobileSliderDots = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 15px;
  width: 100%;
`;

export const ImageSlide = styled.img`
  width: 100%;
  height: auto;
`;

export const ImageSlideContainer = styled.div`
  flex: 0 0 auto;
  width: 100%;
`;

export const SliderWrapper = styled.div`
  display: flex;
  transition: transform 0.3s ease-out;
`;
