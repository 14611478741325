import TagManager from "react-gtm-module";

import {
  SendUserAuthStatusToTagManagerProps,
  TagManagerAddToCheckoutDataProps,
} from "src/types";

export const sendTagManagerFavoritesData = (
  eventName: string,
  vendorCode: string,
  productId: number
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      vendorcode: vendorCode,
      ecommerce: {
        remove: {
          products: [
            {
              id: productId,
            },
          ],
        },
      },
    },
  });
};

export const sendTagManagerAddToCheckoutData = (
  props: TagManagerAddToCheckoutDataProps
): void => {
  if (props.is_retail) return;
  TagManager.dataLayer({
    dataLayer: {
      addtocart: props.productId,
      vendorcode: props.vendorCode,
      event: "add-to-cart",
      ecommerce: {
        currencyCode: "RUB",
        add: {
          products: [
            {
              name: props.productName,
              id: props.productId,
              price: props.productPrice,
              brand: props.productBrand,
              category: props.category,
              quantity: 1,
            },
          ],
        },
      },
    },
  });
};

export const sendUserAuthStatusToTagManager = (
  props: SendUserAuthStatusToTagManagerProps
): void => {
  TagManager.dataLayer({
    dataLayer: {
      customerId1c: props.id_1c,
    },
  });

  TagManager.dataLayer({
    dataLayer: {
      event: "client-auth",
    },
  });
};

export const sendUserRegistredEventToTagManager = (): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: "user-registred",
    },
  });
};

export const sendFastOrderEventToTagManager = (): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: "fast-order",
    },
  });
};

export const sendYaPayOrderEventToTagManager = (): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: "ya-pay-order",
    },
  });
};
