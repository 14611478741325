import React, { FC, useMemo } from "react";

import { convertImageUrl, parseImages } from "src/utils";

import {
  Wrapper,
  MenuList,
  ListTitleLink,
  ListTitle,
  ListItem,
  ItemLink,
  ImageLink,
  Image,
  MenuWrapper,
  MenuFlex,
  MenuGrid,
  LinkWrapper,
  LinkAll,
} from "./GlobalMenu.styles";
import { GlobalMenuProps } from "./GlobalMenu.types";

const GlobalMenu: FC<GlobalMenuProps> = (props) => {
  const { banner, subMenu, onClose } = props;
  const { url, gallery, name, preview_text } = banner || {};

  const images: string[] = useMemo(() => parseImages(gallery), [gallery]);

  return (
    <Wrapper>
      {/* TODO пока закоментил, проверяем другой вариант */}
      {/* <MasonryWrapper
        breakpointCols={{
          default: 5,
          1440: 4,
        }}
        className=""
      > */}
      <MenuWrapper>
        {subMenu &&
          subMenu.map(({ label, url, items }, i) => (
            <MenuList key={label + url + i} role="menu">
              {url ? (
                <ListTitleLink to={url} onClick={onClose}>
                  {label}
                </ListTitleLink>
              ) : (
                <ListTitle>{label}</ListTitle>
              )}
              {!!items &&
                (label === "Топ бренды" ? (
                  <MenuFlex>
                    <MenuGrid>
                      {items?.map(({ label, url }) => (
                        <ListItem key={label + url} role="menuitem">
                          <ItemLink to={url} onClick={onClose}>
                            {label}
                          </ItemLink>
                        </ListItem>
                      ))}
                    </MenuGrid>
                    <LinkWrapper>
                      <LinkAll to={"/brands"}>Все бренды</LinkAll>
                    </LinkWrapper>
                  </MenuFlex>
                ) : (
                  <>
                    {items?.map(({ label, url }) => (
                      <ListItem key={label + url} role="menuitem">
                        <ItemLink to={url} onClick={onClose}>
                          {label}
                        </ItemLink>
                      </ListItem>
                    ))}
                  </>
                ))}
            </MenuList>
          ))}
        {/* </MasonryWrapper> */}
      </MenuWrapper>

      {!!banner && !!banner.id && (
        <ImageLink to={url || "/"}>
          <Image
            src={convertImageUrl(images[0], 1200, 1200)}
            alt={`${name}. ${preview_text}`}
          />
        </ImageLink>
      )}
    </Wrapper>
  );
};

export default GlobalMenu;
