import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  colors: {
    black: "#000000",
    blackOpacity: "rgba(0, 0, 0, 0.5)",
    black30: "rgba(0, 0, 0, 0.3)",
    white: "#FFFFFF",
    grey: "#808080",
    darkGrey: "#484848",
    lightGrey: "#E6E6E6",
    lighterGrey: "#E5E5E5",
    veryLightGray: "#EEEEEE",
    lime: "#9CFF00",
    red: "#FF5151",
    green: "#00C508",
    darkSearchBg: "#0B0B0B",
    orange: "#F04D2A",
  },
};

export default theme;
