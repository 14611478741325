import { AxiosError } from "axios";

import { UseFormErrors } from "src/hooks/useForm/useForm.types";
import { UserAddress } from "src/types";

export function stringifyAddress(address: UserAddress): string {
  const zipCode = address.zip_code ? `${address.zip_code}, ` : "";
  const flat = address.flat ? `, ${address.flat}` : "";
  return `${zipCode}${address.city}, ${address.street}, ${address.house}${flat}`;
}

type AddressPayload = Omit<UserAddress, "id" | "main_address">;

export function getErrors(error: AxiosError): UseFormErrors<AddressPayload> {
  const map = error?.response?.data?.messages as Partial<
    Record<keyof AddressPayload, string[]>
  >;

  const messagesEntries = Object.entries(map).map(([name, value]) => [
    name,
    value?.[0] ?? null,
  ]);

  return Object.fromEntries(messagesEntries);
}

export function getClearFields(
  fromError: UseFormErrors<AddressPayload>
): Partial<Record<keyof AddressPayload, "">> {
  const entries = Object.entries(fromError).map(([key]) => [key, ""]);

  return Object.fromEntries(entries);
}

export function getCurrentAddress(
  incomingIdString: string,
  addresses?: UserAddress[]
): [boolean, UserAddress | undefined] {
  const address = addresses?.find(
    (address) => address.id === +incomingIdString
  );
  const isNew = !address && incomingIdString === "new";
  return [isNew, address];
}
