import React, { useEffect, useMemo, FC } from "react";
import { createPortal } from "react-dom";

import { useClickOutside } from "src/hooks";

import { ModalWrapper, Container } from "./Modal.styles";
import { ModalProps, PortalProps } from "./Modal.types";

const Modal: FC<ModalProps> = (props) => {
  const { onClose, children, className, withoutScroll } = props;

  const ref = useClickOutside<HTMLDivElement>(onClose);

  useEffect(() => {
    withoutScroll
      ? document.body.classList.add("modalOpenedWithoutScroll")
      : document.body.classList.add("modalOpened");

    return (): void => {
      withoutScroll
        ? document.body.classList.remove("modalOpenedWithoutScroll")
        : document.body.classList.remove("modalOpened");
    };
  }, []);

  return (
    <ModalWrapper>
      <Container className={className} ref={ref}>
        {children}
      </Container>
    </ModalWrapper>
  );
};

const Portal: FC<PortalProps> = (props) => {
  const { children, rootElement = "#modal-root", ...rest } = props;

  const root = useMemo(() => document.querySelector(rootElement), [
    rootElement,
  ]);

  return root ? createPortal(<Modal {...rest}>{children}</Modal>, root) : <></>;
};

export default Portal;
