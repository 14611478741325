import styled from "styled-components";

import { BorderlessMaskedInput } from "../BorderlessInput";
import { Button } from "../Button";

export const Input = styled(BorderlessMaskedInput)`
  margin-bottom: 29px;
`;

export const BuyButton = styled(Button)`
  height: 64px;
  margin-bottom: 13px;
  font-size: 14px;
`;

export const Conditions = styled.div`
  font-size: 10px;
  line-height: 14px;
  color: ${({ theme }): string => theme.colors.grey};

  a {
    font-size: 10px;
    line-height: 14px;
    color: ${({ theme }): string => theme.colors.grey};
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;
export const FastOrderWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  gap: 20px;
  flex: 1 1 270px;

  form {
    width: 304px;
    .error_captch {
      color: red;
      margin-top: 10px;
    }
    @media (max-width: 767px) {
      padding-right: 2px;
    }
  }
`;

export const FastOrderImage = styled.img`
  object-fit: cover;
`;

export const FastOrderElem = styled.div`
  display: flex;
  gap: 20px;
  max-height: 230px;

  flex: 1 1 305px;
  img {
    width: 155px;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`;
export const FastOrderText = styled.div`
  .title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 7px;
    display: block;
  }
  .price {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 10px;

    .old {
      text-decoration: line-through;
      color: #aaa;
      margin-right: 10px;
    }
  }
  .size {
    margin-bottom: 20px;
  }
`;
