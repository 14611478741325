import styled from "styled-components";

import { Box } from "../../../Box";
import { Icons } from "../../../Icons";
import { Link } from "../../../Link";

import { ButtonProps } from "./Collapse.types";

export const Container = styled(Box)`
  width: 100%;
`;

export const Button = styled.button<ButtonProps>`
  padding: 0;
  margin: 0;
  width: 100%;
  border: none;
  font-size: 22px;
  background-color: inherit;
  text-align: left;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    transform: ${({ isExpanded }): string =>
      isExpanded ? "none" : "rotate(180deg)"};
  }
`;

export const Content = styled(Box)`
  width: 100%;
  height: auto;
  padding-left: 16px;
`;

export const ArrowIcon = styled(Icons.Arrow)`
  width: 18px;
  height: 18px;
  margin: 0 18px;
  stroke-width: 2px;
  stroke: ${({ theme }): string => theme.colors.black};
  outline: none;
`;

export const MenuLink = styled(Link)`
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  color: ${({ theme }): string => theme.colors.black};
  font-weight: inherit;
`;

export const MenuItem = styled.p`
  font-size: 14px;
  margin: 0;
  color: ${({ theme }): string => theme.colors.black};
  font-weight: 300;
`;
