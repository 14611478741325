export const getPages = (
  currentPage: number,
  totalPages: number,
  pageCount = 3
): number[] => {
  let startPage: number;
  let endPage: number;

  const big = Math.ceil(pageCount / 2);
  const small = Math.floor(pageCount / 2);

  if (totalPages <= pageCount) {
    startPage = 1;
    endPage = totalPages;
  } else {
    if (currentPage <= big) {
      startPage = 1;
      endPage = pageCount;
    } else if (currentPage + small >= totalPages) {
      startPage = totalPages - pageCount + 1;
      endPage = totalPages;
    } else {
      startPage = currentPage - big + 1;
      endPage = currentPage + small;
    }
  }

  const difference = endPage - startPage || 1;

  return [...Array(difference + 1)].map((_, i) => startPage + i);
};
