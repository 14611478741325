import styled from "styled-components";

import ArrowCarousel from "../Icons/Glyphs/ArrowCarousel";

import { arrowCarouselPosition } from "./MainPageBannerSlider.types";

export const Container = styled.div<{
  bannerType: string;
  isTopBanner: boolean;
}>`
  width: ${({ bannerType }): string => `calc(${bannerType} - 20px)`};%;
  margin-left: 20px;
  margin-bottom: 20px;


  @media screen and (max-width: 1440px) {
    margin-left: 12px;
    width: ${({ bannerType }): string => `calc(${bannerType} - 12px)`};
  }

  @media screen and (max-width: 1200px) {
    margin-left: 10px;
    width: ${({ bannerType }): string => `calc(${bannerType} - 10px)`};
  }

  @media screen and (max-width: 768px) {
    margin-left: 10px;
  }
`;

export const MainPageBannerSliderArrow = styled.div<arrowCarouselPosition>`
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  position: absolute;
  opacity: 0.5;
  top: calc(50% - 20px);
  z-index: 100;
  cursor: pointer;
  ${({ isRight }): string =>
    isRight
      ? "right: 0; transform: translateX(-40px)"
      : "left: 0; transform: translateX(52px)"};
  @media screen and (min-width: 770px) {
    &:hover {
      opacity: 0.65;
    }
  }
  @media screen and (max-width: 767px) {
    top: calc(50% - 14px);
    ${({ isRight }): string =>
      isRight ? "transform: translateX(-15px)" : "transform: translateX(35px)"};
    width: 28px;
    height: 28px;
      }
    }};
  }
`;

export const InnerArrow = styled(ArrowCarousel)<arrowCarouselPosition>`
  width: 18px;
  height: 18px;

  ${({ isRight }): string =>
    isRight
      ? `
    transform: rotate(180deg);
    `
      : ""}

  @media (max-width: 767px) {
    width: 12x;
    height: 12px;
  }
`;
