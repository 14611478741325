import React, { FC, useMemo } from "react";

import {
  MainPageBannerSliderArrow,
  InnerArrow,
} from "./MainPageBannerSlider.styles";

const CarouselMainPageBannerPrevArrow: FC = (
  props: any //  eslint-disable-line @typescript-eslint/no-explicit-any
) => {
  const { onClick } = props;
  const currentSlide = props.currentSlide || 0;

  const isMobilePrevArrowDisabled = useMemo(() => {
    if (currentSlide === 0) {
      return true;
    }
  }, [currentSlide]);
  return (
    <MainPageBannerSliderArrow
      isRight={false}
      onClick={onClick}
      isMobilePrevArrowDisabled={isMobilePrevArrowDisabled}
    >
      <InnerArrow isRight={false} />
    </MainPageBannerSliderArrow>
  );
};

export default CarouselMainPageBannerPrevArrow;
