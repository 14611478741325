import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";

import { Box } from "../Box";
import { Button } from "../Button";
import { Carousel } from "../Carousel";
import { FavoriteButton } from "../FavoriteButton";
import { Flex } from "../Flex";
import { Grid } from "../Grid";
import { IconButton } from "../IconButton";
import { Icons } from "../Icons";
import { Link } from "../Link";
import { ProductDescription } from "../ProductDescription";
import { Text } from "../Text";

import { SizeButtonContainerProps, SizeButtonProps } from "./Product.types";

export const Container = styled(Grid)`
  grid-template-columns: 1fr 528px;
  grid-column-gap: 90px;
  width: 100%;

  @media screen and (max-width: 1440px) {
    grid-template-columns: 1fr 362px;
    grid-column-gap: 64px;
  }

  @media screen and (max-width: 1120px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 64px;
  }

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr;
  }
`;

export const Picture = styled.picture`
  width: 100%;
  height: 100%;
`;

export const ImageButton = styled(IconButton)`
  padding: 0;
  max-height: 840px;
  height: 100%;

  @media screen and (min-width: 1920px) {
    max-height: 1191px;
  }

  @media screen and (min-width: 2298px) {
    max-height: 1540px;
  }

  @media screen and (min-width: 2760px) {
    max-height: 2350px;
  }

  @media screen and (max-width: 600px) {
    max-height: 580px;
  }
`;

export const ImageContainerWrapper = styled.div`
  @media screen and (max-width: 1150px) {
    display: none;
  }
`;

export const ImageContainer = styled(Grid)`
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  @media screen and (max-width: 1150px) {
    display: none;
  }
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ImageCarousel = styled(Carousel)`
  display: none;
  max-width: 42vw;

  @media screen and (max-width: 1150px) {
    display: block;
  }

  @media screen and (max-width: 760px) {
    max-width: 100vw;
  }
`;

export const InfoContainer = styled(Box)`
  width: 100%;
  max-width: 487px;

  .container_color {
    margin-top: 32px;
    margin-bottom: -60px;
  }
  @media screen and (max-width: 760px) {
    padding: 0 20px 0;
    .container_color {
      margin-bottom: -33px;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 0 15px 0;
  }
`;

export const StickyContainer = styled.div`
  position: sticky;
  top: 5px;
  .yandex_split {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
    width: 100%;
    max-width: 400px;
    span {
      font-family: "HelveticaNeueCyr";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      white-space: nowrap;
    }
    img {
      width: 98px;
      height: 14px;
      margin: 1px 5px 0;
    }
  }
  #button_container {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 24px;
    .ya-pay-button {
      border-radius: 0 !important;
      height: 55px;
      width: 100%;
      &:focus {
        box-shadow: none !important;
      }
    }
  }
`;

export const HeaderContainer = styled(Box)`
  @media screen and (max-width: 760px) {
    display: none;
  }
`;

export const MobileHeaderContainer = styled(Box)`
  display: none;

  @media screen and (max-width: 760px) {
    display: block;
    padding: 0 20px 0;
    margin-bottom: 26px;
  }

  @media screen and (max-width: 600px) {
    padding: 0 15px 0;
  }
`;

export const Kind = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }): string => theme.colors.grey};
  margin: 0 0 6px;
  text-transform: uppercase;

  & a {
    color: inherit;
    text-decoration: inherit;

    &:hover {
      color: ${({ theme }): string => theme.colors.black} !important;
    }
`;

export const NameContainer = styled.div`
  width: auto;
`;

export const Name = styled.h1`
  font-weight: 500;
  font-size: 34px;
  letter-spacing: 0.01em;
  margin: 0;
  width: auto;

  & a {
    color: inherit;
    text-decoration: inherit;

    &:hover {
      color: ${({ theme }): string => theme.colors.grey};
    }
  }

  @media screen and (max-width: 760px) {
    font-size: 26px;
  }
`;

export const PriceContainer = styled(Flex)`
  margin-top: 10px;
`;

export const Price = styled(Text)`
  font-size: 28px;
  margin: 0;

  @media screen and (max-width: 760px) {
    font-size: 20px;
  }
`;

export const OldPrice = styled(Price)`
  color: ${({ theme }): string => theme.colors.grey};
  margin-right: 24px;
  text-decoration: line-through;
`;

export const Promotion = styled(Text)<{
  isGreen?: boolean;
  isFinalSale?: boolean;
}>`
  font-size: 14px;
  ${({ isFinalSale }): string =>
    isFinalSale ? " color:#000000;" : ""}
  line-height: 20px;
  margin: 23px 0 0;
  background-color: ${({ isGreen, isFinalSale }): string =>
    isGreen ? "#b1fb00" : isFinalSale ? "#f8f8f8" : "#f8f8f8"};
  padding: 10px;

  span {
    display: inline-block;
    text-wrap: balance;
  }

  @media screen and (max-width: 768px) {
    span {
      max-width: 100%;
    }
  }

  @media screen and (max-width: 760px) {
    margin-top: 24px;
  }
`;

export const PromotionMobile = styled(Text)`
  font-size: 14px;
  line-height: 20px;
  margin: 46px 0 0;
  background-color: #f8f8f8;
  padding: 10px;
  display: none;

  @media screen and (max-width: 760px) {
    display: block;
  }
`;

export const AvailabilityContainer = styled(Box)`
  margin-top: 32px;
  width: 100%;

  &.not-msk {
    @media screen and (max-width: 760px) {
      margin-top: 32px;
    }
  }

  &.msk {
    margin-top: 32px;
  }
`;

export const AvailabilityTitle = styled.span`
  font-family: HelveticaNeueCyr, sans-serif;
  font-weight: 500;
  font-size: 22px;
  letter-spacing: 0.01em;
  margin: 0 0 28px;

  @media screen and (max-width: 760px) {
    font-size: 16px;
  }
`;

export const AvailabilityList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

export const AvailabilityItem = styled.li`
  font-size: 16px;
  margin: 14px 0 0;
  letter-spacing: 0.01em;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AvailabilityInnerWrapper = styled(Text)`
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
  min-width: 100px;
  width: 25%;
  margin: 0;
`;

export const AvailabilitySize = styled(Text)`
  margin: 0;
  font-weight: 500;
  font-size: 14px;
`;

export const AvailabilityCount = styled(Text)`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
`;

export const AvailabilityLink = styled(Link)`
  text-decoration: underline;
  font-weight: 500;
  color: ${({ theme }): string => theme.colors.black};
  margin: 0;

  @media screen and (max-width: 760px) {
    font-size: 14px;
  }
`;

export const ButtonContainer = styled(Flex)`
  width: 100%;
  margin-top: 71px;
  justify-content: space-between;
  flex-direction: column;

  @media screen and (max-width: 760px) {
    margin-top: 42px;
  }

  @media screen and (max-width: 374px) {
    flex-direction: column;
  }
`;

export const Description = styled(ProductDescription)`
  max-width: 900px;

  @media screen and (max-width: 1440px) {
    max-width: 100%;
  }

  @media screen and (max-width: 1120px) {
    max-width: 100%;
    padding-top: 38px;
  }

  @media screen and (max-width: 760px) {
    padding-top: 38px;
  }

  @media screen and (max-width: 600px) {
    padding-top: 18px;
  }
`;

export const ProductCategoriesWrapper = styled(Box)`
  @media screen and (max-width: 760px) {
    &.not-mobile {
      display: none;
    }
  }

  @media screen and (min-width: 761px) {
    &.mobile {
      display: none;
    }
  }

  @media screen and (max-width: 1120px) {
    margin-top: 0;
  }

  @media screen and (max-width: 760px) {
    padding: 0 20px 0;
  }

  @media screen and (max-width: 600px) {
    padding: 0 15px 0;
  }
`;

export const DescriptionWrapper = styled(Box)`
  width: 100%;
  margin-top: 48px;
  @media screen and (max-width: 1150px) {
    &.not-mobile {
      display: none;
    }
  }

  @media screen and (min-width: 1151px) {
    &.mobile {
      display: none;
    }
  }

  @media screen and (max-width: 1120px) {
    margin-top: 0;
  }

  @media screen and (max-width: 760px) {
    padding: 0 20px 0;
  }

  @media screen and (max-width: 600px) {
    padding: 0 15px 0;
  }
`;

export const CheckoutButton = styled(Button)`
  width: 100%;
  margin-bottom: 15px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 18px 0 14px;
  position: relative;

  span {
    display: none;
    font-size: 20px;
    align-self: baseline;
    padding-top: 2px;
    position: absolute;
    right: 20px;
    top: 10px;
  }

  :hover span {
    display: inline-block;
  }

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const QuickOrder = styled(Button)`
  width: 100%;
  font-size: 14px;
  height: 55px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const SizeContainer = styled(Box)`
  margin-top: 46px;
  width: 100%;
  .choose_box {
    transition: height 0.3s ease;
    border: unset;
    margin-top: 10px;
  }

  @media screen and (max-width: 760px) {
    margin-top: 60px;
  }
`;

export const SizeTitle = styled(Text)`
  color: ${({ theme }): string => theme.colors.grey};
  font-size: 12px;
  text-transform: uppercase;
  margin: 0;

  @media screen and (max-width: 760px) {
    font-weight: 500;
    color: ${({ theme }): string => theme.colors.black};
  }
`;

export const SizeButton = styled(Button)<SizeButtonProps>`
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  padding: 6px 2px 0;
  margin: 0 25px 10px 0;
  border: 0;
  flex: 10% 0 0;
  position: relative;
  color: ${({ theme }): string => theme.colors.grey};
  background-color: transparent !important;
  white-space: nowrap;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:before {
      content: "";
      position: absolute;
      top: 11px;
      left: 50%;
      height: 1px;
      width: 40px;
      background-color: ${({ theme }): string => theme.colors.black};
      transform: rotate(35deg);
      margin-left: -18px;
    }
  }

  transition: 0.3s ease color background-color;

  &:hover:not(:disabled) {
    color: ${({ theme }): string => theme.colors.black};
  }

  &:hover {
    color: ${({ theme }): string => theme.colors.grey};
  }

  ${({ isActive }): FlattenInterpolation<ThemeProps<DefaultTheme>> | false =>
    isActive &&
    css`
      color: ${({ theme }): string => theme.colors.black};
      font-weight: bold;
    `};
`;

export const SizeButtonContainer = styled(Flex)<SizeButtonContainerProps>`
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: min-content !important;
  margin-top: 24px;
  flex-wrap: wrap;
  border-bottom: 1px solid ${({ theme }): string => theme.colors.lightGrey};
  display: flex;
`;

export const EmptyButton = styled.div`
  width: 100%;
  margin-bottom: 15px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 18px 0 14px;
  border: 1px solid #000000;
  opacity: 0.5;
`;

export const ShowTableSizes = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }): string => theme.colors.black};
  text-decoration: underline;
  cursor: pointer;
  float: right;
  margin-top: 7px;

  &:hover {
    text-decoration: none;
  }
`;

export const BreadCrumbs = styled.ul`
  margin: 21px 0 19px;
  line-height: 30px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #000000;
  margin-left: 0;
  padding: 0;
  list-style: none;

  li {
    display: inline-block;
  }

  a {
    font-size: 14px;
    color: ${({ theme }): string => theme.colors.grey};
    white-space: nowrap;
    text-decoration: none;

    &:hover {
      color: ${({ theme }): string => theme.colors.black};
    }
  }

  @media (max-width: 760px) {
    display: none;
  }
`;

export const Slash = styled.span`
  margin: 0 7px;
  color: #8e8e8e;
`;

export const CategoryName = styled.span`
  white-space: nowrap;
`;

export const BreadCrumbsMobile = styled.div`
  display: none;
  line-height: 30px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #000000;

  a {
    font-size: 14px;
    color: #000000;
    white-space: nowrap;
    text-decoration: none;
  }

  @media (max-width: 760px) {
    display: block;
    margin: 0 16px 9px;
  }

  @media (max-width: 600px) {
    margin: 0 11px 9px;
  }
`;

export const PrevArrow = styled(Icons.Arrow)`
  width: 16px;
  height: 16px;
  transform: rotate(90deg);
  stroke: ${({ theme }): string => theme.colors.black};
  stroke-width: 2px;
  vertical-align: text-bottom;
  transform: rotate(270deg);
  margin: 0 4px 0 0;
`;

export const BrandContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FavoriteButtonProduct = styled(FavoriteButton)`
  display: flex;
  height: 39px;
  justify-content: end;
  margin-top: 3px;

  @media screen and (max-width: 760px) {
    position: relative;
    margin-top: 0;
  }
`;
