import AddToCart from "./AddToCart";
import Arrow from "./Arrow";
import ArrowCarousel from "./ArrowCarousel";
import ArrowFavorites from "./ArrowFavorites";
import ArrowFilterDown from "./ArrowFilterDown";
import ArrowFilterUp from "./ArrowFilterUp";
import BackArrow from "./BackArrow";
import Backspace from "./Backspace";
import Burger from "./Burger";
import Car from "./Car";
import Checkout from "./Checkout";
import Close from "./Close";
import Document from "./Document";
import Edit from "./Edit";
import Facebook from "./Facebook";
import FilledCheckout from "./FilledCheckout";
import FilterArrow from "./FilterArrow";
import FullLogo from "./FullLogo";
import Heart from "./Heart";
import Instagram from "./Instagram";
import Logo from "./Logo";
import Minus from "./Minus";
import Phone from "./Phone";
import Plus from "./Plus";
import Return from "./Return";
import Search from "./Search";
import SelectChecked from "./SelectChecked";
import Share from "./Share";
import Shop from "./Shop";
import Tg from "./Tg";
import TooltipBox from "./TooltipBox";
import User from "./User";
import UserLog from "./UserLog";
import Vk from "./Vk";

export default {
  Arrow,
  ArrowCarousel,
  ArrowFavorites,
  ArrowFilterDown,
  ArrowFilterUp,
  Backspace,
  BackArrow,
  Burger,
  Car,
  Checkout,
  Close,
  Document,
  Edit,
  Facebook,
  Vk,
  Tg,
  TooltipBox,
  FilterArrow,
  FilledCheckout,
  FullLogo,
  Heart,
  Instagram,
  Logo,
  Minus,
  Phone,
  Plus,
  Return,
  Search,
  Share,
  SelectChecked,
  Shop,
  User,
  UserLog,
  AddToCart,
};
