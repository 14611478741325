import React, { FC, useMemo } from "react";

import { InnerArrow, ShopCarouselSliderArrow } from "./ShopCarousel.styles";

const ShopCarouselNextArrow: FC = (
  props: any //  eslint-disable-line @typescript-eslint/no-explicit-any
) => {
  const { onClick } = props;
  const slideCount = props.slideCount || 0;
  const currentSlide = props.currentSlide || 0;

  const isMobileNextArrowDisabled = useMemo(() => {
    return slideCount - currentSlide;
  }, [slideCount, currentSlide]);
  return (
    <ShopCarouselSliderArrow
      isRight={true}
      onClick={onClick}
      isMobileNextArrowDisabled={isMobileNextArrowDisabled}
    >
      <InnerArrow isRight={true} />
    </ShopCarouselSliderArrow>
  );
};

export default ShopCarouselNextArrow;
