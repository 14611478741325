import React, { FC } from "react";

import {
  CloseButton,
  CloseIcon,
  ModalContainer,
  Wrapper,
  LoginLink,
} from "./SaveReminder.styles";
import { SaveReminderProps } from "./SaveReminder.types";

const SaveReminder: FC<SaveReminderProps> = ({ url, onClose }) => {
  return (
    <ModalContainer>
      <CloseButton onClick={onClose} aria-label="Закрыть">
        <CloseIcon />
      </CloseButton>

      <Wrapper>
        <p>
          Войдите в <u>личный кабинет</u>, чтобы отложенные вещи сохранились.
        </p>
        <LoginLink to={url}>Войти</LoginLink>
      </Wrapper>
    </ModalContainer>
  );
};

export default SaveReminder;
