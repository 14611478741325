import React, { useState, useMemo, FC, useEffect } from "react";
import TagManager from "react-gtm-module";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";

// import { FavoriteButton, HeartIcon } from "src/components/FavoriteButton";
import { ApplicationStore } from "src/store";
import { formatCurrency, parseImages, convertImageUrl } from "src/utils";

import { ProductLabel } from "../ProductLabel";

import {
  ContainerItem,
  ImageLink,
  ImageContainer,
  ImageImg,
  InfoContainer,
  Name,
  PriceContainer,
  Price,
  OldPrice,
  Picture,
  ButtonContainer,
  CheckoutButton,
  DeleteButton,
  CrossIcon,
  // SizeList,
  // SizeItem,
  ProductType,
  ProductNameContainer,
} from "./CatalogListItemNew.styles";
import { CatalogListItemProps, Impressions } from "./CatalogListItemNew.types";

const CatalogListItemNew: FC<CatalogListItemProps> = (props) => {
  const {
    item,
    className,
    catalogClassName,
    // onCheckout,
    // onDelete,
    onCheckoutLabel,
    // addToFavorites,
    order,
    categories,
    selectedItemID,
    onClickedMobileID,
    isViewed,
    loadMorePage,
  } = props;

  // is_wish,
  const { name, gallery, link } = item;
  const new_price = item.new_price || 0;
  const old_price = item.old_price || 0;
  const isFavorites = catalogClassName === "favourites";

  const images = useMemo(() => parseImages(gallery), [gallery]);
  const { userData } = useSelector((state: ApplicationStore) => state.user);

  const [isMobile, setMatches] = useState(
    window.matchMedia("(max-width: 767px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(max-width: 767px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  // const [isHelpVisible] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isClickable, setIsClickable] = useState(false);

  const { ref, inView } = useInView({
    threshold: 1,
    triggerOnce: true,
  });

  // const itemSizes = item.offers
  //   .filter((offer) => {
  //     return offer.available;
  //   })
  //   .map((filteredOffer) => {
  //     return filteredOffer.size_name;
  //   });

  const clickProduct = (e?: React.MouseEvent) => {
    if (isMobile && e) {
      e.preventDefault();
    }
    const scroll_box = document.getElementById("scroll_content");
    const scrollY =
      window.scrollY >
        (+`${scroll_box?.scrollHeight}` * Number(loadMorePage)) /
          (+`${loadMorePage}` + 1) && !!loadMorePage
        ? `${
            window.scrollY -
            (+`${scroll_box?.scrollHeight}` * loadMorePage) /
              (+`${loadMorePage}` + 1)
          }`
        : window.scrollY;

    sessionStorage.setItem("_scrollPosition_", `${scrollY}`);
    if (userData?.is_retail) return;
    TagManager.dataLayer({
      dataLayer: {
        event: "product-click",
        ecommerce: {
          click: {
            products: [
              {
                name: item.name,
                id: item.id,
                price: new_price,
                brand: item.brand_name,
                category: categories,
                position: order,
              },
            ],
          },
        },
      },
    });
  };
  const touchProduct = (e: React.TouchEvent, link: string, id: number) => {
    if (isMobile) {
      e.preventDefault();
      if (!selectedItemID) {
        setIsClickable(false);
        onClickedMobileID?.(id);
      } else {
        if (selectedItemID === id) {
          if (!isDragging) {
            window.location.href = link;
          }
        } else {
          setIsClickable(false);
          onClickedMobileID?.(id);
        }
      }
      setIsDragging(false);
    }
  };

  const stopTouchEvent = (link: string) => {
    if (isClickable && !isDragging) {
      window.location.href = link;
    }
    setIsClickable(true);
  };

  useEffect(() => {
    if (inView) {
      const impressions: Impressions = {
        name: item.name,
        id: item.id,
        price: new_price,
        brand: item.brand_name,
        position: order,
      };

      if (categories) {
        impressions.category = categories;
      }

      if (isViewed) {
        impressions.list = "Product-Page-you-saw";
      }

      if (categories || isViewed || categories === "") {
        TagManager.dataLayer({
          dataLayer: {
            event: "impressions",
            ecommerce: {
              currencyCode: "RUB",
              impressions: [impressions],
            },
          },
        });
      }
    }
  }, [categories, inView, isViewed, item, new_price, old_price, order]);

  return (
    <ContainerItem
      className={`${catalogClassName} ${className}`}
      ref={ref}
      data-id={item.id}
    >
      {item.label && (
        <ProductLabel label={item.label} catalogClassName={catalogClassName} />
      )}
      <ImageLink
        to={link || "/"}
        aria-label={name}
        className={selectedItemID === item.id ? "selected" : ""}
        onClick={(e) => {
          clickProduct(e);
        }}
        onTouchStart={(e) => {
          touchProduct(e, link, item.id);
        }}
        onTouchEnd={() => {
          stopTouchEvent(link);
        }}
        onTouchCancel={() => {
          stopTouchEvent(link);
        }}
        onTouchMove={() => setIsDragging(true)}
      >
        {/* {!!itemSizes.length && isMobile && (
          <SizeList className={"sizeListMobile"}>
            {itemSizes.map((size) => (
              <SizeItem key={size}>{size}</SizeItem>
            ))}
          </SizeList>
        )} */}
        {!!images?.[0] && (
          <ImageContainer className={catalogClassName}>
            <Picture className={"image-0"}>
              <ImageImg src={convertImageUrl(images[0], 600, 824)} alt={name} />
            </Picture>
            {images?.[1] && (
              <Picture className={"image-1"}>
                <ImageImg
                  loading="lazy"
                  src={convertImageUrl(images[1], 600, 824)}
                  alt={name}
                />
              </Picture>
            )}
          </ImageContainer>
        )}
      </ImageLink>
      {catalogClassName === "catalog" && (
        <>
          {/* <FavoriteButton
            onClick={() => addToFavorites?.(item)}
            isCatalog={true}
            variant="white"
            aria-label="Добавить в избранное"
            // isFavourite={is_wish}
            isFavourite={false}
            isHelpVisible={isHelpVisible}
          >
            <HeartIcon />
          </FavoriteButton> */}
          {/* {!!itemSizes.length && !isMobile && (
            <SizeList className={"sizeListDesktop"}>
              {itemSizes.map((size) => (
                <SizeItem key={size}>{size}</SizeItem>
              ))}
            </SizeList>
          )} */}
        </>
      )}
      <InfoContainer className={catalogClassName}>
        <ProductNameContainer
          onMouseDown={(e) => {
            clickProduct(e);
          }}
          onTouchStart={(e) => {
            touchProduct(e, link, item.id);
          }}
          onTouchMove={() => setIsDragging(true)}
          aria-label={name}
          to={link || "/"}
        >
          <ProductType className={catalogClassName}>
            {item.type_name}
          </ProductType>
          <Name className={catalogClassName}>{item.brand_name}</Name>
        </ProductNameContainer>
        <PriceContainer className={catalogClassName}>
          {old_price > new_price && (
            <OldPrice>{formatCurrency(old_price)}</OldPrice>
          )}
          <Price className={catalogClassName}>
            {formatCurrency(new_price)}
          </Price>
        </PriceContainer>
      </InfoContainer>
      {isFavorites && (
        <ButtonContainer>
          <CheckoutButton
            variant="white"
            // onClick={() => void onCheckout?.(item)}
          >
            {onCheckoutLabel || "В корзину"}
          </CheckoutButton>
          <DeleteButton
            aria-label="Удалить"
            // onClick={() => void onDelete?.(item)}
          >
            <CrossIcon />
            Удалить
          </DeleteButton>
        </ButtonContainer>
      )}
    </ContainerItem>
  );
};

export default CatalogListItemNew;
