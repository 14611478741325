import styled from "styled-components";

import { Box, Flex, MainMenu, Button, Icons } from "src/components";

import { FilterButtonProps, isHiddenProps } from "./CatalogList.types";

export const Container = styled(Box)`
  width: 100%;
  padding: 36px 40px 180px;

  @media screen and (max-width: 768px) {
    padding: 14px 20px 100px;
  }

  @media screen and (max-width: 600px) {
    padding: 6px 15px 80px;
  }
`;

export const HeaderContainerCollection = styled(Flex)<{
  isCollection?: boolean;
}>`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  position: relative;
  min-height: 64px;

  @media screen and (max-width: 900px) {
    justify-content: flex-start;
    display: none;
    margin-bottom: 0;
  }
`;

const HeaderItemContainer = styled(Flex)`
  width: 100%;
  align-items: center;
`;

export const FilterButtonContainer = styled(HeaderItemContainer)`
  max-width: 360px;
  justify-content: flex-start;

  @media screen and (max-width: 1200px) {
    max-width: 264px;
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const MobileFilterContainer = styled.div`
  display: none;

  @media screen and (max-width: 900px) {
    display: flex;

    justify-content: space-between;

    width: 100%;

    padding: 6px 15px;

    background-color: ${({ theme }): string => theme.colors.white};
  }
`;

export const TitleContainer = styled(HeaderItemContainer)<isHiddenProps>`
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const SelectContainer = styled(HeaderItemContainer)`
  max-width: 27%;
  justify-content: flex-end;
  .width-content {
    margin-left: 6px;
    width: 97px;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export const Title = styled.h1`
  font-family: HelveticaNeueCyr, sans-serif;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${({ theme }): string => theme.colors.black};
  margin: 0;
`;

export const BodyContainer = styled(Flex)``;

export const FilterContainer = styled.aside`
  position: relative;
  width: 100%;
  max-width: 360px;
  flex: 1 0 360px;
  margin-right: 12px;

  @media screen and (max-width: 1440px) {
    max-width: 264px;
    flex: 1 0 264px;
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const ListContainer = styled.main`
  width: 100%;
  @media screen and (max-width: 900px) {
    padding-top: 20px;
  }
  @media screen and (max-width: 770px) {
    padding-top: 40px;
  }
`;

export const TabletMenu = styled(MainMenu)`
  display: none;
  padding: 30px 0 26px;
  border-bottom: 1px solid ${({ theme }): string => theme.colors.lightGrey};
`;

export const FilterButton = styled(Button)<FilterButtonProps>`
  border: none;
  max-width: 360px;
  min-width: 264px;
  flex-shrink: 0;
  font-size: 20px;
  font-weight: 500px;
  text-align: left;
  padding-left: 0;
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ filterIsOpened }): string => (filterIsOpened ? "100%" : "auto")};

  &:hover {
    background-color: transparent;
    color: ${({ theme }): string => theme.colors.grey};
  }

  &:focus {
    outline: 0;
  }

  @media screen and (max-width: 1440px) {
    max-width: 264px;
  }

  @media screen and (max-width: 768px) {
    max-width: 240px;
    min-width: 240px;
    font-size: 16px;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export const MobileFilterButton = styled(Button)`
  display: block;

  width: calc(50% - 3px);

  height: 40px;

  padding: 0;

  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
`;

export const MinusIcon = styled(Icons.Minus)`
  fill: ${({ theme }): string => theme.colors.black30};
  width: 24px;
  height: 24px;
`;

export const PlusIcon = styled(Icons.Plus)`
  fill: ${({ theme }): string => theme.colors.black30};
  width: 24px;
  height: 24px;
  margin-left: 14px;
`;

export const ResetFilterButton = styled.button`
  border: none;
  background-color: inherit;
  padding: 0;
  font-size: 14px;
  color: ${({ theme }): string => theme.colors.black};
  cursor: pointer;
  margin-right: auto;
  position: absolute;
  bottom: -7px;
  left: 0;
  display: flex;
  flex-direction: row-reverse;

  svg {
    width: 9px;
    height: 9px;
    fill: ${({ theme }): string => theme.colors.black};
    margin: 2px 0 0 20px;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export const FilterTagsContainer = styled(Box)`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  display: none;

  @media screen and (max-width: 600px) {
    display: block;
  }
`;

export const BreadCrumbs = styled.ul`
  margin-top: 9px;
  line-height: 30px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #000000;
  text-align: center;
  margin-left: 0;
  padding: 0;
  list-style: none;

  li {
    display: inline-block;
  }

  a {
    font-size: 14px;
    color: ${({ theme }): string => theme.colors.grey};
    white-space: nowrap;

    &:hover {
      color: ${({ theme }): string => theme.colors.black};
    }
  }

  @media screen and (max-width: 600px) {
    margin-top: 35px;
  }
`;

export const Slash = styled.span`
  margin: 0 7px;
  color: #8e8e8e;
`;

export const TabletBreadCrumbs = styled.div`
  line-height: 30px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #000000;
  display: none;
  text-align: center;

  a {
    font-size: 14px;
    color: #8e8e8e;
    white-space: nowrap;
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const MobileBreadCrumbs = styled.div`
  line-height: 30px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #000000;
  display: none;

  a {
    font-size: 14px;
    color: #000000;
  }

  @media screen and (max-width: 900px) {
    display: block;
    white-space: nowrap;

    margin-top: 46px;
    margin-bottom: 6px;
  }
`;

export const MobileSearchTitle = styled.div`
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
  }
`;

export const CategoryName = styled.span`
  white-space: nowrap;
`;

export const PrevArrow = styled(Icons.Arrow)`
  width: 16px;
  height: 16px;
  transform: rotate(90deg);
  stroke: ${({ theme }): string => theme.colors.black};
  stroke-width: 2px;
  vertical-align: text-bottom;
  transform: rotate(270deg);
  margin: 0 4px 0 0;
`;
