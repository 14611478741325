import { ROUTES } from "./routes";

export const ACCOUNT_MENU = [
  { label: "Персональные данные", url: ROUTES.personalData },
  { label: "Мои заказы", url: ROUTES.myOrder },
  { label: "Мои адреса", url: ROUTES.myAddresses },
  { label: "Избранное", url: ROUTES.favorites },
  { label: "Бонусный счёт", url: ROUTES.loyaltyCard },
  { label: "Рассылки", url: ROUTES.newsletters },
];
