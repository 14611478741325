import { useCallback, useState } from "react";

import {
  UseForm,
  UseFormErrors,
  TextAreaChangeEvent,
  InputChangeEvent,
  SelectChangeEvent,
} from "./useForm.types";
import { reduceByType } from "./useForm.utils";

const useForm = <T>(initialValues: T): UseForm<T> => {
  const [state, setState] = useState<T>(initialValues);
  const [errors, setErrors] = useState<UseFormErrors<T>>({});
  const setValues = useCallback(
    (newValues: Partial<T>) => {
      setState((prevState) => ({
        ...prevState,
        ...newValues,
      }));
    },
    [setState]
  );

  const onPaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      const inputName = (event.target as HTMLInputElement).name;
      if (inputName === "phone") {
        let pasteValue = event.clipboardData.getData("Text");
        pasteValue = pasteValue.replace(/\D/g, "");
        if (pasteValue.startsWith("8")) {
          if (pasteValue.length === 11) {
            pasteValue = "7" + pasteValue.slice(1);
          }
        } else if (pasteValue.length === 10) {
          pasteValue = "7" + pasteValue;
        }
        setState((prevState) => ({
          ...prevState,
          [inputName]: pasteValue,
        }));
      }
      setErrors({ ...errors, [inputName]: null });
      event.preventDefault();
    },
    [errors]
  );

  const onChange = useCallback(
    (event: InputChangeEvent | TextAreaChangeEvent | SelectChangeEvent) => {
      event.persist();

      setState((prevState) => ({
        ...prevState,
        ...reduceByType(event),
      }));
      setErrors({ ...errors, [event.target.name]: null });
    },
    [errors, setErrors, setState]
  );

  return {
    onChange,
    setValues,
    onPaste,
    values: state,
    errors,
    setErrors,
  };
};

export default useForm;
