import styled from "styled-components";

import { ButtonVariants } from "src/types";

import { LinkButtonStyles } from "../LinkButton";

export const ExternalLinkButton = styled.a<ButtonVariants>`
  text-decoration: none;
  ${LinkButtonStyles};
`;

export default ExternalLinkButton;
