import React, { FC } from "react";

import { SvgProps } from "src/types";

const ArrowFavorites: FC<SvgProps> = (props) => (
  <svg {...props} fill="none">
    <path
      d="M12.9515 5.94975L7.97559 10.8995L2.99971 5.94975"
      stroke="black"
      stroke-width="2"
    />
  </svg>
);

ArrowFavorites.defaultProps = {
  width: "20px",
  height: "20px",
  fill: "black",
};

export default ArrowFavorites;
