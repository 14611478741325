import React, { FC } from "react";
import { YMaps, Map, Placemark } from "react-yandex-maps";

import { CURRENT_HOST } from "src/constants";
import pin from "src/images/pin.svg";

const API_KEY = ""; // TODO: добавить api-key

type MapProps = {
  lat: number;
  lng: number;
  title: string;
};

const ShopMap: FC<MapProps> = (props) => {
  const mapState = {
    center: [props.lat, props.lng],
    zoom: 16,
    controls: ["zoomControl", "fullscreenControl"],
  };

  return (
    <YMaps query={{ lang: "ru_RU", apikey: API_KEY }}>
      <Map
        defaultState={mapState}
        width="100%"
        height="100%"
        modules={["control.ZoomControl", "control.FullscreenControl"]}
      >
        <Placemark
          modules={["geoObject.addon.balloon"]}
          defaultGeometry={mapState.center}
          options={{
            iconLayout: "default#image",
            iconImageHref: `${CURRENT_HOST}${pin}`,
            iconImageSize: [84, 118],
            iconImageOffset: [-42, -118],
          }}
          properties={{
            balloonContentBody: props.title,
          }}
        />
      </Map>
    </YMaps>
  );
};

export default ShopMap;
