import styled from "styled-components";

import { Button } from "../Button";
import { IconButton } from "../IconButton";
import { Icons } from "../Icons";
import { Modal } from "../Modal";

export const ModalContainer = styled(Modal)`
  background-color: ${({ theme }): string => theme.colors.white};
  padding: 30px;
  width: 710px;
  min-height: 180px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1150px) {
    width: 100%;
    max-width: 420px;
    padding: 35px 20px;
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0;

  &:hover {
    opacity: 0.5;
  }
`;

export const CloseIcon = styled(Icons.Close)`
  width: 14px;
  height: 14px;
  stroke: white;
  stroke-width: 0px;
`;

export const Title = styled.h1`
  font-family: Formular, HelveticaNeueCyr, sans-serif;
  font-size: 26px;
  font-weight: 500;
  margin: 0;
`;

export const CopyButton = styled(Button)`
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  padding: 15px 34px;
  width: max-content;
  height: max-content;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const InputLable = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  font-family: HelveticaNeueCyr, sans-serif;
  margin: 0;
`;

export const Input = styled.input`
  border: 1px #bababa solid;
  font-size: 16px;
  line-height: 20px;
  font-family: HelveticaNeueCyr, sans-serif;
  padding: 14px 18px;
  outline: none;
  border-radius: 0px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
