import styled from "styled-components";

import { Flex } from "src/components";

export const HTMLtext = styled.main`
  width: 100%;

  .contactsRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 120px;

    @media screen and (max-width: 1200px) {
      flex-wrap: wrap;
    }

    @media screen and (max-width: 600px) {
      justify-content: center;
      text-align: center;
    }
  }

  .contactsCol {
    width: 100%;
    max-width: 330px;
  }

  .socialContainer {
    margin-bottom: 44px;

    @media screen and (max-width: 600px) {
      justify-content: center;
    }
  }

  .socialLink {
    margin: 0 32px 0 0;
    text-decoration: none;

    &:last-of-type {
      margin: 0;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  .contactsText {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin: 0 0 10px;
  }

  .innerContainer {
    margin-bottom: 44px;
  }

  .label {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  .text {
    font-size: 30px;
    line-height: 30px;
    font-weight: 500;
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;

    @media screen and (max-width: 600px) {
      font-size: 20px;
      line-height: 20px;
    }
  }

  .notWeekends {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-left: 12px;
  }

  .vkIcon {
    width: 32px;
    height: 32px;
    fill: black;
  }

  .tgIcon {
    width: 32px;
    height: 32px;
    fill: black;
  }
`;

export const contactsRow = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 120px;

  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 600px) {
    justify-content: center;
    text-align: center;
  }
`;

export const Container = styled.main`
  box-sizing: border-box;
  margin: 100px auto 168px;
  max-width: 1362px;

  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 1440px) {
    margin: 100px 100px 154px;
  }

  @media screen and (max-width: 768px) {
    margin: 87px 20px 95px;
  }

  @media screen and (max-width: 600px) {
    margin: 60px 15px 96px;
  }
`;

export const ContactsHeader = styled.h1`
  font-size: 62px;
  font-weight: 500;
  line-height: 76px;
  text-align: center;
  width: 100%;
  margin: 0 0 100px;

  @media screen and (max-width: 600px) {
    font-size: 26px;
    line-height: 32px;
    margin: 0 0 45px;
  }
`;

export const ContactsMapWrapper = styled.div`
  width: 100%;
  height: 510px;
  position: relative;
  background-color: ${({ theme }): string => theme.colors.lightGrey};
`;
