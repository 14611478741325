import React, {
  FC,
  useMemo,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";

import { AnotherColors } from "src/types";
import { convertImageUrl } from "src/utils";

import ProductColorCard from "../ProductColorCard";

import {
  ColorsContainer,
  ColorsContainerWrapper,
  ColorTitle,
  ShowAllProductColors,
  ProductColorContainerWrapper,
} from "./ProductColorContainer.styles";
import { ProductColorContainerProps } from "./ProductColorContainer.types";

const ProductColorContainer: FC<ProductColorContainerProps> = ({
  another_colors,
}) => {
  const colorsContainerRef = useRef<HTMLDivElement>(null);
  const [isOverColors, setIsOverColors] = useState<boolean>(false);
  const [isHideColors, setIsHideColors] = useState<boolean>(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [totalInRow, setTotalInRow] = useState<number>(6);
  const rows = 2;

  useEffect(() => {
    const handleResize = (event: UIEvent) => {
      if (event.target) {
        setWidth((event.target as Window).innerWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (width <= 1000) {
      setTotalInRow(5);
    } else {
      setTotalInRow(6);
    }
    if (totalInRow * rows < another_colors.length) {
      setIsOverColors(true);
      setIsHideColors(true);
    } else {
      setIsOverColors(false);
      setIsHideColors(false);
    }
  }, [another_colors, width]);

  const colorsToShow = useMemo<number>(
    () => (isHideColors ? totalInRow * rows : another_colors.length),
    [another_colors, isHideColors, isOverColors, totalInRow]
  );

  const elementPosition = useCallback(
    (position) => {
      switch (true) {
        case position === 0 || position % totalInRow === 0:
          return "tooltip_left";
        case (position + 1) % totalInRow === 0:
          return "tooltip_right";
        default:
          return "tooltip_center";
      }
    },
    [totalInRow]
  );

  return (
    <>
      <ProductColorContainerWrapper className="container_color">
        <ColorTitle>Доступные расцветки</ColorTitle>
        <ColorsContainerWrapper>
          <ColorsContainer
            className={`choose_box ${isHideColors ? "hide--active" : ""}`}
            ref={colorsContainerRef}
          >
            {another_colors
              .slice(0, colorsToShow)
              .map((color: AnotherColors, index) => {
                return (
                  <ProductColorCard
                    imageUrl={convertImageUrl(color.gallery, 200, 400)}
                    color={color.tooltip}
                    isCurrent={color.current}
                    href={color.url}
                    position={elementPosition(index)}
                  ></ProductColorCard>
                );
              })}
          </ColorsContainer>
        </ColorsContainerWrapper>
        {isOverColors && (
          <ShowAllProductColors onClick={() => setIsHideColors(!isHideColors)}>
            {!isHideColors ? "Скрыть" : "Показать"} все расцветки
          </ShowAllProductColors>
        )}
      </ProductColorContainerWrapper>
    </>
  );
};

export default ProductColorContainer;
