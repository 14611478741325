import styled from "styled-components";

import TooltipBox from "../Icons/Glyphs/TooltipBox";

export const TooltipSvg = styled(TooltipBox)`
  top: 100%;
  display: block;
  position: absolute;

  &.tooltip_left {
    left: 50%;
    transform: translateX(-50%);
  }

  &.tooltip_center {
    left: 50%;
    transform: translateX(-50%);
  }

  &.tooltip_right {
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const ProductColorWrapper = styled.div`
  display: flex;
  position: relative;
  border: 1px solid transparent;

  &.active {
    border: 1px solid #000000;
    cursor: default;
    img {
      opacity: 0.2;
    }
    &:hover {
      opacity: 1;
    }
  }

  :not(.active) {
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }

  @media screen and (max-width: 760px) {
    .choose_color {
      margin: 0 7px 7px 0;
    }
  }

  .tooltipText {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 12px 10px 8px 10px;
    position: absolute;
    z-index: 1;
    bottom: 110%;
    opacity: 0;
    transition: opacity 0.3s;

    &.tooltip_left {
      left: 0%;
      transform: translateX(0%);
    }

    &.tooltip_center {
      left: 50%;
      transform: translateX(-50%);
    }

    &.tooltip_right {
      right: 0%;
      transform: translateX(0%);
    }

    &:hover {
      visibility: visible !important;
      opacity: 1;
    }
  }

  &:hover .tooltipText {
    visibility: visible;
    opacity: 1;
  }
`;

export const Image = styled.img`
  display: inline;
  width: 100%;
  height: 100%;
  object-fit: cover;

  &:hover {
    opacity: 0.6;
  }
`;
