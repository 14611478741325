import React, { FC, useEffect } from "react";
import TagManager from "react-gtm-module";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";

import { setCurrentWorkFlow } from "src/actions";
import { removeToken } from "src/api";
import { ROUTES, ACCOUNT_MENU } from "src/constants";
import {
  ApplicationStore,
  CheckoutTypes,
  UserTypes,
  WishlistTypes,
} from "src/store";
import { SeoMeta } from "src/types";
import {
  setBasketId,
  generateMetaTags,
  setLastViewsToLocalStorage,
  setFavoritesToLocalStorage,
} from "src/utils";

import {
  Container,
  AccountNav,
  AccountTitle,
  UserName,
  Nav,
  NavItem,
  NavItemLink,
  AccountContent,
  Logout,
} from "./Account.styles";
import AddressList from "./AddressList";
import { EditMyAddress } from "./EditAddress";
import LoyaltyCard from "./LoyaltyCard";
import MyOrders from "./MyOrders";
import Newsletters from "./Newsletters";
import NewslettersEdit from "./NewslettersEdit";
import PersonalData from "./PersonalData";
import PersonalDataEdit from "./PersonalDataEdit";

const Account: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const seoTitle = "Личный кабинет LEFORM - интернет-магазин Leform";
  const seoDescription =
    "Личный кабинет. Leform: концептуальный интернет-магазин одежды, обуви, аксессуаров и предметов интерьера";
  const metaTags: SeoMeta[] = generateMetaTags(seoTitle, seoDescription);

  const { isAuthorized, userData } = useSelector(
    (state: ApplicationStore) => state.user
  );

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  }, []);

  if (!isAuthorized) {
    return <Redirect to={ROUTES.home} />;
  }

  const logout = () => {
    dispatch({ type: UserTypes.CLEAR });
    dispatch({ type: CheckoutTypes.CLEAR });
    dispatch({ type: WishlistTypes.CLEAR });
    dispatch({ type: WishlistTypes.CLEAR_NO_UUID });
    dispatch(
      setCurrentWorkFlow({
        userWorkFlow: "anonymous",
      })
    );
    removeToken();
    setLastViewsToLocalStorage([]);
    setFavoritesToLocalStorage([]);
    setBasketId("undefined");
    history.push("/");
  };

  return (
    <Container>
      <HelmetProvider>
        <Helmet defer={false} title={seoTitle} meta={metaTags} />
      </HelmetProvider>
      <AccountNav isRootAccount={location.pathname === "/my_account"}>
        <AccountTitle>Мой аккаунт</AccountTitle>
        <UserName>
          {userData?.userLastName} {userData?.userFirstName}
        </UserName>

        <Nav>
          {ACCOUNT_MENU.map((itemNav, idx) => (
            <NavItem key={idx}>
              <NavItemLink
                to={itemNav.url}
                className={
                  itemNav.url === location.pathname ? "active" : undefined
                }
              >
                {itemNav.label}
              </NavItemLink>
            </NavItem>
          ))}
          <NavItem>
            <Logout onClick={logout} variant="white">
              Выйти
            </Logout>
          </NavItem>
        </Nav>
      </AccountNav>
      <AccountContent>
        <Switch>
          <Route exact path={ROUTES.personalData} component={PersonalData} />
          <Route
            exact
            path={ROUTES.personalDataEdit}
            component={PersonalDataEdit}
          />
          <Route exact path={ROUTES.myOrder} component={MyOrders} />
          <Route
            exact
            path={`${ROUTES.myAddresses}/:id`}
            component={EditMyAddress}
          />
          <Route exact path={ROUTES.myAddresses} component={AddressList} />
          <Route exact path={ROUTES.loyaltyCard} component={LoyaltyCard} />
          <Route exact path={ROUTES.newsletters} component={Newsletters} />
          <Route
            exact
            path={ROUTES.newslettersFormEdit}
            component={NewslettersEdit}
          />
        </Switch>
      </AccountContent>
    </Container>
  );
};

export default Account;
