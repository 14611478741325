import { WishlistTypes } from "src/store";
import { SetWishlist, SetWishlistNoUuid } from "src/store/wishlist";
import { LocalStorageFavoriteItem, WishlistItem } from "src/types";

export const setWishlistUuid = (data: WishlistItem[]): SetWishlist => ({
  type: WishlistTypes.SET,
  payload: data,
});

export const setWishlistNoUuid = (
  items: LocalStorageFavoriteItem[]
): SetWishlistNoUuid => ({
  type: WishlistTypes.SET_NO_UUID,
  payload: items,
});
