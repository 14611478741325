/* eslint-disable max-len */

import React, { FC } from "react";

import { SvgProps } from "src/types";

const Phone: FC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 36 36">
    <path d="M27.7 35.4h-1a32.7 32.7 0 01-25.6-25A4.6 4.6 0 012.5 6l4-3.9A2 2 0 018 1.7a2 2 0 011.4.8l5 7.4a1.6 1.6 0 010 2l-2.6 3a19 19 0 004.2 5.8c1.7 1.7 3.8 3.1 6 4.1l3.2-2.5a1.6 1.6 0 011.8 0l7.6 4.8a2 2 0 01.4 3.1l-3.9 3.9a4.7 4.7 0 01-3.4 1.3zM7.8 3.6l-4 3.9a2.5 2.5 0 00-.7 2.4 30.7 30.7 0 0024 23.5 2.7 2.7 0 002.5-.8l4-3.8-7.4-4.7-3.3 2.6a1 1 0 01-1 .2 20 20 0 01-7.3-4.7c-2.1-2-3.8-4.5-4.8-7.2a1 1 0 01.2-1l2.7-3.2-4.9-7.2z" />
  </svg>
);

Phone.defaultProps = {
  width: "20px",
  height: "20px",
  fill: "black",
};

export default Phone;
