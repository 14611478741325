import styled from "styled-components";

import {
  Box,
  Flex,
  Link,
  LinkButton,
  Button,
  Icons,
  Grid,
} from "src/components";

export const Container = styled(Box)`
  width: 100%;
  padding: 36px 40px 180px;

  @media screen and (max-width: 768px) {
    padding: 14px 20px 100px;
  }

  @media screen and (max-width: 600px) {
    padding: 25px 15px 80px;
  }
`;

export const HeaderContainer = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  position: relative;
  min-height: 64px;

  @media screen and (max-width: 1440px) {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 1200px) {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 1280px) {
    margin-bottom: 10px;
    min-height: unset;
  }
`;

const HeaderItemContainer = styled(Flex)`
  align-items: center;
`;

export const TitleContainer = styled(HeaderItemContainer)`
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 1280px) {
    display: none;
  }
`;

export const MockBlock = styled.div`
  min-width: 332px;
  @media screen and (max-width: 1280px) {
    display: none;
  }
`;

export const SelectContainer = styled(HeaderItemContainer)`
  max-width: 332px;
  width: 100%;
  justify-content: flex-end;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const Title = styled.h1`
  font-family: Formular, HelveticaNeueCyr, sans-serif;
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${({ theme }): string => theme.colors.black};
  margin: 0;
`;

export const MobileTitle = styled.div`
  display: none;
  font-family: Formular, HelveticaNeueCyr, sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${({ theme }): string => theme.colors.black};
  margin: 0 0 18px;

  @media screen and (max-width: 1280px) {
    display: block;
  }
`;

export const PersonalLink = styled(Link)`
  font-family: HelveticaNeueCyr, sans-serif;
  font-size: 14px;
  line-height: 30px;
  width: max-content;
  color: ${({ theme }): string => theme.colors.black};

  &:hover {
    opacity: 0.5;
  }

  @media screen and (max-width: 1280px) {
    display: none;
  }
`;

export const ShareButton = styled.button`
  font-family: HelveticaNeueCyr, sans-serif;
  font-size: 14px;
  line-height: 30px;
  max-width: max-content;
  width: 100%;

  background-color: transparent;
  border: 0;

  outline: 0;
  display: flex;
  justify-content: start;
  cursor: pointer;
  gap: 8px;
  aling-items: center;
  color: ${({ theme }): string => theme.colors.black};

  &:hover {
    opacity: 0.5;
  }
  @media screen and (max-width: 1280px) {
    font-weight: 500;
    letter-spacing: 0.1em;
  }
`;

export const PersonalLinkMobile = styled(Link)`
  display: none;
  font-family: HelveticaNeueCyr, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.1em;
  color: ${({ theme }): string => theme.colors.black};

  @media screen and (max-width: 1280px) {
    display: block;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  width: max-content;
  min-width: 332px;
  @media screen and (max-width: 1280px) {
    justify-content: space-between;
    width: 100%;
  }
`;

export const BackArrow = styled(Icons.Arrow)`
  width: 16px;
  height: 16px;
  stroke: ${({ theme }): string => theme.colors.black};
  stroke-width: 2px;
  vertical-align: text-bottom;
  margin: 0 6px 0 0;
  transform: rotate(270deg);
`;

export const EmptyFavorites = styled(Flex)`
  flex-direction: column;
  align-items: center;
  max-width: 366px;
  margin: 0 auto;

  p {
    font-family: Formular;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #000000;
    margin: 0 0 32px;
  }
`;

export const CatalogLink = styled(LinkButton)`
  max-width: 366px;
`;

export const MobileFilterButton = styled(Button)`
  display: none;
  font-size: 12px;
  max-width: 100px;
  text-transform: none;
  font-weight: 400;
  padding: 5px 2px 3px;

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

export const MockItem = styled(Box)`
  width: 100%;
  height: auto;
  background-color: lightgrey;
  opacity: 0.5;
  margin-bottom: 70px;
  max-height: 670px;

  &:before {
    content: "";
    height: 0;
    display: inline-block;
    padding-top: 170%;
    width: 1px;
    position: relative;
  }

  @media screen and (min-width: 1920px) {
    max-height: 820px;
    &:before {
      padding-top: 180%;
    }
  }

  @media screen and (min-width: 2298px) {
    max-height: 790px;
    &:before {
      padding-top: 180%;
    }
  }

  @media screen and (min-width: 2760px) {
    max-height: 815px;
    &:before {
      padding-top: 200%;
    }
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 55px;
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 60px;
  }
`;

export const ContainerMock = styled(Grid)`
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 64px;

  @media screen and (min-width: 2298px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media screen and (min-width: 2760px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media screen and (max-width: 1600px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 40px;
  }

  @media screen and (max-width: 480px) {
    grid-row-gap: 30px;
    grid-column-gap: 14px;
  }

  &.favourites {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 50px;

    @media screen and (max-width: 1600px) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media screen and (max-width: 1440px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 850px) {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 40px;
    }

    @media screen and (max-width: 480px) {
      display: none;
    }
  }
`;
