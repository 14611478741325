import React, { FC } from "react";

import { SvgProps } from "src/types";

const Burger: FC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 20 20">
    <path d="M0 1h20v2H0V1zM0 9h20v2H0V9zM0 17h20v2H0v-2z" />
  </svg>
);

Burger.defaultProps = {
  width: "20px",
  height: "20px",
  fill: "black",
};

export default Burger;
