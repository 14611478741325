import React, { FC, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useHistory } from "react-router-dom";

import { Garderobo } from "src/components/Garderobo";
import { ROUTES } from "src/constants";
import { SeoMeta } from "src/types";
import { generateMetaTags } from "src/utils";

import {
  ErrorWrapper,
  ErrorDescription,
  ErrorMessage,
  ErrorNumber,
  ErrorButton,
} from "./Page404.styles";

export const Page404: FC = () => {
  const history = useHistory();
  const seoTitle = "Not found";
  const seoDescription = "Страница не найдена";
  const [metaTags, setMetaTags] = useState<SeoMeta[]>([]);

  useEffect(() => {
    setMetaTags(() => [
      ...generateMetaTags(seoTitle, seoDescription),
      {
        name: "prerender-status-code",
        content: "404",
      },
    ]);
  }, []);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet defer={false} title={seoTitle} meta={metaTags} />
      </HelmetProvider>
      <ErrorWrapper>
        <ErrorNumber>404</ErrorNumber>
        <ErrorMessage>Страница не найдена</ErrorMessage>
        <ErrorDescription>
          К сожалению, запрашиваемая вами страница была перемещена или удалена.
        </ErrorDescription>
        <ErrorButton onClick={() => void history.push(ROUTES.home)}>
          НА ГЛАВНУЮ
        </ErrorButton>
      </ErrorWrapper>
      <Garderobo />
    </>
  );
};
