import ru from "date-fns/locale/ru";
import React, { FC, useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Input } from "../EditPersonalInfo/EditPersonalInfo.styles";

import {
  DatePickerWrapper,
  CsutomSelect,
  CustomDatePicker,
} from "./DatePicker.styles";
import { DatePickerProps } from "./DatePicker.types";

registerLocale("ru", ru);

const DatePicker: FC<DatePickerProps> = ({
  dateValue,
  setDateValue,
  className,
  placeholderText,
  disabled = true,
}) => {
  const Month = [
    "январь",
    "февраль",
    "март",
    "апрель",
    "май",
    "июнь",
    "июль",
    "август",
    "сентябрь",
    "октябрь",
    "ноябрь",
    "декабрь",
  ];
  const startYear = 1900;
  const [selectIsOpenM, setSelectIsOpenM] = useState(false);
  const [selectIsOpenY, setSelectIsOpenY] = useState(false);
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const [currentMonth, setCurrentMonth] = useState("январь");
  const [currentYear, setCurrentYear] = useState(2020);
  const [selectArray, setSelectArray] = useState<any>([]); //  eslint-disable-line @typescript-eslint/no-explicit-any
  const [selected, setSelected] = useState<any>(); //  eslint-disable-line @typescript-eslint/no-explicit-any

  useEffect(() => {
    if (dateValue && dateValue > new Date()) {
      setDateValue(new Date());
    }
    if (dateValue) {
      const currentDate = new Date(dateValue);
      setCurrentMonth(
        new Intl.DateTimeFormat("ru-Ru", { month: "long" }).format(currentDate)
      );
      setCurrentYear(currentDate.getFullYear());
    } else {
      const currentDate = new Date();
      setCurrentMonth(
        new Intl.DateTimeFormat("ru-Ru", { month: "long" }).format(currentDate)
      );
      setCurrentYear(currentDate.getFullYear());
    }
  }, [dateValue]);

  return (
    <CustomDatePicker>
      <div
        role="none"
        onClick={() => {
          setCalendarIsOpen(true);
        }}
      >
        <Input
          value={
            dateValue
              ? `${dateValue.getDate()}.${
                  dateValue.getMonth() < 9
                    ? "0" + (dateValue.getMonth() + 1)
                    : dateValue.getMonth() + 1
                }.${dateValue.getFullYear()}`
              : ""
          }
          name="name"
          placeholder={placeholderText}
          aria-label={placeholderText}
          className={className}
          disabled={disabled}
        />
      </div>
      {calendarIsOpen && (
        <>
          <CsutomSelect>
            <DatePickerWrapper
              selected={dateValue}
              onChange={(date: Date) => {
                setDateValue(date);
                setCalendarIsOpen(false);
              }}
              maxDate={new Date()}
              locale="ru"
              dateFormat="P"
              className={className}
              placeholderText={placeholderText}
              inline
            />
            <div className="absolute">
              <div className="select--head">
                <h4
                  role="none"
                  onClick={() => {
                    setSelected(currentYear);
                    const array = [];
                    for (
                      let i = new Date().getFullYear();
                      i >= startYear;
                      i--
                    ) {
                      array.push(i);
                    }
                    setSelectArray(array);
                    setSelectIsOpenM(false);
                    setSelectIsOpenY(true);
                  }}
                >
                  {currentYear}
                  <svg
                    className={selectIsOpenY ? "open" : ""}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.175 5.81641L8 9.64141L11.825 5.81641L13 6.99974L8 11.9997L3 6.99974L4.175 5.81641Z"
                      fill="black"
                    />
                  </svg>
                </h4>
                <h4
                  role="none"
                  onClick={() => {
                    setSelected(currentMonth);
                    setSelectArray(Month);
                    setSelectIsOpenM(true);
                    setSelectIsOpenY(false);
                  }}
                >
                  {currentMonth}
                  <svg
                    className={selectIsOpenM ? "open" : ""}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.175 5.81641L8 9.64141L11.825 5.81641L13 6.99974L8 11.9997L3 6.99974L4.175 5.81641Z"
                      fill="black"
                    />
                  </svg>
                </h4>
              </div>
              {(selectIsOpenM || selectIsOpenY) && (
                <>
                  <div className="select--content">
                    <div className="overflow--scroll">
                      {selectArray.map((
                        m: any //  eslint-disable-line @typescript-eslint/no-explicit-any
                      ) => (
                        <p
                          role="none"
                          onClick={() => {
                            if (selected !== m) {
                              const newDateValue = new Date();
                              newDateValue.setDate(dateValue?.getDate() || 1);
                              if (typeof selected == "number") {
                                newDateValue?.setFullYear(m);
                                newDateValue?.setMonth(
                                  Month.indexOf(currentMonth)
                                );
                              } else {
                                newDateValue?.setMonth(Month.indexOf(m));
                                newDateValue?.setFullYear(currentYear);
                              }
                              setSelectIsOpenM(false);
                              setSelectIsOpenY(false);
                              setDateValue(newDateValue);
                            }
                          }}
                          className={`option ${
                            m === selected ? "option--select" : ""
                          }`}
                          key={m}
                        >
                          {m}
                        </p>
                      ))}
                    </div>
                  </div>
                  <div
                    role="none"
                    onClick={() => {
                      setSelectIsOpenM(false);
                      setSelectIsOpenY(false);
                      setSelectArray([]);
                    }}
                    className="select--click_outside"
                  ></div>
                </>
              )}
            </div>
          </CsutomSelect>
          <div
            role="none"
            onClick={() => {
              setCalendarIsOpen(false);
            }}
            className="select--click_outside"
          ></div>
        </>
      )}
    </CustomDatePicker>
  );
};

export default DatePicker;
