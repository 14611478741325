import React, { FC } from "react";

import { SvgProps } from "src/types";

const ArrowCarousel: FC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9749 16L4.94977 8.97485L11.9749 1.94974"
      stroke="white"
      stroke-width="2"
    />
  </svg>
);

ArrowCarousel.defaultProps = {
  width: "18px",
  height: "18px",
  fill: "none",
};

export default ArrowCarousel;
