/* eslint-disable max-len */

import React, { FC } from "react";

import { SvgProps } from "src/types";

const Return: FC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 9C10.5523 9 11 8.55228 11 8C11 7.44772 10.5523 7 10 7V9ZM5 8H4C4 8.55228 4.44772 9 5 9V8ZM6 3C6 2.44772 5.55228 2 5 2C4.44772 2 4 2.44772 4 3H6ZM6.12969 15.8123C5.82889 15.3491 5.20956 15.2175 4.74638 15.5183C4.28319 15.8191 4.15155 16.4384 4.45235 16.9016L6.12969 15.8123ZM9.19218 19.4907L8.84116 20.4271H8.84116L9.19218 19.4907ZM14.1925 19.6937L14.4664 20.6554L14.1925 19.6937ZM18.5337 7.38281L19.3503 6.80567L18.5337 7.38281ZM4.66436 6.78628C4.34444 7.23646 4.45003 7.86076 4.90021 8.18069C5.35039 8.50061 5.97469 8.39502 6.29462 7.94484L4.66436 6.78628ZM10 7H5V9H10V7ZM6 8V3H4V8H6ZM4.45235 16.9016C5.50065 18.5158 7.03887 19.7515 8.84116 20.4271L9.54321 18.5544C8.14142 18.0289 6.94504 17.0678 6.12969 15.8123L4.45235 16.9016ZM8.84116 20.4271C10.6435 21.1028 12.6153 21.1827 14.4664 20.6554L13.9185 18.732C12.4787 19.1421 10.945 19.0799 9.54321 18.5544L8.84116 20.4271ZM14.4664 20.6554C16.3176 20.1281 17.9504 19.0215 19.1262 17.4976L17.5426 16.2759C16.6282 17.4612 15.3583 18.3218 13.9185 18.732L14.4664 20.6554ZM19.1262 17.4976C20.3018 15.9737 20.9583 14.1129 20.9986 12.1884L18.9991 12.1465C18.9677 13.6432 18.4572 15.0905 17.5426 16.2759L19.1262 17.4976ZM20.9986 12.1884C21.0389 10.264 20.4612 8.3775 19.3503 6.80567L17.717 7.95996C18.5811 9.18253 19.0304 10.6498 18.9991 12.1465L20.9986 12.1884ZM19.3503 6.80567C18.2394 5.2337 16.6535 4.05968 14.8262 3.45536L14.1982 5.35421C15.6197 5.82432 16.8531 6.73752 17.717 7.95996L19.3503 6.80567ZM14.8262 3.45536C12.9989 2.85103 11.0259 2.84809 9.19679 3.44771L9.81981 5.3482C11.2423 4.88189 12.7768 4.88411 14.1982 5.35421L14.8262 3.45536ZM9.19679 3.44771C7.36778 4.04729 5.77934 5.21735 4.66436 6.78628L6.29462 7.94484C7.16183 6.72455 8.39725 5.81453 9.81981 5.3482L9.19679 3.44771Z"
      fill="black"
    />
  </svg>
);

Return.defaultProps = {
  width: "24px",
  height: "24px",
  fill: "none",
};

export default Return;
