import { WishlistState, WishlistActions, WishlistTypes } from "./types";

const initialState: WishlistState = {
  wish_list: [],
  wishListNoUuid: [],
};

export const wishlistReducer = (
  state = initialState,
  action: WishlistActions
): WishlistState => {
  switch (action.type) {
    case WishlistTypes.SET:
      return { ...state, wish_list: action.payload };
    case WishlistTypes.CLEAR: {
      return { ...state, wish_list: [] };
    }
    case WishlistTypes.SET_NO_UUID:
      return { ...state, wishListNoUuid: action.payload };
    case WishlistTypes.CLEAR_NO_UUID: {
      return { ...state, wishListNoUuid: [] };
    }
    default:
      return state;
  }
};

export default wishlistReducer;
