import React, { FC } from "react";
import { Scrollbars } from "react-custom-scrollbars";

type ThumbProps = {
  style?: Record<string, unknown>;
};

const Thumb: FC<ThumbProps> = (props) => {
  const { style, ...rest } = props;

  const thumbStyle = {
    backgroundColor: "#C4C4C4",
    borderRadius: "0px",
    opacity: 1,
    width: "5px",
  };

  return <div style={{ ...style, ...thumbStyle }} {...rest} />;
};

const Scrollbar: FC = (props) => {
  const { children } = props;

  return (
    <Scrollbars
      renderThumbVertical={Thumb}
      renderThumbHorizontal={() => <div />}
      autoHeight={true}
      autoHeightMax={260}
    >
      {children}
    </Scrollbars>
  );
};

export default Scrollbar;
