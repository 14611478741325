import { MenuItem } from "src/types";

export type NavigationMenu = {
  menu: MenuItem[];
};

export enum NavigationMenuTypes {
  SET = "SET_NAVIGATION_MENU",
  CLEAR = "CLEAR_NAVIGATION_MENU",
}

export type SetNavigationMenu = {
  type: NavigationMenuTypes.SET;
  payload: MenuItem[];
};

export type ClearNavigationMenu = {
  type: NavigationMenuTypes.CLEAR;
};

export type NavigationMenuActions = SetNavigationMenu | ClearNavigationMenu;
