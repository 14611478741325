import { FC } from "react";
import { Link, LinkProps } from "react-router-dom";
import styled from "styled-components";

import { Icons } from "src/components";

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: flex-start;
  padding: 0;
`;

export const ListItem = styled.li`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 8px;
`;

export const ListItemLink = styled(Link)`
  flex-shrink: 0;
  padding: 2px;
` as FC<LinkProps>;

export const EditIcon = styled(Icons.Edit).attrs({
  width: "14px",
  height: "14px",
})``;

export const ListItemAddress = styled.span`
  font-family: HelveticaNeueCyr, sans-serif;
  font-size: 14px;
  margin-left: 8px;
`;

export const EmptyLabel = styled.span`
  font-family: HelveticaNeueCyr, sans-serif;
`;
