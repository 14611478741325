import { LocalStorageFavoriteItem, WishlistItem } from "src/types";

export type WishlistState = {
  wish_list: WishlistItem[];
  wishListNoUuid: LocalStorageFavoriteItem[];
};

export enum WishlistTypes {
  SET = "SET_WISHLIST",
  CLEAR = "CLEAR_WISHLIST",
  SET_NO_UUID = "SET_WISHLIST_NO_UUID",
  CLEAR_NO_UUID = "CLEAR_WISHLIST_NO_UUID",
}

export type SetWishlistNoUuid = {
  type: WishlistTypes.SET_NO_UUID;
  payload: LocalStorageFavoriteItem[];
};

export type ClearWishlistNoUuid = {
  type: WishlistTypes.CLEAR_NO_UUID;
  payload: LocalStorageFavoriteItem[];
};

export type SetWishlist = {
  type: WishlistTypes.SET;
  payload: WishlistItem[];
};

export type ClearWishlist = {
  type: WishlistTypes.CLEAR;
};

export type WishlistActions =
  | SetWishlist
  | ClearWishlist
  | SetWishlistNoUuid
  | ClearWishlistNoUuid;
