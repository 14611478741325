import styled, { css } from "styled-components";

const typography = css`
  ol,
  ul {
    padding-inline-start: 1.2em;

    li {
      font-size: 24px;

      @media screen and (max-width: 480px) {
        font-size: 20px;
      }
    }
  }

  p {
    font-family: "HelveticaNeueCyr", sans-serif;
    font-size: 14px;
  }

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 20px;
    margin: 0 0 24px 0;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    color: #8e8e8e;
    font-size: 10px;
    font-weight: normal;

    text-transform: uppercase;
    margin: 0 0 12px 0;
  }

  @media (min-width: 768px) {
    p {
      font-size: 16px;
    }

    h1 {
      font-size: 62px;
    }

    h2 {
      font-size: 34px;
      margin: 0 0 32px 0;
    }

    h3 {
      font-size: 22px;
      margin: 0;
    }

    h4 {
      font-size: 12px;
    }
  }

  @media (min-width: 1200px) {
    h2 {
      font-size: 34px;
    }

    h3 {
      font-size: 22px;
    }

    h4 {
      font-size: 12px;
    }
  }
`;

export const PageRoot = styled.div`
  ${typography};

  * {
    position: relative;
    box-sizing: border-box;
  }
  padding: 0 15px;
  @media (min-width: 768px) {
    padding: 0 20px;
  }
  @media (min-width: 1200px) {
    padding: 0 40px;
  }

  article {
    margin: 0 auto;

    width: 100%;
    @media (min-width: 1440px) {
      width: 920px;
    }
    @media (min-width: 1200px) {
      width: 750px;
    }
    @media (min-width: 1440px) {
      width: 1230px;
    }

    > header {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      > h1 {
        padding: 0;
        text-align: center;

        margin-bottom: 40px;
        @media (min-width: 768px) {
          margin-bottom: 70px;
        }
        @media (min-width: 1200px) {
          margin-bottom: 90px;
        }
        @media (min-width: 1440px) {
          margin-bottom: 120px;
        }
      }

      > img {
        object-fit: cover;
        min-height: 50vh;
        width: calc(100vw - 30px);

        @media (min-width: 768px) {
          width: calc(100vw - 40px);
        }

        @media (min-width: 1200px) {
          width: calc(100vw - 80px);
        }
      }

      margin-bottom: 70px;
      @media (min-width: 768px) {
        margin-bottom: 150px;
      }
      @media (min-width: 1200px) {
        margin-bottom: 95px;
      }

      /* article > header [end] */
    }

    > main {
      > header {
        text-align: center;

        margin-bottom: 43px;
        @media (min-width: 768px) {
          margin-bottom: 75px;
        }
        @media (min-width: 1440px) {
          margin-bottom: 105px;
        }
      }

      > section {
        margin-bottom: 80px;

        @media (min-width: 768px) {
          margin-bottom: 95px;

          > * {
            padding: 0 120px;
          }
        }

        @media (min-width: 1200px) {
          margin-bottom: 150px;
        }

        > div.variant-images {
          display: grid;
          grid-auto-flow: row;
          grid-template-columns: repeat(2, 1fr);
          grid-auto-rows: auto;
          grid-gap: 15px;

          > * {
            // fix img tag sizing
            width: 100%;
          }

          @media (min-width: 1440px) {
            grid-template-columns: repeat(3, 1fr);
          }
        }

        > div.variant-lists {
          display: grid;
          grid-auto-flow: row;
          grid-auto-rows: auto;
          grid-gap: 20px;
          --columns: 1;
          grid-template-columns: repeat(var(--columns), 1fr);

          @media (min-width: 1200px) {
            --columns: 2;
          }
          @media (min-width: 1440px) {
            --columns: 3;
          }
        }
      }

      /* article > main [end] */
    }
  }
`;
