import styled from "styled-components";

export const Container = styled.main`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 0 40px 0 40px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 769px) {
    padding: 0 20px 0 20px;
    margin: 0;
  }
`;
export const Content = styled.div`
  .wrapper-last {
    @media (min-width: 769px) {
      display: grid;
      grid-column-gap: 120px;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: minmax(50px, auto);
      justify-content: space-between;
    }
    @media (min-width: 1201px) {
      margin: 25px 0 150px 0;
    }
    @media (max-width: 1201px) {
      margin: 7px 0 135px 0;
    }
    @media (max-width: 768px) {
      margin: 7px 0 236px 0;
    }
    @media (max-width: 375px) {
      margin: 0 0 200px 0;
    }
  }

  .wrapper {
    @media (min-width: 769px) {
      margin: 0 0 15px 0;
      display: grid;
      grid-column-gap: 120px;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: minmax(50px, auto);
      justify-content: space-between;
    }
    @media (min-width: 768px) {
      margin: 7px 0 15px 0;
    }
    @media (min-width: 1201px) {
      margin: 25px 0 15px 0;
    }
    @media (max-width: 375px) {
      margin: 0 15px 0;
    }
  }

  .item1 {
    @media (min-width: 769px) {
      grid-row: 1 / 3;
    }
  }

  .item2 {
    @media (min-width: 769px) {
      grid-row: 1 / 2;
    }
  }

  .item3 {
    margin-bottom: 0;
    margin-top: 0;
    @media (min-width: 769px) {
      grid-row: 3/4;
    }
    @media (max-width: 768px) {
      margin-bottom: 32px;
    }
    @media (max-width: 375px) {
      margin-bottom: 24px;
    }
  }

  .item4 {
    margin-bottom: 12px;
    margin-top: 0;
    @media screen and (max-width: 769px) {
      margin-bottom: 15px;
      margin-top: 0;
    }
    @media (max-width: 375px) {
      margin-bottom: 0;
    }
    @media (min-width: 769px) {
      grid-row: 2/4;
    }
  }

  .item5 {
    @media (min-width: 769px) {
      grid-row: 1 / 2;
      grid-column: 1/2;
    }
  }

  .item6 {
    margin-bottom: 0;
    margin-top: 0;
    @media (max-width: 768px) {
      margin-bottom: 32px;
    }
    @media (max-width: 375px) {
      margin-bottom: 24px;
    }
    @media (min-width: 769px) {
      grid-row: 1 / 5;
      grid-column: 2/3;
    }
  }

  .item7 {
    margin-bottom: 12px;
    margin-top: 0;
    @media screen and (max-width: 769px) {
      margin-bottom: 15px;
    }
    @media (max-width: 375px) {
      margin-bottom: 0;
    }
    @media (min-width: 769px) {
      grid-row: 2 / 4;
    }
  }

  .item8 {
    @media (min-width: 769px) {
      grid-row: 1 / 2;
    }
  }

  .item9 {
    @media (min-width: 769px) {
      grid-row: 1 / 3;
    }
  }

  .item10 {
    margin-bottom: 0;
    margin-top: 0;
    @media (min-width: 769px) {
      grid-row: 2/5;
    }
  }

  .item11 {
    margin-bottom: 0;
    margin-top: 0;
    @media (min-width: 769px) {
      grid-row: 3/4;
    }
  }
  .div-margin {
    margin-bottom: 12px;
    margin-top: 0;
    @media screen and (max-width: 769px) {
      margin-bottom: 32px;
      margin-top: 0;
    }
  }

  .div-margin24 {
    margin-bottom: 12px;
    margin-top: 0;
    @media screen and (max-width: 769px) {
      margin-bottom: 24px;
      margin-top: 0;
    }
  }

  .div-no-margin {
    margin-bottom: 0;
    margin-top: 0;
    @media screen and (max-width: 769px) {
      margin-bottom: 32px;
      margin-top: 0;
    }
  }

  .div-no-margin24 {
    margin-bottom: 0;
    margin-top: 0;
    @media screen and (max-width: 769px) {
      margin-bottom: 24px;
      margin-top: 0;
    }
  }

  .div-no-mobile-margin0 {
    margin-bottom: 0;
    margin-top: 0;
    @media screen and (max-width: 769px) {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  .hide-content-mobile {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .content {
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    max-width: 500px;
    letter-spacing: 0.01em;

    &-important {
      font-weight: 500;
      font-style: normal;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.01em;
    }

    b {
      font-weight: 400;
    }
  }

  b {
    font-weight: 400;
    margin-right: 5px;
  }

  h3 {
    font-weight: 450;
    font-size: 18px;
    line-height: 18px;
    margin-top: 0;
  }

  h4 {
    font-weight: 450;
    font-size: 15px;
    line-height: 20px;
  }

  ol {
    padding-left: 23px;
    font-weight: 400;
    margin: 10px 0 16px;

    li {
      margin-bottom: 10px;
    }
  }

  ul {
    margin: 0;
    padding: 0;

    .weight-300 {
      font-weight: 300;
    }

    li {
      list-style: none;
      position: relative;
      padding-left: 18px;
      margin-bottom: 7px;
      font-weight: 400;

      b {
        font-weight: 450;
      }

      &::before {
        content: "";
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 100%;
        top: 4px;
        left: 4px;
        background: black;
      }
    }
  }

  .underline {
    text-decoration: underline;
    color: black;
  }

  .link {
    font-family: "HelveticaNeueCyr";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-decoration-line: underline;
  }

  .d-flex {
    display: flex;
    flex-wrap: wrap;
  }

  .mb-74 {
    margin-bottom: 74px;
  }

  .mb-52 {
    margin-bottom: 52px;
  }

  .mb-40 {
    margin-bottom: 40px;
  }

  .mb-32 {
    margin-bottom: 32px;
  }

  .mb-27 {
    margin-bottom: 27px;
  }

  .mb-24 {
    margin-bottom: 24px;
  }

  .mb-3 {
    margin-bottom: 3px;
  }

  .mb-8 {
    margin-bottom: 8px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .mb-14 {
    margin-bottom: 14px;
  }

  .mb-13 {
    margin-bottom: 13px;
    @media screen and (max-width: 769px) {
      margin-bottom: 24px;
    }
  }

  .mb-12 {
    margin-bottom: 12px;
  }

  .mb-6 {
    margin-bottom: 6px;
  }

  .mb-2 {
    margin-bottom: 2px;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mt-16 {
    margin-top: 16px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mt-12 {
    margin-top: 12px;
  }

  .mt-0 {
    margin-top: 0;
  }

  .mb-32-24 {
    @media screen and (max-width: 768px) {
      margin-bottom: 32px;
    }
    @media screen and (max-width: 375px) {
      margin-bottom: 24px;
    }
  }

  @media screen and (max-width: 768px) {
    .mb-74,
    .mb-52,
    .mb-40 {
      margin-bottom: 24px;
    }

    .mb-38 {
      margin-bottom: 38px;
    }

    .mb-32 {
      margin-bottom: 12px;
    }

    .mb-27 {
      margin-bottom: 12px;
    }
  }

  .mb-32-m {
    margin-bottom: 16px;
    @media (max-width: 1199px) {
      margin-bottom: 32px;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 32px;
    }
  }
`;
export const ReturnPageStyle = styled.div`
  text-align: center;
  .bb-none {
    border-bottom: none;
  }
  h1 {
    text-align: center;
    font-family: "Formular";
    font-style: normal;
    font-weight: 500;
    font-size: 62px;
    line-height: 40px;
    letter-spacing: 0.01em;
    margin: 84px 0 82px;
    @media (max-width: 1200px) {
      margin: 84px 0 80px;
    }
    @media (max-width: 769px) {
      margin: 60px 0 100px;
    }
    @media (max-width: 767px) {
      margin: 40px 0 62px;
      font-size: 27px;
      line-height: 27px;
      letter-spacing: 0;
    }
  }
  p {
    font-family: HelveticaNeueCyr;
    font-size: 15px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0;
    text-align: left;
    a {
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      font-weight: 450;
      margin-bottom: 0;
    }
  }
  .bold {
    width: 100%;
    max-width: 409px;
    font-weight: 450;
    margin: 0 auto 14px;
  }
  .weight-450 {
    font-weight: 450;
  }
`;
export const Title = styled.h1`
  font-size: 34px;
  line-height: 42px;
  margin-bottom: 40px;

  @media screen and (max-width: 769px) {
    font-size: 26px;
    line-height: 32px;
  }
`;
