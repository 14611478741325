import { STORAGE } from "src/constants";

export const parseImages = (gallery?: string | string[]): string[] => {
  if (!gallery) {
    return [];
  }
  let parsedImages: string[] = [];

  if (Array.isArray(gallery)) {
    parsedImages = gallery;
  } else if (typeof gallery === "object") {
    parsedImages = Object.values(gallery);
  } else {
    parsedImages = JSON.parse(gallery);
  }

  const filteredImages = parsedImages.filter((image) => image !== null);
  return filteredImages;
};

export const convertImageUrl = (
  urlArray: string,
  width: number,
  height: number
): string => {
  const resolutionPart = `/cache/${height}_${width}_`;
  const imagePathPart = urlArray ? urlArray.replace(/\\/g, "/") : "";
  const convertedImagePath = imagePathPart.replace(/\..*$/, ".webp");
  const convertedUrl = STORAGE + resolutionPart + convertedImagePath;
  return convertedUrl;
};
