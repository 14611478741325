import React, { FC } from "react";
import useCollapse from "react-collapsed";

import { Icons } from "src/components";
import { CollapseProps } from "src/components/MobileGlobalMenu/elements/Collapse/Collapse.types";

import { Button, Content, Divider, Container } from "./Collapse.styles";

const collapseStyles = {
  transitionDuration: "300ms",
  transitionTimingFunction: "cubic-bezier(0.25, 0.46, 0.45, 0.94)",
};

const Collapse: FC<CollapseProps> = (props) => {
  const { title, children, className, onClick } = props;

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    expandStyles: collapseStyles,
    collapseStyles,
  });

  return (
    <Container
      isInnerFilter={className === "inner-filter"}
      className={className}
      onClick={onClick}
    >
      <Button
        isInnerFilter={className === "inner-filter"}
        className={className}
        {...getToggleProps()}
      >
        <span>{title}</span>
        {isExpanded ? <Icons.Minus /> : <Icons.Plus />}
      </Button>
      <Content
        isPrice={title === "Цена"}
        {...getCollapseProps()}
        isInnerFilter={className === "inner-filter"}
      >
        {children}
      </Content>
      {className !== "inner-filter" && <Divider />}
    </Container>
  );
};

export default Collapse;
