import React, { FC } from "react";

import MultiRangeSlider from "src/components/MultiRangeSlider/MultiRangeSlider";
import { useQueryParams } from "src/hooks";
import { Filter, FilterValue } from "src/types";

import { Collapse as CollapseInner } from "../../CatalogFilters/elements/Collapse/";

import { Collapse, Input, FilterWithSearch } from "./elements";
import {
  Block,
  InnerBlock,
  InputContainer,
} from "./MobileCatalogFiltersInner.styles";
import { CatalogFiltersProps } from "./MobileCatalogFiltersInner.types";

const MobileCatalogFiltersInner: FC<CatalogFiltersProps> = (props) => {
  const {
    filters,
    isLoading,
    filterPrice,
    onClose,
    onChange,
    values,
    onReset,
  } = props;

  const { queryParams } = useQueryParams({
    arrayFormat: "bracket",
  });

  const getValues = (
    filter: Filter,
    value: FilterValue,
    isInnerFilter: boolean
  ) => {
    return (
      <InputContainer isInnerFilter={isInnerFilter} key={value.id}>
        <Input
          filterValue={value}
          filterType={filter.type}
          filterCode={filter.code}
          onChange={(
            event: any //  eslint-disable-line @typescript-eslint/no-explicit-any
          ) => {
            if (queryParams["filter[min_price]"]) {
              delete queryParams["filter[min_price]"];
            }
            if (queryParams["filter[max_price]"]) {
              delete queryParams["filter[max_price]"];
            }
            onChange(event, filter, value);
          }}
          values={values}
          disabled={isLoading}
        />
      </InputContainer>
    );
  };

  return (
    <>
      {filters.map(
        (filter) =>
          filter.values.length > 0 && (
            <Block key={filter.name}>
              <Collapse
                title={filter.name}
                onClose={onClose}
                onReset={onReset}
                values={values}
                filters={filters}
              >
                {filter.name === "Бренд" && (
                  <FilterWithSearch
                    filter={filter}
                    values={values}
                    disabled={isLoading}
                    onChange={(
                      event: any //  eslint-disable-line @typescript-eslint/no-explicit-any
                    ) => {
                      onChange(event, filter);
                    }}
                  />
                )}
                {filter.name !== "Цена" &&
                  filter.name !== "Размер" &&
                  filter.name !== "Бренд" && (
                    <>
                      {filter.values.map((value) =>
                        getValues(filter, value, false)
                      )}
                    </>
                  )}
                {filter.name === "Размер" && (
                  <div>
                    {filter.name === "Размер" && (
                      <InputContainer className="filterInner">
                        {filter.sizevalues &&
                          filter.sizevalues.map((value) =>
                            getValues(filter, value, false)
                          )}
                        {!!filter.sizearrayvalues?.values &&
                          filter.sizearrayvalues.map((sizearray) => {
                            return (
                              <>
                                <CollapseInner
                                  className="inner-filter"
                                  title={sizearray.category}
                                  onClose={onClose}
                                >
                                  {sizearray &&
                                    sizearray.values.map((value) => {
                                      return (
                                        <InnerBlock>
                                          {getValues(filter, value, true)}
                                        </InnerBlock>
                                      );
                                    })}
                                </CollapseInner>
                              </>
                            );
                          })}
                      </InputContainer>
                    )}
                  </div>
                )}
                {filter.name === "Цена" && (
                  <MultiRangeSlider
                    min={filterPrice ? +filterPrice.min_price : 0}
                    max={filterPrice ? +filterPrice.max_price : 1000000}
                    minValQuery={queryParams["filter[min_price]"] || null}
                    maxValQuery={queryParams["filter[max_price]"] || null}
                    onChange={(
                      event: any //  eslint-disable-line @typescript-eslint/no-explicit-any
                    ) => {
                      onChange(event, filter);
                    }}
                  />
                )}
              </Collapse>
            </Block>
          )
      )}
    </>
  );
};

export default MobileCatalogFiltersInner;
