import { useCallback, useRef } from "react";

import { UseDebounce } from "./useDebounce.types";

const useDebounce = (): UseDebounce => {
  const timer = useRef<number>();

  return useCallback(
    (callback, delay) => {
      window.clearTimeout(timer.current);
      timer.current = window.setTimeout(callback, delay);
    },
    [timer]
  );
};

export default useDebounce;
