import styled from "styled-components";

import { Button } from "../Button";
import { IconButton } from "../IconButton";
import { Icons } from "../Icons";
import { Modal } from "../Modal";

export const ModalContainer = styled(Modal)`
  position: absolute;

  bottom: 0;
  right: 0;

  z-index: 1000;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 391px;
  min-height: 137px;

  padding: 28px 32px 26px;

  background-color: ${({ theme }): string => theme.colors.white};

  @media (max-width: 1150px) {
    width: 100%;
    max-width: 420px;
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;

  top: 12px;
  right: 12px;

  &:hover {
    opacity: 0.5;
  }
`;

export const CloseIcon = styled(Icons.Close)`
  width: 12px;
  height: 12px;

  padding: 0;

  stroke: white;
  stroke-width: 1px;
`;

export const Title = styled.h3`
  margin-top: 0;
  margin-bottom: 18px;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  font-family: "HelveticaNeueCyr";

  text-align: center;
`;

export const CloseBottomButton = styled(Button)`
  width: 174px;

  padding: 7px 46px;

  background: ${({ theme }): string => theme.colors.black};
  border: 1px solid #3a3a3a;

  color: ${({ theme }): string => theme.colors.white};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.01em;
`;
