import styled from "styled-components";

import { IconButton } from "../IconButton";
import { Icons } from "../Icons";
import { LinkButton } from "../LinkButton";

export const ModalContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.93);
  width: 390px;
  min-height: 190px;
  bottom: 2px;
  right: 2px;
  position: fixed;
  border: 1px solid ${({ theme }): string => theme.colors.grey};
  z-index: 100;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 2px;
  right: 2px;
  background: transparent;

  &:hover {
    opacity: 0.5;
  }
`;

export const CloseIcon = styled(Icons.Close)`
  width: 14px;
  height: 14px;
`;

export const Wrapper = styled.div`
  width: 100%;
  min-height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;

  p {
    margin: 0 0 21px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
  }
`;

export const LoginLink = styled(LinkButton)`
  width: 147px;
  height: 34px;
  font-size: 14px;
`;
