import React, { FC } from "react";

import {
  CloseButton,
  CloseIcon,
  ModalContainer,
  Title,
  CloseBottomButton,
} from "./BasketErrorModal.styles";
import { BasketErrorModalProps } from "./BasketErrorModal.types";

const BasketErrorModal: FC<BasketErrorModalProps> = ({
  errorMessage,
  onClose,
}) => {
  return (
    <ModalContainer onClose={onClose}>
      <CloseButton onClick={onClose} aria-label="Закрыть">
        <CloseIcon />
      </CloseButton>

      <Title>
        {errorMessage
          ? errorMessage
          : "К сожалению, товар из вашей корзины закончился."}
      </Title>
      <CloseBottomButton onClick={onClose}>ОK</CloseBottomButton>
    </ModalContainer>
  );
};

export default BasketErrorModal;
