import React, { FC, useState, useMemo, useEffect } from "react";
import TagManager from "react-gtm-module";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Switch, Redirect, Route, useLocation } from "react-router-dom";

import { ROUTES } from "src/constants";
import { SeoMeta } from "src/types";
import { generateMetaTags } from "src/utils";

import { Container } from "./Auth.styles";
import { ConfirmationPage } from "./Conf";
import { LoginPage } from "./Login";
import { ResetPage } from "./Reset";
import { UserInfoPage } from "./UserInfo";
import { WelcomePage } from "./Welcome";

const AuthPage: FC = () => {
  const { search } = useLocation();
  const [returnTo, setReturnTo] = useState("");

  const seoTitle = "Авторизация - интернет-магазин Leform";
  const seoDescription =
    "Авторизация. Leform: концептуальный интернет-магазин одежды, обуви, аксессуаров и предметов интерьера";
  const metaTags: SeoMeta[] = generateMetaTags(seoTitle, seoDescription);

  const returnUrl = useMemo(() => {
    const urlParams = new URLSearchParams(search);
    return urlParams.get("returnTo");
  }, [search]);

  useEffect(() => {
    if (returnUrl) {
      setReturnTo(returnUrl);
    }
  }, [returnUrl]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  }, []);

  // if (isAuthorized && !isFirstTime) {
  //   return <Redirect to={ROUTES.personalData} />;
  // }

  return (
    <Container>
      <HelmetProvider>
        <Helmet defer={false} title={seoTitle} meta={metaTags} />
      </HelmetProvider>
      <Switch>
        <Route exact path={ROUTES.auth} component={WelcomePage} />
        <Route exact path={ROUTES.login} component={LoginPage} />
        <Route path={ROUTES.reset} component={ResetPage} />
        <Route exact path={ROUTES.confirmationAuth}>
          <ConfirmationPage returnTo={returnTo} />
        </Route>
        <Route exact path={ROUTES.userInfo} component={UserInfoPage} />

        <Redirect to={ROUTES.auth} />
      </Switch>
    </Container>
  );
};

export default AuthPage;
