import React, { useState, useCallback, FC, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { loginUser } from "src/api";
import { Preloader } from "src/components";
import { LoginFormValues } from "src/components/LoginForm/LoginForm.types";
import { ROUTES } from "src/constants";
import { useForm } from "src/hooks";
import { usePreloaderTimer } from "src/hooks/usePreloaderTimer";

import { AuthHeader } from "../elements";

import { Container, Form, ErrorCaptcha, WrapperError } from "./Login.styles";

const LoginPage: FC = () => {
  function handleLoad() {
    grecaptcha.ready(() =>
      grecaptcha.render("html_element", {
        sitekey: "6LeDjJcmAAAAAJf88E5LHqqeaF7Zh3aC4BmKJSGz",
        theme: "dark",
        callback: verifyCallback,
        "expired-callback": clearToken,
        "error-callback": clearToken,
      })
    );
  }
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.onload = handleLoad;

    document.getElementsByTagName("head")[0].appendChild(script);
  }, []);
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);

  const initialValues: LoginFormValues = {
    phone: "",
    token: "",
    codeLength: 11,
  };

  const { values, onChange, onPaste, setValues, errors, setErrors } = useForm(
    initialValues
  );

  const [isLoaderShown, onStartFetching, onEndFetching] = usePreloaderTimer();
  const verifyCallback = (token_google: string) => {
    setValues({
      token: token_google,
    });
  };
  const clearToken = () => {
    setValues({
      token: "",
    });
  };
  const onSubmit = useCallback(async () => {
    const phoneValue = values.phone.replace(/[^0-9]/g, "");
    if (!values.token.length) {
      setErrors({
        token: "Подтвердите, что вы не робот",
      });
      return;
    }

    if (phoneValue.length !== values.codeLength || !/^\d+$/.test(phoneValue)) {
      setErrors({
        phone: "Неправильный формат",
      });
      return;
    }

    setLoading(true);
    onStartFetching();

    loginUser({
      phone: phoneValue,
      token: values.token,
    })
      .then(({ data }) => {
        sessionStorage.setItem("phone", phoneValue);
        sessionStorage.setItem("use_external", data.use_external);
        history.push({
          pathname: ROUTES.confirmationAuth,
          state: {
            isRegister: data.use_external,
          },
        });
      })
      .catch((error) => {
        setValues(initialValues);
        setErrors({
          phone: error.message,
        });
        setLoading(false);
      })
      .finally(onEndFetching);
  }, [
    values,
    history,
    setValues,
    onPaste,
    setErrors,
    onStartFetching,
    onEndFetching,
  ]);

  return (
    <Container>
      {isLoaderShown && <Preloader />}

      <AuthHeader />
      <Form
        values={values}
        onChange={onChange}
        onSubmit={onSubmit}
        onPaste={onPaste}
        isLoading={isLoading}
        errors={errors}
      />
      <div id="html_element"></div>
      {(errors.token || errors.phone) && (
        <WrapperError>
          <ErrorCaptcha>{errors.token || errors.phone}</ErrorCaptcha>
        </WrapperError>
      )}
    </Container>
  );
};

export default LoginPage;
