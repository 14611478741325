import styled from "styled-components";

import { Link, Button } from "src/components";

import { AccountNavProps } from "./types";

export const Container = styled.main`
  position: relative;
  width: 100%;
  height: calc(100% - 160px);
  display: flex;
  padding: 70px 40px;

  @media (max-width: 767px) {
    padding: 40px 15px;
  }
`;

export const AccountNav = styled.aside<AccountNavProps>`
  flex: 477px 0 0;

  @media (max-width: 1200px) {
    flex: 410px 0 0;
  }

  @media (max-width: 1150px) {
    display: ${({ isRootAccount }) => (isRootAccount ? "block" : "none")};

    ${({ isRootAccount }) =>
      isRootAccount &&
      `
        flex: 100% 0 0;
        text-align: center;
      `};
  }
`;
export const AccountContent = styled.section`
  flex: 1 0 0;
`;

export const AccountTitle = styled.h3`
  font-family: Formular;
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.black};
  margin: 0 0 22px;
`;

export const UserName = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.grey};
  text-transform: uppercase;
  margin: 0 0 60px;
`;

export const Nav = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const NavItem = styled.li`
  margin-bottom: 37px;
`;

export const NavItemLink = styled(Link)`
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.grey};

  &.active {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const Logout = styled(Button)`
  max-width: 100px;
`;
