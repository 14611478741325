import React, { useState, createContext, FC } from "react";

export type ActiveSliderContextValue = {
  activeSliderId: string | null;
  setActiveSliderId: (id: string | null) => void;
};

const INITIAL_VALUE: ActiveSliderContextValue = {
  activeSliderId: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActiveSliderId: (id: string | null) => {},
};

export const ActiveSliderContext = createContext<ActiveSliderContextValue>(
  INITIAL_VALUE
);

export const ActiveSliderProvider: FC = (props) => {
  const { children } = props;

  const [activeSliderId, setActiveSliderId] = useState<string | null>(null);

  const value: ActiveSliderContextValue = {
    activeSliderId,
    setActiveSliderId,
  };

  return (
    <ActiveSliderContext.Provider value={value}>
      {children}
    </ActiveSliderContext.Provider>
  );
};

export const useActiveSlider = (): ActiveSliderContextValue =>
  React.useContext(ActiveSliderContext);
