import React, { FC } from "react";

import { MenuList, MenuItem, MenuLink } from "./SecondaryMenu.styles";
import { SecondaryMenuProps } from "./SecondaryMenu.types";

const SecondaryMenu: FC<SecondaryMenuProps> = (props) => {
  const { className, items } = props;

  return (
    <MenuList className={className} role="menu">
      {items.map(({ label, to, isActive }) => (
        <MenuItem key={`${label}-${to}`} isActive={isActive} role="menuitem">
          <MenuLink to={to}>{label}</MenuLink>
        </MenuItem>
      ))}
    </MenuList>
  );
};

export default SecondaryMenu;
