import React, { FC } from "react";

import { SvgProps } from "src/types";

const Minus: FC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 1024 1024">
    <path d="M872 474H152a8 8 0 00-8 8v60a8 8 0 008 8h720a8 8 0 008-8v-60a8 8 0 00-8-8z" />
  </svg>
);

Minus.defaultProps = {
  width: "20px",
  height: "20px",
  fill: "black",
};

export default Minus;
