import { AxiosRequestConfig, AxiosResponse } from "axios";

import { API } from "src/constants";
import { LocalStorageFavoriteItem } from "src/types";

import { Http } from "../http";

import {
  CatalogResponse,
  ProductResponse,
  DeliveryInfoResponse,
  CommonProductsResponse,
  SearchResponse,
  AddFavoriteResponse,
  ProductQuantityResponse,
  LastViewsResponse,
  FavoriteResponse,
  FavoriteListResponse,
  ShareFavoriteResponse,
  GetIdForShareResponse,
} from "./catalog.types";

export const getCatalog = (
  pathname: string,
  params: AxiosRequestConfig["params"],
  checkoutId?: string | null
): CatalogResponse => {
  let umper = "?";
  if (pathname.includes("?")) {
    umper = "&";
  }
  return Http.get(`${API}${pathname}${umper}basket_uuid=${checkoutId}`, {
    params,
  });
};

export const getProduct = (
  id: string,
  checkoutId?: string | null
): ProductResponse =>
  Http.get(`${API}/catalog/product/${id}?basket_uuid=${checkoutId}`);

export const getProductQuantity = (
  id: number,
  basketId: string | undefined
): ProductQuantityResponse =>
  Http.get(
    `${API}/stocks/get_item_quantity?product_id=${id}&basket_uuid=${basketId}`
  );

export const getDeliveryInfo = (): DeliveryInfoResponse =>
  Http.get(`${API}/include_area?code=product_delivery`);

export const getRecommendation = (id: number): CommonProductsResponse =>
  Http.get(`${API}/recommendation?id=${id}`);

export const searchInCatalog = (search: string): SearchResponse =>
  Http.get(`${API}/search`, { params: { string: search } });

export const getLastViewsProductsByUuid = (
  basket_uuid: string
): LastViewsResponse =>
  Http.post(`${API}/catalog/last_views/get-by-uuid`, { basket_uuid });

export const getLastViewsProductsByIds = (
  product_ids: string[]
): LastViewsResponse =>
  Http.post(`${API}/catalog/last_views/get-by-ids`, {
    product_ids,
  });

export const addFavoriteProduct = (
  productId: number | string,
  userId: number | string
): AddFavoriteResponse =>
  Http.get(`${API}/wishlist?product_id=${productId}&user_id=${userId}`);

export const getFavoriteProducts = (
  userId: number | string
): CommonProductsResponse => Http.get(`${API}/wishlist_get?user_id=${userId}`);

export const addLastViewsProductsByUuid = (
  basket_uuid: string,
  product_id: number
): Promise<AxiosResponse> =>
  Http.post(`${API}/add_last_view`, { basket_uuid, product_id });

export const addFavoriteProductWithBasketUuid = (
  product_id: number | string,
  basket_uuid: string,
  offer_id?: number
): AddFavoriteResponse =>
  Http.get(`${API}/wishlist`, {
    params: { product_id, basket_uuid, offer_id },
  });

export const getFavoritesListByUuid = (
  basket_uuid: string
): FavoriteListResponse =>
  Http.post(`${API}/catalog/favorites/get-list-by-uuid`, { basket_uuid });

export const getFavoriteProductsByUuid = (
  basket_uuid: string
): FavoriteResponse =>
  Http.post(`${API}/catalog/favorites/get-by-uuid`, { basket_uuid });

export const getFavoriteProductsByIds = (request: {
  products?: LocalStorageFavoriteItem[];
}): FavoriteResponse =>
  Http.post(`${API}/catalog/favorites/get-by-ids`, {
    products: request.products,
  });

export const getFavoritesListByUserId = (id: string): ShareFavoriteResponse =>
  Http.get(`${API}/catalog/favorites/get-for-user/${id}`);

export const getIdForShare = (): GetIdForShareResponse =>
  Http.get(`${API}/create-share-favorites`);

export const removeFavorite = (
  product_id: number | string,
  basket_uuid: string
): AddFavoriteResponse =>
  Http.get(`${API}/wishlist`, { params: { product_id, basket_uuid } });
