import { combineReducers, createStore, Store } from "redux";

import { CheckoutReducer } from "./checkout";
import { NavigationMenuReducer } from "./navigationMenu";
import { ApplicationStore, RootReducer } from "./types";
import { UserReducer } from "./user";
import { VariablesReducer } from "./variables";
import { WishlistReducer } from "./wishlist";

const rootReducer = combineReducers<RootReducer>({
  user: UserReducer,
  checkout: CheckoutReducer,
  navigationMenu: NavigationMenuReducer,
  wishlist: WishlistReducer,
  variables: VariablesReducer,
});

const configureStore = (initialState?: ApplicationStore): Store =>
  createStore(rootReducer, initialState);

export default configureStore;
