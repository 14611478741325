import { SelectOption } from "src/components/Select/Select.types";
import { LocalStorageFavoriteItem, SortItems } from "src/types";

export const parseSortingItems = (items?: SortItems): SelectOption[] => {
  if (!items) return [];

  return Object.entries(items).reduce(
    (acc: SelectOption[], [value, item]) => [
      ...acc,
      { value, label: item.name },
    ],
    []
  );
};

export const getBasketId = (): string | null => {
  const basketId = localStorage.getItem("basket_id");
  if (basketId === null) {
    return null;
  } else if (basketId === "undefined") {
    return "undefined";
  } else {
    const uuidv4Regex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
    return uuidv4Regex.test(basketId) ? basketId : "undefined";
  }
};

export const setBasketId = (id: string): void => {
  localStorage.setItem("basket_id", id);
};

export const getFavoritesFromLocalStorage = (): LocalStorageFavoriteItem[] => {
  const favorites = localStorage.getItem("favorites");
  return favorites ? (JSON.parse(favorites) as LocalStorageFavoriteItem[]) : [];
};

export const setFavoritesToLocalStorage = (
  favorites: LocalStorageFavoriteItem[]
): void => {
  localStorage.setItem("favorites", JSON.stringify(favorites));
};

export const getLastViewsFromLocalStorage = (): string[] => {
  const lastViews = localStorage.getItem("last_views");
  return lastViews ? (JSON.parse(lastViews) as string[]) : [];
};

export const setLastViewsToLocalStorage = (lastViews: string[]): void => {
  localStorage.setItem("last_views", JSON.stringify(lastViews));
};

export const clearBasketId = (): void => {
  localStorage.removeItem("basket_id");
};

export const getCookie = (name: string): string | undefined => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts && parts.length === 2) {
    return parts.pop()?.split(";").shift();
  }
};
