import React, { FC } from "react";

import { SvgProps } from "src/types";

const Shop: FC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_8861_7298)">
      <path
        d="M21 13V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V13H2V11L3 6H21L22 11V13H21ZM5 13V19H19V13H5ZM6 14H14V17H6V14ZM3 3H21V5H3V3Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_8861_7298">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

Shop.defaultProps = {
  width: "24px",
  height: "24px",
  fill: "none",
};

export default Shop;
