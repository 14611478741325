import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  KeyboardEvent,
} from "react";

import { searchInCatalog } from "src/api";
import { stringifyConfig } from "src/constants";
import { useDebounce, useQueryParams } from "src/hooks";
import { CatalogListItem } from "src/types";

import {
  SearchWrapper,
  SearchContainer,
  Form,
  SearchInput,
  SearchButton,
  SearchIcon,
  CloseButton,
  CloseIcon,
  ResultContainer,
  ResultCount,
  Result,
  ResultItem,
} from "./Search.styles";
import { SearchProps } from "./Search.types";

const DEBOUNCE_TIME = 500;

const Search: FC<SearchProps> = ({ onClose }) => {
  const debounce = useDebounce();

  const [items, setItems] = useState<CatalogListItem[]>([]);
  const [searchValue, setSearchValue] = useState("");

  const { setQueryParams } = useQueryParams({
    arrayFormat: "bracket",
  });

  const onSearch = useCallback(() => {
    setQueryParams(
      { q: searchValue || undefined },
      true,
      stringifyConfig,
      "/catalog"
    );
    onClose();
  }, [onClose, searchValue, setQueryParams]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setSearchValue(event.target.value),
    [setSearchValue]
  );

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        onSearch();
      }
    },
    [onSearch]
  );

  const handleSearch = useCallback(() => {
    onSearch();
  }, [onSearch]);

  useEffect(() => {
    if (searchValue) {
      debounce(() => {
        searchInCatalog(searchValue).then(({ data }) => {
          setItems(data.items);
        });
      }, DEBOUNCE_TIME);
    }
  }, [debounce, setItems, searchValue]);

  return (
    <SearchWrapper>
      <SearchContainer>
        <Form>
          <SearchButton onClick={handleSearch} aria-label="Найти">
            <SearchIcon />
          </SearchButton>
          <SearchInput
            value={searchValue}
            placeholder="Введите слова для поиска"
            onChange={handleChange}
            onKeyPress={handleKeyPress}
          />
          <CloseButton onClick={onClose} aria-label="Закрыть">
            <CloseIcon />
          </CloseButton>
        </Form>

        {/* fast-links in search */}
        {/* <SearchWords>
          {fastSearch.map((itemLink) => (
            <FastLink
              onClick={() => handleFastSearch(itemLink.word)}
              key={itemLink.word}
            >
              {itemLink.word}
            </FastLink>
          ))}
        </SearchWords> */}

        {items.length && (
          <ResultContainer>
            <ResultCount>Найдено товаров ({items.length})</ResultCount>
            <Result>
              {items.map((item) => (
                <ResultItem to={item.link} onClick={onClose} key={item.id}>
                  {item.name}
                </ResultItem>
              ))}
            </Result>
          </ResultContainer>
        )}
      </SearchContainer>
    </SearchWrapper>
  );
};

export default Search;
