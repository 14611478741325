import styled from "styled-components";

import { Box } from "../../../Box";

export const Button = styled.button<{ isInnerFilter: boolean }>`
  margin: 0;
  width: 100%;
  border: none;
  font-size: 20px;
  background-color: inherit;
  text-align: left;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 20px 0;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }

  @media screen and (max-width: 600px) {
    font-size: 20px;
    padding-left: 14px;
  }

  svg {
    fill: ${({ theme }): string => theme.colors.black30};
    width: 24px;
    height: 24px;
  }

  span {
    color: ${({ theme }): string => theme.colors.black};
  }

  ${(props) =>
    props.isInnerFilter &&
    `
    margin-top: 0;
    &:not(:first-child) {
      margin-top: 60px;
    }
    padding-bottom: 16px;
    font-size: 16px;
    `}
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
`;

export const Content = styled(Box)<{
  isInnerFilter: boolean;
  isPrice?: boolean;
}>`
  width: 100%;
  height: ${({ isPrice }) => (isPrice ? "80px" : "auto")};
  margin-top: 8px;

  @media screen and (max-width: 600px) {
    padding: 0 14px;
  }

  ${(props) =>
    props.isInnerFilter &&
    `
    padding: 0;
    margin: 0;
  `}
`;

export const Divider = styled(Box)`
  position: relative;
  width: 100%;
  height: 1px;
  margin-top: 12px;
  background-color: ${({ theme }): string => theme.colors.lightGrey};
`;

export const Container = styled(Box)<{ isInnerFilter: boolean }>`
  width: 100%;

  @media screen and (max-width: 767px) {
    &.sortingFavorites {
      > div {
        padding: 0 14px;
      }

      > button {
        font-size: 20px;
        padding-left: 14px;
      }
    }
  }

  ${(props) =>
    props.isInnerFilter &&
    `
    margin-bottom: 0;
    `}
`;
