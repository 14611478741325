import styled from "styled-components";

import { Box } from "../Box";
import { Flex } from "../Flex";
import { Text } from "../Text";

export const ProductColorContainer = styled(Box)`
  margin-top: 46px;
  width: 100%;
  .choose_box {
    transition: height 0.3s ease;
    border: unset;
    margin-top: 10px;
  }

  @media screen and (max-width: 760px) {
    margin-top: 60px;
  }
`;

export const ColorTitle = styled(Text)`
  color: ${({ theme }): string => theme.colors.grey};
  font-size: 12px;
  text-transform: uppercase;
  margin: 0;

  @media screen and (max-width: 760px) {
    font-weight: 500;
    color: ${({ theme }): string => theme.colors.black};
  }
`;

export const ColorsContainerWrapper = styled.div`
  position: relative;
`;

export const ColorsContainer = styled(Flex)`
  display: grid;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 9px;
  border: 0;
  grid-gap: 11px 11px;
  grid-template-columns: repeat(6, 1fr);

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media screen and (min-width: 1050px) {
    grid-gap: 14px 14px;
  }
`;

export const ShowAllProductColors = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }): string => theme.colors.black};
  text-decoration: underline;
  cursor: pointer;
  float: right;
  margin-top: 6px;
  margin-bottom: 14px;

  &:hover {
    text-decoration: none;
  }
`;

export const ProductColorContainerWrapper = styled.div`
  margin: 0;
  border: 0;
`;
