import React, { FC, useMemo } from "react";

import pay from "../Icons/Payment/YandexPay.svg";
import split from "../Icons/Payment/YandexSplit.svg";

import {
  Fieldset,
  Item,
  Img,
  Text,
  RadioInput,
  StyledRadioButton,
} from "./styles";
import { RadioGroupProps } from "./types";

const RadioGroup: FC<RadioGroupProps> = ({
  name,
  current,
  onChange,
  items,
  className,
}) => {
  const getClassName = useMemo(() => {
    if (className) {
      if (className.includes("payment_radio")) {
        return "payment_radio";
      }
      return "";
    } else {
      return "";
    }
  }, [className]);

  return (
    <Fieldset className={className}>
      {items.map((item) => (
        <Item key={item.value} className={getClassName}>
          <RadioInput
            name={name}
            value={item.value}
            checked={item.value === current}
            onChange={() => onChange(item.value)}
          />
          <StyledRadioButton />
          {item?.code === "yapay" && <Img src={pay} alt="Yandex Pay logo" />}
          {item?.code === "ysplit" && (
            <Img src={split} alt="Yandex Split logo" />
          )}
          <Text>{item.text}</Text>
        </Item>
      ))}
    </Fieldset>
  );
};
export default RadioGroup;
