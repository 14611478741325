export const PHONE = "+7 800 775-19-70";

export const ADDRESSES = [
  {
    id: 1,
    address: "Поварская ул., 35/28,",
    metro: "м. Баррикадная",
    phone: "+7 (495) 691-82-20",
    name: "Баррикадная",
  },
  {
    id: 2,
    address: "Дмитровский пер., д. 7,",
    metro: "м. Театральная",
    phone: "+7 (495) 660-02-80",
    name: "Театральная",
  },
  {
    id: 3,
    address: "Краснопролетарская, 30, стр. 1,",
    metro: "м. Новослободская",
    phone: "+7 (495) 657-82-88",
    name: "Новослободская",
  },
  {
    id: 4,
    address:
      "МО, городской округ Мытищи,\nдеревня Шолохово,\nДмитровскоe шоссе, д. 2б, с. 1",
    metro: "",
    phone: "+7 (498) 672-83-36",
    name: "Каштановая роща",
  },
];

export const INSTAGRAM = "https://instagram.com/leformstore/";
export const FACEBOOK = "https://www.facebook.com/LEFORM.store";
export const VK = "https://vk.com/leformstore";
export const TG = "https://t.me/leform_store";
