import React, { FC } from "react";

import { SvgProps } from "src/types";

const Share: FC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 10V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V10"
      stroke="black"
      stroke-width="1.2"
      stroke-linejoin="round"
    />
    <path
      d="M16 8L12 4L8 8"
      stroke="black"
      stroke-width="1.2"
      stroke-linejoin="round"
    />
    <path
      d="M12 4L12 15"
      stroke="black"
      stroke-width="1.2"
      stroke-linejoin="round"
    />
  </svg>
);

Share.defaultProps = {
  width: "24px",
  height: "24px",
  fill: "none",
};

export default Share;
