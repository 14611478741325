import React, { FC, useEffect, useState, useRef } from "react";

import { ArticleWrapper } from "./Article.styles";
import { ArticleProps } from "./Article.types";

const Article: FC<ArticleProps> = ({ productName, productArticle }) => {
  const [isTextOverflowing, setIsTextOverflowing] = useState<boolean>(false);
  const articleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (articleRef.current) {
        const isOverflowing = articleRef.current.clientHeight > 35;
        setIsTextOverflowing(!!isOverflowing);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [productArticle, productName, articleRef]);

  return (
    <ArticleWrapper id="article" ref={articleRef}>
      {isTextOverflowing ? (
        <>
          <div>{productName}</div> <div>Артикул: {productArticle}</div>
        </>
      ) : (
        <>
          {productName} | Артикул: {productArticle}
        </>
      )}
    </ArticleWrapper>
  );
};

export default Article;
