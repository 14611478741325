import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setUserAddresses } from "src/actions";
import { getAddresses, deleteAddress, setMainAddress } from "src/api";
import { ROUTES } from "src/constants";
import { ApplicationStore } from "src/store";
import { stringifyAddress } from "src/utils";

import {
  Title,
  List,
  ListItem,
  ListItemLink,
  ListItemAddress,
  ListItemAddressTitle,
  CheckboxWrapper,
  MainAddress,
  AddAddressLink,
  PlusIcon,
  HeaderWrapper,
  BackLink,
  BackIcon,
  BackIconMobile,
  DeleteItemLink,
} from "./AddressList.styles";

const AddressList: FC = () => {
  const dispatch = useDispatch();
  const { userAddresses, userData } = useSelector(
    (state: ApplicationStore) => state.user
  );

  const handleDelete = (address: {
    id: number;
    city?: string;
    street?: string;
    house?: string;
    flat?: string;
    zip_code?: string;
    main_address?: boolean | undefined;
    data?: unknown;
  }) => {
    if (!address.id) return;
    deleteAddress(address.id).then(() => {
      getAddresses().then((addresses) => {
        dispatch(setUserAddresses(addresses.data));
      });
    });
  };

  const setMainAddressHandler = (addressId: number) => {
    if (userData?.userId) {
      setMainAddress(userData?.userId, addressId).then(() => {
        if (userAddresses) {
          const addressesList = userAddresses.map((address) => {
            const result = address;
            result.main_address = address.id === addressId;
            return result;
          });
          dispatch(setUserAddresses(addressesList));
        }
      });
    }
  };

  return (
    <>
      <HeaderWrapper>
        <BackLink to={ROUTES.accountPage}>
          <BackIcon />
          <BackIconMobile />
          <span>Назад</span>
        </BackLink>
        <Title>Мои адреса</Title>
      </HeaderWrapper>
      {userAddresses && userAddresses.length > 0 && (
        <List>
          {userAddresses.map((address) => (
            <ListItem key={address.id}>
              <CheckboxWrapper>
                <MainAddress
                  name="main_address"
                  label="Основной адрес"
                  type="radio"
                  value={address.id}
                  checked={address.main_address}
                  onChange={() => setMainAddressHandler(address.id)}
                />
              </CheckboxWrapper>

              <ListItemAddress>
                <ListItemAddressTitle>Адрес доставки: </ListItemAddressTitle>
                {stringifyAddress(address)}
              </ListItemAddress>

              <ListItemLink to={`${ROUTES.myAddresses}/${address.id}`}>
                изменить
              </ListItemLink>
              <DeleteItemLink to="#" onClick={() => handleDelete(address)}>
                удалить
              </DeleteItemLink>
            </ListItem>
          ))}
        </List>
      )}

      <AddAddressLink to={ROUTES.addMyAddress}>
        <PlusIcon />
        Добавить адрес
      </AddAddressLink>
    </>
  );
};

export default AddressList;
