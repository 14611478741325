import styled from "styled-components";

export const Label = styled.span`
  font-weight: bold;
  margin-bottom: 10px;
`;

export const Address = styled.span`
  line-height: 20px;
`;

export const Wrapper = styled.div`
  display: grid;
`;
