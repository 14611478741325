import { ParsedQuery, StringifiableRecord } from "query-string";
import { ChangeEvent } from "react";

import { Filter } from "src/types";

import { CatalogFilterValues } from "./CatalogFilters.types";

type addParamsType = {
  is_new?: boolean;
  is_sale?: boolean;
  advanced_color?: string;
  gender_id?: string;
  product_section?: string;
  type_id?: string;
  brand_id?: string;
};
export const getFilterKeys = (filters: Filter[]): string[] => {
  return filters.map(({ code }) => `filter[${code}]`);
};

const arrayToObject = <T>(arr: T[]) => {
  return arr.reduce((acc, item) => {
    return { ...acc, [`${item}`]: true };
  }, {});
};

export const getFilters = (
  data: StringifiableRecord,
  keys: string[],
  filters: Filter[],
  addParams?: addParamsType
): CatalogFilterValues => {
  const obj: CatalogFilterValues = {};

  keys.forEach((key) => {
    const item = data[key];
    if (item) {
      obj[key] = Array.isArray(item) ? arrayToObject(item) : item;
    }
  });

  if (addParams) {
    const keysArr = Object.keys(addParams);
    for (let i = 0; i < keysArr.length; i++) {
      const key = keysArr[i];
      const code = addParams[key as keyof typeof addParams];

      const searchElId: any = filters //  eslint-disable-line @typescript-eslint/no-explicit-any
        .filter(
          (
            i: any //  eslint-disable-line @typescript-eslint/no-explicit-any
          ) => i.code === key
        )[0]
        ?.values.filter(
          (
            i: any //  eslint-disable-line @typescript-eslint/no-explicit-any
          ) => i.code === code
        )[0];
      if (searchElId) {
        const curElID: any = {}; //  eslint-disable-line @typescript-eslint/no-explicit-any
        curElID[`${searchElId.id}`] = true;
        obj[`filter[${key}]`] = curElID;
      }
      if (key === "is_new" || key === "is_sale") {
        obj[`filter[${key}]`] = code;
      }
    }
  }

  return obj;
};

export const deserializeFilters = (
  query: ParsedQuery,
  filterArr: Filter[],
  addParams?: addParamsType
): CatalogFilterValues => {
  const keys = getFilterKeys(filterArr);

  return getFilters(query, keys, filterArr, addParams);
};
const setNewValue = (
  names: string[],
  value: boolean | string
): StringifiableRecord => {
  return names.reduceRight((acc, str, idx): CatalogFilterValues => {
    if (idx === names.length - 1) {
      return { [str]: value };
    }

    return { [str]: acc };
  }, {});
};

export const setFilterValues = (
  event: ChangeEvent<HTMLInputElement>
): StringifiableRecord => {
  const { name, value, checked, type: inputType } = event.target;

  const names = name.split(":");

  if (inputType === "checkbox") {
    return setNewValue(names, checked);
  }

  return setNewValue(names, value);
};

export const serializeFilters = (
  data: StringifiableRecord,
  filterArr: Filter[]
): StringifiableRecord => {
  const obj: StringifiableRecord = { ...data };
  const keys = getFilterKeys(filterArr);

  keys.forEach((key) => {
    const item = obj[key];
    if (item && typeof item === "object") {
      obj[key] = Object.entries(item).reduce((acc: string[], [name, value]) => {
        return value ? [...acc, name] : acc;
      }, []);
    }
  });

  return obj;
};
