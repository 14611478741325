import React, { FC } from "react";
import useCollapse from "react-collapsed";

import { AccordeonBody } from "./Accordeon.styles";
import { AccordeonProps } from "./Accordeon.types";

const Accordeon: FC<AccordeonProps> = ({ header, content, className = "" }) => {
  const collapseStyles = {
    transitionDuration: "300ms",
    transitionTimingFunction: "cubic-bezier(0.25, 0.46, 0.45, 0.94)",
  };
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    expandStyles: collapseStyles,
    duration: 800,
    collapseStyles,
  });
  return (
    <AccordeonBody className={className}>
      <div
        {...getToggleProps()}
        role="none"
        className={`accordeon-header ${
          isExpanded ? "accordeon-header--active" : ""
        }`}
      >
        <h2 className="header">{header}</h2>
        {isExpanded ? (
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="horiaontal"
          >
            <path
              d="M18.8019 10.8965V8.99702H8.90239V8.90247H0.99694V10.802H10.8964V10.8965H18.8019Z"
              fill="black"
            />
          </svg>
        ) : (
          <div>
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="vertical"
            >
              <path
                d="M18.8019 10.90247H10.8964V0.99702H8.90239V8.8965H8.90239V18.802H10.8964V10.8965H18.8019Z"
                fill="black"
              />
            </svg>
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="horiaontal"
            >
              <path
                d="M18.8019 10.8965V8.99702H8.90239V8.90247H0.99694V10.802H10.8964V10.8965H18.8019Z"
                fill="black"
              />
            </svg>
          </div>
        )}
      </div>
      <div {...getCollapseProps()} className="accordeon-content">
        {content}
      </div>
    </AccordeonBody>
  );
};

export default Accordeon;
