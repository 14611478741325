import styled from "styled-components";

import { BorderlessInput, Button } from "src/components";

import { Flex } from "../Flex";

import { PromoCouponProps } from "./Promocode.types";

export const Promo = styled(Flex)`
  padding: 6px 0;
  justify-content: space-between;
  position: relative;
  width: 100%;

  @media screen and (max-width: 768px) {
    max-width: 370px;
  }
`;

export const PromoCoupon = styled(BorderlessInput)<PromoCouponProps>`
  font-size: 12px;
  width: 100%;
  line-height: 20px;
  border: none;
  padding: 0 10px;
  border: 1px solid ${({ theme }): string => theme.colors.lightGrey};
  border-right: 0;
  height: 50px;

  &::placeholder {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const PromoButton = styled(Button)`
  width: 138px;
  height: 50px;
`;

export const PromoText = styled.p`
  font-size: 12px;
  line-height: 20px;
  bottom: -54px;
  left: 30px;
  height: 20px;
  z-index: 10;

  @media screen and (max-width: 768px) {
    bottom: -44px;
  }
`;

export const PromoHeader = styled.p`
  font-size: 12px;
  line-height: 20px;
  margin: 10px 0;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const PromoError = styled.p`
  font-size: 12px;
  line-height: 20px;
  color: red;
  width: 100%;
  margin: 0;
`;
