import { UserFormValues } from "./UserInfoForm.types";

export const validateUserInfoForm = (
  values: UserFormValues
): Partial<Record<keyof UserFormValues, string | null>> => {
  const errors: Partial<Record<keyof UserFormValues, string | null>> = {};

  if (!values.name.trim()) {
    errors.name = "Введите имя";
  }

  if (!values.email.trim()) {
    errors.email = "Введите email";
  }

  if (!values.birthDate) {
    errors.birthDate = "Укажите дату рождения";
  }

  return errors;
};
