import styled from "styled-components";

import { Box } from "../Box";
import { Button } from "../Button";
import { Link } from "../Link";

export const Form = styled.form`
  width: 100%;
  padding: 0;
  margin: 0;
  .pl-63 {
    padding-left: 70px;
    position: relative;
    z-index: 2;
  }
  .relative {
    position: relative;
  }

  .select--content {
    position: relative;
    z-index: 100;
    background: white;
    min-width: 100%;
    height: 165px;
    border: 1px solid #000000;
    padding: 14px 18px;

    .overflow--scroll {
      height: 143px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background: #c4c4c4;
        border-radius: 17px;
      }
    }

    .option {
      font-family: "HelveticaNeueCyr";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      color: #000000;
      cursor: pointer;
      user-select: none;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      opacity: 0.4;
      margin: 0;
      img {
        width: 23px;
        height: 24px;
        margin-right: 8px;
      }

      &:hover {
        opacity: 1;
      }
    }

    .option--select {
      color: black;
      cursor: unset;
      opacity: 1;

      &:hover {
        opacity: 1;
      }
    }
  }
`;

export const LoginButton = styled(Button)`
  height: 50px;
  margin-top: 60px;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const PasswordContainer = styled(Box)`
  width: 100%;
  margin-top: 40px;
`;

export const ResetLink = styled(Link)`
  font-size: 12px;
  color: ${({ theme }): string => theme.colors.grey};
  background-color: ${({ theme }): string => theme.colors.white};
  padding: 12px 0;
  position: absolute;
  bottom: 1px;
  right: 0;
  z-index: 2;

  :hover {
    color: ${({ theme }): string => theme.colors.black};
  }
`;

export const CustomSelect = styled.div`
  .absolute {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
  }
  .select--head {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    padding: 4px 8px;
    width: 59px;
    h4 {
      display: flex;
      align-items: center;
      font-family: "HelveticaNeueCyr";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      color: #000000;
      margin: 0 5px 0 0;
      text-transform: capitalize;
      cursor: pointer;
      &:hover {
        opacity: 0.4;
      }
    }
    img {
      width: 23px;
      height: 24px;
      margin-right: 7px;
    }
    svg {
      transition: transform 0.3s ease;
    }
    .open {
      transform: rotate(180deg);
    }
  }
  }
`;
