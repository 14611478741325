import React, { ChangeEvent, FC, useEffect, useState } from "react";

import { getAddressDataWithType } from "src/api";
import { useDebounce } from "src/hooks";
import { SearchAddressData, SearchAddressType } from "src/types";

import {
  Wrapper,
  SearchAddressInput,
  ResultAddresses,
  AddressItem,
} from "./SearchAddress.styles";
import { SearchAddressProps } from "./SearchAddress.types";
import { getAddressValues } from "./SearchAddress.utils";

const DEBOUNCE_TIME = 100;

const SearchAddress: FC<SearchAddressProps> = ({
  setValues,
  value,
  query,
  type,
  disabled,
  error,
}) => {
  const debounce = useDebounce();

  const [resultAddresses, setResultAddresses] = useState<SearchAddressType[]>(
    []
  );
  const searchTypes = [
    {
      type: "c",
      name: "city",
      placeholder: "Город / Регион",
    },
    {
      type: "s",
      name: "street",
      placeholder: "Улица",
    },
    {
      type: "h",
      name: "house",
      placeholder: "Дом",
    },
    {
      type: "f",
      name: "flat",
      placeholder: "Квартира / Офис",
    },
    {
      type: "z",
      name: "zip_code",
      placeholder: "Индекс",
    },
  ];

  const currentType =
    searchTypes.find((t) => t.type === type) || searchTypes[0];

  const [userHasInput, setUserHasInput] = useState(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [isMouseDown, setIsMouseDown] = useState(false);

  const searchAddressHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({ [currentType.name]: e.target.value });
    setUserHasInput(true);
  };

  useEffect(() => {
    if (type !== "z" && userHasInput && query && query.length >= 2) {
      if (value && value.length >= 1) {
        debounce(() => {
          getAddressDataWithType(query, type)
            .then(({ data }) => {
              if (!isFocused) {
                if (
                  type === "h" &&
                  !isSelected &&
                  data?.result[0]?.data?.postal_code
                ) {
                  setValues(getAddressValues(data.result[0].data));
                }
                if (resultAddresses.length !== 0) {
                  setResultAddresses([]);
                }
              } else {
                setResultAddresses(data.result);
              }
            })
            .catch((error) => {
              console.error(error);
            });
          setUserHasInput(false);
        }, DEBOUNCE_TIME);
      }
    }
  }, [value, query, debounce, userHasInput, isFocused]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (!isMouseDown) {
      setIsFocused(false);
    }
  };

  const handleMouseDown = () => {
    setIsMouseDown(true);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const handleSetValues = (data: SearchAddressData) => {
    setIsSelected(true);
    setValues(getAddressValues(data));
    setResultAddresses([]);
    setIsFocused(false);
    setIsSelected(false);
  };

  return (
    <Wrapper>
      <SearchAddressInput
        placeholder={currentType.placeholder}
        value={value}
        onChange={searchAddressHandler}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className="mb-25"
        hasError={error}
        disabled={disabled}
      />
      {isFocused && resultAddresses && resultAddresses.length > 0 && (
        <>
          <ResultAddresses
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onTouchStart={handleMouseDown}
            onTouchEnd={handleMouseUp}
          >
            {resultAddresses.map((addressItem, idx) => (
              <AddressItem
                key={type + idx}
                role="button"
                onClick={() => {
                  handleSetValues(addressItem.data);
                }}
              >
                {addressItem.value}
              </AddressItem>
            ))}
          </ResultAddresses>
        </>
      )}
    </Wrapper>
  );
};

export default SearchAddress;
