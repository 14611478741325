import { VariablesTypes } from "src/store";
import { SetCID, SetQuantity } from "src/store/variables";
import { cidData, quantityData } from "src/types";

export const setCID = (data: cidData): SetCID => ({
  type: VariablesTypes.SET_CID,
  payload: {
    cid: data.cid,
  },
});

export const setQuantity = (data: quantityData): SetQuantity => ({
  type: VariablesTypes.SET_QUANTITY,
  payload: {
    new_quantity: data.new_quantity,
  },
});
