import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router";

import { sendYaPayOrderEventToTagManager } from "src/utils";

import { Http } from "../../../api/http";
import { ROUTES } from "../../../constants";
import { useQueryParams } from "../../../hooks";
import { OrderCompleted } from "../elements";
import {
  CheckoutHeadline,
  Layout,
  OrderCompletedWrapper,
  ReturnLink,
} from "../elements/styles";

export const YaPayResult: FC = () => {
  const [orderId, setOrderId] = useState<string>("");
  const [error, setError] = useState(false);
  const [order, setOrder] = useState({
    total_price: 0,
    delivery_price: 0,
    basket: [
      {
        product: {
          id: 0,
          name: "",
        },
        result_price: 0,
        quantity: 0,
      },
    ],
  });
  const history = useHistory();

  const { queryParams } = useQueryParams({
    arrayFormat: "bracket",
  });
  useEffect(() => {
    if (orderId) {
      Http.get(`https://leback.leform.ru/api/auth/orders/${orderId}`).then(
        (res) => {
          setOrder(res.data);
        }
      );
    }
  }, [orderId]);
  useEffect(() => {
    const id = queryParams["order_id"];
    const error = queryParams["success"];
    if (id && typeof id == "string") {
      setOrderId(id.replaceAll(/\//g, ""));
    }
    if (error && error === "n") {
      setError(true);
    } else {
      sendYaPayOrderEventToTagManager();
    }
  }, []);

  useEffect(() => {
    if (order) {
      let url = `https://www.google-analytics.com/collect?v=1&TID=UA-82137536-1&CID=13987239.91782397123&ti=${orderId}&tr=${
        order.total_price
      }&ts=${order.delivery_price ? order.delivery_price : ""}`;
      order.basket?.map((item, index) => {
        const key = index + 1;
        url += `&pa=purchase&pr${key}id=${item.product.id}&pr${key}nm=${item.product.name}&pr${key}pr=${item.result_price}&pr${key}qt=${item.quantity}`;
      });
      //   &pr1ca=Мужское/Обувь/Кроссовки  \\ вложенность категорий первого товара в заказе
      //   &pr1br=Balenciaga                         // производитель\бренд первого товара в заказе.
      if (orderId) {
        Http.get(url);
      }
    }
  }, [order]);
  return !error ? (
    <OrderCompletedWrapper>
      <OrderCompleted orderNumber={orderId} paymentLink={undefined} />
      <ReturnLink
        variant="white"
        onClick={() => void history.push(ROUTES.home)}
      >
        Вернуться на главную страницу
      </ReturnLink>
    </OrderCompletedWrapper>
  ) : (
    <Layout>
      <CheckoutHeadline className="text_align--center">
        К сожалению при оплате возникла ошибка.
        <br />
        <br />
        Ваш заказ оформлен и совсем скоро свяжемся для подтверждения.
        <br />
        <span>Номер заказа: {orderId}</span>
      </CheckoutHeadline>
    </Layout>
  );
};
