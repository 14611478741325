import { indexOf } from "lodash";

import { LETTERS } from "src/constants";
import { GenderBrands } from "src/pages/Brands/Brands.types";
import { BrandsItem } from "src/types";

export const getSortedBrandsArray = (array: BrandsItem[]): BrandsItem[] => {
  return array.sort((brandA, brandB) => brandA.name.localeCompare(brandB.name));
};

export const getLettersArray = (array: BrandsItem[]): BrandsItem[][] => {
  const lettersArray: BrandsItem[][] = Array(28).fill(null);

  array.forEach((brand) => {
    if (brand.name.toLowerCase()[0] < "a") {
      if (!lettersArray[0]) {
        lettersArray[0] = [];
      }
      lettersArray[0].push(brand);
    } else if (brand.name.toLowerCase()[0] > "z") {
      if (brand.name.toLowerCase()[0] === "№") {
        if (!lettersArray[0]) {
          lettersArray[0] = [];
        }
        lettersArray[0].push(brand);
      } else {
        if (!lettersArray[27]) {
          lettersArray[27] = [];
        }
        lettersArray[27].push(brand);
      }
    } else {
      const letterIndex = indexOf(LETTERS, brand.name.toLowerCase()[0]);

      if (letterIndex > 0) {
        if (!lettersArray[letterIndex]) {
          lettersArray[letterIndex] = [];
        }
        lettersArray[letterIndex].push(brand);
      } else {
        if (!lettersArray[0]) {
          lettersArray[0] = [];
        }
        lettersArray[0].push(brand);
      }
    }
  });
  return lettersArray;
};

export const getFilteredBrandsArray = (
  array: BrandsItem[][],
  filterValue: GenderBrands
): BrandsItem[][] => {
  const filteredArray: BrandsItem[][] = [];

  array.forEach((brand, index) => {
    if (brand && brand.length) {
      const filtered = brand.filter((f) => f[filterValue]);
      filteredArray[index] = filtered;
    }
  });

  return filteredArray;
};
