import { Dispatch } from "react";

import {
  authorizeUser,
  setCheckout,
  setCurrentWorkFlow,
  setUserAddresses,
  setUserData,
  setWishlistUuid,
} from "src/actions";
import {
  getAddresses,
  getCheckout,
  getUserData,
  isTokenExist,
  loadToken,
  refreshToken,
  removeToken,
  setToken,
} from "src/api";
import { UserTypes, WishlistTypes } from "src/store";

import { getBasketId, getFavoritesFromLocalStorage, setBasketId } from "./data";

export const updateUser = (
  dispatch: Dispatch<any> // eslint-disable-line @typescript-eslint/no-explicit-any
): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    try {
      if (isTokenExist()) {
        loadToken();
        const { data: tokenData } = await refreshToken();
        setToken(tokenData.access_token);
        dispatch(
          authorizeUser({ ...tokenData, userWorkFlow: "authenticated" })
        );
        const { data: userData } = await getUserData();
        dispatch(setUserData(userData));
        setBasketId(userData.basket_uuid);
        const { data: addressesData } = await getAddresses();
        dispatch(setUserAddresses(addressesData));
      } else {
        dispatch({ type: UserTypes.CLEAR });
        dispatch({ type: WishlistTypes.CLEAR });
        const basketId = getBasketId();
        if (basketId === "undefined" || basketId === null) {
          const favoritesItemsFromLocalStorage = getFavoritesFromLocalStorage();
          if (favoritesItemsFromLocalStorage) {
            dispatch({
              type: WishlistTypes.SET_NO_UUID,
              payload: favoritesItemsFromLocalStorage,
            });
          }
        } else {
          const { data: checkoutData } = await getCheckout(basketId);
          dispatch(setCheckout(checkoutData));
          dispatch(setWishlistUuid(checkoutData.wish_list));
        }
        removeToken();
        dispatch(
          setCurrentWorkFlow({
            userWorkFlow: "anonymous",
          })
        );
      }
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};
