import React, { useEffect, useState, useMemo, FC, useRef } from "react";
import TagManager from "react-gtm-module";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getBanners, getTopTextBanner } from "src/api";
import { MainPageBannerSlider } from "src/components";
import { MainPageMagazine } from "src/components/MainPageMagazine";
import { MainPageMagazineState } from "src/components/MainPageMagazine/MainPageMagazine.types";
import { ApplicationStore } from "src/store";
import { Banner, SeoMeta } from "src/types";
import {
  calculateTopBanners,
  generateMetaTags,
  getButtonVisibility,
} from "src/utils";

import { BannerItems } from "./BannerItems";
import { Container, Promotion, Menu } from "./Main.styles";

const MainPage: FC = () => {
  const history = useHistory();
  const seoTitle = "LEFORM — Концептуальный интернет-магазин";
  const seoDescription =
    "Leform: концептуальный интернет-магазин одежды, обуви, аксессуаров и предметов интерьера";
  const metaTags = useMemo(() => {
    const meta: SeoMeta[] = generateMetaTags(seoTitle, seoDescription);
    if (navigator.userAgent === "prerender") {
      if (history.action === "REPLACE") {
        meta.push({
          name: "prerender-status-code",
          content: "301",
        });
      }
    }
    return meta;
  }, [history.action, navigator.userAgent]);

  const { userData } = useSelector((state: ApplicationStore) => state.user);

  const [banners, setBanners] = useState<Banner[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [isTopBannerShow, setIsTopBannerShow] = useState<boolean>(false);
  const previousBannerType = useRef<MainPageMagazineState>(null);

  const mainBanners = useMemo(
    () => banners.slice(0, calculateTopBanners(banners)),
    [banners]
  );

  const otherBanners = useMemo(
    () => banners.slice(calculateTopBanners(banners)),
    [banners]
  );

  useEffect(() => {
    if (userData?.userId) {
      TagManager.dataLayer({ dataLayer: { userId: userData.userId } });
    }
  }, [userData]);

  useEffect(() => {
    getTopTextBanner()
      .then(({ data }) => {
        setIsTopBannerShow(!!data.items[0]);
      })
      .catch(() => setIsTopBannerShow(false));
  }, []);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  }, []);

  useEffect(() => {
    setLoading(true);

    getBanners()
      .then(({ data }) => {
        setBanners(data.banners);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading, setBanners]);

  const renderSliderBanner = (banner: Banner) => {
    if (banner.is_slider && banner.slides && banner.slides.length > 0) {
      return (
        <MainPageBannerSlider
          slides={banner.slides}
          key={banner.id}
          timer={banner.timer_for_slider || null}
          type={`${banner.type || "50"}%`}
        />
      );
    }
    return null;
  };

  const renderMainPageMagazine = (
    banner: Banner,
    previousBannerType: MainPageMagazineState,
    nextBannerType: MainPageMagazineState
  ) => {
    if (banner.code === "magazine-banner") {
      return (
        <MainPageMagazine
          key={banner.id}
          banner={banner}
          previousBannerType={previousBannerType}
          nextBannerType={nextBannerType}
        />
      );
    }
    return null;
  };

  const renderBannerItems = (banner: Banner, isFirst: boolean) => {
    if (banner.is_selection) {
      return <BannerItems banner={banner} isFirst={isFirst} key={banner.id} />;
    }
    return null;
  };

  const renderPromotion = (banner: Banner) => {
    if (!banner.is_selection && banner.code !== "magazine-banner") {
      return (
        <Promotion
          item={banner}
          key={banner.id}
          withoutDescription={!banner.under_buy_button_text_show}
          withButton={getButtonVisibility(banner.type, banner.show_buy_button)}
          bannerType={`${banner.type || "25"}%`}
          isTopBanner={false}
        />
      );
    }
    return null;
  };

  const getBannerType = (banner: Banner) => {
    if (banner.is_slider) {
      return "SliderBanner";
    } else if (banner.code === "magazine-banner") {
      return "MainPageMagazine";
    } else if (banner.is_selection) {
      return "BannerItems";
    } else {
      return "Promotion";
    }
  };

  return (
    <Container isTopBannerShow={isTopBannerShow}>
      <HelmetProvider>
        <Helmet defer={false} title={seoTitle} meta={metaTags} />
      </HelmetProvider>

      {!isLoading && banners.length > 0 && (
        <>
          {mainBanners.map((banner) => {
            if (
              banner.is_slider === true &&
              banner.slides &&
              banner.slides.length > 0
            ) {
              return (
                <MainPageBannerSlider
                  slides={banner.slides}
                  key={banner.id}
                  timer={banner.timer_for_slider || null}
                  type={`${banner.type || "50"}%`}
                />
              );
            } else {
              return (
                <Promotion
                  item={banner}
                  key={banner.id}
                  withoutDescription={true}
                  withButton={false}
                  bannerType={`${banner.type || "25"}%`}
                  isTopBanner={true}
                />
              );
            }
          })}
          <Menu />
          {(() => {
            const elements: JSX.Element[] = [];

            otherBanners.forEach((banner, i) => {
              let nextBannerType: MainPageMagazineState = null;

              const sliderBanner = renderSliderBanner(banner);
              if (sliderBanner) {
                elements.push(sliderBanner);
                return;
              }

              if (i < otherBanners.length - 1) {
                nextBannerType = getBannerType(otherBanners[i + 1]);
              }

              const mainPageMagazine = renderMainPageMagazine(
                banner,
                previousBannerType.current,
                nextBannerType
              );
              if (mainPageMagazine) {
                previousBannerType.current = "MainPageMagazine";
                elements.push(mainPageMagazine);
                return;
              }

              const bannerItems = renderBannerItems(banner, i === 0);
              if (bannerItems) {
                previousBannerType.current = "BannerItems";
                elements.push(bannerItems);
                return;
              }

              const promotion = renderPromotion(banner);
              if (promotion) {
                previousBannerType.current = "Promotion";
                elements.push(promotion);
                return;
              }
            });

            return elements;
          })()}
        </>
      )}
    </Container>
  );
};

export default MainPage;
