import styled from "styled-components";

import { IconButton } from "../IconButton";
import { Icons } from "../Icons";
import { Modal } from "../Modal";

export const ModalContainer = styled(Modal)`
  background-color: ${({ theme }): string => theme.colors.white};
  padding: 40px 35px;
  display: flex;
  flex-direction: column;
  width: 420px;
  right: 0;
  left: unset;

  @media (max-width: 1150px) {
    width: 100%;
    max-width: 420px;
    padding: 35px 20px;
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 48px;
  right: 38px;

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 1150px) {
    right: 20px;
  }
`;

export const CloseIcon = styled(Icons.Close)`
  width: 14px;
  height: 14px;
  stroke: white;
  stroke-width: 1px;
`;

export const ModalTitle = styled.h3`
  font-family: Formular;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin: 10px 0 29px 0;
`;

export const Subtitle = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  margin: 0 0 16px;
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin: 0 0 56px;
`;

export const Table = styled.table`
  border: none;
  border-collapse: collapse;
  overflow: hidden;
  margin-bottom: 27px;
  transition: all 0.2s linear;

  td,
  th {
    padding: 13px 0 7px;
    position: relative;
    border: 1px dashed ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.black};
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    font-size: 14px;
    line-height: 17px;

    span {
      z-index: 1;
      position: relative;
    }
  }

  thead {
    position: relative;
    z-index: 1;
    th {
      background-color: ${({ theme }) => theme.colors.black};
      font-size: 20px;
      line-height: 20px;
      font-weight: normal;
      color: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.black};
    }
  }

  tr {
    &:hover {
      background-color: rgba(196, 196, 196, 0.6);
    }
  }

  td {
    &:hover {
      &:after {
        background-color: rgba(196, 196, 196, 0.6);
        content: "";
        height: 10000px;
        left: 0;
        position: absolute;
        top: -5000px;
        width: 100%;
      }
    }
  }
`;
