import React, { FC, useCallback, useEffect, useState } from "react";

import {
  CloseButton,
  CloseIcon,
  CopyButton,
  Input,
  InputContainer,
  InputLable,
  ModalContainer,
  Title,
  Wrapper,
} from "./ShareFavoritesLink.styles";
import { ShareFavoritesLinkProps } from "./ShareFavoritesLink.types";

const ShareFavoritesLink: FC<ShareFavoritesLinkProps> = ({
  onClose,
  copyUrl,
  isMobile,
}) => {
  const [isCopied, setCopied] = useState(false);

  const handleCopy = useCallback(() => {
    if (!isCopied) {
      setCopied(true);
      navigator.clipboard.writeText(copyUrl);
    }
  }, [copyUrl]);

  useEffect(() => {
    if (isMobile) {
      navigator.share({
        title: "Поделиться избранным",
        url: copyUrl,
      });
      onClose();
    }
  }, [isMobile]);

  if (isMobile) return <></>;
  return (
    <>
      <ModalContainer onClose={onClose}>
        <Wrapper>
          <CloseButton onClick={onClose} aria-label="Закрыть">
            <CloseIcon />
          </CloseButton>
          <Title>Поделиться избранным в LEFORM</Title>
          <InputContainer>
            <Input disabled value={copyUrl} />
            <InputLable>
              Эту страницу смогут увидеть все, кто перейдёт по вашей уникальной
              ссылке.
            </InputLable>
          </InputContainer>
          <CopyButton onClick={handleCopy}>
            {!isCopied ? "Скопировать ссылку" : "Скопированно"}
          </CopyButton>
        </Wrapper>
      </ModalContainer>
    </>
  );
};

export default ShareFavoritesLink;
