import React, { FC, useState } from "react";

import { useClickOutside } from "src/hooks";

import { Selector, List, ListItem, Check, Container } from "./styles";
import { CheckoutSelectProps } from "./types";

const CheckoutSelect: FC<CheckoutSelectProps> = ({
  label,
  items,
  value,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => void setIsOpen((s) => !s);
  const ref = useClickOutside<HTMLDivElement>(() => setIsOpen(false));

  return (
    <Container ref={ref}>
      <Selector onClick={toggleOpen} isOpen={isOpen}>
        {value ? items.filter((item) => item.value === value)[0]?.text : label}
      </Selector>
      <List style={{ display: isOpen ? undefined : "none" }}>
        {items.map((item) => (
          <ListItem
            key={item.value}
            onClick={() => {
              setIsOpen(false);
              onChange?.(item.value);
            }}
          >
            {item.text}
            {item.value === value && <Check />}
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default CheckoutSelect;
