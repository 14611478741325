import React, { VFC } from "react";

import { SvgProps } from "src/types";

const LoaderIcon: VFC<SvgProps> = (props) => (
  <svg {...props} viewBox="0 0 100 100">
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="#444242"
      stroke-width="10"
      r="35"
      strokeDasharray="164.93361431346415 56.97787143782138"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        values="0 50 50;360 50 50"
        dur="0.6s"
        repeatCount="indefinite"
      ></animateTransform>
    </circle>
  </svg>
);

LoaderIcon.defaultProps = {
  width: "32px",
  height: "32px",
  fill: "black",
};

export default LoaderIcon;
