import { API } from "src/constants";
import { PersonalDataSend, UserAddress } from "src/types";

import { Http } from "../http";

import {
  AuthResponse,
  PersonalDataResponse,
  UserResponse,
  GetAddressesResponse,
  MutateAddressResponse,
  AddressResponse,
} from "./auth.types";

export const isTokenExist = (): boolean => !!localStorage.getItem("token");

export const setToken = (token: string): void => {
  localStorage.setItem("token", token);
  Http.defaults.headers["Authorization"] = `Bearer ${token}`;
};

export const loadToken = (): void => {
  const token = localStorage.getItem("token");

  if (token) {
    Http.defaults.headers["Authorization"] = `Bearer ${token}`;
  }
};

export const removeToken = (): void => {
  localStorage.removeItem("token");
  delete Http.defaults.headers["Authorization"];
};

export const refreshToken = (): AuthResponse =>
  Http.post(`${API}/auth/refresh`);

export const loginUser = (data: {
  phone: string;
  basket_uuid?: string;
  token?: string;
}): AuthResponse => Http.get(`${API}/phone_register`, { params: data });

export const registerUser = (data: {
  name: string;
  email: string;
  password: string;
  basket_uuid?: string;
}): AuthResponse =>
  Http.get(`${API}/auth/register`, {
    params: data,
  });

export const getUserData = (): UserResponse => Http.get(`${API}/auth/personal`);

export const setUserPersonalData = (
  data: PersonalDataSend
): PersonalDataResponse => Http.post(`${API}/auth/personal`, data);

export const getAddresses = (): GetAddressesResponse =>
  Http.get(`${API}/auth/addresses`);

export const addAddress = (data: Omit<UserAddress, "id">): AddressResponse =>
  Http.post(`${API}/auth/addresses`, data);

export const updateAddress = (
  id: number,
  data: Partial<UserAddress>
): AddressResponse => Http.post(`${API}/auth/addresses`, { ...data, id });

export const deleteAddress = (id: number): MutateAddressResponse =>
  Http.delete(`${API}/auth/addresses/delete`, { params: { id } });

export const phoneAuth = (data: {
  sms_code: string;
  phone: string;
  use_external?: string;
  basket_uuid?: string;
  wishlist?: string;
}): AuthResponse => Http.post(`${API}/phone_auth`, data);

export const setMainAddress = (
  user_id: number,
  address_id: number
): MutateAddressResponse =>
  Http.post(`${API}/auth/addresses/set_main`, { user_id, address_id });

export const setNewsletters = (
  userId: number,
  urlParamsStr: string
): PersonalDataResponse =>
  Http.post(`${API}/subscribe?user_id=${userId}&${urlParamsStr}`);
