import styled, { css } from "styled-components";

import { Button } from "src/components";

import { Icons } from "../Icons";

type AddressButtonProps = {
  withMargin?: boolean;
};

export const Container = styled.form`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 100%;
  justify-content: center;

  margin: 0;

  @media screen and (max-width: 768px) {
    width: auto;
  }
`;

export const ButtonGroup = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 1fr;
  grid-auto-columns: 1fr;
  grid-column-gap: 10px;
`;

export const Headline = styled.h2`
  font-size: 24px;
  line-height: 24px;
  margin: 20px 0 32px;
`;

export const FormButton = styled(Button)`
  display: flex;
  width: 237px;
  height: 50px;
  align-items: center;
  justify-content: center;
  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-weight: 450;
  font-size: 10px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 10px;
  svg {
    margin-right: 11px;
  }
  &:hover svg path {
    fill: white;
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const AddressButton = styled(Button)<AddressButtonProps>`
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  padding-left: 23px;
  border: none;
  background-color: transparent;

  ${({ withMargin }) =>
    !!withMargin &&
    css`
      margin-top: 18px;
    `};

  position: relative;

  cursor: pointer;

  color: initial;
  text-decoration: none;
  text-transform: none;

  &:hover {
    opacity: 0.5;
  }

  svg {
    margin-top: 7px;
  }
`;

export const EditButton = styled(Button)`
  background-color: transparent;
  width: auto;
  position: relative;
`;

export const EditIcon = styled(Icons.Edit)`
  width: 18px;
  height: 18px;
  padding: 2px;

  position: absolute;
  left: 0;
  top: -2px;
`;

export const ChangeAddressWrapper = styled.div`
  display: flex;
`;
