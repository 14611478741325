import React, { useCallback, useRef, FC, ChangeEvent, MouseEvent } from "react";

import {
  CheckBoxContainer,
  CustomCheckbox,
  Label,
  NativeCheckbox,
} from "./Checkbox.styles";
import { CheckboxProps } from "./Checkbox.types";

const Checkbox: FC<CheckboxProps> = (props) => {
  const {
    className,
    label,
    type = "checkbox",
    onChange,
    checked,
    name,
    ...rest
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  // Снятие флага активности при нажатии на уже выбранный элемент
  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (
        type === "radio" &&
        !!onChange &&
        checked &&
        inputRef?.current?.checked === checked
      ) {
        event.preventDefault();
        inputRef.current.value = "";
        onChange(({
          target: { type, name, value: "", checked: false },
          persist: () => {
            return null;
          },
        } as unknown) as ChangeEvent<HTMLInputElement>);
      }
    },
    [name, onChange, type, checked]
  );

  return (
    <CheckBoxContainer className={className}>
      <Label onClick={handleClick}>
        <NativeCheckbox
          type={type}
          ref={inputRef}
          onChange={onChange}
          checked={checked}
          name={name}
          {...rest}
        />
        <CustomCheckbox isRadio={type === "radio"} />
        <p>{label}</p>
      </Label>
    </CheckBoxContainer>
  );
};

export default Checkbox;
