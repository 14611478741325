import React, { useMemo, FC } from "react";
import { useLocation } from "react-router-dom";

import { useQueryParams } from "src/hooks";

import {
  Container,
  DescriptionContainer,
  DescriptionText,
  Menu,
  InfoContainerModel,
  InfoBlock,
  InfoTitle,
  InfoText,
  Span,
} from "./ProductDescription.styles";
import { ProductDescriptionProps } from "./ProductDescription.types";
import { getMenuItems, DELIVERY, BRAND } from "./ProductDescription.utils";

const ProductDescription: FC<ProductDescriptionProps> = (props) => {
  const { className, item, deliveryInfo } = props;
  const { detail_text, brand, structure, on_model } = item.product;

  const location = useLocation();
  const {
    queryParams: { tab },
  } = useQueryParams();

  const menuItems = useMemo(() => getMenuItems(location.pathname, tab), [
    location.pathname,
    tab,
  ]);

  return (
    <Container className={className}>
      <DescriptionContainer>
        <Menu items={menuItems} />

        <DescriptionText>
          {tab !== BRAND && tab !== DELIVERY && detail_text && (
            <Span
              itemProp="description"
              dangerouslySetInnerHTML={{ __html: detail_text }}
            />
          )}
          {tab === BRAND &&
            (!!brand?.detail_text ? (
              <Span dangerouslySetInnerHTML={{ __html: brand.detail_text }} />
            ) : (
              ""
            ))}
          {tab === DELIVERY &&
            (!!deliveryInfo?.detail_text ? (
              <Span
                dangerouslySetInnerHTML={{
                  __html: deliveryInfo?.detail_text,
                }}
              />
            ) : (
              ""
            ))}
        </DescriptionText>
      </DescriptionContainer>
      {(structure || on_model) && (
        <InfoContainerModel>
          {structure && (
            <InfoBlock>
              <InfoTitle>Состав:</InfoTitle>
              <InfoText>{structure}</InfoText>
            </InfoBlock>
          )}
          {on_model && (
            <InfoBlock>
              <InfoTitle>На модели:</InfoTitle>
              <InfoText>
                <span dangerouslySetInnerHTML={{ __html: on_model }} />
              </InfoText>
            </InfoBlock>
          )}
        </InfoContainerModel>
      )}
    </Container>
  );
};

export default ProductDescription;
