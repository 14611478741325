import React, { FC, useMemo } from "react";

import { BoxArrow } from "./Carousel.styles";

const CarouselNextArrow: FC = (
  props: any //  eslint-disable-line @typescript-eslint/no-explicit-any
) => {
  const { onClick } = props;
  const slideCount = props.slideCount || 0;
  const currentSlide = props.currentSlide || 0;

  const isMobileNextArrowDisabled = useMemo(() => {
    return slideCount - currentSlide;
  }, [slideCount, currentSlide]);
  return (
    <BoxArrow
      isRight={true}
      onClick={onClick}
      isMobileNextArrowDisabled={isMobileNextArrowDisabled}
    >
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.02512 2.00003L13.0502 9.02515L6.02512 16.0503"
          stroke="white"
          stroke-width="2"
        />
      </svg>
    </BoxArrow>
  );
};

export default CarouselNextArrow;
