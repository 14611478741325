import React, { FC } from "react";

import { VK, TG, ROUTES } from "src/constants";
import { ClassName } from "src/types";

import {
  Container,
  NavContainer,
  NavLink,
  SocialContainer,
  SocialLink,
  TgIcon,
  VkIcon,
} from "./MainMenu.styles";

const MainMenu: FC<ClassName> = (props) => {
  const { className } = props;

  return (
    <Container className={className}>
      <NavContainer>
        <NavLink to={ROUTES.manCatalog}>Мужское</NavLink>
        <NavLink to={ROUTES.womanCatalog}>Женское</NavLink>
      </NavContainer>

      <SocialContainer>
        Подпишитесь:
        <SocialLink
          href={VK}
          rel="noopener noreferrer"
          target="_blank"
          aria-label="Facebook"
        >
          <VkIcon />
        </SocialLink>
        <SocialLink
          href={TG}
          rel="noopener noreferrer"
          target="_blank"
          aria-label="Instagram"
        >
          <TgIcon />
        </SocialLink>
      </SocialContainer>
    </Container>
  );
};

export default MainMenu;
