import styled from "styled-components";

import { Grid } from "../Grid";

export const Container = styled(Grid)`
  width: 100%;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 85px;
  grid-row-gap: 100px;

  @media screen and (min-width: 2298px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media screen and (min-width: 2760px) {
    grid-template-columns: repeat(7, 1fr);
  }

  @media screen and (max-width: 1440px) {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 57px;
  }

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 113px;
    grid-row-gap: 79px;
  }

  @media screen and (max-width: 768px) {
    grid-column-gap: 28px;
    grid-row-gap: 77px;
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 50px;
  }
`;
