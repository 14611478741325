import styled from "styled-components";

import { Box } from "../Box";

export const Block = styled(Box)`
  &:not(:first-child) {
    margin-top: 40px;
  }
`;

export const InputContainer = styled(Box)`
  &:not(:first-child) {
    margin-top: 10px;
  }
`;
